import { TranslationString } from '@/shared/types/localization';
import { Options, OptionsTagConfig } from '@/shared/types/values';

import { CompanyUserRoleServer, UserRole } from '../user';

import { CompanyEmployeeRoleServer, CompanyEmployeeStatus } from './types';
import { CompanyEmployeeRole } from './types/client';

const T_OPTIONS = { ns: 'profile' } as const;

export const mapEmployeeRoleToLabel =
  (role: CompanyEmployeeRole): TranslationString =>
  (t) =>
    t(`userRoles.${role}`, { ns: 'user' });

export const mapEmployeeStatusToLabel =
  (type: CompanyEmployeeStatus): TranslationString =>
  (t) =>
    t(`employee.statuses.${type}`, T_OPTIONS);

export const employeeRoleOptions: Options<CompanyEmployeeRole> = [
  {
    label: mapEmployeeRoleToLabel(UserRole.clientEmployee),
    value: UserRole.clientEmployee,
  },
  {
    label: mapEmployeeRoleToLabel(UserRole.clientObserver),
    value: UserRole.clientObserver,
  },
];

export const mapEmployeeRoleToConfig: Record<CompanyEmployeeRole, OptionsTagConfig> = {
  [UserRole.clientEmployee]: {
    color: 'geekblue',
    label: mapEmployeeRoleToLabel(UserRole.clientEmployee),
  },
  [UserRole.clientObserver]: {
    color: 'magenta',
    label: mapEmployeeRoleToLabel(UserRole.clientObserver),
  },
};

export const mapCompanyEmployeeRoleToServer: Record<CompanyEmployeeRole, CompanyEmployeeRoleServer> = {
  [UserRole.clientEmployee]: CompanyUserRoleServer.clientEmployee,
  [UserRole.clientObserver]: CompanyUserRoleServer.clientObserver,
};
