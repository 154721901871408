import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { UploadOrGenerateDocument } from '@/entities/file';
import { useTypedTranslation } from '@/shared/hooks';

import { CustomerSpecificationModel } from '../../model/fieldsModel';

import CustomerSpecificationForm from './CustomerSpecificationForm';

type CustomerSpecificationProps = {
  model: CustomerSpecificationModel;
  disabled?: boolean;
};

const CustomerSpecification: React.FC<CustomerSpecificationProps> = ({ model, disabled }) => {
  const { t } = useTypedTranslation('stage');

  return (
    <UploadOrGenerateDocument
      model={model}
      disabled={disabled}
      tooltip={t('ingot.tooltip')}
      FieldsComponent={CustomerSpecificationForm}
      gridContent={false}
    />
  );
};

export default observer(CustomerSpecification);
