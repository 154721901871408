import { CompanyType } from '@/entities/company';

export const chats = {
  en: {
    header: {
      title: 'Chats',
      clients: {
        all: 'All',
        supplier: 'Suppliers',
        customer: 'Customers',
      },
      mainSearchInput: 'Find a client/trade',
      searchInput: 'Find a manager/trade',
    },
    plugs: {
      selectChat: 'Select a chat to join the discussion',
      emptyChat: 'There are no messages in this chat yet',
      emptyChatList: 'Сhat list is empty for now',
      emptyFilteredChatList: 'No chats were found based on filtering or search results, please change your query',
      emptyMessageList: 'Message list is empty for now',
      emptyTemplatesModal: 'There are currently no templates available',
    },
    chatPublic: 'General questions',
    threadPublic: 'General stream',
    ownMessagePrefix: 'You: ',
    sentTo: 'Sent to',
    generalStreamModal: {
      title: 'Choose where to send your message',
      alertText:
        'A message sent to the deal chat is automatically duplicated in the general stream. To send a message only to the general stream, check the box below',
      checkboxLabel: 'Only in the general stream',
      labelListDeal: 'Select a chat from the list',
    },
    exceedsTotalFilesSizeModal: {
      title: 'Files size exceeds 100 MB',
      alertText:
        'The message cannot be sent because the total size of the files exceeds 100 MB. Replace the files and send the message again',
    },
    messageMenu: {
      saveTemplate: 'Save as template',
    },
    tradeWorkflowModal: {
      tradeinfo: 'Trade information',
      requiredSteps: 'Current required steps',
      optionalSteps: 'Optional steps',
    },
    messageInput: {
      placeholder: 'Write your message',
      uploadTooltip: 'Upload files up to 100 MB in total',
      sendTooltip: 'Press Enter to send, Shift+Enter to go to a new line',
      templateTooltip: 'Select template',
      templatesButton: 'Templates',
    },
    chatsButtons: {
      [CompanyType.owner]: 'Go to trade chat',
      [CompanyType.supplier]: 'Chat with supplier',
      [CompanyType.customer]: 'Chat with customer',
    },
    systemMessages: {
      actions: {
        goToBid: 'Go to bid',
      },
      messages: {
        newBid: 'New bid: {{bidType}} «{{productName}}» {{difference}} {{differenceValue}}.',
        rejectedReplyToBid:
          'Your reply to bid has been rejected: {{bidType}} «{{productName}}» {{difference}} {{differenceValue}}.',
        approvedReplyToBid:
          'Your reply to bid has been approved: {{bidType}} «{{productName}}» {{difference}} {{differenceValue}}.',
      },
    },
    messages: {
      loadChatListError: 'Failed to load chat list',
      loadMessageListError: 'Failed to load message list',
      sendMessageError: 'There was an error sending your message',
      sendMessageSuccess: 'Message sent successfully',
      deleteMessageSuccess: 'Message deleted successfully',
      deleteMessageError: 'Failed to delete message',
      editMessageSuccess: 'Message edited successfully',
      editMessageError: 'Unable to edit message',
      saveMessageTemplateError: 'Failed to save message as template',
      saveMessageTemplateSuccessTitle: 'The message was successfully saved as a template',
      saveMessageTemplateSuccessText: 'You can find it in the templates section and use it in your next messages',
      editMessageTemplateError: 'Failed to edit template',
      editMessageTemplateSuccessTitle: 'Template successfully edited',
      deleteMessageTemplateError: 'An error occurred while deleting the template',
      deleteMessageTemplateSuccessTitle: 'Template deleted successfully',
    },
    createTemplateModal: {
      title: 'Create template',
      labels: {
        name: 'Name template',
        text: 'Text template',
      },
    },
    templatesModal: {
      title: 'Templates messages',
      labels: {
        name: 'Name',
        text: 'Template',
      },
      labelsEdit: {
        name: 'Edit name',
        text: 'Edit text',
      },
      searchTemplate: 'Find template',
      buttonAdd: 'Add template',
      messageEmptyList: 'There are currently no templates available',
    },
    templateDeleteModal: {
      title: 'Confirm template deletion',
      template: 'Template:',
    },
    errors: {
      template_already_exists: 'A template with this name already exists',
    },
  },
  ru: {
    header: {
      title: 'Чаты',
      clients: {
        all: 'Все',
        supplier: 'Поставщики',
        customer: 'Покупатели',
      },
      mainSearchInput: 'Найти клиента/сделку',
      searchInput: 'Найти менеджера/сделку',
    },
    plugs: {
      selectChat: 'Выберите чат, чтобы присоедениться к обсуждению',
      emptyChat: 'В этом чате пока нет сообщений',
      emptyChatList: 'Список чатов пока пуст',
      emptyFilteredChatList: 'По результатам фильтрации или поиска не было найдено чатов, поменяйте запрос',
      emptyMessageList: 'Список сообщений пока пуст',
      emptyTemplatesModal: 'На данный момент нет доступных шаблонов',
    },
    chatPublic: 'Общие вопросы',
    threadPublic: 'Общий Стрим',
    ownMessagePrefix: 'Вы: ',
    sentTo: 'Отправлено в',
    generalStreamModal: {
      title: 'Выберите, куда отправить ваше сообщение',
      alertText:
        'Сообщение, отправленное в чат сделки, автоматические дублируется в общем стриме. Чтобы отправить сообщение только в общий стрим, отметьте галочку ниже',
      checkboxLabel: 'Только в общий стрим',
      labelListDeal: 'Выберите чат из списка',
    },
    exceedsTotalFilesSizeModal: {
      title: 'Размер файлов превышает 100 MB',
      alertText:
        'Нельзя отправить сообщение, так как общий размер файлов превышает 100 MB. Замените файлы и отправьте сообщение снова',
    },
    messageMenu: {
      saveTemplate: 'Сохранить как шаблон',
    },
    tradeWorkflowModal: {
      tradeinfo: 'Информация о сделке',
      requiredSteps: 'Текущие обязательные шаги',
      optionalSteps: 'Опциональные шаги',
    },
    messageInput: {
      placeholder: 'Напишите ваше сообщение',
      uploadTooltip: 'Загрузите файлы общим объемом до 100 MB',
      sendTooltip: 'Нажмите Enter чтобы отправить, Shift+Enter чтобы перейти на новую строку',
      templateTooltip: 'Выбрать шаблон',
      templatesButton: 'Шаблоны',
    },
    chatsButtons: {
      [CompanyType.owner]: 'Перейти в чат по сделке',
      [CompanyType.supplier]: 'Чат с поставщиком',
      [CompanyType.customer]: 'Чат с покупателем',
    },
    systemMessages: {
      actions: {
        goToBid: 'Перейти к заявке',
      },
      messages: {
        newBid: 'Новая заявка: {{bidType}} «{{productName}}» {{difference}} {{differenceValue}}.',
        rejectedReplyToBid:
          'Ваш отклик на заявку был отклонен: {{bidType}} «{{productName}}» {{difference}} {{differenceValue}}.',
        approvedReplyToBid:
          'Ваш отклик на заявку был одобрен: {{bidType}} «{{productName}}» {{difference}} {{differenceValue}}.',
      },
    },
    messages: {
      loadChatListError: 'Не удалось загрузить список чатов',
      loadMessageListError: 'Не удалось загрузить список сообщений',
      sendMessageError: 'Произошла ошибка при отправке сообщения',
      sendMessageSuccess: 'Сообщение успешно отправлено',
      deleteMessageSuccess: 'Сообщение успешно удалено',
      deleteMessageError: 'Не удалось удалить сообщение',
      editMessageSuccess: 'Сообщение успешно отредактировано',
      editMessageError: 'Не удалось отредактировать сообщение',
      saveMessageTemplateError: 'Не удалось сохранить сообщение как шаблон',
      saveMessageTemplateSuccessTitle: 'Сообщение успешно сохранено как шаблон',
      saveMessageTemplateSuccessText: 'Вы можете найти его в разделе шаблонов и использовать в следующих сообщениях',
      editMessageTemplateError: 'Не удалось отредактировать шаблон',
      editMessageTemplateSuccessTitle: 'Шаблон успешно отредактирован',
      deleteMessageTemplateError: 'Произошла ошибка при удалении шаблона',
      deleteMessageTemplateSuccessTitle: 'Шаблон успешно удален',
    },
    createTemplateModal: {
      title: 'Создание шаблона',
      labels: {
        name: 'Название шаблона',
        text: 'Текст шаблона',
      },
    },
    templatesModal: {
      title: 'Шаблоны сообщений',
      labels: {
        name: 'Название',
        text: 'Шаблон',
      },
      labelsEdit: {
        name: 'Редактирование названия',
        text: 'Редактирование текста',
      },
      searchTemplate: 'Найти шаблон',
      buttonAdd: 'Добавить шаблон',
      messageEmptyList: 'На данный момент нет доступных шаблонов',
    },
    templateDeleteModal: {
      title: 'Подтвердите удаление шаблона',
      template: 'Шаблон:',
    },
    errors: {
      template_already_exists: 'Шаблон с таким названием уже существует',
    },
  },
  zh: {
    header: {
      title: '聊天',
      clients: {
        all: '所有',
        supplier: '供应商',
        customer: '买方',
      },
      mainSearchInput: '寻找客户/交易',
      searchInput: '寻找经理/交易',
    },
    plugs: {
      selectChat: '选择一个聊天室加入讨论',
      emptyChat: '此聊天中还没有消息',
      emptyChatList: '聊天列表暂时是空的',
      emptyFilteredChatList: '根据筛选或搜索结果未找到聊天记录，请更改您的查询',
      emptyMessageList: '消息列表仍然为空',
      emptyTemplatesModal: '目前没有可用的模板',
    },
    chatPublic: '共同问题',
    threadPublic: '共同直播',
    ownMessagePrefix: '您：',
    sentTo: '发送至',
    generalStreamModal: {
      title: '请选择将您的信息发送至哪里',
      alertText: '发送到交易聊天的信息会自动复制到普通信息流中。要只向共享信息流发送信息，请勾选以下复选框',
      checkboxLabel: '仅发送至主流',
      labelListDeal: '从列表中选择聊天',
    },
    exceedsTotalFilesSizeModal: {
      title: '文件大小超过 100 MB',
      alertText: '由于文件总大小超过 100 MB，您无法发送信息。替换文件并再次发送信息',
    },
    messageMenu: {
      saveTemplate: '保存为模板',
    },
    tradeWorkflowModal: {
      tradeinfo: '交易信息',
      requiredSteps: '当前必要步骤',
      optionalSteps: '可选步骤',
    },
    messageInput: {
      placeholder: '请留言',
      uploadTooltip: '上传总容量高达100 MB的文件',
      sendTooltip: '按 Enter 发送，按 Shift+Enter 移至新行',
      templateTooltip: '选择模版',
      templatesButton: '模板',
    },
    chatsButtons: {
      [CompanyType.owner]: '前往交易聊天',
      [CompanyType.supplier]: '与供应商联系',
      [CompanyType.customer]: '与客户聊天',
    },
    systemMessages: {
      actions: {
        goToBid: '转到申请',
      },
      messages: {
        newBid: '新申请：{{bidType}} “{{productName}}” {{difference}} {{differenceValue}}。',
        rejectedReplyToBid: '您的申请响应被拒绝：{{bidType}} "{{productName}}" {{difference}} {{differenceValue}}。',
        approvedReplyToBid: '您的申请响应已批准：{{bidType}} "{{productName}}" {{difference}} {{differenceValue}}。',
      },
    },
    messages: {
      loadChatListError: '加载聊天列表失败',
      loadMessageListError: '加载信息列表失败',
      sendMessageError: '发送消息时发生错误',
      sendMessageSuccess: '消息已成功发送',
      deleteMessageSuccess: '消息已成功删除',
      deleteMessageError: '无法删除消息',
      editMessageSuccess: '消息编辑成功',
      editMessageError: '消息编辑失败',
      saveMessageTemplateError: '无法将消息另存为模板',
      saveMessageTemplateSuccessTitle: '消息成功另存为模板',
      saveMessageTemplateSuccessText: '您可以在模板部分找到它并在以后的消息中使用',
      editMessageTemplateError: '无法编辑模板',
      editMessageTemplateSuccessTitle: '模板已成功编辑',
      deleteMessageTemplateError: '删除模板时发生错误',
      deleteMessageTemplateSuccessTitle: '模板已成功删除',
    },
    createTemplateModal: {
      title: '创建模板',
      labels: {
        name: '模板名称',
        text: '模板文本',
      },
    },
    templatesModal: {
      title: '消息模板',
      labels: {
        name: '名称',
        text: '模板',
      },
      labelsEdit: {
        name: '编辑名称',
        text: '编辑文本',
      },
      searchTemplate: '找到模板',
      buttonAdd: '添加模板',
      messageEmptyList: '目前没有可用的模板',
    },
    templateDeleteModal: {
      title: '确认删除模板',
      template: '模板:',
    },
    errors: {
      template_already_exists: '已存在同名模板',
    },
  },
};
