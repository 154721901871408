import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { action, autorun, computed, makeObservable, reaction } from 'mobx';

import { IDictionariesStore } from '@/entities/dictionary';
import { CustomerPaymentServer, ManagerTransactionAction } from '@/entities/stage/types';
import { ITradeWorkflowStore, TradeResponse } from '@/entities/trade';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore, ValueModel } from '@/shared/model';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { InputTextAreaModel } from '@/shared/model/form/InputTextAreaModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable } from '@/shared/types/values';
import { formatDate, numberParser } from '@/shared/utils';
import { FieldError, emptyValueValidator, numberValidator, stringLengthValidator } from '@/shared/utils/validators';

enum PaymentHandlingMode {
  add = 'add',
  edit = 'edit',
  approved = 'approved',
}

type Params = {
  id: number;
  transactionNumber: InputModel;
  date: DatePickerModel;
  price: InputModel;
  unit: SelectModel;
  priceUSD: InputModel;
  comment: InputTextAreaModel;
  tradeWorkflowStore: ITradeWorkflowStore;
  handlingMode: PaymentHandlingMode;
};

type CustomerPaymentPayload = {
  action: ManagerTransactionAction.addTransaction | ManagerTransactionAction.editTransaction;
  identifier: string;
  created_at: string;
  amount: number;
  price_unit_id: number;
  amount_usd: number;
  description: string;
};

export class CustomerPaymentModel extends LocalStore {
  readonly id: number;
  readonly transactionNumber: InputModel;
  readonly date: DatePickerModel;
  readonly price: InputModel;
  readonly unit: SelectModel;
  readonly priceUSD: InputModel;
  readonly comment: InputTextAreaModel;

  readonly approvingStage = new LoadingStageModel();
  readonly deletingStage = new LoadingStageModel();

  private readonly _handlingMode: ValueModel<PaymentHandlingMode>;
  private readonly _tradeWorkflowStore: ITradeWorkflowStore;

  protected readonly _paymentRequest: ApiRequest<TradeResponse>;

  constructor(params: Params) {
    super();

    this.id = params.id;
    this.transactionNumber = params.transactionNumber;
    this.date = params.date;
    this.price = params.price;
    this.unit = params.unit;
    this.priceUSD = params.priceUSD;
    this.comment = params.comment;

    this._handlingMode = new ValueModel(params.handlingMode);
    this._tradeWorkflowStore = params.tradeWorkflowStore;

    this._paymentRequest = apiStore.createRequest({
      multipartFormData: true,
      method: 'POST',
      url: apiUrls.trade.action(params.tradeWorkflowStore.tradeId),
    });

    makeObservable(this, {
      isAddMode: computed,
      isEditMode: computed,
      isApprovedMode: computed,
      isError: computed,
      currencyList: computed,

      validate: action.bound,
      editPayment: action.bound,
      cancelEditPayment: action.bound,
      approvePayment: action.bound,
      deletePayment: action.bound,
    });

    this.addReactions([
      // Если значений для Валют не было при инициализации,
      // то устанавливаем значения, после получения словарей
      autorun(() => {
        if (this._tradeWorkflowStore.dictionaries.loadingStage.isSuccess) {
          if (!this.unit.value) {
            const defaultCurrency =
              this.currencyList.items.find((currency) => currency.label === 'RUB') ?? this.currencyList.items[0];

            this.unit.change(defaultCurrency.value);
          }
        }
      }),
      reaction(
        () => ({ priceValue: this.price.value, priceError: this.price.error, unitError: this.unit.error }),
        ({ priceError, unitError }) => {
          if (priceError || !unitError) {
            return;
          }

          this.price.changeError(unitError);
        },
      ),
    ]);
  }

  get isAddMode(): boolean {
    return this._handlingMode.value === PaymentHandlingMode.add;
  }

  get isEditMode(): boolean {
    return this._handlingMode.value === PaymentHandlingMode.edit;
  }

  get isApprovedMode(): boolean {
    return this._handlingMode.value === PaymentHandlingMode.approved;
  }

  get isError(): boolean {
    return (
      this.transactionNumber.isError ||
      this.price.isError ||
      this.unit.isError ||
      this.comment.isError ||
      this.priceUSD.isError ||
      this.date.isError
    );
  }

  get currencyList(): IDictionariesStore['currencies']['list'] {
    return this._tradeWorkflowStore.dictionaries.currencies.list;
  }

  editPayment(): void {
    this._handlingMode.change(PaymentHandlingMode.edit);
  }

  cancelEditPayment(): void {
    this._handlingMode.change(PaymentHandlingMode.approved);
  }

  validate(): boolean {
    this.transactionNumber.validate();
    this.date.validate();
    this.price.validate();
    this.unit.validate();
    this.priceUSD.validate();
    this.comment.validate();

    return this.isError;
  }

  async approvePayment(): Promise<BaseResponse> {
    if (this.validate()) {
      return { isError: true };
    }

    const payload = this._toJson();

    if (!payload) {
      return { isError: true };
    }

    this.approvingStage.loading();

    const response = await this._paymentRequest.call({
      data: payload,
    });

    if (response.isError) {
      this.approvingStage.error();

      return { isError: true };
    }

    this.approvingStage.success();
    this._tradeWorkflowStore.updateTradeWorkflow(response.data);

    return { isError: false };
  }

  async deletePayment(): Promise<BaseResponse> {
    this.deletingStage.loading();

    const response = await this._paymentRequest.call({
      data: {
        action: ManagerTransactionAction.deleteTransaction,
        id: this.id,
      },
    });

    if (response.isError) {
      this.deletingStage.error();

      return { isError: true };
    }

    this.deletingStage.success();
    this._tradeWorkflowStore.updateTradeWorkflow(response.data);

    return { isError: false };
  }

  private _toJson(): Nullable<CustomerPaymentPayload> {
    if (this.isApprovedMode) {
      return null;
    }

    const payload: Partial<CustomerPaymentPayload> = {
      action: this.isAddMode ? ManagerTransactionAction.addTransaction : ManagerTransactionAction.editTransaction,
      ...(this.isEditMode ? { id: this.id } : {}),
      ...(this.transactionNumber.value ? { identifier: this.transactionNumber.value } : {}),
      ...(this.date.value ? { created_at: formatDate(this.date.value) } : {}),
      ...(this.price.value ? { amount: Number(this.price.value) } : {}),
      ...(this.unit.value ? { price_unit_id: Number(this.unit.value) } : {}),
      ...(this.priceUSD.value ? { amount_usd: Number(this.priceUSD.value) } : {}),
      ...(this.comment.value ? { description: this.comment.value } : {}),
    };

    const payloadKeys = Object.keys(payload) as (keyof CustomerPaymentPayload)[];

    if (payloadKeys.some((key) => !payload[key])) {
      return null;
    }

    return payload as CustomerPaymentPayload;
  }

  static fromJson({
    id,
    payment,
    tradeWorkflowStore,
  }: {
    id: number;
    payment?: CustomerPaymentServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): CustomerPaymentModel {
    return new CustomerPaymentModel({
      id,
      transactionNumber: new InputModel({
        initialValue: payment?.identifier ?? '',
        placeholder: (t) => t('manager.customerPayments.placeholders.number', { ns: 'finalizationStage' }),
        validators: [emptyValueValidator(), stringLengthValidator(16)],
        required: true,
      }),
      date: new DatePickerModel({
        initialValue: payment?.created_at ? new Date(payment.created_at) : null,
        placeholder: (t) => t('manager.customerPayments.placeholders.date', { ns: 'finalizationStage' }),
        validators: [emptyValueValidator()],
        required: true,
      }),
      price: new InputModel({
        initialValue: payment?.amount ? String(payment.amount) : '',
        placeholder: (t) => t('manager.customerPayments.placeholders.price', { ns: 'finalizationStage' }),
        parser: numberParser(),
        validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
        required: true,
      }),
      unit: new SelectModel({
        initialValue: payment?.price_unit.id ?? null,
        validators: [emptyValueValidator(FieldError.paymentUnit)],
        required: true,
      }),
      priceUSD: new InputModel({
        initialValue: payment?.amount_usd ? String(payment.amount_usd) : '',
        placeholder: (t) => t('manager.customerPayments.placeholders.priceUSD', { ns: 'finalizationStage' }),
        parser: numberParser(),
        validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
        required: true,
      }),
      comment: new InputTextAreaModel({
        initialValue: payment?.description ?? '',
        placeholder: (t) => t('manager.customerPayments.placeholders.comment', { ns: 'finalizationStage' }),
        validators: [stringLengthValidator(512)],
        maxRows: 2,
      }),
      handlingMode: payment ? PaymentHandlingMode.approved : PaymentHandlingMode.add,
      tradeWorkflowStore,
    });
  }
}
