import { ITradeWorkflowStore } from '@/entities/trade';

import { BaseStepModel } from '../BaseStepModel';

enum ManagerTransferStepAction {
  transfer = 'transfer',
}

export class ManagerTransferStepModel extends BaseStepModel<ManagerTransferStepAction, ManagerTransferStepAction> {
  constructor(tradeWorkflowStore: ITradeWorkflowStore) {
    super({
      step: ManagerTransferStepAction.transfer,
      approveAction: ManagerTransferStepAction.transfer,
      tradeWorkflowStore,
    });
  }

  static fromJson(tradeWorkflowStore: ITradeWorkflowStore): ManagerTransferStepModel {
    return new ManagerTransferStepModel(tradeWorkflowStore);
  }
}
