import { action, computed, makeObservable } from 'mobx';

import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';
import { numberAndLetterParser, numberParser } from '@/shared/utils';
import { emptyValueValidator, numberValidator, stringLengthValidator } from '@/shared/utils/validators';

const T_OPTIONS = { ns: 'stage' } as const;

export type IngotModelParams = {
  ingotNumber: string;
  weight: string;
  ligatureWeight: string;
  metalPurity: string;
  year: Nullable<Date>;
  uin: string;
};

export class IngotModel {
  readonly ingotNumber: InputModel;
  readonly weight: InputModel;
  readonly ligatureWeight: InputModel;
  readonly metalPurity: InputModel;
  readonly year: DatePickerModel;
  readonly uin: InputModel;

  constructor(params: IngotModelParams) {
    this.ingotNumber = new InputModel({
      initialValue: params.ingotNumber,
      label: (t) => t('ingot.ingotNumber', T_OPTIONS),
      placeholder: (t) => t('ingot.ingotNumberPlaceholder', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), stringLengthValidator(16)],
      parser: numberAndLetterParser,
    });
    this.weight = new InputModel({
      initialValue: params.weight,
      label: (t) => t('ingot.weight', T_OPTIONS),
      placeholder: (t) => t('ingot.weightPlaceholder', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      parser: numberParser({ maxFractionDigits: 3 }),
    });
    this.ligatureWeight = new InputModel({
      initialValue: params.ligatureWeight,
      label: (t) => t('ingot.ligatureWeight', T_OPTIONS),
      placeholder: (t) => t('ingot.weightPlaceholder', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      parser: numberParser({ maxFractionDigits: 3 }),
    });
    this.metalPurity = new InputModel({
      initialValue: params.metalPurity,
      label: (t) => t('ingot.metalPurity', T_OPTIONS),
      placeholder: (t) => t('ingot.metalPurityPlaceholder', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      parser: numberParser(),
    });
    this.year = new DatePickerModel({
      initialValue: params.year,
      label: (t) => t('ingot.year', T_OPTIONS),
      placeholder: (t) => t('ingot.year', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator()],
      ignoreOnBlurValidation: true,
    });
    this.uin = new InputModel({
      initialValue: params.uin,
      label: (t) => t('ingot.uin', T_OPTIONS),
      placeholder: (t) => t('ingot.uin', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
      parser: numberParser({ maxFractionDigits: 0 }),
    });

    makeObservable(this, {
      fields: computed,

      reset: action.bound,
    });
  }

  get fields(): Array<BaseFieldModel<any>> {
    return [];
  }

  reset(): void {
    this.fields.forEach((field) => field.reset());
  }
}
