import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { computed, makeObservable } from 'mobx';

import { ClientCompanyType } from '@/entities/company';
import { BaseUserModel, BaseUserModelParams, mapCompanyUserRoleServerToUserRole, UserModel } from '@/entities/user';
import { apiStore, apiUrls } from '@/shared/api';
import { ValueModel } from '@/shared/model';

import { CompanyEmployeeRole, CompanyEmployeeStatus, EmployeeServer, IEmployee } from '../types';

type Params = BaseUserModelParams & {
  type: ClientCompanyType;
  role: CompanyEmployeeRole;
  status: CompanyEmployeeStatus;
};

export class EmployeeModel extends UserModel<ClientCompanyType, CompanyEmployeeRole> implements IEmployee {
  private readonly _status: ValueModel<CompanyEmployeeStatus>;
  private readonly _blockRequest: ApiRequest<EmployeeServer>;

  constructor(params: Params) {
    super(params);

    this._status = new ValueModel<CompanyEmployeeStatus>(params.status);
    this._blockRequest = apiStore.createRequest<EmployeeServer>({
      method: 'PATCH',
      url: apiUrls.profile.employee.edit(this.id),
    });

    makeObservable(this, {
      status: computed,
    });
  }

  get status(): CompanyEmployeeStatus {
    return this._status.value;
  }

  async blockHandling(): Promise<BaseResponse> {
    const response = await this._blockRequest.call({
      data: {
        is_blocked: this.status !== CompanyEmployeeStatus.blocked,
      },
    });

    if (response.isError) {
      return { isError: true };
    }

    this._status.change(response.data.status);

    return response;
  }

  static fromJson({ data, companyType }: { data: EmployeeServer; companyType: ClientCompanyType }): EmployeeModel {
    return new EmployeeModel({
      ...BaseUserModel.baseUserParamsFromJson(data),
      type: companyType,
      role: mapCompanyUserRoleServerToUserRole[data.role],
      status: data.status,
    });
  }
}
