import { when } from 'mobx';

import { routerUrls } from '@/shared/config/routes';

import { IPresentedElement } from '../../types';
import { PresentedElementModel } from '../PresentedElementModel';

import { BasePageModel, BasePageModelParams } from './BasePageModel';

const T_OPTIONS = { ns: 'onboarding' } as const;

export class BidsPageModel extends BasePageModel {
  readonly createButton = new PresentedElementModel<HTMLButtonElement>({
    title: (t) => t('bids.createButton.title', T_OPTIONS),
    description: (t) => t('bids.createButton.description', T_OPTIONS),
    afterActionForward: async () => {
      await when(() => Boolean(this.createButton.node));
      this.createButton.node?.click();
    },
  });

  readonly applyCreateModal = new PresentedElementModel<HTMLButtonElement>({
    title: (t) => t('bids.applyCreateModal.title', T_OPTIONS),
    description: (t) => t('bids.applyCreateModal.description', T_OPTIONS),
  });

  readonly replyButton = new PresentedElementModel<HTMLButtonElement>({
    title: (t) => t('bids.replyButton.title', T_OPTIONS),
    description: (t) => t('bids.replyButton.description', T_OPTIONS),
    afterActionForward: async () => {
      await when(() => Boolean(this.replyButton.node));
      this.replyButton.node?.click();
    },
    afterActionBackward: async () => {
      await when(() => Boolean(this.createButton.node));
      this.createButton.node?.click();
    },
  });

  readonly discount = new PresentedElementModel({
    title: (t) => t('bids.discount.title', T_OPTIONS),
    description: (t) => t('bids.discount.description', T_OPTIONS),
  });

  readonly myReplies = new PresentedElementModel({
    title: (t) => t('bids.myReplies.title', T_OPTIONS),
    description: (t) => t('bids.myReplies.description', T_OPTIONS),
    beforeAction: () => this._rootStore.routerStore.navigate(routerUrls.bids.children.reply.create()),
    afterActionBackward: async () => {
      const { userStore, routerStore } = this._rootStore;

      if (userStore.isClientObserver) {
        return;
      } else {
        routerStore.navigate(routerUrls.bids.create());

        await when(() => Boolean(this.replyButton.node));
        this.replyButton.node?.click();
      }
    },
  });

  readonly myBids = new PresentedElementModel({
    title: (t) => t('bids.myBids.title', T_OPTIONS),
    description: (t) => t('bids.myBids.description', T_OPTIONS),
    beforeAction: () => this._rootStore.routerStore.navigate(routerUrls.bids.children.my.create()),
  });

  constructor(params: BasePageModelParams) {
    super({
      ...params,
      routeKey: 'bids',
      url: routerUrls.bids.create(),
    });
  }

  get elements(): Array<IPresentedElement> {
    const { isClientObserver } = this._rootStore.userStore;

    if (isClientObserver) {
      return [this.myReplies, this.myBids] as Array<IPresentedElement>;
    }

    return [
      this.createButton,
      this.applyCreateModal,
      this.replyButton,
      this.discount,
      this.myReplies,
      this.myBids,
    ] as Array<IPresentedElement>;
  }
}
