import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { FormRadioGroup } from '@/shared/ui/form/FormRadioGroup';
import { FormSelect } from '@/shared/ui/form/FormSelect';

import { CustomerSpecificationFieldsModel } from '../../model/fieldsModel/CustomerSpecificationModel';
import HandlingIngotsTable from '../HandlingIngotsTable';

import ExpandIngotList from './ExpandIngotList';

type CustomerSpecificationFormProps = {
  model: CustomerSpecificationFieldsModel;
};

const CustomerSpecificationForm: React.FC<CustomerSpecificationFormProps> = ({ model }) => {
  const { dictionariesStore } = useRootStore();

  const { signatureId, yearOrUin, loadingState, ingots } = model;

  return (
    <Flex vertical gap={12}>
      <FormSelect
        model={signatureId}
        options={dictionariesStore.signatories.list.items}
        disabled={loadingState.isLoading}
      />
      <FormRadioGroup model={yearOrUin} />
      <HandlingIngotsTable model={ingots} mobileRender={<ExpandIngotList model={ingots} />} />
    </Flex>
  );
};

export default observer(CustomerSpecificationForm);
