import { ITradeWorkflowStore } from '@/entities/trade';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { Nullable } from '@/shared/types/values';

import { WaitingStepModelParams } from '../WaitingStepModel/WaitingStepModel';

type Params<Step> = WaitingStepModelParams<Step> & {
  date: DatePickerModel;
};

export class WaitingDateStepModel<Step> {
  readonly step: Step;
  readonly tradeWorkflowStore: ITradeWorkflowStore;
  readonly date: DatePickerModel;

  constructor({ step, tradeWorkflowStore, date }: Params<Step>) {
    this.step = step;
    this.tradeWorkflowStore = tradeWorkflowStore;
    this.date = date;
  }

  static fromJson<Step>({
    data,
    ...params
  }: { data?: Nullable<Date> } & WaitingStepModelParams<Step>): WaitingDateStepModel<Step> {
    return new WaitingDateStepModel({
      date: new DatePickerModel({
        initialValue: data || null,
      }),
      ...params,
    });
  }
}
