import { computed, makeObservable } from 'mobx';

import { CompanyType } from '@/entities/company';
import {
  AsyncFilesUploadModel,
  DocumentFileType,
  DocumentServer,
  DocumentsServer,
  DocumentsWithStatusServer,
  DocumentWithStatusServer,
} from '@/entities/file';
import { mapUserServerToCompanyType } from '@/entities/user';
import { UploadFileModel } from '@/shared/model';
import { CheckStatus } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { BaseStepModel, BaseStepModelParams } from '../BaseStepModel';

export type UploadFilesStepModelParams<Step, Action, DocType extends DocumentFileType> = BaseStepModelParams<
  Step,
  Action
> & {
  files: AsyncFilesUploadModel<DocType>;
  status: Nullable<CheckStatus>;
  error: Nullable<string>;
  uploadedBy: Nullable<CompanyType>;
};

type ServerPayload<Action> = {
  action: Action;
};

export class UploadFilesStepModel<
  Step,
  Action,
  DocType extends DocumentFileType,
  Payload extends ServerPayload<Action> = ServerPayload<Action>,
> extends BaseStepModel<Step, Action, Payload> {
  readonly files: AsyncFilesUploadModel<DocType>;

  readonly status: Nullable<CheckStatus>;
  readonly error: Nullable<string>;
  readonly uploadedBy: Nullable<CompanyType>;

  constructor({ files, status, error, uploadedBy, ...params }: UploadFilesStepModelParams<Step, Action, DocType>) {
    super(params);

    this.files = files;
    this.status = status;
    this.error = error;
    this.uploadedBy = uploadedBy;

    makeObservable(this, {
      isNeedVerifying: computed,
      isWaiting: computed,
      isApproved: computed,
      isRejected: computed,
      approveDisabled: computed,
    });
  }

  private get _isSupplierDocument(): boolean {
    return this.uploadedBy === CompanyType.supplier;
  }

  get isNeedVerifying(): boolean {
    const { isOwner } = this.tradeWorkflowStore.rootStore.userStore;

    return this._isSupplierDocument && isOwner;
  }

  get isWaiting(): boolean {
    return this.status === CheckStatus.waiting;
  }

  get isApproved(): boolean {
    return this.status === CheckStatus.approved;
  }

  get isRejected(): boolean {
    return this.status === CheckStatus.rejected;
  }

  get approveDisabled(): boolean {
    return !this.files.isUploaded || this.files.isError;
  }

  static fromJson<Step, Action, DocType extends DocumentFileType>({
    data,
    docType,
    maxCount,
    ...params
  }: {
    data: Nullable<DocumentServer | DocumentWithStatusServer | DocumentsServer | DocumentsWithStatusServer>;
    docType: DocType;
    maxCount?: number;
  } & BaseStepModelParams<Step, Action>): UploadFilesStepModel<Step, Action, DocType> {
    const documents = data && 'documents' in data ? data.documents : null;
    const document = data && 'document' in data ? data.document : null;

    return new UploadFilesStepModel({
      files: new AsyncFilesUploadModel<DocType>({
        initialValue: UploadFileModel.fileListFromJson(documents ?? document),
        required: true,
        docType,
        maxCount,
        tradeWorkflowStore: params.tradeWorkflowStore,
      }),
      status: data && 'status' in data ? data.status : null,
      error: data && 'error_message' in data ? data.error_message : null,
      uploadedBy: document && mapUserServerToCompanyType(document.uploaded_by),
      ...params,
    });
  }
}
