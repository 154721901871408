import { ManagerShipmentStep } from '@/entities/stage/types';

export const stepsOrder: Array<ManagerShipmentStep> = [
  ManagerShipmentStep.supplierSpecification,
  ManagerShipmentStep.supplierSpecificationVerifying,
  ManagerShipmentStep.shipmentType,
  ManagerShipmentStep.transitCountry,
  ManagerShipmentStep.transitCountryAwb,
  ManagerShipmentStep.transitCountryAwbVerifying,
  ManagerShipmentStep.destinationCountryDocs,
  ManagerShipmentStep.destinationCountryAwb,
  ManagerShipmentStep.cargoShipment,
  ManagerShipmentStep.transfer,
];
