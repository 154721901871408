import { BaseResponse } from '@kts-front/types';
import { autorun, computed, makeObservable, reaction } from 'mobx';

import { IDictionariesStore, IDictionary } from '@/entities/dictionary';
import {
  DocumentFileType,
  FileModel,
  GenerationDocumentLanguage,
  GenerationFieldsModel,
  SelectGenerationDocumentLanguageModel,
  UploadOrGenerateDocumentModelParams,
} from '@/entities/file';
import { BasePaymentBillDocPayload, FullPaymentBillDocPayload, PaymentBillDocResponse } from '@/entities/stage/types';
import { ValueModel } from '@/shared/model';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { InputTextAreaModel } from '@/shared/model/form/InputTextAreaModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { UploadFilesModel } from '@/shared/model/form/UploadFilesModel';
import { isNullable } from '@/shared/types/typesGuard';
import { Nullable, Options } from '@/shared/types/values';
import { convertGramPriceToOuncePrice, formatDate, numberAndLetterParser, numberParser } from '@/shared/utils';
import {
  AcceptFileFormats,
  emptyValueValidator,
  numberValidator,
  stringLengthValidator,
} from '@/shared/utils/validators';

const T_OPTIONS = { ns: 'stage' } as const;

export class PaymentBillFieldsModel extends GenerationFieldsModel<
  DocumentFileType.paymentBill,
  BasePaymentBillDocPayload | FullPaymentBillDocPayload
> {
  readonly invoiceNumber = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.invoiceNumber', T_OPTIONS),
    placeholder: (t) => t('paymentBill.invoiceNumber', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(16)],
    parser: numberAndLetterParser,
  });
  readonly invoiceDate = new DatePickerModel({
    initialValue: new Date(Date.now()),
    label: (t) => t('paymentBill.invoiceDate', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly departureCountryId = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('paymentBill.departureCountry', T_OPTIONS),
  });
  readonly destinationCountryId = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('paymentBill.destinationCountry', T_OPTIONS),
  });
  readonly manufacturer = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.manufacturer', T_OPTIONS),
    placeholder: (t) => t('paymentBill.manufacturerPlaceholder', T_OPTIONS),
    validators: [stringLengthValidator(128)],
  });
  readonly additionalInfo = new InputTextAreaModel({
    initialValue: '',
    label: (t) => t('paymentBill.additionalInfo', T_OPTIONS),
    validators: [stringLengthValidator(512)],
  });
  readonly supplierInitials = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.supplierInitials', T_OPTIONS),
    placeholder: (t) => t('paymentBill.supplierInitialsPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(128)],
  });
  readonly supplierPosition = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.supplierPosition', T_OPTIONS),
    placeholder: (t) => t('paymentBill.supplierPositionPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(128)],
  });
  readonly sealAndSignature = new UploadFilesModel({
    initialValue: [],
    label: (t) => t('paymentBill.sealAndSignature', T_OPTIONS),
    acceptFileFormats: [AcceptFileFormats.png, AcceptFileFormats.jpg, AcceptFileFormats.jpeg],
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly documentLanguage = new SelectGenerationDocumentLanguageModel({
    initialValue: null,
    documentLanguages: [GenerationDocumentLanguage.Ru, GenerationDocumentLanguage.En],
  });

  readonly countIngot = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.countIngot', T_OPTIONS),
    placeholder: (t) => t('paymentBill.countIngotPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser({ maxFractionDigits: 0 }),
  });
  readonly exchangeRate = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.exchangeRate', T_OPTIONS),
    placeholder: (t) => t('paymentBill.exchangeRatePlaceholder', T_OPTIONS),
    validators: [numberValidator, stringLengthValidator(16)],
    parser: numberParser(),
  });
  readonly currencyId = new SelectModel<number>({
    initialValue: null,
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly gramPrice = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.gramPrice', T_OPTIONS),
    placeholder: (t) => t('paymentBill.placeholderPrice', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser(),
  });
  readonly ouncePrice = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.ouncePrice', T_OPTIONS),
    placeholder: (t) => t('paymentBill.placeholderPrice', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser(),
  });
  readonly consignee = new InputModel({
    initialValue: '',
    label: (t) => t('paymentBill.consignee', T_OPTIONS),
    placeholder: (t) => t('paymentBill.consignee', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(128)],
  });

  private readonly _deliveryConfirmationGenerated = new ValueModel(false);
  private readonly _needConsignee = new ValueModel(false);

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.paymentBill>) {
    super({
      ...params,
      multipartFormData: true,
    });

    makeObservable(this, {
      needConsignee: computed,
      deliveryConfirmationGenerated: computed,
      currencyOptions: computed,
    });

    this.addReactions([
      autorun(() => {
        if (this._rubCurrency) {
          this.currencyId.change(this._rubCurrency.value);
        }
      }),
      reaction(
        () => this.gramPrice.value,
        (gramPrice) => {
          if (gramPrice) {
            const value = convertGramPriceToOuncePrice(Number(gramPrice));
            this.ouncePrice.change(value.toString());
          } else {
            this.ouncePrice.change('');
          }
        },
      ),
      reaction(
        () => (this.currencyId.value ? this._dictionaries.currencies.list.getEntity(this.currencyId.value) : null),
        (currency) => {
          this.exchangeRate.reset();

          if (currency && currency.label === 'USD') {
            this.exchangeRate.changeRequired(true);
            this.exchangeRate.changeValidators([emptyValueValidator(), numberValidator, stringLengthValidator(16)]);
          } else {
            this.exchangeRate.changeRequired(false);
            this.exchangeRate.changeValidators([numberValidator, stringLengthValidator(16)]);
          }
        },
      ),
    ]);
  }

  private get _dictionaries(): IDictionariesStore {
    return this._tradeWorkflowStore.dictionaries;
  }

  private get _rubCurrency(): Nullable<IDictionary> {
    return this._dictionaries.currencies.list.items.find((currency) => currency.label === 'RUB') ?? null;
  }

  get currencyOptions(): Options<number> {
    return this._dictionaries.currencies.list.items.filter(
      (currency) => currency.label === 'RUB' || currency.label === 'USD',
    );
  }

  get deliveryConfirmationGenerated(): boolean {
    return this._deliveryConfirmationGenerated.value;
  }

  get needConsignee(): boolean {
    return this._needConsignee.value;
  }

  get fields(): Array<BaseFieldModel<any>> {
    const fields = [
      this.invoiceNumber,
      this.invoiceDate,
      this.departureCountryId,
      this.destinationCountryId,
      this.manufacturer,
      this.additionalInfo,
      this.supplierInitials,
      this.supplierPosition,
      this.documentLanguage,
      this.sealAndSignature,
    ];

    if (!this.deliveryConfirmationGenerated) {
      fields.push(this.countIngot, this.currencyId, this.gramPrice, this.ouncePrice, this.exchangeRate);
    }

    if (this.needConsignee) {
      fields.push(this.consignee);
    }

    return fields;
  }

  toJson(): Nullable<BasePaymentBillDocPayload | FullPaymentBillDocPayload> {
    return this.deliveryConfirmationGenerated ? this._toBaseJson() : this._toFullJson();
  }

  async loadFieldsData(): Promise<BaseResponse> {
    if (this.loadingState.isLoading) {
      this._fieldsDataRequest.cancel();
    }

    this.loadingState.loading();

    const response = await this._fieldsDataRequest.call<PaymentBillDocResponse>();

    if (response.isError) {
      this.loadingState.error();
    } else {
      this._fillFieldsByResponseData(response.data);
      this.loadingState.success();
    }

    return response;
  }

  protected readonly _fillFieldsByResponseData = (data: PaymentBillDocResponse): void => {
    this._deliveryConfirmationGenerated.change(data.delivery_confirmation_generated);
    this._needConsignee.change(data.need_consignee);

    this.invoiceNumber.change(data.invoice_number ?? '');
    this.invoiceDate.change(data.invoice_date ? new Date(data.invoice_date) : null);
    this.departureCountryId.change(data.departure_country_id ?? null);
    this.destinationCountryId.change(data.destination_country_id ?? null);
    this.manufacturer.change(data.manufacturer ?? '');
    this.additionalInfo.change(data.additional_info ?? '');
    this.countIngot.change(data.number_of_ingots?.toString() ?? '');
    this.gramPrice.change(data.price_per_gram ?? '');
    this.ouncePrice.change(data.price_per_ounce ?? '');
    this.currencyId.change(data.currency_id ?? this._rubCurrency?.value ?? null);
    this.exchangeRate.change(data.exchange_rate?.toString() ?? '');
    this.supplierInitials.change(data.supplier_initials ?? '');
    this.documentLanguage.change(data.language ?? null);
    this.sealAndSignature.change(FileModel.fileListFromJson(data.signature_with_seal));
  };

  private readonly _toBaseJson = (): Nullable<BasePaymentBillDocPayload> => {
    const sealAndSignature = this.sealAndSignature.value.at(0);
    const consignee = this.consignee.value;

    if (
      !this.invoiceNumber.value ||
      !this.invoiceDate.value ||
      !this.supplierInitials.value ||
      !this.supplierPosition.value ||
      !this.documentLanguage.value ||
      !sealAndSignature ||
      (this.needConsignee && !consignee)
    ) {
      return null;
    }

    const departure_country_id = this.departureCountryId.value;
    const destination_country_id = this.destinationCountryId.value;
    const manufacturer = this.manufacturer.value;
    const additional_info = this.additionalInfo.value;

    return {
      invoice_number: this.invoiceNumber.value,
      invoice_date: formatDate(this.invoiceDate.value),
      language: this.documentLanguage.value,
      signature_with_seal: sealAndSignature.originFileObj ?? sealAndSignature.uid,
      supplier_initials: this.supplierInitials.value,
      seller_type: this.supplierPosition.value,
      ...(departure_country_id ? { departure_country_id } : {}),
      ...(destination_country_id ? { destination_country_id } : {}),
      ...(manufacturer ? { manufacturer } : {}),
      ...(additional_info ? { additional_info } : {}),
      ...(consignee ? { consignee } : {}),
    };
  };

  private readonly _toFullJson = (): Nullable<FullPaymentBillDocPayload> => {
    const baseJson = this._toBaseJson();

    if (
      isNullable(baseJson) ||
      !this.currencyId.value ||
      !this.gramPrice.value ||
      !this.ouncePrice.value ||
      !this.countIngot.value
    ) {
      return null;
    }

    const exchange_rate = this.exchangeRate.value;

    return {
      ...baseJson,
      ...(exchange_rate ? { exchange_rate: Number(exchange_rate) } : {}),
      number_of_ingots: Number(this.countIngot.value),
      currency_id: this.currencyId.value,
      price_per_gram: this.gramPrice.value,
      price_per_ounce: this.ouncePrice.value,
    };
  };
}
