import { ITradeWorkflowStore } from '@/entities/trade';

export type WaitingStepModelParams<Step> = {
  step: Step;
  tradeWorkflowStore: ITradeWorkflowStore;
};

export class WaitingStepModel<Step> {
  readonly step: Step;
  readonly tradeWorkflowStore: ITradeWorkflowStore;

  constructor({ step, tradeWorkflowStore }: WaitingStepModelParams<Step>) {
    this.step = step;
    this.tradeWorkflowStore = tradeWorkflowStore;
  }

  static fromJson<Step>(params: WaitingStepModelParams<Step>) {
    return new WaitingStepModel(params);
  }
}
