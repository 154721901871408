import * as React from 'react';
import { DotProps } from 'recharts';

import { themeConfig } from '@/shared/config/themeConfig';
import { isNullable } from '@/shared/types/typesGuard';

const ActiveDot: React.FC<DotProps> = (props) => {
  const { cx, cy } = props;

  if (isNullable(cx) || isNullable(cy)) {
    return null;
  }

  return (
    <svg
      x={cx - 6}
      y={cy - 6}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="5.5" fill={themeConfig.token.colorWhite} />
      <circle cx="6" cy="6" r="5" stroke={themeConfig.token.colorTextPlaceholder} />
    </svg>
  );
};

export default React.memo(ActiveDot);
