// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useTranslation, UseTranslationOptions } from 'react-i18next';

import { NameSpace } from '@/app/locales/types';

import { TranslationString, TypedTranslationResponse } from '../types/localization';
import { renderTranslationString } from '../utils';

export const useTypedTranslation = <NE extends NameSpace | undefined = undefined>(
  ns?: NE,
  options?: UseTranslationOptions<''>,
): TypedTranslationResponse<NE> => {
  const base = useTranslation(ns, options);

  // @ts-ignore
  return {
    ...base,
    lng: base.i18n.language,
  } as TypedTranslationResponse<NE>;
};

export const useTranslationString = (str: TranslationString): string => {
  const { t } = useTypedTranslation();

  return renderTranslationString(str, t);
};
