import { bids } from './bids';
import { chats } from './chats';
import { notifications } from './notifications';
import { profile } from './profile';
import { trades } from './trades';

export const onboarding = {
  en: {
    buttons: {
      skip: 'Skip',
      prevStep: 'Back',
      nextStep: 'Next step',
      flowEnd: 'End',
      startFromBeginning: 'Start onboarding from the beginning',
      startFromCurrentPage: 'Onboarding from this page',
    },
    profile: profile.en,
    notifications: notifications.en,
    bids: bids.en,
    trades: trades.en,
    chats: chats.en,
  },
  ru: {
    buttons: {
      skip: 'Пропустить',
      prevStep: 'Назад',
      nextStep: 'Следующий шаг',
      flowEnd: 'Готово',
      startFromBeginning: 'Пройти онбординг сначала',
      startFromCurrentPage: 'Онбординг с этой страницы',
    },
    profile: profile.ru,
    notifications: notifications.ru,
    bids: bids.ru,
    trades: trades.ru,
    chats: chats.ru,
  },
  zh: {
    buttons: {
      skip: null,
      prevStep: null,
      nextStep: null,
      flowEnd: null,
      startFromBeginning: null,
      startFromCurrentPage: null,
    },
    profile: profile.zh,
    notifications: notifications.zh,
    bids: bids.zh,
    trades: trades.zh,
    chats: chats.zh,
  },
};
