import { computed, makeObservable } from 'mobx';

import { CompanyType } from '@/entities/company';
import { DocumentFileType, UploadOrGenerateDocumentModel } from '@/entities/file';
import {
  AwbDirectAction,
  AwbTransitAction,
  ManagerAwbVerifyingAction,
  ManagerShipmentAction,
  ManagerShipmentStageDataServer,
  ManagerShipmentStep,
  ManagerSpecificationVerifyingAction,
  OptionFieldsAction,
  ShipmentType,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/entities/stage/utils';
import { ToggleModel } from '@/shared/model';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import {
  ConfirmationStepModel,
  DestinationCountryDocumentsStepModel,
  DocumentVerifyingStepModel,
  SupplierSpecificationModel,
  UploadAwbOrFlightNumberStepModel,
  UploadOrGenerateDocumentStepModel,
} from '../../fieldsModel';
import { ShipmentTypeFieldsModel } from '../fieldsModel/ShipmentTypeFieldsModel';
import { TransitCountryFieldsModel } from '../fieldsModel/TransitCountryFieldsModel';

import { stepsOrder } from './config';

type Params = BaseManagerStageModelParams<ManagerShipmentStep> & {
  supplierSpecificationStep: UploadOrGenerateDocumentStepModel<
    ManagerShipmentStep.supplierSpecification,
    ManagerShipmentAction,
    DocumentFileType.supplierSpecification,
    SupplierSpecificationModel
  >;
  supplierSpecificationVerifyingStep: DocumentVerifyingStepModel<ManagerShipmentStep, ManagerShipmentAction>;
  shipmentTypeStep: ShipmentTypeFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  transitCountryStep: TransitCountryFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  transitCountryAwbStep: UploadAwbOrFlightNumberStepModel<
    ManagerShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber,
    DocumentFileType.awbForTransitCountry
  >;
  transitCountryAwbVerifyingStep: DocumentVerifyingStepModel<ManagerShipmentStep, ManagerShipmentAction>;
  destinationCountryDocsStep: DestinationCountryDocumentsStepModel<ManagerShipmentStep, ManagerShipmentAction>;
  destinationCountryAwbStep: UploadAwbOrFlightNumberStepModel<
    ManagerShipmentStep,
    AwbDirectAction.uploadFile,
    AwbDirectAction.flightNumber,
    DocumentFileType.awbForDirectCountry
  >;
  cargoShipmentStep: ConfirmationStepModel<ManagerShipmentStep, ManagerShipmentAction>;
};

export class ManagerShipmentStageModel extends BaseManagerStageModel<StageType.shipment, ManagerShipmentStep> {
  readonly supplierSpecificationStep: UploadOrGenerateDocumentStepModel<
    ManagerShipmentStep.supplierSpecification,
    ManagerShipmentAction,
    DocumentFileType.supplierSpecification,
    SupplierSpecificationModel
  >;
  readonly supplierSpecificationVerifyingStep: DocumentVerifyingStepModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly shipmentTypeStep: ShipmentTypeFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly cargoShipmentStep: ConfirmationStepModel<ManagerShipmentStep, ManagerShipmentAction>;
  /** Поля для StageType.transit */
  readonly transitCountryStep: TransitCountryFieldsModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly transitCountryAwbStep: UploadAwbOrFlightNumberStepModel<
    ManagerShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber,
    DocumentFileType.awbForTransitCountry
  >;
  readonly transitCountryAwbVerifyingStep: DocumentVerifyingStepModel<ManagerShipmentStep, ManagerShipmentAction>;
  /** Поля для StageType.direct */
  readonly destinationCountryDocsStep: DestinationCountryDocumentsStepModel<ManagerShipmentStep, ManagerShipmentAction>;
  readonly destinationCountryAwbStep: UploadAwbOrFlightNumberStepModel<
    ManagerShipmentStep,
    AwbDirectAction.uploadFile,
    AwbDirectAction.flightNumber,
    DocumentFileType.awbForDirectCountry
  >;

  readonly deleteSupplierSpecModalState = new ToggleModel();

  constructor(params: Params) {
    super({
      type: StageType.shipment,
      ...params,
    });

    this.supplierSpecificationStep = params.supplierSpecificationStep;
    this.supplierSpecificationVerifyingStep = params.supplierSpecificationVerifyingStep;
    this.shipmentTypeStep = params.shipmentTypeStep;
    this.cargoShipmentStep = params.cargoShipmentStep;

    this.transitCountryStep = params.transitCountryStep;
    this.transitCountryAwbStep = params.transitCountryAwbStep;
    this.transitCountryAwbVerifyingStep = params.transitCountryAwbVerifyingStep;

    this.destinationCountryDocsStep = params.destinationCountryDocsStep;
    this.destinationCountryAwbStep = params.destinationCountryAwbStep;

    makeObservable(this, {
      shipmentTypeChoiceDisabled: computed,
      shipmentTypeChosen: computed,
    });
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): ManagerShipmentStep[] {
    const steps: ManagerShipmentStep[] = [
      ManagerShipmentStep.supplierSpecification,
      /** Если нужна верификация спецификации, то добавляем шаг верификации */
      ...(this.supplierSpecificationStep.isNeedVerifying ? [ManagerShipmentStep.supplierSpecificationVerifying] : []),
      ManagerShipmentStep.shipmentType,
    ];

    const shipmentType = this.shipmentTypeStep.shipmentType;

    /** Если тип отгрузки = transit, то добавляем шаги для transit */
    if (shipmentType === ShipmentType.transit) {
      steps.push(
        ManagerShipmentStep.transitCountry,
        ManagerShipmentStep.transitCountryAwb,
        /** Если нужна верификация Awb, то добавляем шаг верификации Awb */
        ...(this.transitCountryAwbStep.isNeedVerifying ? [ManagerShipmentStep.transitCountryAwbVerifying] : []),
      );
    }

    /** Если тип отгрузки = direct, то добавляем шаги для direct */
    if (shipmentType === ShipmentType.direct) {
      steps.push(ManagerShipmentStep.destinationCountryDocs, ManagerShipmentStep.destinationCountryAwb);
    }

    /** Если выбран тип отгрузки, то добавляем шаг подтверждения отгрузки */
    if (shipmentType) {
      steps.push(ManagerShipmentStep.cargoShipment);
    }

    /** Если шаг = transfer, то добавляем шаг transfer */
    if (this.step === ManagerShipmentStep.transfer) {
      steps.push(ManagerShipmentStep.transfer);
    }

    return steps;
  }

  get shipmentTypeChoiceDisabled(): boolean {
    return (
      this.step === ManagerShipmentStep.supplierSpecification ||
      this.step === ManagerShipmentStep.supplierSpecificationVerifying
    );
  }

  get shipmentTypeChosen(): boolean {
    return this.shipmentTypeStep.shipmentType !== null && this.step !== ManagerShipmentStep.shipmentType;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerShipmentStageDataServer>): ManagerShipmentStageModel {
    const { current_step, steps } = dataServer;
    const isSpecVerifyingStep =
      current_step === ManagerShipmentStep.supplierSpecification &&
      steps.upload_shipment_specification?.document?.uploaded_by?.company?.type === CompanyType.supplier;

    return new ManagerShipmentStageModel({
      supplierSpecificationStep: UploadOrGenerateDocumentStepModel.fromJson<
        ManagerShipmentStep.supplierSpecification,
        ManagerShipmentAction,
        DocumentFileType.supplierSpecification,
        SupplierSpecificationModel
      >({
        uploadOrGenerateDocument: new SupplierSpecificationModel(
          UploadOrGenerateDocumentModel.paramsFromJson({
            data: dataServer.steps[ManagerShipmentStep.supplierSpecification]?.document,
            docType: DocumentFileType.supplierSpecification,
            tradeWorkflowStore,
            required: true,
            fileName: (t) => t('customerSpecification.title', { ns: 'stage' }),
          }),
        ),
        data: dataServer.steps[ManagerShipmentStep.supplierSpecification],
        step: ManagerShipmentStep.supplierSpecification,
        approveAction: ManagerShipmentStep.supplierSpecification,
        rejectAction: OptionFieldsAction.deleteSupplierSpec,
        tradeWorkflowStore,
      }),
      supplierSpecificationVerifyingStep: DocumentVerifyingStepModel.fromJson({
        data: steps[ManagerShipmentStep.supplierSpecification],
        step: ManagerShipmentStep.supplierSpecificationVerifying,
        approveAction: ManagerSpecificationVerifyingAction.approveSpecification,
        rejectAction: ManagerSpecificationVerifyingAction.rejectSpecification,
        tradeWorkflowStore,
      }),
      shipmentTypeStep: ShipmentTypeFieldsModel.fromJson({
        data: steps[ManagerShipmentStep.shipmentType]?.type,
        step: ManagerShipmentStep.shipmentType,
        approveAction: ManagerShipmentStep.shipmentType,
        tradeWorkflowStore,
      }),
      cargoShipmentStep: ConfirmationStepModel.fromJson({
        data: steps[ManagerShipmentStep.cargoShipment]?.shipped,
        step: ManagerShipmentStep.cargoShipment,
        approveAction: ManagerShipmentStep.cargoShipment,
        tradeWorkflowStore,
      }),
      transitCountryStep: TransitCountryFieldsModel.fromJson({
        data: steps.select_transit_country?.id,
        step: ManagerShipmentStep.transitCountry,
        approveAction: ManagerShipmentStep.transitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwbStep: UploadAwbOrFlightNumberStepModel.fromServer({
        data: steps[ManagerShipmentStep.transitCountryAwb],
        step: ManagerShipmentStep.transitCountryAwb,
        approveFileAction: AwbTransitAction.uploadFile,
        approveNumberAction: AwbTransitAction.flightNumber,
        docType: DocumentFileType.awbForTransitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwbVerifyingStep: DocumentVerifyingStepModel.fromJson({
        data: steps[ManagerShipmentStep.transitCountryAwb],
        step: ManagerShipmentStep.transitCountryAwbVerifying,
        approveAction: ManagerAwbVerifyingAction.approveAwb,
        rejectAction: ManagerAwbVerifyingAction.rejectAwb,
        tradeWorkflowStore,
      }),
      destinationCountryDocsStep: DestinationCountryDocumentsStepModel.fromJson({
        shipmentType: ShipmentType.direct,
        data: steps[ManagerShipmentStep.destinationCountryDocs],
        step: ManagerShipmentStep.destinationCountryDocs,
        approveAction: ManagerShipmentStep.destinationCountryDocs,
        tradeWorkflowStore,
      }),
      destinationCountryAwbStep: UploadAwbOrFlightNumberStepModel.fromServer({
        data: steps[ManagerShipmentStep.destinationCountryAwb],
        step: ManagerShipmentStep.destinationCountryAwb,
        approveFileAction: AwbDirectAction.uploadFile,
        approveNumberAction: AwbDirectAction.flightNumber,
        docType: DocumentFileType.awbForDirectCountry,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
      step: isSpecVerifyingStep ? ManagerShipmentStep.supplierSpecificationVerifying : current_step,
    });
  }
}
