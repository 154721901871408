import { HistoryActions } from '@/entities/tradeHistory';
import { ApiListResponse } from '@/shared/types/api';
import { SupportedLocale } from '@/shared/types/localization';
import { ID, ListDirection } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

export type NotificationServer = {
  id: number;
  action: HistoryActions;
  trade_id: number;
  created_at: string;
  is_new: boolean;
};

export type NotificationListResponse = ApiListResponse<NotificationServer>;

export enum NotificationCategory {
  statusChange = 'trade_status_change',
  docUpload = 'document_upload',
  docVerification = 'document_verification',
  bidInfo = 'bid_info',
}

export enum NotificationService {
  telegram = 'telegram',
  whatsapp = 'whatsapp',
  email = 'email',
  chat = 'chat',
}

export type NotificationListPayload = {
  limit: number;
  from_id: Nullable<number>;
  direction: ListDirection;
  category?: Nullable<Array<NotificationCategory>>;
  date_after?: Nullable<string>;
  date_before?: Nullable<string>;
};

export type NotificationsSetting<T> = {
  slug: T;
  enabled: boolean;
};

export type TelegramAccountServer = {
  account_id: ID;
  account_link: string;
  first_name: Nullable<string>;
  last_name: Nullable<string>;
  username: Nullable<string>;
};

export type WhatsAppAccountServer = {
  phone: string;
};

export type AccountServer = TelegramAccountServer | WhatsAppAccountServer;

export type NotificationsSettingsResponse = {
  categories: Array<NotificationsSetting<NotificationCategory>>;
  services: Array<NotificationsSetting<NotificationService>>;
  external_services_language: SupportedLocale;
  accounts?: Partial<Record<NotificationService, AccountServer>>;
  email_backups: Array<string>;
};

export type NotificationsSettingsPayload = Partial<{
  category: NotificationsSetting<NotificationCategory>;
  service: NotificationsSetting<NotificationService>;
  external_services_language: SupportedLocale;
  email_backups: Array<string>;
}>;

export type ChangeEmailListResponse = {
  email_backups: Array<string>;
};
