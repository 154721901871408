import { ID } from '@/shared/types/meta';

export enum WSLocation {
  main = 'main',
  chat = 'chat',
  bid = 'bid',
  stockMarket = 'stock_market',
}

export enum WsClientEvent {
  state = 'state',
  connected = 'connected',
  disconnected = 'disconnected',
  subscribed = 'subscribed',
  subscribing = 'subscribing',
  unsubscribed = 'unsubscribed',
  publication = 'publication',
}

export type SubscriptionChannel<Location extends WSLocation, Key extends ID> = `${Location}:${Key}`;
