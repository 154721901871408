import { BellFilled, BellOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';

import s from './NotificationsBell.module.scss';

type NotificationsBellProps = {
  className?: string;
  color?: string;
  style?: React.CSSProperties;
};

const NotificationsBell: React.FC<NotificationsBellProps> = observer(({ className, ...props }) => {
  const { userStore } = useRootStore();

  return userStore.newNotificationsCount > 0 ? (
    <BellFilled className={cn(s.bell, s.bell_notifications, className)} {...props} />
  ) : (
    <BellOutlined className={className} {...props} />
  );
});

export default React.memo(NotificationsBell);
