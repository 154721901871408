import { FileSyncOutlined } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CustomModal } from '@/shared/ui';
import { CustomButton } from '@/shared/ui/form/CustomButton';
import { CustomFormItem } from '@/shared/ui/form/CustomFormItem';
import { CustomUpload } from '@/shared/ui/form/CustomUpload';
import { UploadButton } from '@/shared/ui/form/buttons';

import { GenerationFieldsModel, UploadOrGenerateDocumentModel } from '../../model';
import { DocumentFileType } from '../../types';

import s from './UploadOrGenerateDocument.module.scss';

type UploadOrGenerateDocumentProps<
  DocType extends DocumentFileType,
  Payload,
  FieldsModel extends GenerationFieldsModel<DocType, Payload>,
> = {
  className?: string;
  modalСlassName?: string;
  model: UploadOrGenerateDocumentModel<DocType, Payload, FieldsModel>;
  disabled?: boolean;
  width?: number;
  height?: number;
  tooltip?: React.ReactNode;
  gridContent?: boolean;
  generatable?: boolean;
  FieldsComponent: React.ComponentType<{ model: FieldsModel }>;
};

const UploadOrGenerateDocument = <
  DocType extends DocumentFileType,
  Payload,
  FieldsModel extends GenerationFieldsModel<DocType, Payload>,
>({
  className,
  modalСlassName,
  model,
  FieldsComponent,
  disabled,
  width,
  height,
  tooltip,
  generatable = true,
  gridContent = true,
}: UploadOrGenerateDocumentProps<DocType, Payload, FieldsModel>) => {
  const { fileName, isUploaded, isOpenModal, generationFields, pollingState, openModal, closeModal, generate } = model;

  const canBeGenerated = React.useMemo(() => generatable && model.canBeGenerated, [generatable, model.canBeGenerated]);

  return (
    <>
      {canBeGenerated && (
        <CustomModal
          className={cn(s.modal, gridContent && s.modal_gridContent, modalСlassName)}
          width={width ?? 960}
          style={{ height: height }}
          title={fileName}
          open={isOpenModal}
          onCancel={closeModal}
          cancelButtonProps={{ disabled: generationFields.loadingState.isLoading }}
          onOk={generate}
          okText={(t) => t('buttons.create', { ns: 'shared' })}
          okButtonProps={{
            loading: generationFields.loadingState.isLoading,
            disabled: !generationFields.isFilled || generationFields.isError,
          }}
        >
          <FieldsComponent model={generationFields} />
        </CustomModal>
      )}
      <CustomFormItem className={cn(s.upload, className)} model={model}>
        <Flex gap={8} flex={1} wrap="wrap">
          <CustomUpload
            className={cn(isUploaded && s.upload_fullWidth)}
            {...model.props}
            disabled={disabled || pollingState.isLoading || generationFields.loadingState.isLoading}
          >
            {model.props.openFileDialogOnClick && (
              <UploadButton
                disabled={
                  disabled || model.props.disabled || pollingState.isLoading || generationFields.loadingState.isLoading
                }
              />
            )}
          </CustomUpload>
          {canBeGenerated && (
            <Tooltip placement="top" title={tooltip}>
              <CustomButton
                size="small"
                loading={pollingState.isLoading || generationFields.loadingState.isLoading}
                disabled={disabled || model.props.disabled}
                onClick={openModal}
                icon={<FileSyncOutlined />}
              >
                {(t) => t('buttons.generate', { ns: 'shared' })}
              </CustomButton>
            </Tooltip>
          )}
        </Flex>
      </CustomFormItem>
    </>
  );
};

export default observer(UploadOrGenerateDocument);
