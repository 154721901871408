import { action, makeObservable } from 'mobx';

import { ID } from '@/shared/types/meta';
import { Nullable, Options } from '@/shared/types/values';

import { BaseFieldModel, BaseFieldModelParams } from './BaseFieldModel';

export type RadioListModelParams<Value> = BaseFieldModelParams<Nullable<Value>> & {
  options: Options<Value>;
};

type RadioListModelProps<Value extends ID> = {
  value: Nullable<Value>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  options: Options<Value>;
};

export class RadioListModel<Value extends ID> extends BaseFieldModel<Nullable<Value>> {
  protected readonly _options: Options<Value>;

  constructor({ options, ...params }: RadioListModelParams<Value>) {
    super(params);

    this._options = options;

    makeObservable(this, {
      onChange: action.bound,
    });
  }

  get props(): RadioListModelProps<Value> {
    return {
      value: this._value,
      onChange: this.onChange,
      options: this._options,
    };
  }

  onChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.change(e.target.value as Value);
  }
}
