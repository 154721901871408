import { Tooltip } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { themeToken } from '@/shared/config/themeConfig';
import { useLastKnown } from '@/shared/hooks';

import { OnboardingButton } from '../OnboardingButton';

import TooltipTitle from './TooltipTitle';
import { usePresentedElement } from './usePresentedElement';

import s from './Onboarding.module.scss';

const Onboarding: React.FC = () => {
  const { onboardingStore } = useRootStore();
  const { isOnboarding, currentElement, resetOnboarding } = onboardingStore;

  const { ghostElementRef } = usePresentedElement({ currentElement });

  const [lastKnownElement, resetLastKnown] = useLastKnown(currentElement);

  const afterOpenChange = React.useCallback(
    (visible: boolean) => {
      if (!visible && !isOnboarding) {
        resetLastKnown();
        resetOnboarding();
      }
    },
    [currentElement, isOnboarding],
  );

  return (
    <>
      <OnboardingButton />
      <div className={cn(s.overlay, currentElement?.presentable && s.overlay_open)}>
        <Tooltip
          rootClassName={s.tooltip_root}
          title={<TooltipTitle currentElement={lastKnownElement} />}
          open={isOnboarding && currentElement?.presentable}
          color={themeToken.colorWhite}
          afterOpenChange={afterOpenChange}
          destroyTooltipOnHide
        >
          <div className={s.tooltip_ghost_element} ref={ghostElementRef} />
        </Tooltip>
      </div>
    </>
  );
};

export default observer(Onboarding);
