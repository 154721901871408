import { SupportedLocale } from '@/shared/types/localization';

import { ChartType, CurrencyPairType, ProductMetalType, ProductWeightType, TickerType } from '../types';

type TickerTypeTranslation = Record<TickerType, string>;

const currencyPairType = {
  [CurrencyPairType.AEDRUB]: 'AED/RUB',
  [CurrencyPairType.CNYRUB]: 'CNY/RUB',
  [CurrencyPairType.USDRUB]: 'USD/RUB',
};

export const dictionary = {
  en: {
    tickerDrawer: {
      title: 'Exchange',
    },
    tickerType: {} as TickerTypeTranslation,
    currencyPairType,
    metalType: {
      [ProductMetalType.gold]: 'Gold',
      [ProductMetalType.palladium]: 'Palladium',
      [ProductMetalType.platinum]: 'Platinum',
      [ProductMetalType.silver]: 'Silver',
      [ProductMetalType.rhodium]: 'Rhodium',
    },
    weightType: {
      [`${ProductWeightType.gram}_one`]: 'gram',
      [`${ProductWeightType.gram}_other`]: 'grams',
      [`${ProductWeightType.kilogram}_one`]: 'kilogram',
      [`${ProductWeightType.kilogram}_other`]: 'kilograms',
      [`${ProductWeightType.ton}_one`]: 'ton',
      [`${ProductWeightType.ton}_other`]: 'tons',
      [`${ProductWeightType.troyOunce}_one`]: 'ounce',
      [`${ProductWeightType.troyOunce}_other`]: 'ounces',
    },
    chartType: {
      [ChartType.day]: '{{count}} D',
      [ChartType.week]: '{{count}} W',
      [ChartType.month]: '{{count}} M',
    },
    scheduleTooltip: {
      price: 'Price: {{ price }}',
    },
    messages: {
      loadDictionariesError: 'Error loading dictionaries',
    },
  },
  ru: {
    tickerDrawer: {
      title: 'Биржа',
    },
    tickerType: {} as TickerTypeTranslation,
    currencyPairType,
    metalType: {
      [ProductMetalType.gold]: 'Золото',
      [ProductMetalType.palladium]: 'Палладий',
      [ProductMetalType.platinum]: 'Платина',
      [ProductMetalType.silver]: 'Серебро',
      [ProductMetalType.rhodium]: 'Родий',
    },
    weightType: {
      [`${ProductWeightType.gram}_one`]: 'грамм',
      [`${ProductWeightType.gram}_few`]: 'грамма',
      [`${ProductWeightType.gram}_many`]: 'грамм',
      [`${ProductWeightType.kilogram}_one`]: 'килограмм',
      [`${ProductWeightType.kilogram}_few`]: 'килограмма',
      [`${ProductWeightType.kilogram}_many`]: 'килограмм',
      [`${ProductWeightType.ton}_one`]: 'тонна',
      [`${ProductWeightType.ton}_few`]: 'тонны',
      [`${ProductWeightType.ton}_many`]: 'тонн',
      [`${ProductWeightType.troyOunce}_one`]: 'унция',
      [`${ProductWeightType.troyOunce}_few`]: 'унции',
      [`${ProductWeightType.troyOunce}_many`]: 'унций',
    },
    chartType: {
      [ChartType.day]: '{{count}} Д',
      [ChartType.week]: '{{count}} Н',
      [ChartType.month]: '{{count}} М',
    },
    scheduleTooltip: {
      price: 'Цена: {{ price }}',
    },
    messages: {
      loadDictionariesError: 'Ошибка загрузки словарей',
    },
  },
  zh: {
    tickerDrawer: {
      title: null,
    },
    tickerType: {} as TickerTypeTranslation,
    currencyPairType,
    metalType: {
      [ProductMetalType.gold]: '金',
      [ProductMetalType.palladium]: '钯',
      [ProductMetalType.platinum]: '铂',
      [ProductMetalType.silver]: '银',
      [ProductMetalType.rhodium]: '铑',
    },
    weightType: {
      [ProductWeightType.gram]: '克',
      [ProductWeightType.troyOunce]: '盎司',
      [ProductWeightType.kilogram]: '千克',
      [ProductWeightType.ton]: '吨',
    },
    chartType: {
      [ChartType.day]: null,
      [ChartType.week]: null,
      [ChartType.month]: null,
    },
    scheduleTooltip: {
      price: '价格: {{ price }}',
    },
    messages: {
      loadDictionariesError: null,
    },
  },
};

/**
 * Заполняем объект tickerType переводами для всех языков
 * */
Object.values(SupportedLocale).forEach((locale) => {
  dictionary[locale].tickerType = {
    ...dictionary[locale].currencyPairType,
    ...dictionary[locale].metalType,
  };
});
