import cn from 'classnames';
import * as React from 'react';

import { ListPageHeader, ListPageHeaderProps } from '@/shared/ui';

import { TickerMarquee } from '../TickerMarquee';

import s from './ListPageHeaderWithMarquee.module.scss';

type ListPageHeaderWithMarqueeProps = ListPageHeaderProps;

const ListPageHeaderWithMarquee: React.FC<ListPageHeaderWithMarqueeProps> = ({ className, ...props }) => {
  return (
    <div className={cn(className, s.header)}>
      <ListPageHeader {...props} />
      <TickerMarquee className={s.header_marquee} />
    </div>
  );
};

export default React.memo(ListPageHeaderWithMarquee);
