import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StepDescription } from '@/shared/ui';
import { FormUpload } from '@/shared/ui/form/FormUpload';

import { WaitingFileStepModel } from '../../model';
import { StageModel, StepComponentProps } from '../../types';

type Props<StageModel> = StepComponentProps<StageModel>;

const WaitingWithFileStep = <Model extends StageModel>({ model, stepKey, description }: Props<Model>) => {
  const step = stepKey && model[stepKey];

  if (!(step instanceof WaitingFileStepModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {step.file.isUploaded && <FormUpload model={step.file} disabled={true} />}
    </StepDescription>
  );
};

export default observer(WaitingWithFileStep);
