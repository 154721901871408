import { DownOutlined } from '@ant-design/icons';
import { useToggleState } from '@kts-front/hooks';
import { Button, Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ContentCard } from '../ContentCard';

import s from './CustomExpand.module.scss';

type CustomCollapseProps = {
  children: React.ReactNode;
  footer?: React.ReactNode;
  className?: string;
};

const CustomExpand = ({
  footer,
  children,
  className,
}: CustomCollapseProps): React.ReactElement<CustomCollapseProps> => {
  const { opened, open, toggle } = useToggleState();

  return (
    <ContentCard className={s['card']}>
      <div className={cn(s['content'], opened && s.content_expand, className)} onFocus={open}>
        {children}
      </div>
      <Flex justify="end" align="center">
        {footer}
        <Button
          type="text"
          className={cn(s.button, opened && s.button_open)}
          icon={<DownOutlined />}
          onClick={toggle}
          size="large"
        />
      </Flex>
    </ContentCard>
  );
};

export default observer(CustomExpand);
