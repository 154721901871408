export const notifications = {
  en: {
    filters: {
      title: null,
      description: null,
    },
    settingsButton: {
      title: null,
      description: null,
    },
    settingsTitle: {
      title: null,
      description: null,
    },
  },
  ru: {
    filters: {
      title: 'Фильтрация уведомлений',
      description: 'Вы можете отфильтровать уведомления по их типу или дате.',
    },
    settingsButton: {
      title: 'Настройки уведомлений',
      description:
        'После нажатия на кнопку “Настройки уведомлений“ вы перейдете на страницу с их детальными настройками.',
    },
    settingsTitle: {
      title: 'Настройки уведомлений',
      description: 'Здесь вы можете выбрать канал получения уведомлений, их типы и язык.',
    },
  },
  zh: {
    filters: {
      title: null,
      description: null,
    },
    settingsButton: {
      title: null,
      description: null,
    },
    settingsTitle: {
      title: null,
      description: null,
    },
  },
};
