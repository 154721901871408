import { DocumentFileType, UploadOrGenerateDocumentModel } from '@/entities/file';
import {
  StageModelJsonParams,
  StageType,
  SupplierPaymentStageDataServer,
  SupplierPaymentStep,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/entities/stage/utils';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { PaymentBillModel, UploadOrGenerateDocumentStepModel, WaitingFileStepModel } from '../../fieldsModel';

import { stepsOrder } from './config';

type Params = BaseStageModelParams<SupplierPaymentStep> & {
  uploadBillStep: UploadOrGenerateDocumentStepModel<
    SupplierPaymentStep.uploadBill,
    SupplierPaymentStep.uploadBill,
    DocumentFileType.paymentBill,
    PaymentBillModel
  >;
  payBillStep: WaitingFileStepModel<SupplierPaymentStep>;
};

export class SupplierPaymentStageModel extends BaseStageModel<StageType.payment, SupplierPaymentStep> {
  readonly uploadBillStep: UploadOrGenerateDocumentStepModel<
    SupplierPaymentStep.uploadBill,
    SupplierPaymentStep.uploadBill,
    DocumentFileType.paymentBill,
    PaymentBillModel
  >;
  readonly payBillStep: WaitingFileStepModel<SupplierPaymentStep>;

  constructor({ uploadBillStep, payBillStep, ...params }: Params) {
    super({
      type: StageType.payment,
      ...params,
    });

    this.uploadBillStep = uploadBillStep;
    this.payBillStep = payBillStep;
  }

  get isPaidBill(): boolean {
    return this.payBillStep.file.isUploaded;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): SupplierPaymentStep[] {
    return [SupplierPaymentStep.uploadBill, SupplierPaymentStep.payBill];
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<SupplierPaymentStageDataServer>): SupplierPaymentStageModel {
    return new SupplierPaymentStageModel({
      uploadBillStep: UploadOrGenerateDocumentStepModel.fromJson({
        uploadOrGenerateDocument: new PaymentBillModel(
          UploadOrGenerateDocumentModel.paramsFromJson({
            data: dataServer.steps[SupplierPaymentStep.uploadBill]?.document,
            docType: DocumentFileType.paymentBill,
            tradeWorkflowStore: tradeWorkflowStore,
            required: true,
            fileName: (t) => t('paymentBill.fileName', { ns: 'stage' }),
          }),
        ),
        data: dataServer.steps[SupplierPaymentStep.uploadBill],
        step: SupplierPaymentStep.uploadBill,
        approveAction: SupplierPaymentStep.uploadBill,
        tradeWorkflowStore,
      }),
      payBillStep: WaitingFileStepModel.fromJson({
        data: dataServer.steps[SupplierPaymentStep.payBill],
        step: SupplierPaymentStep.payBill,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
