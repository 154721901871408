import { Flex } from 'antd';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { Nullable } from '@/shared/types/values';
import { TrendArrow, TypographyTextWithTooltip } from '@/shared/ui';
import { formatNumber } from '@/shared/utils';

import { BidPrices, BidPricesKeys } from '../../types';

type BidPriceProps = {
  priceKey: BidPricesKeys;
  bidPrices: Nullable<BidPrices>;
};

const BidPrice: React.FC<BidPriceProps> = ({ bidPrices, priceKey }) => {
  const { lng } = useTypedTranslation();

  if (!bidPrices) {
    return null;
  }

  const price = bidPrices[priceKey];

  return (
    <Flex align="center" gap={8}>
      <TypographyTextWithTooltip text={formatNumber(price, { unit: bidPrices.priceUnit.title, language: lng })} />
      <TrendArrow positive={bidPrices.isPositive} negative={bidPrices.isNegative} />
    </Flex>
  );
};

export default React.memo(BidPrice);
