import { UploadChangeParam, UploadFile, UploadProps } from 'antd/es/upload';
import { action, computed, makeObservable } from 'mobx';

import { VerificationDocumentType } from '@/entities/verification/types';
import { apiStore, apiUrls } from '@/shared/api';
import { UploadFileModel, ValueModel } from '@/shared/model';
import { UploadFilesModel, UploadFilesModelParams, UploadFilesModelProps } from '@/shared/model/form/UploadFilesModel';
import { ApiErrorData } from '@/shared/types/api';
import { CheckStatus } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

type VerificationUploadFilesModelParams<DocType extends VerificationDocumentType> = UploadFilesModelParams & {
  docType: DocType;
  checkStatus: Nullable<CheckStatus>;
};

type VerificationUploadFilesModelProps = UploadFilesModelProps & {
  onRemove?: UploadProps['onRemove'];
};

export class VerificationUploadFilesModel<DocType extends VerificationDocumentType> extends UploadFilesModel {
  protected readonly _docType: DocType;

  private readonly _checkStatus: ValueModel<CheckStatus>;

  constructor({ docType, ...params }: VerificationUploadFilesModelParams<DocType>) {
    super(params);

    this._docType = docType;
    this._checkStatus = new ValueModel<CheckStatus>(params.checkStatus ?? CheckStatus.waiting);

    makeObservable(this, {
      checkStatus: computed,

      onRemove: action.bound,
    });
  }

  get checkStatus(): CheckStatus {
    return this._checkStatus.value;
  }

  get isUploaded(): boolean {
    return this._value.length > 0 && this._value.every((file) => file.status === 'done');
  }

  get props(): VerificationUploadFilesModelProps {
    return {
      fileList: this._value,
      onChange: this.onChange,
      onRemove: this.onRemove,
      required: this.required,
      maxCount: this._maxCount,
      accept: this._accept,
      disabled: this.disabled,
      openFileDialogOnClick: this._value.length < this._maxCount,
    };
  }

  async onChange({ file }: UploadChangeParam): Promise<void> {
    const fileModel = UploadFileModel.fromJson(file);

    /** Добавляем файл в список */
    this.change([...this._value, fileModel]);

    /** Выполняем валидацию */
    for (const validator of this._validators) {
      const error = validator([file]);

      /** Если валидация не прошла, показываем ошибку и ничего не делаем */
      if (error) {
        fileModel.changeError(error);
        fileModel.setErrorStatus();

        return;
      }
    }

    /** Устанавливаем статус загрузки */
    fileModel.setUploadingStatus();

    const response = await apiStore
      .createRequest({
        method: 'POST',
        url: apiUrls.verification.upload,
        multipartFormData: true,
      })
      .call({
        data: {
          doc_type: this._docType,
          file,
        },
      });

    if (response.isError) {
      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      fileModel.changeError(data?.message ?? ((t) => t('messages.uploadDocError', { ns: 'file', count: 1 })));
      fileModel.setErrorStatus();

      return;
    }

    fileModel.setDoneStatus();
  }

  async onRemove(fileToDelete: UploadFile): Promise<false> {
    const fileId = fileToDelete.uid;

    if (fileToDelete.status !== 'done') {
      this.change(this._value.filter((file) => file.uid !== fileId));

      return false;
    }

    const fileModel = fileToDelete instanceof UploadFileModel ? fileToDelete : UploadFileModel.fromJson(fileToDelete);

    /** Заменяем файл нашей моделью для возможности отображения статуса */
    this.change(this._value.map((file) => (file.uid === fileId ? fileModel : file)));

    fileModel.setUploadingStatus();

    const response = await apiStore
      .createRequest({
        method: 'DELETE',
        url: apiUrls.verification.delete,
        multipartFormData: true,
      })
      .call({
        data: {
          doc_type: this._docType,
        },
      });

    /** Если произошла ошибка при удалении файла, устанавливаем статус ошибки */
    if (response.isError) {
      const data: Nullable<ApiErrorData> = response.data?.data ?? null;

      fileModel.changeError(data?.message ?? ((t) => t('messages.deleteDocError', { ns: 'file' })));
      /** Возвращаем статус Done, чтобы при следующей попытке удаления процесс пошел через бэкенд */
      fileModel.setDoneStatus();
      /** Без этого ошибка удаления в списке не отображается, зависает на загрузке */
      this.change([...this._value]);
    } else {
      fileModel.setRemoveStatus();
      this.change(this._value.filter((file) => file.uid !== fileId));
      this._checkStatus.change(CheckStatus.waiting);
    }

    return false;
  }
}
