import { SupplierIngotServer } from '@/entities/stage/types';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { formatDate } from '@/shared/utils';
import { emptyValueValidator, stringLengthValidator } from '@/shared/utils/validators';

import { IngotModel, IngotModelParams } from '../IngotModel';

const T_OPTIONS = { ns: 'stage' } as const;

type SupplierIngotModelParams = IngotModelParams & {
  series: string;
};

export class SupplierIngotModel extends IngotModel {
  readonly series: InputModel;

  constructor(params: SupplierIngotModelParams) {
    super(params);

    this.series = new InputModel({
      initialValue: params.series,
      label: (t) => t('supplierSpecification.series', T_OPTIONS),
      placeholder: (t) => t('ingot.ingotNumberPlaceholder', T_OPTIONS),
      required: true,
      validators: [emptyValueValidator(), stringLengthValidator(16)],
    });
  }

  get fields(): Array<BaseFieldModel<any>> {
    return [this.ingotNumber, this.weight, this.series, this.ligatureWeight, this.metalPurity, this.year];
  }

  readonly toJson = (): Omit<SupplierIngotServer, 'number'> => {
    return {
      bar_number: this.ingotNumber.value,
      bar_series: this.series.value,
      weight: Number(this.weight.value),
      ligature_weight: Number(this.ligatureWeight.value),
      metal_purity: Number(this.metalPurity.value),
      ...(this.year.value ? { year: formatDate(this.year.value, 'YYYY') } : {}),
      ...(this.uin.value ? { uin: this.uin.value } : {}),
    };
  };

  static fromDefaultParams(): SupplierIngotModel {
    return new SupplierIngotModel({
      ingotNumber: '',
      weight: '',
      ligatureWeight: '',
      metalPurity: '99.99',
      year: null,
      uin: '',
      series: '',
    });
  }

  static formJson(data: SupplierIngotServer): SupplierIngotModel {
    return new SupplierIngotModel({
      ingotNumber: data.bar_number,
      weight: data.weight.toString(),
      ligatureWeight: data.ligature_weight ? data.ligature_weight.toString() : '',
      metalPurity: data.metal_purity.toString(),
      year: new Date(Number(data.year), 0, 1),
      uin: data.uin?.toString() || '',
      series: data.bar_series?.toString() || '',
    });
  }
}
