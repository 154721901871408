import { VerificationServer, VerificationStatus, VerificationStep } from '../../types/server';
import { BaseVerificationFieldsModel } from '../BaseVerificationFieldsModel';
import { CompanyInfoStepModel } from '../CompanyInfoStepModel';
import { QuestionnaireStepModel } from '../QuestionnaireStepModel';
import { ResidencyStepModel } from '../ResidencyStepModel';
import { StatutoryDocsStepModel } from '../StatutoryDocsStepModel';
import { VerificationStatusStepModel } from '../VerificationStatusStepModel';

type Params = {
  status: VerificationStatus;
  currentStep: VerificationStep;
  [VerificationStep.residency]: ResidencyStepModel;
  [VerificationStep.questionnaire]: QuestionnaireStepModel;
  [VerificationStep.statutoryDocs]: StatutoryDocsStepModel;
  [VerificationStep.companyInfo]: CompanyInfoStepModel;
  [VerificationStep.verificationStatus]: VerificationStatusStepModel;
};

export class StepsModel extends BaseVerificationFieldsModel<null, BaseVerificationFieldsModel<unknown>> {
  readonly status: VerificationStatus;
  readonly currentStep: VerificationStep;

  readonly [VerificationStep.residency]: ResidencyStepModel;
  readonly [VerificationStep.questionnaire]: QuestionnaireStepModel;
  readonly [VerificationStep.statutoryDocs]: StatutoryDocsStepModel;
  readonly [VerificationStep.companyInfo]: CompanyInfoStepModel;
  readonly [VerificationStep.verificationStatus]: VerificationStatusStepModel;

  constructor(params: Params) {
    super();

    this.status = params.status;
    this.currentStep = params.currentStep;

    this[VerificationStep.residency] = params[VerificationStep.residency];
    this[VerificationStep.questionnaire] = params[VerificationStep.questionnaire];
    this[VerificationStep.statutoryDocs] = params[VerificationStep.statutoryDocs];
    this[VerificationStep.companyInfo] = params[VerificationStep.companyInfo];
    this[VerificationStep.verificationStatus] = params[VerificationStep.verificationStatus];
  }

  get isVerified(): boolean {
    return this.status === VerificationStatus.verified;
  }

  get isPending(): boolean {
    return this.status === VerificationStatus.pending;
  }

  get isVerifying(): boolean {
    return this.status === VerificationStatus.inProgress || this.status === VerificationStatus.rejected;
  }

  toJson(): null {
    return null;
  }

  protected get _fields(): Array<BaseVerificationFieldsModel<unknown>> {
    return [
      this[VerificationStep.residency],
      this[VerificationStep.questionnaire],
      this[VerificationStep.statutoryDocs],
      this[VerificationStep.companyInfo],
    ] as Array<BaseVerificationFieldsModel<unknown>>;
  }

  static fromJson(server: VerificationServer): StepsModel {
    return new StepsModel({
      status: server.status,
      currentStep: server.current_step,
      [VerificationStep.residency]: ResidencyStepModel.fromJson(server.steps[VerificationStep.residency]),
      [VerificationStep.questionnaire]: QuestionnaireStepModel.fromJson(server.steps[VerificationStep.questionnaire]),
      [VerificationStep.statutoryDocs]: StatutoryDocsStepModel.fromJson(server.steps[VerificationStep.statutoryDocs]),
      [VerificationStep.companyInfo]: CompanyInfoStepModel.fromJson(server.steps[VerificationStep.companyInfo]),
      [VerificationStep.verificationStatus]: VerificationStatusStepModel.fromJson(
        server.steps[VerificationStep.verificationStatus],
      ),
    });
  }
}
