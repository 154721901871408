import { DocumentFileType, UploadOrGenerateDocumentModel } from '@/entities/file';
import {
  ManagerPaymentAction,
  ManagerPaymentStageDataServer,
  ManagerPaymentStep,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/entities/stage/utils';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import { PaymentBillModel, UploadFilesStepModel, UploadOrGenerateDocumentStepModel } from '../../fieldsModel';

import { stepsOrder } from './config';

const MAX_COUNT_PAI_BILL = 10;

type Params = BaseManagerStageModelParams<ManagerPaymentStep> & {
  uploadBillStep: UploadOrGenerateDocumentStepModel<
    ManagerPaymentStep.uploadBill,
    ManagerPaymentStep.uploadBill,
    DocumentFileType.paymentBill,
    PaymentBillModel
  >;
  payBillStep: UploadFilesStepModel<ManagerPaymentStep, ManagerPaymentAction, DocumentFileType.paidBill>;
};

export class ManagerPaymentStageModel extends BaseManagerStageModel<StageType.payment, ManagerPaymentStep> {
  readonly uploadBillStep: UploadOrGenerateDocumentStepModel<
    ManagerPaymentStep.uploadBill,
    ManagerPaymentStep.uploadBill,
    DocumentFileType.paymentBill,
    PaymentBillModel
  >;
  readonly payBillStep: UploadFilesStepModel<ManagerPaymentStep, ManagerPaymentAction, DocumentFileType.paidBill>;

  constructor({ uploadBillStep, payBillStep, ...params }: Params) {
    super({
      type: StageType.payment,
      ...params,
    });

    this.uploadBillStep = uploadBillStep;
    this.payBillStep = payBillStep;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): ManagerPaymentStep[] {
    const steps: ManagerPaymentStep[] = [ManagerPaymentStep.uploadBill, ManagerPaymentStep.payBill];

    if (this.step === ManagerPaymentStep.transfer) {
      steps.push(ManagerPaymentStep.transfer);
    }

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerPaymentStageDataServer>): ManagerPaymentStageModel {
    return new ManagerPaymentStageModel({
      uploadBillStep: UploadOrGenerateDocumentStepModel.fromJson({
        uploadOrGenerateDocument: new PaymentBillModel(
          UploadOrGenerateDocumentModel.paramsFromJson({
            data: dataServer.steps[ManagerPaymentStep.uploadBill]?.document,
            docType: DocumentFileType.paymentBill,
            tradeWorkflowStore: tradeWorkflowStore,
            required: true,
            fileName: (t) => t('paymentBill.fileName', { ns: 'stage' }),
          }),
        ),
        data: dataServer.steps[ManagerPaymentStep.uploadBill],
        step: ManagerPaymentStep.uploadBill,
        approveAction: ManagerPaymentStep.uploadBill,
        tradeWorkflowStore,
      }),
      payBillStep: UploadFilesStepModel.fromJson({
        maxCount: MAX_COUNT_PAI_BILL,
        data: dataServer.steps[ManagerPaymentStep.payBill],
        step: ManagerPaymentStep.payBill,
        approveAction: ManagerPaymentStep.payBill,
        docType: DocumentFileType.paidBill,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
