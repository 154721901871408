import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { DictionaryPage } from '@/entities/dictionary';
import { useTypedTranslation } from '@/shared/hooks';
import { CustomResult } from '@/shared/ui';

import { useRootStore } from '../store';

type Params<P> = {
  Component: React.ComponentType<P>;
  page: DictionaryPage;
};

/* HOC для предварительной загрузки необходимых словарей */
export const withDictionaries = <P extends Record<string, unknown>>({ Component, page }: Params<P>) =>
  observer((props: P) => {
    const { userStore, dictionariesStore, localizationStore } = useRootStore();
    const { t } = useTypedTranslation('dictionary');

    React.useEffect(() => {
      if (!userStore.userRole) {
        return;
      }

      dictionariesStore.loadDictionariesByPage(page, userStore.userRole);
    }, [page, localizationStore.lng, userStore.userRole]);

    if (dictionariesStore.loadingStage.isError) {
      return <CustomResult status="error" title={t('messages.loadDictionariesError')} />;
    }

    return <Component {...props} />;
  });
