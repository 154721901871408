import { Nullable } from '@/shared/types/values';

type Params<Step> = {
  step: Nullable<Step>;
  steps: Array<Step>;
  stepsOrder: Array<Step>;
};

export const mapStepToNumber = <Step extends string>({ step, steps, stepsOrder }: Params<Step>): number => {
  if (!step || !stepsOrder.includes(step)) {
    return -1;
  }

  let lastStepIndex = 0;

  const stepsMap = stepsOrder.reduce<Record<Step, number>>(
    (acc, curr) => {
      const index = steps.indexOf(curr);

      if (index !== -1) {
        lastStepIndex = index;
      }

      acc[curr] = lastStepIndex;

      return acc;
    },
    {} as Record<Step, number>,
  );

  return stepsMap[step];
};
