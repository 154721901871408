import { CheckCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StepDescription } from '@/shared/ui';

import { WaitingDateStepModel } from '../../model/fieldsModel/WaitingDateStepModel';
import { StageModel, StepComponentProps } from '../../types';

type WaitingStepProps<Model> = StepComponentProps<Model>;

const WaitingDateStep = <Model extends StageModel>({ model, stepKey, description }: WaitingStepProps<Model>) => {
  const step = stepKey && model[stepKey];

  if (!(step instanceof WaitingDateStepModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {step.date.value && (
        <DatePicker
          value={step.date.props.value}
          style={{ width: '50%' }}
          suffixIcon={<CheckCircleFilled />}
          disabled
        />
      )}
    </StepDescription>
  );
};

export default observer(WaitingDateStep);
