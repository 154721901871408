export enum VerificationStatus {
  inProgress = 'in_progress',
  pending = 'pending',
  rejected = 'rejected',
  verified = 'verified',
}

export enum VerificationStep {
  residency = 'residency',
  questionnaire = 'questionnaire',
  statutoryDocs = 'statutory_documents',
  companyInfo = 'company_info',
  verificationStatus = 'verification',
}

export enum ResidentType {
  resident = 'russia',
  nonResident = 'other',
}

export enum EAEUCountrySlug {
  Armenia = 'ARM',
  Belarus = 'BLR',
  Kazakhstan = 'KAZ',
  Kyrgyzstan = 'KGZ',
  Russia = 'RUS',
}

/**
 * @field questionnaire - Анкета
 * @field companyCharter - Устав Компании
 * @field unifiedStateRegisterExtract - Выписка из единого государственного реестра
 * @field directorAppointOrder - Приказ о назначении Директора
 * @field directorPassport - Паспорт директора/учредителя
 * @field leaseAgreement - Договор аренды
 * @field memorandumAssociation - Учредительный договор
 * @field directorAuthorityCertificate - Свидетельство о полномочиях директора
 * @field tradeRegisterExtract - Выписка из торгового реестра
 * @field stateRegistrationCertificate - Свидетельство о государственной регистрации
 * @field companyEstablishDecision - Решение о создании Компании (опционально)
 * @field activitiesLicense - Лицензия на право осуществления деятельности (опционально)
 * @field attorneyPower - Доверенность (опционально)
 * @field directorAppointOrderOrDecision - Приказ о назначении директора или решение о назначении директора (опционально)
 * @field businessReputationReview - Отзыв о деловой репутации (опционально)
 */
export enum VerificationDocumentType {
  questionnaire = 'questionnaire',
  companyCharter = 'company_charter',
  unifiedStateRegisterExtract = 'unified_state_register_extract',
  directorAppointOrder = 'director_appoint_order',
  directorPassport = 'director_passport',
  leaseAgreement = 'lease_agreement',
  memorandumAssociation = 'memorandum_association',
  directorAuthorityCertificate = 'director_authority_certificate',
  tradeRegisterExtract = 'trade_register_extract',
  stateRegistrationCertificate = 'state_registration_certificate',
  companyEstablishDecision = 'company_establish_decision',
  activitiesLicense = 'activities_license',
  attorneyPower = 'attorney_power',
  directorAppointOrderOrDecision = 'director_appoint_order_or_decision',
  businessReputationReview = 'business_reputation_review',
}
