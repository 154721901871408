import dayjs from 'dayjs';

import { DateFormat } from '../types/meta';

/**
 * Форматирование даты
 * @param date - Дата в формате Date или строка
 * @param format - Формат даты, по умолчанию = 'YYYY-MM-DD'
 * @param keepLocalTime - Оставлять ли локальное время, по умолчанию = false
 * @returns string
 */
export const formatDate = (date: Date | string, format: string = DateFormat.date, keepLocalTime = false): string => {
  return dayjs(date)
    .tz(keepLocalTime ? undefined : 'Europe/London')
    .format(format);
};
