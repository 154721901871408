import { CheckCircleFilled, CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';
import { CheckStatus } from '@/shared/types/meta';

import { TransComp } from '../../TransComp';
import { TypographyTextWithTooltip } from '../../TypographyTextWithTooltip';

import { FormItemOptional } from './FormItemOptional';

import s from './FormItem.module.scss';

type FormItemLabelProps = Partial<{
  label: TranslationString;
  required: boolean;
  tooltip: TranslationString;
  checkStatus: CheckStatus;
}>;

const FormItemLabel: React.FC<FormItemLabelProps> = ({ label, required, tooltip, checkStatus }) => {
  if (!label) return null;

  const checkStatusNode = React.useMemo(
    () => (
      <>
        {checkStatus === CheckStatus.approved && (
          <CheckCircleFilled className={cn(s['check-status'], s['check-status_approved'])} />
        )}
        {checkStatus === CheckStatus.rejected && (
          <CloseCircleFilled className={cn(s['check-status'], s['check-status_rejected'])} />
        )}
      </>
    ),
    [checkStatus],
  );

  const labelNode = React.useMemo(() => {
    return (
      <>
        <TransComp>{label}</TransComp> <FormItemOptional required={required} />
      </>
    );
  }, [label, required]);

  return (
    <Flex className={s['form-item_label']} align="center">
      {checkStatusNode}
      <TypographyTextWithTooltip text={labelNode} />
      {tooltip && (
        <Tooltip placement="top" title={<TransComp>{tooltip}</TransComp>}>
          <QuestionCircleOutlined className={s['form-item_tooltip']} />
        </Tooltip>
      )}
    </Flex>
  );
};

export default React.memo(FormItemLabel);
