import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { apiStore, apiUrls } from '@/shared/api';
import { ListModel, ToggleModel } from '@/shared/model';

import { ITicker, ITickerHistory, ITickerStore } from '../../types/client';
import { TickerListResponse, TickerType } from '../../types/server';
import { CurrentTickerHistoryModel } from '../CurrentTickerHistoryModel';
import { TickerHistoryModel } from '../TickerHistoryModel';
import { TickerModel } from '../TickerModel';

export class TickersStore implements ITickerStore {
  readonly list = new ListModel<ITicker, TickerType>();

  /** Историю храним отдельно для избежания ререндера графика при обновлении списка по вебсокету */
  readonly historyList = new ListModel<ITickerHistory, TickerType>();

  readonly currentTickerHistory: CurrentTickerHistoryModel;

  private readonly _drawerState = new ToggleModel();

  private readonly _rootStore: IRootStore;

  constructor(rootStore: IRootStore) {
    this._rootStore = rootStore;

    this.currentTickerHistory = new CurrentTickerHistoryModel({ rootStore });

    makeObservable(this, {
      isOpenDrawer: computed,

      openDrawer: action.bound,
      closeDrawer: action.bound,
      destroy: action.bound,
      load: action.bound,
    });
  }

  get isOpenDrawer(): boolean {
    return this._drawerState.isOpen;
  }

  openDrawer(ticker?: TickerType): void {
    this._drawerState.open();

    if (ticker) {
      this.currentTickerHistory.openHistory(ticker);
    }
  }

  closeDrawer(): void {
    this.currentTickerHistory.closeHistory();
    this._drawerState.close();
  }

  destroy() {
    this.currentTickerHistory.destroy();
  }

  async load(): Promise<BaseResponse> {
    const response = await this._ticketListRequest.call();

    if (response.isError) {
      return { isError: true };
    }

    this.list.fillByRawData(
      response.data,
      (raw) => ({
        key: raw.ticker,
        entity: TickerModel.fromJson(raw, this._rootStore.localizationStore),
      }),
      true,
    );

    this.historyList.fillByRawData(
      response.data,
      (raw) => ({
        key: raw.ticker,
        entity: TickerHistoryModel.fromJson(raw),
      }),
      true,
    );

    return { isError: false };
  }

  private readonly _ticketListRequest = apiStore.createRequest<TickerListResponse>({
    url: apiUrls.dictionaries.tickerList,
  });
}
