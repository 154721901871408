import { BaseResponse } from '@kts-front/types';
import { throttle } from '@kts-front/utils';
import { Subscription } from 'centrifuge';
import { action, makeObservable, observable, reaction, runInAction } from 'mobx';

import { IRootStore } from '@/app/store';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore, ValueModel } from '@/shared/model';
import { ID } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { SubscriptionChannel, WsClientEvent, WSLocation } from '../types';
import { IBaseWsEventsHandling } from '../types/client';

export type BaseWsEventsHandlingModelParams<Location extends WSLocation> = {
  location: Location;
  rootStore: IRootStore;
};

export abstract class BaseWsEventsHandlingModel<Events, Location extends WSLocation, Key extends ID>
  extends LocalStore
  implements IBaseWsEventsHandling
{
  readonly handlingStage = new LoadingStageModel();

  private readonly _location: Location;

  private readonly _subscription = new ValueModel<Nullable<Subscription>>(null);

  private readonly _subscriptionRequest = apiStore.createRequest<{ token: string }>({
    url: apiUrls.ws.subscription,
  });

  protected readonly _eventQueue: Array<Events> = [];

  protected readonly _rootStore: IRootStore;

  constructor(params: BaseWsEventsHandlingModelParams<Location>) {
    super();

    this._location = params.location;
    this._rootStore = params.rootStore;

    makeObservable<this, '_eventQueue' | '_wsEventHandler'>(this, {
      _eventQueue: observable,

      _wsEventHandler: action.bound,
      unsubscribe: action.bound,
      subscribe: action.bound,
    });

    this.addReactions([reaction(() => [this._eventQueue.length, this.handlingStage.value], this._wsEventHandler)]);
  }

  async subscribe(): Promise<BaseResponse> {
    const result = await this._rootStore.centrifugeStore.getClient();
    const channel = this._subscriptionChannel;

    if (result.isError || !channel) {
      return { isError: true };
    }

    const client = result.data;

    let subscription = client.getSubscription(channel);

    if (!subscription) {
      const token = await this._getSubscriptionToken(channel);

      subscription = client.newSubscription(channel, {
        token,
        getToken: () => this._getSubscriptionToken(channel),
      });
    }

    subscription.on(WsClientEvent.publication, this._addWSEvent);
    subscription.subscribe();

    this._subscription.change(subscription);

    return { isError: false };
  }

  unsubscribe(): void {
    if (!this._subscription.value) {
      return;
    }

    this._subscription.value.off(WsClientEvent.publication, this._addWSEvent);
    this._subscription.value.unsubscribe();
  }

  private readonly _updateEventQueue = ({ data }: { data: Events }) => {
    runInAction(() => {
      this._eventQueue.push(data);
    });
  };

  private readonly _addWSEvent = throttle({
    func: this._updateEventQueue,
    timeout: 100,
  });

  private readonly _getSubscriptionToken = async (channel: string): Promise<string> => {
    const response = await this._subscriptionRequest.call({
      params: {
        channel,
        location: this._location,
      },
    });

    if (response.isError) {
      return '';
    }

    return response.data.token;
  };

  protected abstract get _subscriptionChannel(): Nullable<SubscriptionChannel<Location, Key>>;
  protected abstract _wsEventHandler(): Promise<void> | void;

  destroy(): void {
    super.destroy();
    this.unsubscribe();
  }
}
