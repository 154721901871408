import * as React from 'react';

import { IconProps } from '../types';

const WhatsAppOutlined: React.FC<IconProps> = ({ size = '1em', ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8501 2.15009C10.7057 0.99421 9.18584 0.285348 7.56477 0.151364C5.9437 0.0173804 4.3281 0.467094 3.0094 1.41939C1.6907 2.37168 0.755705 3.76387 0.373068 5.34482C-0.00956973 6.92578 0.185339 8.59143 0.922637 10.0413L0.198887 13.5551C0.191378 13.5901 0.191165 13.6262 0.198263 13.6613C0.20536 13.6963 0.219616 13.7295 0.240137 13.7588C0.270199 13.8033 0.313112 13.8375 0.363143 13.857C0.413175 13.8764 0.467944 13.8801 0.520137 13.8676L3.96389 13.0513C5.40972 13.77 7.06361 13.9523 8.63128 13.566C10.199 13.1797 11.5787 12.2497 12.5251 10.9415C13.4714 9.63339 13.923 8.03191 13.7994 6.42207C13.6758 4.81224 12.9851 3.29845 11.8501 2.15009ZM10.7764 10.7201C9.98454 11.5097 8.96488 12.0309 7.86109 12.2104C6.75731 12.3898 5.62505 12.2183 4.62389 11.7201L4.14389 11.4826L2.03264 11.9826L2.03889 11.9563L2.47639 9.83134L2.24139 9.36759C1.72984 8.36293 1.54938 7.22214 1.72587 6.10864C1.90236 4.99514 2.42674 3.96607 3.22389 3.16884C4.22552 2.16752 5.58384 1.605 7.00014 1.605C8.41644 1.605 9.77476 2.16752 10.7764 3.16884C10.7849 3.17862 10.7941 3.18781 10.8039 3.19634C11.7931 4.20024 12.3453 5.5545 12.3402 6.96388C12.335 8.37325 11.7729 9.72344 10.7764 10.7201Z"
        fill="currentColor"
      />
      <path
        d="M10.5891 9.16131C10.3303 9.56881 9.92155 10.0676 9.4078 10.1913C8.5078 10.4088 7.12655 10.1988 5.4078 8.59631L5.38655 8.57756C3.8753 7.17631 3.4828 6.01006 3.5778 5.08506C3.6303 4.56006 4.0678 4.08506 4.43655 3.77506C4.49485 3.7253 4.56398 3.68988 4.63842 3.67162C4.71286 3.65336 4.79054 3.65277 4.86524 3.66989C4.93995 3.68702 5.00961 3.72139 5.06866 3.77026C5.12771 3.81912 5.1745 3.88113 5.2053 3.95131L5.76155 5.20131C5.7977 5.28236 5.8111 5.37172 5.80031 5.45981C5.78951 5.54789 5.75494 5.63138 5.7003 5.70131L5.41905 6.06631C5.3587 6.14168 5.32229 6.23338 5.31449 6.32961C5.3067 6.42585 5.32787 6.52221 5.3753 6.60631C5.5328 6.88256 5.9103 7.28881 6.32905 7.66506C6.79905 8.09006 7.3203 8.47881 7.6503 8.61131C7.73861 8.64738 7.83569 8.65619 7.92904 8.63658C8.02239 8.61698 8.10773 8.56986 8.17405 8.50131L8.5003 8.17256C8.56326 8.11049 8.64154 8.06621 8.72718 8.04425C8.81282 8.02229 8.90275 8.02344 8.9878 8.04756L10.3091 8.42256C10.3819 8.44492 10.4487 8.48364 10.5044 8.53578C10.56 8.58792 10.6029 8.65208 10.63 8.72337C10.657 8.79465 10.6673 8.87117 10.6603 8.94708C10.6532 9.02298 10.6288 9.09626 10.5891 9.16131Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(WhatsAppOutlined);
