import { Input } from 'antd';
import { SearchProps } from 'antd/es/input';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';

import s from './SearchBar.module.scss';

type SearchBarProps = SearchProps & {
  className?: string;
  fullWidth?: boolean;
};

const SearchBar = React.forwardRef<HTMLDivElement, SearchBarProps>(
  ({ className, fullWidth = false, ...props }, ref): React.ReactElement<SearchBarProps> => {
    const { t } = useTypedTranslation('shared');

    return (
      <div ref={ref} className={cn(s.wrapper, fullWidth && s.wrapper_fullWidth, className)}>
        <Input.Search className={s['search-bar']} placeholder={t('placeholders.search')} {...props} allowClear />
      </div>
    );
  },
);

SearchBar.displayName = 'SearchBar';

export default SearchBar;
