import { PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Table, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation, useWidth } from '@/shared/hooks';
import { CustomTypographyText } from '@/shared/ui';

import { IHandlingIngots } from '../../types';

import s from './HandlingIngotsTable.module.scss';

type Props<TableData> = {
  model: IHandlingIngots<TableData>;
  mobileRender: React.ReactElement;
};

const HandlingIngotsTable = <TableData extends Record<string, unknown>>({ model, mobileRender }: Props<TableData>) => {
  const { countIngots, columns, rows, addIngot, isError } = model;
  const { t } = useTypedTranslation('stage');
  const { isMobile } = useWidth();

  return (
    <Flex vertical gap={12}>
      <Flex justify="space-between" align="middle">
        <Typography.Title level={5}>{t('handlingIngotsTable.informationAboutIngots')}</Typography.Title>
        <CustomTypographyText type="secondary">
          {t('handlingIngotsTable.countAddIngots', { count: countIngots })}
        </CustomTypographyText>
      </Flex>
      <Flex className={s.table_wrapper} vertical justify="space-between" flex={1}>
        {isError && (
          <Typography.Text className={s.table_error} type="danger">
            {t('handlingIngotsTable.emptyIngotFieldsError')}
          </Typography.Text>
        )}
        {isMobile ? (
          mobileRender
        ) : (
          <Table
            className={s.table}
            columns={columns}
            dataSource={rows}
            pagination={false}
            bordered
            sticky
            scroll={{ x: '350px' }}
            size="small"
          />
        )}
      </Flex>
      <Flex justify="end">
        <Button type="dashed" icon={<PlusOutlined />} onClick={addIngot}>
          {t('handlingIngotsTable.action')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default observer(HandlingIngotsTable);
