import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { CustomAlert, StepDescription } from '@/shared/ui';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { ShipmentStageModel, SupplierSpecificationModel, UploadOrGenerateDocumentStepModel } from '../../model';
import { StepComponentProps } from '../../types';
import SupplierSpecification from '../SupplierSpecification';

const SupplierSpecificationStep = <Model extends ShipmentStageModel>({ model, stepKey }: StepComponentProps<Model>) => {
  const { userStore } = useRootStore();
  const step = stepKey && model[stepKey];

  const isSupplierSpecification =
    step instanceof UploadOrGenerateDocumentStepModel &&
    step.uploadOrGenerateDocument instanceof SupplierSpecificationModel;

  const canApprove = React.useMemo(() => {
    if (!isSupplierSpecification) {
      return false;
    }

    const isCurrentStep = step.isCurrentStep;
    const isOwnerDocument = step.isOwnerDocument;
    const isApproved = step.isApproved;

    return !(isCurrentStep && isOwnerDocument && isApproved);
  }, [isSupplierSpecification, step]);

  if (!isSupplierSpecification) {
    return null;
  }

  return (
    <StepDescription
      description={(t) =>
        t(canApprove ? 'supplier.uploadSpec.description' : 'supplier.specVerifying.waitDescription', {
          ns: 'shipmentStage',
        })
      }
    >
      {step.isRejected && (
        <CustomAlert
          type="error"
          message={(t) => t('messages.rejectedDocError', { ns: 'file' })}
          description={step.error}
        />
      )}
      <SupplierSpecification
        model={step.uploadOrGenerateDocument}
        disabled={step.isDisabled || step.isLoading || !canApprove}
      />
      {canApprove && (
        <ApproveButton
          loading={step.isLoading}
          disabled={step.isDisabled || step.approveDisabled}
          onClick={step.approveStep}
          block={userStore.isSupplier}
        />
      )}
    </StepDescription>
  );
};

export default observer(SupplierSpecificationStep);
