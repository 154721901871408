import { Flex, Tooltip } from 'antd';
import * as React from 'react';
import { DotProps, TooltipProps } from 'recharts';

import { ChartType } from '@/entities/dictionary/types';
import { useTypedTranslation } from '@/shared/hooks';
import { CustomTypographyText } from '@/shared/ui';
import { formatDate, formatNumber } from '@/shared/utils';

import { mapChartTypeToDateFormat } from '../config';

import ActiveDot from './ActiveDot';

import s from './PriceChartTooltip.module.scss';

type Props = DotProps & {
  tooltipProps: TooltipProps<string, string>;
  chartType: ChartType;
  currency?: string;
};

const PriceChartTooltip: React.FC<Props> = ({ chartType, currency, tooltipProps, ...props }) => {
  const { t, lng } = useTypedTranslation('dictionary');

  const { active, label, payload } = tooltipProps;

  const formattedTime = React.useMemo(() => formatDate(label, mapChartTypeToDateFormat[chartType]), [label, chartType]);

  const price = React.useMemo(() => {
    const priceValue = payload?.at(0)?.value;

    return priceValue && formatNumber(Number(priceValue), { unit: currency, language: lng });
  }, [payload]);

  return (
    <Tooltip
      open={active}
      placement="top"
      title={
        <Flex vertical>
          <CustomTypographyText type="secondary">{formattedTime}</CustomTypographyText>
          <CustomTypographyText className={s.price}>{t('scheduleTooltip.price', { price })}</CustomTypographyText>
        </Flex>
      }
    >
      <ActiveDot {...props} />
    </Tooltip>
  );
};

export default React.memo(PriceChartTooltip);
