import { DocumentFileType, UploadOrGenerateDocumentModel, UploadOrGenerateDocumentModelParams } from '@/entities/file';
import { CustomerSpecificationDocPayload } from '@/entities/stage/types';

import { CustomerSpecificationFieldsModel } from './CustomerSpecificationFieldsModel';

export class CustomerSpecificationModel extends UploadOrGenerateDocumentModel<
  DocumentFileType.customerSpecification,
  CustomerSpecificationDocPayload,
  CustomerSpecificationFieldsModel
> {
  readonly generationFields: CustomerSpecificationFieldsModel;

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.customerSpecification>) {
    super(params);

    this.generationFields = new CustomerSpecificationFieldsModel(params);
  }
}
