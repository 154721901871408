import { computed, makeObservable } from 'mobx';

import { CompanyType } from '@/entities/company';
import { VerificationStatus } from '@/entities/verification';
import { ValueModel } from '@/shared/model';

import { mapCompanyUserRoleServerToUserRole } from '../../config';
import { ClientUserRole, ISupplierUser, SupplierServer } from '../../types';
import { BaseUserModel, BaseUserModelParams } from '../BaseUserModel';

type Params = BaseUserModelParams & {
  role: ClientUserRole;
  kycStatus: ValueModel<VerificationStatus>;
};

export class SupplierModel extends BaseUserModel implements ISupplierUser<ClientUserRole> {
  readonly type = CompanyType.supplier;
  readonly role: ClientUserRole;
  readonly kycStatus: ValueModel<VerificationStatus>;

  constructor(data: Params) {
    super(data);

    this.role = data.role;
    this.kycStatus = data.kycStatus;

    makeObservable(this, {
      verified: computed,
    });
  }

  get verified(): boolean {
    return this.kycStatus.value === VerificationStatus.verified;
  }

  static fromJson(data: SupplierServer): SupplierModel {
    return new SupplierModel({
      ...BaseUserModel.baseUserParamsFromJson(data),
      role: mapCompanyUserRoleServerToUserRole[data.role],
      kycStatus: new ValueModel(data.company.kyc_status),
    });
  }
}
