import { action, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { DocumentFileType, FileModel } from '@/entities/file';
import { MessageType } from '@/entities/message';
import {
  ICreationStage,
  ManagerCreationStageDataServer,
  ManagerCreationStep,
  OptionFieldsAction,
} from '@/entities/stage/types';
import { CreateTradeModel, ITradeWorkflowStore, TradeResponse } from '@/entities/trade';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, ToggleModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseManagerStageModel } from '../../../BaseManagerStageModel';

type Params = {
  data: ICreationStage;
  rootStore: IRootStore;
  tradeWorkflowStore?: Nullable<ITradeWorkflowStore>;
};

const T_OPTIONS = { ns: 'trade' } as const;

export class CreateTradeFieldsModel extends CreateTradeModel {
  readonly deleteSupplierSpecStage = new LoadingStageModel();
  readonly deleteSupplierSpecModalState = new ToggleModel();

  private readonly _tradeRequest = apiStore.createRequest<TradeResponse>({
    method: 'POST',
  });

  constructor(params: Params) {
    super(params);

    makeObservable(this, {
      editTrade: action.bound,
      deleteSupplierSpec: action.bound,
    });
  }

  async deleteSupplierSpec(): Promise<void> {
    if (this.deleteSupplierSpecStage.isLoading || !this._tradeWorkflowStore) {
      return;
    }

    this.deleteSupplierSpecStage.loading();

    const response = await this._tradeRequest.call({
      url: apiUrls.trade.action(this._tradeWorkflowStore.tradeId),
      multipartFormData: true,
      data: {
        action: OptionFieldsAction.deleteSupplierSpec,
      },
    });

    if (response.isError) {
      this.deleteSupplierSpecStage.error();

      return;
    }

    this.deleteSupplierSpecStage.success();
    this.deleteSupplierSpecModalState.close();

    this._tradeWorkflowStore.updateTradeWorkflow(response.data);
  }

  async editTrade(): Promise<void> {
    if (this.requestStage.isLoading || !this._tradeWorkflowStore) {
      return;
    }

    this.formFields.forEach((field) => {
      if (field == 'productId' && !this.productOptions) {
        return;
      }

      this[field].validate();
    });

    if (this.isError) {
      this.scrollToErrorField();

      return;
    }

    const transferFields =
      this._tradeWorkflowStore.stageModel instanceof BaseManagerStageModel
        ? this._tradeWorkflowStore.stageModel.transferFields
        : null;

    this.requestStage.loading();
    transferFields?.loadingStage.loading();

    const response = await this._tradeRequest.call({
      url: apiUrls.trade.edit(this._tradeWorkflowStore.tradeId),
      multipartFormData: true,
      data: this.toJson(),
    });

    if (response.isError) {
      this.requestStage.error();
      transferFields?.loadingStage.error();
      this._rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('messages.createTradeError', T_OPTIONS),
      });

      return;
    }

    this.requestStage.success();
    transferFields?.loadingStage.success();
    this._rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('messages.createTradeSuccess', T_OPTIONS),
    });

    this._tradeWorkflowStore.updateTradeWorkflow(response.data);
  }

  static fromJson({
    server,
    tradeWorkflowStore,
  }: {
    server: ManagerCreationStageDataServer;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): CreateTradeFieldsModel {
    const info = server.steps[ManagerCreationStep.createTrade];
    const supplierSpecification = server[DocumentFileType.supplierSpecification];

    return new CreateTradeFieldsModel({
      tradeWorkflowStore,
      rootStore: tradeWorkflowStore.rootStore,
      data: {
        /** Скрываем инфу для наблюдателя владельца */
        ...(tradeWorkflowStore.rootStore.userStore.isOwnerObserver
          ? {
              customerCompany: null,
              supplierCompany: null,
              customerEmployee: null,
              supplierEmployee: null,
              legalCompanyFrom: null,
              legalCompanyTo: null,
            }
          : {
              customerCompany: info.customer_company && info.customer_company.id,
              supplierCompany: info.supplier_company && info.supplier_company.id,
              customerEmployee: info.customer && info.customer.id,
              supplierEmployee: info.supplier && info.supplier.id,
              legalCompanyFrom: info.legal_company_from?.id ?? null,
              legalCompanyTo: info.legal_company_to?.id ?? null,
            }),
        /** */

        supplierSpec: FileModel.fileListFromJson(supplierSpecification),
        productTypeId: info.product_type_id,
        productId: info.product_id,
        chemicalWeight: String(info.weight),
        ligatureWeight: String(info.ligature_weight ?? ''),
        weightUnit: info.weight_unit_id,
        fixingDate: new Date(info.fixing_date),
        sellPrice: info.sell_price ? String(info.sell_price) : '',
        sellPriceUnit: info.sell_price_unit?.id ?? null,
        buyPrice: info.buy_price ? String(info.buy_price) : '',
        buyPriceUnit: info.buy_price_unit?.id ?? null,
        sellPriceTotal: info.sell_price_total ? String(info.sell_price_total) : '',
        buyPriceTotal: info.buy_price_total ? String(info.buy_price_total) : '',
      },
    });
  }
}
