import { isString } from 'antd/es/button';
import { action, autorun, computed, makeObservable } from 'mobx';
import { nanoid } from 'nanoid';

import { IRootStore } from '@/app/store';
import { IDictionariesStore, ProductMetalType } from '@/entities/dictionary';
import { IHandlingIngots } from '@/entities/stage/types';
import { ListModel, LocalStore } from '@/shared/model';
import { Nullable } from '@/shared/types/values';
import { ColumnsData } from '@/shared/ui/Table';
import { getChemicalWeight, getLigatureWeight } from '@/shared/utils';

import { SupplierIngotModel } from '../SupplierIngotModel';

import { getColumns } from './config';
import { SupplierIngotsTableData } from './types';

type SupplierIngotListModelParams = {
  productId: Nullable<number>;
  rootStore: IRootStore;
  productTypeId: number;
};

export class SupplierIngotListModel extends LocalStore implements IHandlingIngots<SupplierIngotsTableData> {
  readonly list = new ListModel<SupplierIngotModel, string>();
  private readonly _rootStore: IRootStore;
  readonly productId: Nullable<number>;
  readonly productTypeId: number;

  constructor({ rootStore, productId, productTypeId }: SupplierIngotListModelParams) {
    super();
    this.addIngot();
    this._rootStore = rootStore;
    this.productId = productId;
    this.productTypeId = productTypeId;

    makeObservable(this, {
      columns: computed,
      rows: computed,
      isError: computed,
      isEmptyValue: computed,
      countIngots: computed,
      isNeedLigatureWeight: computed,
      dictionaries: computed,

      addIngot: action.bound,
      deleteIngot: action.bound,
    });

    this.addReactions([
      /**
       * Если изменяется химический вес и нужно отобразить лигатурный (this.isNeedLigatureWeight === false),
       * то выставляем лигатурный вес
       * */
      autorun(() => {
        if (!this.isNeedLigatureWeight) {
          this.list.items.forEach((ingot) =>
            ingot.ligatureWeight.change(String(getLigatureWeight(ingot.weight.value) || '')),
          );
        }
      }),
      /**
       * Если изменяется лигатурный вес и он обязателен (this.isNeedLigatureWeight === true),
       * то выставляем химический вес
       * */
      autorun(() => {
        if (this.isNeedLigatureWeight) {
          this.list.items.forEach((ingot) =>
            ingot.weight.change(String(getChemicalWeight(ingot.ligatureWeight.value) || '')),
          );
        }
      }),
    ]);
  }

  get isNeedLigatureWeight(): boolean {
    if (!this.productId) {
      return false;
    }

    const productType = this.dictionaries.productTypes.list.entities.get(this.productTypeId);

    return productType?.productList.entities.get(this.productId)?.slug !== ProductMetalType.gold;
  }

  get dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get isError(): boolean {
    return this.list.items.some((row) => row.fields.some((field) => field.isError));
  }

  get isEmptyValue(): boolean {
    return this.list.items.some((row) =>
      row.fields.some((field) => !(isString(field.value) ? field.value.trim() : field.value)),
    );
  }

  get countIngots(): number {
    return this.list.items.reduce((acc, row) => {
      if (
        row.fields.some(
          (field) => field.required && ((isString(field.value) ? !field.value.trim() : !field.value) || field.isError),
        )
      )
        return acc;

      return acc + 1;
    }, 0);
  }

  get columns(): ColumnsData<SupplierIngotsTableData> {
    return getColumns(this);
  }

  get rows(): SupplierIngotsTableData[] {
    return this.list.items.map((_, index) => {
      const key = this.list.keys[index];

      return {
        key: key,
        ingotNumber: key,
        weight: key,
        ligatureWeight: key,
        metalPurity: key,
        year: key,
        uin: key,
        series: key,
      };
    });
  }

  addIngot(): void {
    this.list.addEntity({
      key: nanoid(),
      entity: SupplierIngotModel.fromDefaultParams(),
    });
  }

  deleteIngot(key: string): void {
    if (this.list.length > 1) {
      this.list.removeEntity(key);
    }
  }
}
