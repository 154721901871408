import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { ID } from '@/shared/types/meta';
import { FormRadioGroup, FormRadioGroupProps } from '@/shared/ui/form/FormRadioGroup';

import s from './VerificationRadioGroup.module.scss';

const VerificationRadioGroup = <Value extends ID>({ className, ...props }: FormRadioGroupProps<Value>) => {
  return <FormRadioGroup className={cn(s.group, className)} {...props} />;
};

export default observer(VerificationRadioGroup);
