import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { isNullable } from '@/shared/types/typesGuard';

import { ITicker } from '../../types';
import TickerList from '../TickerList';

import s from './TickerMarquee.module.scss';

type TickerMarqueeProps = {
  className?: string;
};

type Content = Array<ITicker>;

const TickerMarquee: React.FC<TickerMarqueeProps> = ({ className }) => {
  const { dictionariesStore } = useRootStore();
  const tickerStore = dictionariesStore.tickers;
  const tickers = tickerStore.list.items;

  const [contentRepeatCount, setContentRepeatCount] = React.useState(2);

  const marqueeRef = React.useRef<HTMLDivElement>(null);
  const contentRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    if (isNullable(marqueeRef.current) || isNullable(contentRef.current)) {
      return;
    }

    if (marqueeRef.current.clientWidth > contentRef.current.clientWidth) {
      const repeatCount = Math.ceil((marqueeRef.current.clientWidth * 2) / contentRef.current.clientWidth);

      setContentRepeatCount(repeatCount);
    }
  }, [marqueeRef.current, contentRef.current]);

  return (
    <div className={cn(s.marquee, className)} ref={marqueeRef}>
      {tickers.length > 0 &&
        Array<Content>(contentRepeatCount)
          .fill(tickers)
          .map((tickerList, index) => (
            <TickerList
              className={s.marquee_content}
              key={index}
              ref={contentRef}
              tickerList={tickerList}
              onClick={tickerStore.openDrawer}
            />
          ))}
    </div>
  );
};

export default observer(TickerMarquee);
