import { BidDifference, BidDifferenceType, BidFixingType, BidStatus, BidType } from '@/entities/bid';
import { PriceProvider } from '@/entities/dictionary/types';

export const bids = {
  en: {
    title: 'Bids',
    searchPlaceholder: 'Find bid',
    emptyList: {
      buy: 'There are currently no bids available for purchase',
      sell: 'There are currently no bids available for sale',
      my: "You don't have any created bids yet. When they appear, they will be displayed in this section",
      reply: 'You have not left any replies yet. All your responses will be displayed here',
    },
    actions: {
      createBid: 'Create bid',
      editBid: 'Edit bid',
      replyBid: 'Reply',
      approveReply: 'Approve',
      publishBid: 'Publish',
      deleteBid: 'Delete',
      goToTrade: 'Go to trade',
    },
    fixingType: {
      [BidFixingType.am]: 'Morning',
      [BidFixingType.pm]: 'Evening',
      [BidFixingType.spot]: 'Spot',
    },
    difference: {
      [`with_${BidDifference.premium}`]: 'with a premium',
      [`with_${BidDifference.discount}`]: 'with a discount',
    },
    differenceType: {
      [BidDifferenceType.price]: 'Sum',
      [BidDifferenceType.percent]: 'Percent',
    },
    bidType: {
      [BidType.buy]: 'Buying',
      [BidType.sell]: 'Selling',
    },
    priceProvider: {
      [PriceProvider.profinance]: 'Profinance',
    },
    status: {
      [BidStatus.new]: 'New',
      [BidStatus.review]: 'Under consideration',
      [BidStatus.published]: 'Published',
      [BidStatus.inProgress]: 'Progress',
      [BidStatus.approved]: 'Approved',
      [BidStatus.rejected]: 'Rejected',
      [BidStatus.cancelled]: 'Cancelled',
      [BidStatus.tradeCreated]: 'Trade created',
    },
    BidHandlingModal: {
      createTitle: 'Creating a bid',
      editTitle: 'Editing a bid',
      companyBidInfoTitle: 'Counterparty details',
      bidType: 'Trade type',
      buyer: 'Buyer',
      seller: 'Seller',
      metal: 'Metal',
      shape: 'Product shape',
      fixingType: 'Fixing type',
      priceProvider: 'Price basis',
      chemicalWeight: 'Pure weight',
      ligatureWeight: 'Gross weight',
      weightPlaceholder: 'Weight',
      deliveryBasis: 'Delivery basis',
      currency: 'Trade currency',
      differenceType: 'Type of discount/premium',
      differenceSum: 'Sum of discount/premium',
      differencePercent: 'Percent of discount/premium',
      differenceDescription: 'You can enter either a positive value (premium) or a negative value (discount)',
      fixingDate: 'Fixing date',
      currentPrice: 'Exchange price',
      resultMessage: 'You create a bid with the "{{fixingType}}" fixing type {{difference}} of {{differenceValue}}',
      companyEmployee: 'Responsible person',
      companyDeliveryBasis: 'Counterparty delivery basis',
      companyDifferenceType: 'Type of counterparty discount/premium',
      companyDifferenceSum: 'Sum of counterparty discount/premium',
      companyDifferencePercent: 'Percent of counterparty discount/premium',
    },
    BidReplyModal: {
      title: 'Reply to the bid',
      reply: 'Reply',
      alertText: 'Once the manager approves your reply, we will get back to you!',
    },
    BidInfo: {
      title: 'Bid information',
      fields: {
        type: 'Bid type',
        status: 'Bid status',
        metal: 'Metal',
        shape: 'Product shape',
        currency: 'Trade currency',
        fixingType: 'Fixing type',
        fixingDate: 'Fixing date',
        fixingPrice: 'Fixing price',
        weightWithUnit: 'Pure weight',
        ligatureWeightWithUnit: 'Gross weight',
        sellerName: 'Seller',
        buyerName: 'Buyer',
        buyerCountry: 'Buyer country',
        deliveryBasis: 'Delivery basis',
        difference: 'Discount/premium',
        managerDeliveryBasis: 'Recommended delivery basis',
        managerDifference: 'Recommended discount/premium',
        respondentDeliveryBasis: 'Delivery basis (on reply)',
        respondentDifference: 'Discount/premium (on reply)',
      },
    },
    BidDiscountEditing: {
      title: 'Enter discount',
      deliveryBasis: 'Recommended delivery basis',
      differenceType: 'Recommended discount/premium type',
      differenceSum: 'Recommended discount/premium sum',
      differencePercent: 'Recommended discount/premium percentage',
    },
    BidReplyList: {
      title_sell: 'Select a customer',
      title_buy: 'Select a supplier',
      columns: {
        [BidType.sell]: 'Customer',
        [BidType.buy]: 'Supplier',
        country: 'Country',
        difference: 'Discount/premium',
        deliveryBasis: 'Delivery basis',
      },
    },
    BidDeleteModal: {
      title: 'Confirm deletion of the bid',
    },
    messages: {
      createBidSuccess: 'Bid created successfully',
      createBidError: 'Failed to create bid',
      editBidSuccess: 'Bid updated successfully',
      editBidError: 'Failed to update bid',
      replyBidSuccess: 'Reply sent successfully',
      replyBidError: 'Failed to reply to the bid',
      publishBidSuccess: 'Bid published successfully',
      publishBidError: 'Failed to publish bid',
      approveReplySuccess: 'Reply approved successfully',
      approveReplyError: 'Failed to approve reply',
      createTradeSuccess: 'Trade created successfully',
      createTradeError: 'Failed to create trade',
      deleteBidSuccess: 'Bid deleted successfully',
      deleteBidError: 'Failed to delete bid',
    },
    menu: {
      buy: 'Buy',
      sell: 'Sell',
      my: 'My bids',
      reply: 'My responses',
    },
    columns: {
      type: 'Type bids',
      metal: 'Metal',
      shape: 'Commodity form',
      status: 'Status',
      companyName: 'Client name',
      weight: 'Weight',
      chemicalWeight: 'Pure',
      ligatureWeight: 'Gross',
      currency: 'Trade currency',
      difference: 'Discount/premium',
      deliveryBasis: 'Delivery basis',
      managerDifference: 'Recommended discount/premium',
      managerDeliveryBasis: 'Recommended delivery basis',
      fixingType: 'Fixing type',
      fixingPrice: 'Fixing price',
      ouncePrice: 'Price per ounce',
      gramPrice: 'Price per gram',
      totalPrice: 'Total cost',
      totalOuncePrice: 'Without discount/premium',
      totalOuncePriceWithDiff: 'With discount/premium',
      reply: 'Reply',
    },
    tooltip: {
      tooltipWeight: 'Please note! Weight changed from {{ prevWeight }} to {{ weight }}',
    },
    replyCount: {
      reply_one: '{{ count }} reply',
      reply_other: '{{ count }} replies',
    },
  },
  ru: {
    title: 'Заявки',
    searchPlaceholder: 'Найти заявку',
    emptyList: {
      buy: 'На данный момент нет доступных заявок на покупку',
      sell: 'На данный момент нет доступных заявок на продажу',
      my: 'У вас пока нет созданных заявок. Когда они появятся, они будут отображены в этом разделе',
      reply: 'Вы еще не оставили откликов. Здесь будут отображаться все ваши отклики',
    },
    actions: {
      createBid: 'Создать заявку',
      editBid: 'Редактировать заявку',
      replyBid: 'Откликнуться',
      approveReply: 'Утвердить',
      publishBid: 'Опубликовать',
      deleteBid: 'Удалить',
      goToTrade: 'Перейти к сделке',
    },
    fixingType: {
      [BidFixingType.am]: 'Утренняя',
      [BidFixingType.pm]: 'Вечерняя',
      [BidFixingType.spot]: 'Спот',
    },
    difference: {
      [`with_${BidDifference.premium}`]: 'с премией',
      [`with_${BidDifference.discount}`]: 'с дисконтом',
    },
    differenceType: {
      [BidDifferenceType.price]: 'Сумма',
      [BidDifferenceType.percent]: 'Процент',
    },
    bidType: {
      [BidType.buy]: 'Покупка',
      [BidType.sell]: 'Продажа',
    },
    priceProvider: {
      [PriceProvider.profinance]: 'Профинанс',
    },
    status: {
      [BidStatus.new]: 'Новое',
      [BidStatus.review]: 'На рассмотрении',
      [BidStatus.published]: 'Опубликован',
      [BidStatus.inProgress]: 'В работе',
      [BidStatus.approved]: 'Утвержден',
      [BidStatus.rejected]: 'Отклонен',
      [BidStatus.cancelled]: 'Отменен',
      [BidStatus.tradeCreated]: 'Сделка создана',
    },
    BidHandlingModal: {
      createTitle: 'Создание заявки',
      editTitle: 'Редактирование заявки',
      companyBidInfoTitle: 'Данные контрагента',
      bidType: 'Тип заявки',
      buyer: 'Покупатель',
      seller: 'Продавец',
      metal: 'Металл',
      shape: 'Товарная форма',
      fixingType: 'Тип фиксации',
      priceProvider: 'Базис цены',
      chemicalWeight: 'Вес химический',
      ligatureWeight: 'Вес лигатурный',
      deliveryBasis: 'Базис поставки',
      currency: 'Валюта сделки',
      differenceType: 'Вид дисконта/премии',
      differenceSum: 'Сумма дисконта/премии',
      differencePercent: 'Процент дисконта/премии',
      differenceDescription:
        'Вы можете ввести как положительное значение (премия), так и отрицательное значение (дисконт)',
      fixingDate: 'Дата фиксинга',
      currentPrice: 'Биржевая цена',
      resultMessage: 'Вы создаете сделку с типом фиксации "{{fixingType}}" {{difference}} в {{differenceValue}}',
      companyEmployee: 'Ответственное лицо',
      companyDeliveryBasis: 'Базис поставки контрагента',
      companyDifferenceType: 'Вид дисконта/премии контрагента',
      companyDifferenceSum: 'Сумма дисконта/премии контрагента',
      companyDifferencePercent: 'Процент дисконта/премии контрагента',
    },
    BidReplyModal: {
      title: 'Отклик на заявку',
      alertText: 'После того как менеджер одобрит ваш отклик, мы вернемся к вам!',
    },
    BidInfo: {
      title: 'Информация о заявке',
      fields: {
        type: 'Тип заявки',
        status: 'Статус заявки',
        metal: 'Металл',
        shape: 'Товарная форма',
        currency: 'Валюта сделки',
        fixingType: 'Тип фиксации',
        fixingDate: 'Дата фиксинга',
        fixingPrice: 'Цена фиксации',
        weightWithUnit: 'Вес химический',
        ligatureWeightWithUnit: 'Вес лигатурный',
        sellerName: 'Продавец',
        buyerName: 'Покупатель',
        buyerCountry: 'Страна покупателя',
        deliveryBasis: 'Базис поставки',
        difference: 'Дисконт/премия',
        managerDeliveryBasis: 'Рекомендуемый базис поставки',
        managerDifference: 'Рекомендуемый дисконт/премия',
        respondentDeliveryBasis: 'Базис поставки (при отклике)',
        respondentDifference: 'Дисконт/премия (при отклике)',
      },
    },
    BidDiscountEditing: {
      title: 'Введите дисконт',
      deliveryBasis: 'Рекомендуемый базис поставки',
      differenceType: 'Вид рекомендуемого дисконта/премии',
      differenceSum: 'Сумма рекомендуемого дисконта/премии',
      differencePercent: 'Процент рекомендуемого дисконта/премии',
    },
    BidReplyList: {
      title_sell: 'Выберите покупателя',
      title_buy: 'Выберите продавца',
      columns: {
        [BidType.sell]: 'Покупатель',
        [BidType.buy]: 'Продавец',
        country: 'Страна',
        difference: 'Дисконт/премия',
        deliveryBasis: 'Базис поставки',
      },
    },
    BidDeleteModal: {
      title: 'Подтвердите удаление заявки',
    },
    messages: {
      createBidSuccess: 'Заявка успешно создана',
      createBidError: 'Не удалось создать заявку',
      editBidSuccess: 'Заявка успешно отредактирована',
      editBidError: 'Не удалось отредактировать заявку',
      replyBidSuccess: 'Отклик успешно отправлен',
      replyBidError: 'Не удалось откликнуться на заявку',
      publishBidSuccess: 'Заявка успешно опубликована',
      publishBidError: 'Не удалось опубликовать заявку',
      approveReplySuccess: 'Отклик успешно одобрен',
      approveReplyError: 'Не удалось одобрить отклик',
      createTradeSuccess: 'Сделка успешно создана',
      createTradeError: 'Не удалось создать сделку',
      deleteBidSuccess: 'Заявка успешно удалена',
      deleteBidError: 'Не удалось удалить заявку',
    },
    menu: {
      buy: 'Купить',
      sell: 'Продать',
      my: 'Мои заявки',
      reply: 'Мои отклики',
    },
    columns: {
      type: 'Тип заявки',
      metal: 'Металл',
      shape: 'Товарная форма',
      status: 'Статус',
      companyName: 'Имя клиента',
      weight: 'Вес',
      chemicalWeight: 'Химический',
      ligatureWeight: 'Лигатурный',
      currency: 'Валюта сделки',
      difference: 'Дисконт/премия',
      deliveryBasis: 'Базис поставки',
      managerDifference: 'Рекомендуемый дисконт/премия',
      managerDeliveryBasis: 'Рекомендуемый базис поставки',
      fixingType: 'Тип фиксации',
      fixingPrice: 'Цена фиксации',
      ouncePrice: 'Цена за унцию',
      gramPrice: 'Цена за грамм',
      totalPrice: 'Общая стоимость',
      totalOuncePrice: 'Без дисконта/премии',
      totalOuncePriceWithDiff: 'С дисконтом/премией',
      reply: 'Отклик',
    },
    tooltip: {
      tooltipWeight: 'Обратите внимание! Вес изменился с {{ prevWeight }} на {{ weight }}',
    },
    replyCount: {
      reply_one: '{{ count }} отклик',
      reply_few: '{{ count }} отклика',
      reply_many: '{{ count }} откликов',
    },
  },
  zh: {
    title: '申请',
    searchPlaceholder: '找到申请',
    emptyList: {
      buy: '目前没有可用的申请',
      sell: '目前没有可用的申请',
      my: '您目前没有任何创建的申请。当它们出现时，它们将显示在此部分中',
      reply: '您尚未留下任何回应。您的所有回应都会显示在这里',
    },
    actions: {
      createBid: '创建申请',
      editBid: '编辑申请',
      replyBid: '回应',
      approveReply: '批准',
      publishBid: '发布',
      deleteBid: '删除',
      goToTrade: '转到交易',
    },
    fixingType: {
      [BidFixingType.am]: '早晨',
      [BidFixingType.pm]: '夜晚',
      [BidFixingType.spot]: '现货',
    },
    difference: {
      [`with_${BidDifference.premium}`]: '带奖金',
      [`with_${BidDifference.discount}`]: '带折扣',
    },
    differenceType: {
      [BidDifferenceType.price]: '金额',
      [BidDifferenceType.percent]: '率',
    },
    bidType: {
      [BidType.buy]: '购买',
      [BidType.sell]: '销售',
    },
    priceProvider: {
      [PriceProvider.profinance]: null,
    },
    status: {
      [BidStatus.new]: '新',
      [BidStatus.review]: '待办',
      [BidStatus.published]: '已发布',
      [BidStatus.inProgress]: '工作中',
      [BidStatus.approved]: '已批准',
      [BidStatus.rejected]: '已拒绝',
      [BidStatus.cancelled]: '已取消',
      [BidStatus.tradeCreated]: '交易已创建',
    },
    BidHandlingModal: {
      createTitle: '创建申请',
      editTitle: '编辑申请',
      companyBidInfoTitle: '契约当事人数据',
      bidType: '申请类型',
      buyer: '买方',
      seller: '卖方',
      metal: '金属',
      shape: '货物形态',
      fixingType: '固定类型',
      priceProvider: '基础价格',
      chemicalWeight: '化学重量',
      ligatureWeight: '结构重量',
      deliveryBasis: '交货依据',
      currency: '交易货币',
      differenceType: '折扣/奖金类型',
      differenceSum: '折扣/奖金金额',
      differencePercent: '折扣/奖金率',
      differenceDescription: '您可以输入正值（奖金）或负值（折扣）',
      fixingDate: '拍板成交日期',
      currentPrice: '行价',
      resultMessage: '您正在创建一个固定类型的交易“{{fixingType}}”{{difference}}在{{differenceValue}}',
      companyEmployee: '责任人',
      companyDeliveryBasis: '契约当事人的交货依据',
      companyDifferenceType: '契约当事人的折扣/奖金类型',
      companyDifferenceSum: '契约当事人的折扣/奖金金额',
      companyDifferencePercent: '契约当事人的折扣/奖金率',
    },
    BidReplyModal: {
      title: '对申请的回应',
      alertText: '经理人批准您的回应后，我们会回复您！',
    },
    BidInfo: {
      title: '申请信息',
      fields: {
        type: '申请类型',
        status: '申请状态',
        metal: '金属',
        shape: '货物形态',
        currency: '交易货币',
        fixingType: '固定类型',
        fixingDate: '拍板成交日期',
        fixingPrice: '固定价格',
        weightWithUnit: '化学重量',
        ligatureWeightWithUnit: '结构重量',
        sellerName: '卖方',
        buyerName: '买方',
        buyerCountry: '买方国家',
        deliveryBasis: '交货依据',
        difference: '折扣/奖金',
        managerDeliveryBasis: '推荐的交货依据',
        managerDifference: '推荐的折扣/奖金',
        respondentDeliveryBasis: '交货依据（回应时）',
        respondentDifference: '折扣/奖金（回应时）',
      },
    },
    BidDiscountEditing: {
      title: '输入折扣',
      deliveryBasis: '推荐的交货依据',
      differenceType: '推荐的折扣/奖金类型',
      differenceSum: '推荐的折扣/奖金金额',
      differencePercent: '推荐的折扣/奖金率',
    },
    BidReplyList: {
      title_sell: '选择买方',
      title_buy: '选择卖方',
      columns: {
        [BidType.sell]: '买方',
        [BidType.buy]: '卖方',
        country: '国籍',
        difference: '折扣/奖金',
        deliveryBasis: '交货依据',
      },
    },
    BidDeleteModal: {
      title: '确认删除申请',
    },
    messages: {
      createBidSuccess: '申请已成功创建',
      createBidError: '未成功创建申请',
      editBidSuccess: '申请已成功编辑',
      editBidError: '未成功编辑申请',
      replyBidSuccess: '回应发送成功',
      replyBidError: '未成功回应申请',
      publishBidSuccess: '申请已成功发布',
      publishBidError: '未成功发布申请',
      approveReplySuccess: '回应已成功批准',
      approveReplyError: '未成功批准回应',
      createTradeSuccess: '交易已成功创建',
      createTradeError: '未成功创建交易',
      deleteBidSuccess: '申请已成功删除',
      deleteBidError: '无法删除申请',
    },
    menu: {
      buy: '购买',
      sell: '出售',
      my: '我的申请',
      reply: '我的回应',
    },
    columns: {
      type: '申请类型',
      metal: '金属',
      shape: '货物形态',
      status: '状态',
      companyName: '客户姓名',
      weight: '重量',
      chemicalWeight: '化学',
      ligatureWeight: '结构',
      currency: '交易货币',
      difference: '折扣/奖金',
      deliveryBasis: '交货依据',
      managerDifference: '推荐的折扣/奖金',
      managerDeliveryBasis: '推荐的交货依据',
      fixingType: '固定类型',
      fixingPrice: '固定价格',
      ouncePrice: null,
      gramPrice: null,
      totalPrice: null,
      totalOuncePrice: null,
      totalOuncePriceWithDiff: null,
      reply: '回应',
    },
    tooltip: {
      tooltipWeight: '请注意！重量从{{ prevWeight }}变更为{{ weight }}',
    },
    replyCount: {
      reply_other: '{{ count }} 回应',
    },
  },
};
