import { FileModel } from '@/entities/file';
import { UploadFilesModel } from '@/shared/model/form/UploadFilesModel';
import { CheckStatus } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator } from '@/shared/utils/validators';

import { QuestionnaireStepServer, VerificationDocumentType, VerificationStep } from '../../types/server';
import { BaseVerificationStepModel } from '../BaseVerificationStepModel';
import { VerificationUploadFilesModel } from '../VerificationUploadFilesModel';

type Params = {
  questionnaire: VerificationUploadFilesModel<VerificationDocumentType.questionnaire>;
};

const T_OPTIONS = { ns: 'verification' } as const;

export class QuestionnaireStepModel extends BaseVerificationStepModel<VerificationStep.questionnaire, null> {
  readonly questionnaire: VerificationUploadFilesModel<VerificationDocumentType.questionnaire>;

  constructor(params: Params) {
    super({ step: VerificationStep.questionnaire });
    this.questionnaire = params.questionnaire;
  }

  get isError(): boolean {
    return this.questionnaire.isError || this.questionnaire.checkStatus === CheckStatus.rejected;
  }

  get isFilled(): boolean {
    return this._fields.every((field) => !field.required || field.isUploaded);
  }

  get isChanged(): boolean {
    return this._fields.some((field) => field.touched);
  }

  toJson(): null {
    return null;
  }

  protected get _fields(): Array<UploadFilesModel> {
    return [this.questionnaire];
  }

  static fromJson(server: Nullable<QuestionnaireStepServer>): QuestionnaireStepModel {
    return new QuestionnaireStepModel({
      questionnaire: new VerificationUploadFilesModel<VerificationDocumentType.questionnaire>({
        docType: VerificationDocumentType.questionnaire,
        checkStatus: server?.status ?? null,
        initialValue: FileModel.fileListFromJson(server?.document),
        label: (t) => t('content.questionnaireStep.uploadForm', T_OPTIONS),
        required: true,
        validators: [emptyValueValidator()],
      }),
    });
  }
}
