import { SupplierShipmentStep } from '@/entities/stage/types';

export const stepsOrder: Array<SupplierShipmentStep> = [
  SupplierShipmentStep.supplierSpecification,
  SupplierShipmentStep.supplierSpecificationVerifying,
  SupplierShipmentStep.transitCountry,
  SupplierShipmentStep.transitCountryAwb,
  SupplierShipmentStep.transitCountryAwbVerifying,
  SupplierShipmentStep.cargoShipment,
  SupplierShipmentStep.transfer,
];
