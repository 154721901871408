import { DocumentFileType, UploadOrGenerateDocumentModel, UploadOrGenerateDocumentModelParams } from '@/entities/file';
import { AdditionalAgreementDocPayload } from '@/entities/stage/types';

import { AdditionalAgreementFiedsModel } from './AdditionalAgreementFiedsModel';

export class AdditionalAgreementModel extends UploadOrGenerateDocumentModel<
  DocumentFileType.customerAdditionalAgreement,
  AdditionalAgreementDocPayload,
  AdditionalAgreementFiedsModel
> {
  readonly generationFields: AdditionalAgreementFiedsModel;

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.customerAdditionalAgreement>) {
    super(params);

    this.generationFields = new AdditionalAgreementFiedsModel(params);
  }
}
