import { DocumentWithStatusServer } from '@/entities/file';
import { UploadFileModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator } from '@/shared/utils/validators';

import { VerificationDocumentType } from '../../types/server';
import { VerificationUploadFilesModel } from '../VerificationUploadFilesModel';

type Params<DocType extends VerificationDocumentType> = {
  server: Nullable<Partial<Record<DocType, DocumentWithStatusServer>>>;
  docType: DocType;
  required: boolean;
};

export const initVerificationUploadFilesModel = <DocType extends VerificationDocumentType>({
  server,
  docType,
  required,
}: Params<DocType>): VerificationUploadFilesModel<DocType> =>
  new VerificationUploadFilesModel<DocType>({
    docType,
    initialValue: UploadFileModel.fileListFromJson(server?.[docType]?.document),
    label: (t) => t(`docType.${docType}`, { ns: 'verification' }),
    checkStatus: server?.[docType]?.status ?? null,
    validators: required ? [emptyValueValidator()] : [],
    required,
  });
