import { computed, makeObservable } from 'mobx';

import { ID, MapDataFieldsToNullable } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { AccountServer, TelegramAccountServer, WhatsAppAccountServer } from '../types';

type FullAccountServer = MapDataFieldsToNullable<TelegramAccountServer & WhatsAppAccountServer>;

type AccountModelParams = {
  id: Nullable<ID>;
  link: Nullable<string>;
  firstName: Nullable<string>;
  lastName: Nullable<string>;
  username: Nullable<string>;
  phone: Nullable<string>;
};

export class AccountModel {
  readonly id: Nullable<ID>;
  readonly link: Nullable<string>;
  readonly firstName: Nullable<string>;
  readonly lastName: Nullable<string>;
  readonly username: Nullable<string>;
  readonly phone: Nullable<string>;

  constructor(params: AccountModelParams) {
    this.id = params.id;
    this.link = params.link;
    this.firstName = params.firstName;
    this.lastName = params.lastName;
    this.username = params.username;
    this.phone = params.phone;

    makeObservable(this, {
      accountName: computed,
    });
  }

  get accountName(): Nullable<string> {
    if (this.username) {
      return this.username;
    }

    if (this.firstName || this.lastName) {
      return `${this.firstName ?? ''} ${this.lastName ?? ''}`.trim();
    }

    return null;
  }

  static fromJson(server: AccountServer): AccountModel {
    const fullServer: FullAccountServer = {
      account_id: null,
      account_link: null,
      first_name: null,
      last_name: null,
      username: null,
      phone: null,
      ...server,
    };

    return new AccountModel({
      id: fullServer.account_id,
      link: fullServer.account_link,
      firstName: fullServer.first_name,
      lastName: fullServer.last_name,
      username: fullServer.username,
      phone: fullServer.phone,
    });
  }
}
