import { BaseResponse } from '@kts-front/types';
import { autorun, computed, makeObservable, reaction } from 'mobx';

import { IDictionariesStore, IDictionary } from '@/entities/dictionary';
import {
  DocumentFileType,
  FileModel,
  GenerationDocumentLanguage,
  GenerationFieldsModel,
  SelectGenerationDocumentLanguageModel,
  UploadOrGenerateDocumentModelParams,
} from '@/entities/file';
import { DeliveryConfirmationDocPayload, DeliveryConfirmationDocResponse, Incoterms } from '@/entities/stage/types';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { UploadFilesModel } from '@/shared/model/form/UploadFilesModel';
import { Nullable, Options } from '@/shared/types/values';
import { convertGramPriceToOuncePrice, formatDate, numberParser } from '@/shared/utils';
import {
  AcceptFileFormats,
  emptyValueValidator,
  numberLimitValidator,
  numberValidator,
  stringLengthValidator,
} from '@/shared/utils/validators';

const T_OPTIONS = { ns: 'stage' } as const;
const DEFAULT_METAL_PURITY = '99.99';

export class DeliveryConfirmationFieldsModel extends GenerationFieldsModel<
  DocumentFileType.deliveryConfirmation,
  DeliveryConfirmationDocPayload
> {
  readonly docNumber = new InputModel({
    initialValue: '',
    label: (t) => t('deliveryConfirmation.docNumber', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderNumber', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(16)],
  });
  readonly docCreateDate = new DatePickerModel({
    initialValue: new Date(),
    label: (t) => t('deliveryConfirmation.docCreateDate', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderDate', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly tradeNumber = new InputModel({
    initialValue: '',
    label: (t) => t('deliveryConfirmation.tradeNumber', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderNumber', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(16)],
  });
  readonly tradeCreateDate = new DatePickerModel({
    initialValue: new Date(),
    label: (t) => t('deliveryConfirmation.tradeCreateDate', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderDate', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly metalPurity = new InputModel({
    initialValue: DEFAULT_METAL_PURITY,
    label: (t) => t('deliveryConfirmation.metalPurity', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.metalPurity', T_OPTIONS),
    required: true,
    validators: [
      emptyValueValidator(),
      numberValidator,
      numberLimitValidator({ min: 0, max: 1_000 }),
      stringLengthValidator(16),
    ],
    parser: numberParser(),
  });
  readonly countIngot = new InputModel({
    initialValue: '',
    label: (t) => t('deliveryConfirmation.countIngot', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.countIngotPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser({ maxFractionDigits: 0 }),
  });
  readonly gramPrice = new InputModel({
    initialValue: '',
    label: (t) => t('deliveryConfirmation.gramPrice', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderPrice', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser(),
  });
  readonly ouncePrice = new InputModel({
    initialValue: '',
    label: (t) => t('deliveryConfirmation.ouncePrice', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderPrice', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), numberValidator, stringLengthValidator(16)],
    parser: numberParser(),
  });
  readonly currencyId = new SelectModel<number>({
    initialValue: null,
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly datePayment = new DatePickerModel({
    initialValue: new Date(),
    label: (t) => t('deliveryConfirmation.datePayment', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderDate', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly dateSupplies = new DatePickerModel({
    initialValue: new Date(),
    label: (t) => t('deliveryConfirmation.dateSupplies', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderDate', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly deliveryCountry = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('deliveryConfirmation.placeDelivery', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.placeholderPlace', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly exportCountry = new SelectModel<number>({
    initialValue: null,
    label: (t) => t('deliveryConfirmation.countryExport', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly incoterms = new SelectModel<Incoterms>({
    initialValue: null,
    label: (t) => t('deliveryConfirmation.incoterms', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly exchangeRate = new InputModel({
    initialValue: '',
    label: (t) => t('deliveryConfirmation.exchangeRate', T_OPTIONS),
    placeholder: (t) => t('deliveryConfirmation.exchangeRatePlaceholder', T_OPTIONS),
    validators: [numberValidator, stringLengthValidator(16)],
    parser: numberParser(),
  });
  readonly supplierInitials = new InputModel({
    initialValue: '',
    label: (t) => t('deliveryConfirmation.supplierInitials', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(128)],
  });
  readonly customerInitials = new InputModel({
    initialValue: '',
    label: (t) => t('deliveryConfirmation.customerInitials', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(128)],
  });
  readonly sealAndSignature = new UploadFilesModel({
    initialValue: [],
    label: (t) => t('deliveryConfirmation.sealAndSignature', T_OPTIONS),
    acceptFileFormats: [AcceptFileFormats.png, AcceptFileFormats.jpg, AcceptFileFormats.jpeg],
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly documentLanguage = new SelectGenerationDocumentLanguageModel({
    initialValue: null,
    documentLanguages: [GenerationDocumentLanguage.Ru, GenerationDocumentLanguage.Ru_En],
  });

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.deliveryConfirmation>) {
    super({
      ...params,
      multipartFormData: true,
    });

    makeObservable(this, {
      currencyOptions: computed,
    });

    this.addReactions([
      autorun(() => {
        if (this._rubCurrency) {
          this.currencyId.change(this._rubCurrency.value);
        }
      }),
      reaction(
        () => this.gramPrice.value,
        (gramPrice) => {
          if (gramPrice) {
            const value = convertGramPriceToOuncePrice(Number(this.gramPrice.value));
            this.ouncePrice.change(value.toString());
          } else {
            this.ouncePrice.change('');
          }
        },
      ),
      reaction(
        () => (this.currencyId.value ? this._dictionaries.currencies.list.getEntity(this.currencyId.value) : null),
        (currency) => {
          this.exchangeRate.reset();

          if (currency && currency.label === 'USD') {
            this.exchangeRate.changeRequired(true);
            this.exchangeRate.changeValidators([emptyValueValidator(), numberValidator, stringLengthValidator(16)]);
          } else {
            this.exchangeRate.changeRequired(false);
            this.exchangeRate.changeValidators([numberValidator, stringLengthValidator(16)]);
          }
        },
      ),
    ]);
  }

  private get _dictionaries(): IDictionariesStore {
    return this._tradeWorkflowStore.dictionaries;
  }

  private get _rubCurrency(): Nullable<IDictionary> {
    return this._dictionaries.currencies.list.items.find((currency) => currency.label === 'RUB') ?? null;
  }

  get currencyOptions(): Options<number> {
    return this._dictionaries.currencies.list.items.filter(
      (currency) => currency.label === 'RUB' || currency.label === 'USD',
    );
  }

  get fields() {
    return [
      this.docNumber,
      this.docCreateDate,
      this.tradeNumber,
      this.tradeCreateDate,
      this.metalPurity,
      this.countIngot,
      this.gramPrice,
      this.currencyId,
      this.ouncePrice,
      this.datePayment,
      this.dateSupplies,
      this.deliveryCountry,
      this.incoterms,
      this.exportCountry,
      this.exchangeRate,
      this.supplierInitials,
      this.customerInitials,
      this.documentLanguage,
      this.sealAndSignature,
    ];
  }

  toJson(): Nullable<DeliveryConfirmationDocPayload> {
    const sealAndSignatureFile = this.sealAndSignature.value.at(0);

    if (
      !this.docNumber.value ||
      !this.docCreateDate.value ||
      !this.tradeNumber.value ||
      !this.tradeCreateDate.value ||
      !this.currencyId.value ||
      !this.datePayment.value ||
      !this.dateSupplies.value ||
      !this.incoterms.value ||
      !this.currencyId.value ||
      !this.deliveryCountry.value ||
      !this.exportCountry.value ||
      !this.documentLanguage.value ||
      !this.supplierInitials.value ||
      !this.customerInitials.value ||
      !this.metalPurity.value ||
      !this.countIngot.value ||
      !this.gramPrice.value ||
      !this.ouncePrice.value ||
      !sealAndSignatureFile
    )
      return null;

    return {
      contract_number: this.docNumber.value,
      contract_date: formatDate(this.docCreateDate.value),
      trade_number: this.tradeNumber.value,
      trade_date: formatDate(this.tradeCreateDate.value),
      metal_purity: this.metalPurity.value,
      number_of_ingots: this.countIngot.value,
      price_per_gram: this.gramPrice.value,
      currency_id: this.currencyId.value,
      price_per_ounce: this.ouncePrice.value,
      payment_date: formatDate(this.datePayment.value),
      delivery_date: formatDate(this.dateSupplies.value),
      incoterms: this.incoterms.value,
      delivery_country_id: this.deliveryCountry.value,
      export_country_id: this.exportCountry.value,
      language: this.documentLanguage.value,
      supplier_initials: this.supplierInitials.value,
      customer_initials: this.customerInitials.value,
      signature_with_seal: sealAndSignatureFile.originFileObj ?? sealAndSignatureFile.uid,
      ...(this.exchangeRate.value ? { exchange_rate: this.exchangeRate.value } : {}),
    };
  }

  async loadFieldsData(): Promise<BaseResponse> {
    if (this.loadingState.isLoading) {
      this._fieldsDataRequest.cancel();
    }

    this.loadingState.loading();

    const response = await this._fieldsDataRequest.call<DeliveryConfirmationDocResponse>();

    if (response.isError) {
      this.loadingState.error();
    } else {
      this._fillFieldsByResponseData(response.data);
      this.loadingState.success();
    }

    return response;
  }

  private readonly _fillFieldsByResponseData = (data: DeliveryConfirmationDocResponse): void => {
    this.docNumber.change(data.contract_number || '');
    this.docCreateDate.change(data.contract_date ? new Date(data.contract_date) : null);
    this.tradeNumber.change(data.trade_number || '');
    this.tradeCreateDate.change(data.trade_date ? new Date(data.trade_date) : new Date());
    this.metalPurity.change(data.metal_purity || DEFAULT_METAL_PURITY);
    this.countIngot.change(data.number_of_ingots || '');
    this.gramPrice.change(data.price_per_gram || '');
    this.currencyId.change(data.currency_id ?? this._rubCurrency?.value ?? null);
    this.ouncePrice.change(data.price_per_ounce || '');
    this.datePayment.change(data.payment_date ? new Date(data.payment_date) : null);
    this.dateSupplies.change(data.delivery_date ? new Date(data.delivery_date) : null);
    this.deliveryCountry.change(data.delivery_country_id || null);
    this.incoterms.change(data.incoterms || null);
    this.exchangeRate.change(data.exchange_rate || '');
    this.exportCountry.change(data.export_country_id || null);
    this.supplierInitials.change(data.supplier_initials || '');
    this.customerInitials.change(data.customer_initials || '');
    this.documentLanguage.change(data.language || null);
    this.sealAndSignature.change(FileModel.fileListFromJson(data.signature_with_seal));
  };
}
