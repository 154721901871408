import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable, reaction, runInAction } from 'mobx';

import { IRootStore } from '@/app/store';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel, LocalStore, ValueModel } from '@/shared/model';
import { DateFormat } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';
import { formatDate, formatNumber } from '@/shared/utils';

import { ChartType, TickerHistories, TickerHistoriesServer, TickerHistory, TickerType, TickInfo } from '../types';

import { mapScheduleChartTypeToDateFormat } from './config';

type Params = {
  rootStore: IRootStore;
};

export class CurrentTickerHistoryModel extends LocalStore {
  private readonly _chartType = new ValueModel<ChartType>(ChartType.day);

  private readonly _tickerType = new ValueModel<Nullable<TickerType>>(null);

  private readonly _history = new ValueModel<Nullable<TickerHistories>>(null);

  readonly loadingStage = new LoadingStageModel();

  private readonly _rootStore: IRootStore;

  constructor({ rootStore }: Params) {
    super();

    this._rootStore = rootStore;

    makeObservable(this, {
      tickerType: computed,
      chartType: computed,
      chartTypeHistory: computed,
      priceDifference: computed,
      xAxisTicks: computed,

      openHistory: action.bound,
      closeHistory: action.bound,
      changeChartType: action.bound,
      formatPrice: action.bound,
      formatDateTime: action.bound,
    });

    this.addReactions([
      reaction(
        () => this.tickerType,
        (tickerType) => {
          if (tickerType) {
            this._loadHistory(tickerType);
          }
        },
      ),
    ]);
  }

  get tickerType(): Nullable<TickerType> {
    return this._tickerType.value;
  }

  get chartType(): ChartType {
    return this._chartType.value;
  }

  get chartTypeHistory(): TickerHistory {
    return this._history.value ? this._history.value[this.chartType] : [];
  }

  get priceDifference(): Nullable<{ sum: string; percent: string; isPositive: boolean; isNegative: boolean }> {
    const chartTypeHistory = this.chartTypeHistory;

    if (chartTypeHistory.length < 2 || this.loadingStage.isLoading) {
      return null;
    }

    const firstPrice = chartTypeHistory[0].price;
    const lastPrice = chartTypeHistory[chartTypeHistory.length - 1].price;

    const priceDiff = lastPrice - firstPrice;

    const sum = formatNumber(priceDiff, {
      language: this._rootStore.localizationStore.lng,
    });

    const percent = formatNumber((priceDiff / firstPrice) * 100, {
      language: this._rootStore.localizationStore.lng,
    });

    return {
      sum,
      percent,
      isPositive: firstPrice < lastPrice,
      isNegative: firstPrice > lastPrice,
    };
  }

  get xAxisTicks(): Array<TickInfo> {
    const dateFormat = this.chartType === ChartType.day ? 'YYYY-MM-DD-HH' : 'YYYY-MM-DD';

    const ticksMap = new Map<string, TickInfo>();

    this.chartTypeHistory.forEach(({ datetime }) => {
      const datetimeKey = formatDate(datetime, dateFormat);
      const datetimeData = ticksMap.get(datetimeKey);

      if (datetimeData) {
        ticksMap.set(datetimeKey, {
          ...datetimeData,
          pointCount: datetimeData.pointCount + 1,
        });

        return;
      }

      ticksMap.set(datetimeKey, { datetime, pointCount: 1 });
    });

    return Array.from(ticksMap.values());
  }

  formatPrice(price: number): string {
    return formatNumber(price, { language: this._rootStore.localizationStore.lng });
  }

  formatDateTime(dateTime: Date | string): string {
    return formatDate(dateTime, mapScheduleChartTypeToDateFormat[this.chartType]);
  }

  changeChartType(value: ChartType) {
    this._chartType.change(value);
  }

  openHistory(tickerType: TickerType) {
    this._tickerType.change(tickerType);
  }

  closeHistory() {
    this._tickerType.change(null);
  }

  private readonly _loadHistory = async (tickerType: TickerType): Promise<BaseResponse> => {
    this.loadingStage.loading();

    const response = await apiStore
      .createRequest<TickerHistoriesServer>({
        url: apiUrls.dictionaries.tickerHistory(tickerType),
      })
      .call();

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    /**
     * Проверяем историю за день, чтобы на начала каждого часа были данные
     */
    const verifiedDayHistory = response.data.history.day.reduce<{ hours: Array<string>; history: TickerHistory }>(
      (acc, item) => {
        /** Получаем час и минуту по лондону */
        const [hour, minute] = formatDate(item.datetime, DateFormat.time).split(':');

        /** Добавляем час в массив если его нет */
        if (!acc.hours.includes(hour)) {
          acc.hours.push(hour);

          const prevItem = acc.history.at(-1);

          /**
           * Если данные не на начало часа (minute !== '00'),
           * то для начала часа (HH:00:00) указываем предыдущую цену
           * и добавляем новые данные в историю
           */
          if (minute !== '00' && prevItem) {
            acc.history.push({
              datetime: formatDate(item.datetime, 'YYYY-MM-DDTHH:00:00[Z]'),
              price: prevItem.price,
            });
          }
        }

        /** Добавляем серверные данные в историю */
        acc.history.push(item);

        return acc;
      },
      { hours: [], history: [] },
    );

    runInAction(() => {
      this._history.change({
        month: response.data.history.month,
        week: response.data.history.week,
        day: verifiedDayHistory.history,
      });
      this.loadingStage.success();
    });

    return { isError: false };
  };
}
