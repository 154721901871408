import { CompanyType } from '@/entities/company';
import { DocumentFileType, UploadOrGenerateDocumentModel } from '@/entities/file';
import {
  AwbTransitAction,
  StageModelJsonParams,
  StageType,
  SupplierShipmentStageDataServer,
  SupplierShipmentStep,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/entities/stage/utils';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import {
  SupplierSpecificationModel,
  UploadAwbOrFlightNumberStepModel,
  UploadOrGenerateDocumentStepModel,
  WaitingStepModel,
} from '../../fieldsModel';

import { stepsOrder } from './config';

type Params = BaseStageModelParams<SupplierShipmentStep> & {
  supplierSpecificationStep: UploadOrGenerateDocumentStepModel<
    SupplierShipmentStep.supplierSpecification,
    SupplierShipmentStep.supplierSpecification,
    DocumentFileType.supplierSpecification,
    SupplierSpecificationModel
  >;
  supplierSpecificationVerifyingStep: WaitingStepModel<SupplierShipmentStep>;
  transitCountryStep: WaitingStepModel<SupplierShipmentStep>;
  transitCountryAwbStep: UploadAwbOrFlightNumberStepModel<
    SupplierShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber,
    DocumentFileType.awbForTransitCountry
  >;
  transitCountryAwbVerifyingStep: WaitingStepModel<SupplierShipmentStep>;
  isCargoShipped: boolean;
};

export class SupplierShipmentStageModel extends BaseStageModel<StageType.shipment, SupplierShipmentStep> {
  readonly supplierSpecificationStep: UploadOrGenerateDocumentStepModel<
    SupplierShipmentStep.supplierSpecification,
    SupplierShipmentStep.supplierSpecification,
    DocumentFileType.supplierSpecification,
    SupplierSpecificationModel
  >;
  readonly supplierSpecificationVerifyingStep: WaitingStepModel<SupplierShipmentStep>;
  /** Поля для StageType.transit */
  readonly transitCountryStep: WaitingStepModel<SupplierShipmentStep>;
  readonly transitCountryAwbStep: UploadAwbOrFlightNumberStepModel<
    SupplierShipmentStep,
    AwbTransitAction.uploadFile,
    AwbTransitAction.flightNumber,
    DocumentFileType.awbForTransitCountry
  >;
  readonly transitCountryAwbVerifyingStep: WaitingStepModel<SupplierShipmentStep>;

  readonly isCargoShipped: boolean;

  constructor(params: Params) {
    super({
      type: StageType.shipment,
      ...params,
    });

    this.supplierSpecificationStep = params.supplierSpecificationStep;
    this.supplierSpecificationVerifyingStep = params.supplierSpecificationVerifyingStep;
    this.transitCountryStep = params.transitCountryStep;
    this.transitCountryAwbStep = params.transitCountryAwbStep;
    this.transitCountryAwbVerifyingStep = params.transitCountryAwbVerifyingStep;
    this.isCargoShipped = params.isCargoShipped;
  }

  private get _isAwbVerifying(): boolean {
    return this.transitCountryAwbStep.uploadedBy === CompanyType.supplier;
  }

  get isApprovedSpec(): boolean {
    return this.supplierSpecificationStep.isApproved;
  }

  get isApprovedAwb(): boolean {
    return this.transitCountryAwbStep.isApproved;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): SupplierShipmentStep[] {
    const steps: SupplierShipmentStep[] = [
      SupplierShipmentStep.supplierSpecification,
      /** Если нужна верификация спецификации, то добавляем шаг верификации */
      ...(this.supplierSpecificationStep.isWaitVerifying ? [SupplierShipmentStep.supplierSpecificationVerifying] : []),
    ];

    /** Если тип отгрузки = transit, то добавляем шаги для transit */
    if (this.isShipmentTypeTransit) {
      steps.push(
        SupplierShipmentStep.transitCountry,
        SupplierShipmentStep.transitCountryAwb,
        /** Если нужна верификация Awb, то добавляем шаг верификации Awb */
        ...(this._isAwbVerifying ? [SupplierShipmentStep.transitCountryAwbVerifying] : []),
      );
    }

    steps.push(SupplierShipmentStep.cargoShipment, SupplierShipmentStep.transfer);

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<SupplierShipmentStageDataServer>): SupplierShipmentStageModel {
    const { current_step, steps } = dataServer;

    return new SupplierShipmentStageModel({
      supplierSpecificationStep: UploadOrGenerateDocumentStepModel.fromJson({
        uploadOrGenerateDocument: new SupplierSpecificationModel(
          UploadOrGenerateDocumentModel.paramsFromJson({
            data: dataServer.steps[SupplierShipmentStep.supplierSpecification]?.document,
            docType: DocumentFileType.supplierSpecification,
            tradeWorkflowStore,
            required: true,
            fileName: (t) => t('supplierSpecification.fileName', { ns: 'stage' }),
          }),
        ),
        data: dataServer.steps[SupplierShipmentStep.supplierSpecification],
        step: SupplierShipmentStep.supplierSpecification,
        approveAction: SupplierShipmentStep.supplierSpecification,
        tradeWorkflowStore,
      }),
      supplierSpecificationVerifyingStep: WaitingStepModel.fromJson({
        step: SupplierShipmentStep.supplierSpecificationVerifying,
        tradeWorkflowStore,
      }),
      transitCountryStep: WaitingStepModel.fromJson({
        step: SupplierShipmentStep.transitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwbStep: UploadAwbOrFlightNumberStepModel.fromServer({
        data: steps[SupplierShipmentStep.transitCountryAwb],
        step: SupplierShipmentStep.transitCountryAwb,
        approveFileAction: AwbTransitAction.uploadFile,
        approveNumberAction: AwbTransitAction.flightNumber,
        docType: DocumentFileType.awbForTransitCountry,
        tradeWorkflowStore,
      }),
      transitCountryAwbVerifyingStep: WaitingStepModel.fromJson({
        step: SupplierShipmentStep.transitCountryAwbVerifying,
        tradeWorkflowStore,
      }),
      isCargoShipped:
        dataServer.steps[SupplierShipmentStep.cargoShipment]?.shipped || current_step === SupplierShipmentStep.transfer,

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
      step: current_step,
    });
  }
}
