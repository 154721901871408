import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { UploadOrGenerateDocument } from '@/entities/file';

import { SupplierSpecificationModel } from '../../model';

import SupplierSpecificationForm from './SupplierSpecificationForm';

export type SupplierSpecificationProps = {
  model: SupplierSpecificationModel;
  disabled?: boolean;
};

const SupplierSpecification: React.FC<SupplierSpecificationProps> = ({ model, disabled }) => {
  const { userStore } = useRootStore();

  return (
    <UploadOrGenerateDocument
      model={model}
      disabled={disabled}
      generatable={userStore.isSupplier}
      gridContent={false}
      FieldsComponent={SupplierSpecificationForm}
    />
  );
};

export default observer(SupplierSpecification);
