import { BaseResponse } from '@kts-front/types';
import { nanoid } from 'nanoid';

import {
  DocumentFileType,
  FileModel,
  GenerationDocumentLanguage,
  GenerationFieldsModel,
  SelectGenerationDocumentLanguageModel,
  UploadOrGenerateDocumentModelParams,
} from '@/entities/file';
import {
  SupplierIngotServer,
  SupplierSpecificationDocPayload,
  SupplierSpecificationDocResponse,
} from '@/entities/stage/types';
import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { UploadFilesModel } from '@/shared/model/form/UploadFilesModel';
import { Nullable } from '@/shared/types/values';
import { formatDate, numberParser } from '@/shared/utils';
import { AcceptFileFormats, emptyValueValidator, stringLengthValidator } from '@/shared/utils/validators';

import { SupplierIngotListModel } from './SupplierIngotListModel';
import { SupplierIngotModel } from './SupplierIngotModel';

const T_OPTIONS = { ns: 'stage' } as const;

export class SupplierSpecificationFieldsModel extends GenerationFieldsModel<
  DocumentFileType.supplierSpecification,
  SupplierSpecificationDocPayload
> {
  readonly specificationNumber = new InputModel({
    initialValue: '',
    label: (t) => t('supplierSpecification.numberSpecification', T_OPTIONS),
    placeholder: (t) => t('supplierSpecification.numberSpecificationPlaceholder', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(16)],
    parser: numberParser(),
  });
  readonly specificationDate = new DatePickerModel({
    initialValue: new Date(),
    label: (t) => t('supplierSpecification.dateSpecification', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly ingots = new SupplierIngotListModel({
    rootStore: this._tradeWorkflowStore.rootStore,
    productId: this._tradeWorkflowStore.tradeInfo.productId,
    productTypeId: this._tradeWorkflowStore.tradeInfo.productTypeId,
  });
  readonly supplierInitials = new InputModel({
    initialValue: '',
    label: (t) => t('supplierSpecification.supplierInitials', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(128)],
  });
  readonly supplierPosition = new InputModel({
    initialValue: '',
    label: (t) => t('supplierSpecification.supplierPosition', T_OPTIONS),
    required: true,
    validators: [emptyValueValidator(), stringLengthValidator(128)],
  });
  readonly sealAndSignature = new UploadFilesModel({
    initialValue: [],
    label: (t) => t('supplierSpecification.uploadSealAndSignature', T_OPTIONS),
    acceptFileFormats: [AcceptFileFormats.png, AcceptFileFormats.jpg, AcceptFileFormats.jpeg],
    required: true,
    validators: [emptyValueValidator()],
  });
  readonly documentLanguage = new SelectGenerationDocumentLanguageModel({
    initialValue: null,
    documentLanguages: [GenerationDocumentLanguage.Ru, GenerationDocumentLanguage.En],
  });

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.supplierSpecification>) {
    super({
      ...params,
      multipartFormData: true,
    });
  }

  get fields() {
    return [
      this.specificationNumber,
      this.specificationDate,
      this.supplierInitials,
      this.supplierPosition,
      this.sealAndSignature,
      this.documentLanguage,
      ...this.ingots.list.items.flatMap((item) => item.fields),
    ];
  }

  toJson(): Nullable<SupplierSpecificationDocPayload> {
    const specification_number = this.specificationNumber.value;
    const specification_date = this.specificationDate.value && formatDate(this.specificationDate.value);
    const supplier_initials = this.supplierInitials.value;
    const seller_type = this.supplierPosition.value;
    const language = this.documentLanguage.value;
    const sealAndSignature = this.sealAndSignature.value.at(0);

    if (
      !specification_number ||
      !specification_date ||
      !supplier_initials ||
      !seller_type ||
      !sealAndSignature ||
      !language ||
      this.ingots.isEmptyValue
    ) {
      return null;
    }

    const bars: Array<SupplierIngotServer> = this.ingots.list.items.map((item, index) => {
      return {
        ...item.toJson(),
        number: index + 1,
      };
    });

    return {
      specification_number,
      specification_date,
      supplier_initials,
      seller_type,
      language,
      signature_with_seal: sealAndSignature.originFileObj ?? sealAndSignature.uid,
      bars: JSON.stringify(bars),
    };
  }

  async loadFieldsData(): Promise<BaseResponse> {
    if (this.loadingState.isLoading) {
      this._fieldsDataRequest.cancel();
    }

    this.loadingState.loading();

    const response = await this._fieldsDataRequest.call<SupplierSpecificationDocResponse>();

    if (response.isError) {
      this.loadingState.error();
    } else {
      this._fillFieldsByResponseData(response.data);
      this.loadingState.success();
    }

    return response;
  }

  private readonly _fillFieldsByResponseData = (data: SupplierSpecificationDocResponse): void => {
    this.specificationNumber.change(data.specification_number ?? '');
    this.specificationDate.change(data.specification_date ? new Date(data.specification_date) : new Date());
    this.supplierInitials.change(data.supplier_initials ?? '');
    this.documentLanguage.change(data.language ?? null);
    this.sealAndSignature.change(FileModel.fileListFromJson(data.signature_with_seal));

    const bars = data.bars ?? [];

    if (bars.length === 0) return;

    this.ingots.list.fillByRawData(
      bars,
      (rawItem) => ({
        entity: SupplierIngotModel.formJson(rawItem),
        key: nanoid(),
      }),
      true,
    );
  };
}
