export const login = {
  en: {
    title: 'Login',
    form: {
      email: 'Email',
      password: 'Password',
      rememberMe: 'Remember me',
      forgotPassword: 'Forgot password?',
      submit: 'Submit',
    },
    verification: {
      title: 'KYC verification',
      description: "To fully access your company's platform tools, you must be verified.",
      submit: 'Pass verification',
    },
  },
  ru: {
    title: 'Вход',
    form: {
      email: 'Email',
      password: 'Пароль',
      rememberMe: 'Запомнить меня',
      forgotPassword: 'Забыли пароль?',
      submit: 'Войти',
    },
    verification: {
      title: 'Верификация KYC',
      description: 'Для полного доступа к инструментам платформы вашей компании необходимо пройти верификацию.',
      submit: 'Пройти верификацию',
    },
  },
  zh: {
    title: '登录',
    form: {
      email: '电子邮箱',
      password: '密码',
      rememberMe: '记住我',
      forgotPassword: '忘了密码？',
      submit: '登录',
    },
    verification: {
      title: null,
      description: null,
      submit: null,
    },
  },
};
