import { DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CustomerIngotListModel } from '@/entities/stage/model';
import { IngotIdType } from '@/entities/stage/types';
import { CustomExpand } from '@/shared/ui';
import { CustomButton } from '@/shared/ui/form/CustomButton';
import { FormDatePicker } from '@/shared/ui/form/FormDatePicker';
import { FormInput } from '@/shared/ui/form/FormInput';

import s from './ExpandIngotList.module.scss';

type ExpandIngotListProps = {
  model: CustomerIngotListModel;
};

const ExpandIngotList: React.FC<ExpandIngotListProps> = ({ model }) => {
  return model.list.items.map((item, index) => (
    <CustomExpand
      key={index}
      className={cn(model.isError && s.error)}
      footer={
        <CustomButton
          className={s.delete}
          disabled={model.list.keys.length === 1}
          type="text"
          icon={<DeleteOutlined />}
          onClick={() => model.deleteIngot(model.list.keys[index])}
        />
      }
    >
      <FormInput model={item.ingotNumber} />
      <FormInput model={item.ligatureWeight} />
      <FormInput model={item.weight} />
      <FormInput model={item.metalPurity} />
      {model.yearOrUin.value === IngotIdType.year ? (
        <FormDatePicker model={item.year} picker="year" />
      ) : (
        <FormInput model={item.uin} />
      )}
    </CustomExpand>
  ));
};

export default observer(ExpandIngotList);
