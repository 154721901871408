import { Button, Dropdown } from 'antd';
import { MenuItemType } from 'antd/es/menu/hooks/useItems';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useRootStore } from '@/app/store';
import { RouteKey } from '@/shared/config/routes';
import { useTypedTranslation } from '@/shared/hooks';
import { getPathname } from '@/shared/utils';

import EducationIcon from './EducationIcon';

import s from './OnboardingButton.module.scss';

const PREFERRED_PATHNAME = 'notifications';

const OnboardingButton: React.FC = () => {
  const { onboardingStore } = useRootStore();
  const location = useLocation();
  const rootPathname = getPathname({ location, preferredKey: PREFERRED_PATHNAME });

  const { t } = useTypedTranslation('onboarding');

  const items: Array<MenuItemType> = React.useMemo(
    () => [
      {
        key: 'startFromBeginning',
        label: t('buttons.startFromBeginning'),
        onClick: () => onboardingStore.startOnboarding(),
      },
      {
        key: 'startFromCurrentPage',
        label: t('buttons.startFromCurrentPage'),
        onClick: () => onboardingStore.startOnboarding(rootPathname as RouteKey),
      },
    ],
    [onboardingStore, rootPathname, t],
  );

  return (
    onboardingStore.showOnboardingButton && (
      <Dropdown menu={{ items }} trigger={['click']} placement="topRight" destroyPopupOnHide>
        <Button className={s.button} type="primary" size="large" icon={<EducationIcon />} />
      </Dropdown>
    )
  );
};

export default observer(OnboardingButton);
