import { ITradeWorkflowStore } from '@/entities/trade';

import { BaseStageDataServer, StageType } from '../../types';
import { BaseStageModel, BaseStageModelParams } from '../BaseStageModel';
import { ManagerTransferStepModel } from '../fieldsModel';

export type BaseManagerStageModelParams<Step> = BaseStageModelParams<Step> & {
  transferFields: ManagerTransferStepModel;
};

type Params<TStage extends StageType, Step> = BaseManagerStageModelParams<Step> & {
  type: TStage;
};

export abstract class BaseManagerStageModel<TStage extends StageType, Step> extends BaseStageModel<TStage, Step> {
  readonly transferFields: ManagerTransferStepModel;

  constructor({ transferFields, ...params }: Params<TStage, Step>) {
    super(params);

    this.transferFields = transferFields;
  }

  static paramsFromJson<Step, Steps>({
    dataServer,
    tradeWorkflowStore,
  }: {
    dataServer: BaseStageDataServer<Step, Steps>;
    tradeWorkflowStore: ITradeWorkflowStore;
  }): BaseManagerStageModelParams<Step> {
    return {
      transferFields: ManagerTransferStepModel.fromJson(tradeWorkflowStore),
      ...BaseStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    };
  }
}
