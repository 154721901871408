import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { CustomTypographyText, TrendArrow } from '@/shared/ui';

import { ITicker, TickerType } from '../../types';

import s from './Ticker.module.scss';

type TickerProps = {
  className?: string;
  model: ITicker;
  showTrendArrow?: boolean;
  onClick?: (tickerType: TickerType) => void;
};

const Ticker: React.FC<TickerProps> = ({ className, model, showTrendArrow = true, onClick }) => {
  const { tickerType, isPositive, isNegative, formatedCurrent, formatedDifference } = model;

  const { t } = useTypedTranslation('dictionary');

  const handleClick = React.useCallback(() => {
    if (onClick) {
      onClick(tickerType);
    }
  }, [onClick, tickerType]);

  return (
    <Flex className={cn(s.ticker, className)} onClick={handleClick}>
      <CustomTypographyText className="ticker_label">{t(`tickerType.${tickerType}`)}</CustomTypographyText>
      <CustomTypographyText className="ticker_value" type="secondary">
        {formatedCurrent}
      </CustomTypographyText>
      <Flex align="center">
        {showTrendArrow && <TrendArrow positive={isPositive} negative={isNegative} size={12} />}
        <CustomTypographyText
          className={cn(
            'ticker_difference',
            isPositive && 'ticker_difference_positive',
            isNegative && 'ticker_difference_negative',
          )}
        >
          <span className="ticker_difference_prefix">
            {isPositive && '+'}
            {isNegative && '-'}
          </span>
          {formatedDifference.replace('-', '')}
        </CustomTypographyText>
      </Flex>
    </Flex>
  );
};

export default observer(Ticker);
