import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { CustomAlert, StepDescription } from '@/shared/ui';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { ConfirmationStageModel, DeliveryConfirmationModel, UploadOrGenerateDocumentStepModel } from '../../model';
import { StepComponentProps } from '../../types';
import DeliveryConfirmation from '../DeliveryConfirmation';

const DeliveryConfirmationStep = <Model extends ConfirmationStageModel>({
  model,
  stepKey,
  description,
}: StepComponentProps<Model>) => {
  const { userStore } = useRootStore();
  const step = stepKey && model[stepKey];

  const isDeliveryConfirmation =
    step instanceof UploadOrGenerateDocumentStepModel &&
    step.uploadOrGenerateDocument instanceof DeliveryConfirmationModel;

  if (!isDeliveryConfirmation) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {userStore.isSupplier && step.isRejected && (
        <CustomAlert
          type="error"
          message={(t) => t('messages.rejectedDocError', { ns: 'file' })}
          description={step.error}
        />
      )}
      <DeliveryConfirmation model={step.uploadOrGenerateDocument} disabled={step.isDisabled || step.isLoading} />
      <ApproveButton
        loading={step.isLoading}
        disabled={step.isDisabled || step.approveDisabled}
        onClick={step.approveStep}
        block={userStore.isSupplier}
      />
    </StepDescription>
  );
};

export default observer(DeliveryConfirmationStep);
