import { StageType } from '@/entities/stage';

export const trades = {
  en: {
    title: 'Trades',
    stageStatus: {
      [StageType.creation]: 'Creation',
      [StageType.confirmation]: 'Confirmation',
      [StageType.payment]: 'Payment',
      [StageType.shipment]: 'Shipment',
      [StageType.transportation]: 'Transportation',
      [StageType.finalization]: 'Completing the deal',
      [StageType.finished]: 'Completed',
    },
    columns: {
      number: 'Number',
      product: 'Metal',
      weight: 'Weight',
      chemicalWeight: 'Pure',
      ligatureWeight: 'Gross',
      amount: 'Trade amount',
      customerPayment: 'Customer payment',
      fixingDate: 'Fixing date',
      transitCountryCargoArrivalDate: 'Arrival in transit',
      transitCountryCargoShipmentDate: 'Departure from transit',
      destinationCountryCargoArrivalDate: 'Arrival at the arrival airport',
      cargoDeliveredDate: 'Delivery to the customer',
      paymentDate: 'Payment date',
      customerCompany: 'Customer',
      supplierCompany: 'Supplier',
      status: 'Status',
      etdDate: 'ETD',
      fixingPrice: 'Fixing price',
    },
    actions: {
      download: 'Download list',
      create: 'Create trade',
    },
    deleteModal: {
      title: 'Confirm deletion of trade №\u00A0{{tradeId}}',
      deleteSuccess: 'The trade successfully deleted',
      deleteError: 'An error occurred while deleting the trade',
    },
    downloadMenu: {
      all: 'Download all trades',
      fixingDate: 'By fixing date',
      etdDate: 'By expected arrival date',
      currentWeek: 'Current week',
      currentMonth: 'Current month',
    },
  },
  ru: {
    title: 'Сделки',
    stageStatus: {
      [StageType.creation]: 'Создание сделки',
      [StageType.confirmation]: 'Подтверждение',
      [StageType.payment]: 'Оплата',
      [StageType.shipment]: 'Отгрузка',
      [StageType.transportation]: 'Перевозка',
      [StageType.finalization]: 'Завершение сделки',
      [StageType.finished]: 'Сделка завершена',
    },
    columns: {
      number: 'Номер сделки',
      product: 'Металл',
      weight: 'Вес',
      chemicalWeight: 'Химический',
      ligatureWeight: 'Лигатурный',
      amount: 'Сумма сделки',
      customerPayment: 'Оплата покупателя',
      fixingDate: 'Дата фиксинга',
      transitCountryCargoArrivalDate: 'Прилет в транзит',
      transitCountryCargoShipmentDate: 'Вылет из транзита',
      destinationCountryCargoArrivalDate: 'Прилет в аэропорт прибытия',
      cargoDeliveredDate: 'Доставка покупателю',
      paymentDate: 'Дата оплаты',
      customerCompany: 'Покупатель',
      supplierCompany: 'Поставщик',
      status: 'Статус',
      etdDate: null,
      fixingPrice: 'Цена фиксации',
    },
    actions: {
      download: 'Скачать список',
      create: 'Создать сделку',
    },
    deleteModal: {
      title: 'Подтвердите удаление сделки №\u00A0{{tradeId}}',
      deleteSuccess: 'Сделка успешно удалена',
      deleteError: 'Произошла ошибка при удалении сделки',
    },
    downloadMenu: {
      all: 'Скачать все сделки',
      fixingDate: 'По дате фиксинга',
      etdDate: 'По ожидаемой дате прибытия',
      currentWeek: 'Текущая неделя',
      currentMonth: 'Текущий месяц',
    },
  },
  zh: {
    title: '交易',
    stageStatus: {
      [StageType.creation]: '交易创建中',
      [StageType.confirmation]: '确认',
      [StageType.payment]: '付款',
      [StageType.shipment]: '装运',
      [StageType.transportation]: '搬运',
      [StageType.finalization]: '完成交易',
      [StageType.finished]: '交易完成',
    },
    columns: {
      number: '交易编号',
      product: '金属',
      weight: '重量',
      chemicalWeight: '化学',
      ligatureWeight: '结构',
      amount: '交易总额',
      customerPayment: '买方付款',
      fixingDate: '拍板成交日期',
      transitCountryCargoArrivalDate: '抵达过境点',
      transitCountryCargoShipmentDate: '从过境点出发',
      destinationCountryCargoArrivalDate: '抵达机场',
      cargoDeliveredDate: '交付给买方',
      paymentDate: '付款日期',
      customerCompany: '买方',
      supplierCompany: '供应商',
      status: '状态',
      etdDate: null,
      fixingPrice: '固定价格',
    },
    actions: {
      download: '下载列表',
      create: '创建交易',
    },
    deleteModal: {
      title: '确认删除 {{tradeId}}号交易',
      deleteSuccess: '交易已成功删除',
      deleteError: '删除交易时出错',
    },
    downloadMenu: {
      all: '下载所有交易',
      fixingDate: '按定盘日期',
      etdDate: '按预计抵达日期',
      currentWeek: '当前周',
      currentMonth: '当前月',
    },
  },
};
