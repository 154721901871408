import { Flex, UploadProps } from 'antd';
import { ItemRender } from 'antd/es/upload/interface';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { UploadFilesModel } from '@/shared/model/form/UploadFilesModel';
import { CheckStatus } from '@/shared/types/meta';

import { CustomTypographyText } from '../../CustomTypographyText';
import { CustomFormItem } from '../CustomFormItem';
import { CustomUpload } from '../CustomUpload';
import { UploadDragger } from '../UploadDragger';
import { UploadButton } from '../buttons';

/**
 *  default - загрузка через системное окно
 *  dragger - загрузка через drag&drop
 */
type FormUploadVariant = 'default' | 'dragger';

type FormUploadProps<UploadModel extends UploadFilesModel> = Pick<
  UploadProps,
  'className' | 'disabled' | 'itemRender'
> & {
  model: UploadModel;
  variant?: FormUploadVariant;
  buttonText?: string;
  omitTriggerButton?: boolean;
  checkStatus?: CheckStatus;
};

const itemRender: ItemRender = (originNode, file) => {
  return (
    <Flex vertical>
      {originNode}
      {file.error && <CustomTypographyText type="secondary">{file.error}</CustomTypographyText>}
    </Flex>
  );
};

const FormUpload = <UploadModel extends UploadFilesModel>({
  className,
  model,
  variant = 'default',
  disabled,
  buttonText,
  omitTriggerButton = false,
  checkStatus,
  ...props
}: FormUploadProps<UploadModel>) => {
  return (
    <CustomFormItem className={className} ref={model.ref} model={model} checkStatus={checkStatus}>
      {variant === 'default' ? (
        <CustomUpload {...model.props} {...props} disabled={disabled} itemRender={props.itemRender ?? itemRender}>
          {model.props.openFileDialogOnClick && !omitTriggerButton && (
            <UploadButton disabled={model.props.disabled || disabled} text={buttonText} />
          )}
        </CustomUpload>
      ) : (
        <UploadDragger {...model.props} {...props} disabled={disabled} />
      )}
    </CustomFormItem>
  );
};

export default observer(FormUpload);
