import * as React from 'react';

import { IconProps } from '../types';

const Building: React.FC<IconProps> = ({ size = 16, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.25 20.577V7.07703H7.25V3.07703H16.75V11.077H20.75V20.577H13.25V16.577H10.75V20.577H3.25ZM4.75 19.077H7.25V16.577H4.75V19.077ZM4.75 15.077H7.25V12.577H4.75V15.077ZM4.75 11.077H7.25V8.57703H4.75V11.077ZM8.75 15.077H11.25V12.577H8.75V15.077ZM8.75 11.077H11.25V8.57703H8.75V11.077ZM8.75 7.07703H11.25V4.57703H8.75V7.07703ZM12.75 15.077H15.25V12.577H12.75V15.077ZM12.75 11.077H15.25V8.57703H12.75V11.077ZM12.75 7.07703H15.25V4.57703H12.75V7.07703ZM16.75 19.077H19.25V16.577H16.75V19.077ZM16.75 15.077H19.25V12.577H16.75V15.077Z"
        fill="currentColor"
        fillOpacity="0.88"
      />
    </svg>
  );
};

export default React.memo(Building);
