import { DocumentFileType } from '@/entities/file';

export const transportationStage = {
  en: {
    manager: {
      transitCountryCargoArrival: {
        title: 'Arrival of cargo in the transit country',
        description: 'Enter the actual date of arrival of the cargo in the transit country',
      },
      cargoArrival: {
        title: 'Arrival of cargo',
        description: 'Enter the actual date of arrival of the cargo in the destination country',
      },
      supplierAcceptanceCertificate: {
        title: 'Upload the acceptance certificate signed on one side',
        description: 'Upload the acceptance certificate and click “Approve”',
      },
      supplierAcceptanceCertificateVerifying: {
        title: 'Check acceptance certificate',
        description:
          'Download the document of the acceptance certificate from the previous step. Check that all the information is correct and click the “All correct” button to proceed further',
      },
      supplierSignedAcceptanceCertificate: {
        title: 'Upload the acceptance certificate, signed on both sides',
        description: 'Upload the acceptance certificate and click “Approve”',
      },
      destinationCountryDocs: {
        title: 'Upload documents for destination country',
        descriptions: {
          exportInvoice: 'Upload invoice for export 4%',
          customerCommercialInvoice: 'Upload the commercial invoice for the customer',
          customerSpecification: 'Upload the specification for the customer on our company letterhead',
          customerAcceptanceCertificate: 'Transfer and acceptance report for the customer',
          customerAdditionalAgreement: 'Supplementary agreement for the customer',
        },
      },
      destinationCountryAwb: {
        title: "Upload the destination country's AWB or enter the flight number",
      },
      transitCountryCargoShipment: {
        title: 'Shipment of cargo',
        description: 'When the shipment leaves the transit country, confirm shipment',
      },
      destinationCountryCargoArrival: {
        title: 'Arrival of cargo in the destination country',
        description: 'Enter the actual date of arrival of the cargo in the destination country',
      },
    },
    customer: {
      cargoArrival: {
        title: 'Waiting for the cargo to arrive',
        description: 'Wait until the manager marks the arrival of the cargo in the destination country',
        dataTitle: 'Actual date of arrival of the cargo in the destination country',
      },
      destinationCountryDocs: {
        title: 'Wait for the deal documents to load',
        waitDescription: 'Once the documents are uploaded they will appear here',
        approveDescription: 'Documents uploaded',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Invoice',
          [DocumentFileType.customerSpecification]: 'Specification',
          [DocumentFileType.customerAcceptanceCertificate]: 'Transfer and acceptance report signed on one side',
          [DocumentFileType.customerAdditionalAgreement]: 'Supplementary agreement',
        },
      },
      transfer: {
        title: 'The cargo has arrived',
      },
    },
  },
  ru: {
    manager: {
      transitCountryCargoArrival: {
        title: 'Прибытие груза в страну транзита',
        description: 'Введите фактическую дату прибытия груза в страну транзита',
      },
      cargoArrival: {
        title: 'Прибытие груза',
        description: 'Введите фактическую дату прибытия груза в страну назначения',
      },
      supplierAcceptanceCertificate: {
        title: 'Загрузите акт приема-передачи, подписанный с одной стороны',
        description: 'Загрузите акт приема-передачи и нажмите “Подтвердить”',
      },
      supplierAcceptanceCertificateVerifying: {
        title: 'Проверьте акт приема-передачи',
        description:
          'Скачайте документ акта приема-передачи из предыдущего шага. Проверьте, что вся информация верна, и нажмите кнопку “Все правильно”, чтобы пройти дальше',
      },
      supplierSignedAcceptanceCertificate: {
        title: 'Загрузите акт приема-передачи, подписанный с двух сторон',
        description: 'Загрузите акт приема-передачи и нажмите “Подтвердить”',
      },
      destinationCountryDocs: {
        title: 'Загрузите документы для страны назначения',
        descriptions: {
          exportInvoice: 'Загрузите инвойс для экспорта 4%',
          customerCommercialInvoice: 'Загрузите коммерческий инвойс для покупателя',
          customerSpecification: 'Загрузите спецификацию для покупателя на бланке нашей компании',
          customerAcceptanceCertificate: 'Акт приема-передачи, подписанный с одной стороны',
          customerAdditionalAgreement: 'Дополнительное соглашение для покупателя',
        },
      },
      destinationCountryAwb: {
        title: 'Загрузите AWB страны назначения или введите номер рейса',
      },
      transitCountryCargoShipment: {
        title: 'Отгрузка груза',
        description: 'Когда груз будет отправлен из страны транзита, подтвердите отгрузку',
      },
      destinationCountryCargoArrival: {
        title: 'Прибытие груза в страну назначения',
        description: 'Введите фактическую дату прибытия груза в страну назначения',
      },
    },
    customer: {
      cargoArrival: {
        title: 'Ожидание прибытия груза',
        description: 'Дождитесь, пока менеджер отметит прибытие груза в страну назначения',
        dataTitle: 'Фактическая дата прибытия груза в страну назначения',
      },
      destinationCountryDocs: {
        title: 'Ожидайте загрузку документов по сделке',
        waitDescription: 'Когда документы будут загружены, они появятся здесь',
        approveDescription: 'Документы загружены',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: 'Инвойс',
          [DocumentFileType.customerSpecification]: 'Спецификация',
          [DocumentFileType.customerAcceptanceCertificate]: 'Акт-приема передачи, подписанный с одной стороны',
          [DocumentFileType.customerAdditionalAgreement]: 'Дополнительное соглашение',
        },
      },
      transfer: {
        title: 'Груз прибыл',
      },
    },
  },
  zh: {
    manager: {
      transitCountryCargoArrival: {
        title: '货物抵达过境国已确认',
        description: '输入货物抵达中转国的实际日期',
      },
      cargoArrival: {
        title: '货物抵达',
        description: '输入货物到达目的地国家的实际日期',
      },
      supplierAcceptanceCertificate: {
        title: '上传单方面签字的验收证书',
        description: '上传验收证书并点击“确认”',
      },
      supplierAcceptanceCertificateVerifying: {
        title: '检查验收证书',
        description: '下载上一步的验收证书文件。检查所有信息是否正确，然后单击“全部正确”按钮继续下一步',
      },
      supplierSignedAcceptanceCertificate: {
        title: '上传双方签字的验收证书',
        description: '上传验收证书并点击“确认”',
      },
      destinationCountryDocs: {
        title: '上传目的国文件',
        descriptions: {
          exportInvoice: '下载出口发票 4%',
          customerCommercialInvoice: '下载买方的商业发票',
          customerSpecification: '下载带有我们公司抬头的买方规格',
          customerAcceptanceCertificate: '一方签署的验收证明书',
          customerAdditionalAgreement: '买方的附加协议',
        },
      },
      destinationCountryAwb: {
        title: '下载目的地国家的AWB或输入航班号',
      },
      transitCountryCargoShipment: {
        title: '装运',
        description: '货件离开过境国后，确认货件',
      },
      destinationCountryCargoArrival: {
        title: '货物抵达目的地国家',
        description: '输入货物到达目的地国家的实际日期',
      },
    },
    customer: {
      cargoArrival: {
        title: '等待货物到达',
        description: '等待经理标记货物到达目的国',
        dataTitle: '货物抵达目的地国家的实际日期',
      },
      destinationCountryDocs: {
        title: '等待交易文件加载',
        waitDescription: '文件上传后，它们将出现在此处',
        approveDescription: '文件已上传',
        docsDescription: {
          [DocumentFileType.customerCommercialInvoice]: '发票',
          [DocumentFileType.customerSpecification]: '规格',
          [DocumentFileType.customerAcceptanceCertificate]: '一方签署的验收证明书 ',
          [DocumentFileType.customerAdditionalAgreement]: '附加协议',
        },
      },
      transfer: {
        title: '货物已抵达',
      },
    },
  },
};
