import { QuestionCircleOutlined } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import * as React from 'react';

import { themeToken } from '@/shared/config/themeConfig';
import { Nullable, WeightWithUnit } from '@/shared/types/values';
import { TransComp, TypographyTextWithTooltip } from '@/shared/ui';

type Props = {
  className?: string;
  current: WeightWithUnit;
  previous: Nullable<string>;
};

const WeightWithUnitTooltip: React.FC<Props> = ({ className, current, previous }) => {
  const tooltipNode = previous && (
    <TransComp>
      {(t) => t(`tooltip.tooltipWeight`, { prevWeight: previous, weight: current.gram, ns: 'bids' })}
    </TransComp>
  );

  return (
    <div className={className}>
      <Flex gap={4}>
        <TypographyTextWithTooltip text={current.gram} />
        {tooltipNode && (
          <Tooltip title={tooltipNode}>
            <QuestionCircleOutlined style={{ color: themeToken.colorIcon }} />
          </Tooltip>
        )}
      </Flex>
      <TypographyTextWithTooltip text={current.ounce} />
    </div>
  );
};

export default React.memo(WeightWithUnitTooltip);
