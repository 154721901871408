import cn from 'classnames';
import * as React from 'react';

import { TypographyTextWithTooltip } from '@/shared/ui';

import { mapVerificationStatusToLabel } from '../../config';
import { VerificationStatus } from '../../types/server';

import s from './VertificationStatusView.module.scss';

type VerificationStatusProps = {
  className?: string;
  status: VerificationStatus;
};

const VerificationStatusView = ({
  className,
  status,
}: VerificationStatusProps): React.ReactElement<VerificationStatusProps> => (
  <TypographyTextWithTooltip
    className={cn(s.status, s[`status_${status}`], className)}
    text={mapVerificationStatusToLabel(status)}
  />
);

export default React.memo(VerificationStatusView);
