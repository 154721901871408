import { CompanyAdminServer } from '@/entities/user';
import { VerificationStatus } from '@/entities/verification';
import { ApiListResponse } from '@/shared/types/api';
import { Nullable } from '@/shared/types/values';

/**
 * @field owner - Владелец компании посредника (Arvar)
 * @field customer - Покупатель
 * @field supplier - Поставщик
 */
export enum CompanyType {
  owner = 'owner',
  customer = 'customer',
  supplier = 'supplier',
}

export enum TaxType {
  TIN = 'tin',
  TRN = 'trn',
  BR = 'br',
  CR = 'cr',
  licenceNumber = 'licence_number',
  certificateNumber = 'certificate_number',
}

export type TaxAttributeServer = {
  slug: TaxType;
  identifier: string;
};

export type ClientCompanyType = CompanyType.customer | CompanyType.supplier;

export type CompanyServer<CT extends ClientCompanyType> = {
  id: number;
  email: string;
  name: string;
  type: CT;
  phone: Nullable<string>;
  address: Nullable<string>;
};

export type CompanyWithAdminServer<CT extends ClientCompanyType = ClientCompanyType> = CompanyServer<CT> & {
  admin: CompanyAdminServer;
  kyc_status: VerificationStatus;
};

export type CompanyListResponse = ApiListResponse<CompanyWithAdminServer>;

export type CreateCompanyPayload = {
  type: ClientCompanyType;
  name: string;
  email: string;
  address?: string;
  phone?: string;
};

export type EditCompanyPayload = {
  name: string;
  address: Nullable<string>;
  phone: Nullable<string>;
};
