import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { SelectGenerationDocumentLanguage } from '@/entities/file';
import { CustomSelect } from '@/shared/ui/form/CustomSelect';
import { FormDatePicker } from '@/shared/ui/form/FormDatePicker';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormInputTextArea } from '@/shared/ui/form/FormInputTextArea';
import { FormSelect } from '@/shared/ui/form/FormSelect';
import { FormUpload } from '@/shared/ui/form/FormUpload';

import { PaymentBillFieldsModel } from '../../model';

import s from './PaymentBill.module.scss';

type Props = {
  model: PaymentBillFieldsModel;
};

const PaymentBillForm: React.FC<Props> = ({ model }) => {
  const {
    invoiceNumber,
    invoiceDate,
    departureCountryId,
    destinationCountryId,
    gramPrice,
    ouncePrice,
    currencyId,
    countIngot,
    exchangeRate,
    manufacturer,
    additionalInfo,
    documentLanguage,
    sealAndSignature,
    supplierInitials,
    supplierPosition,
    consignee,

    needConsignee,
    deliveryConfirmationGenerated,
    loadingState,
    currencyOptions,
  } = model;

  const { dictionariesStore } = useRootStore();

  return (
    <>
      <FormInput model={invoiceNumber} disabled={loadingState.isLoading} />
      <FormDatePicker model={invoiceDate} disabled={loadingState.isLoading} />
      {!deliveryConfirmationGenerated && (
        <>
          <FormInput
            model={gramPrice}
            disabled={loadingState.isLoading}
            addonAfter={
              <CustomSelect
                className={s.select}
                popupClassName={s.select_popup}
                {...currencyId.props}
                options={currencyOptions}
                disabled={loadingState.isLoading}
              />
            }
          />
          <FormInput
            model={ouncePrice}
            disabled
            addonAfter={
              <CustomSelect
                className={s.select}
                popupClassName={s.select_popup}
                {...currencyId.props}
                options={currencyOptions}
                disabled
              />
            }
          />
          <FormInput model={countIngot} disabled={loadingState.isLoading} />
          <FormInput model={exchangeRate} disabled={!exchangeRate.required || loadingState.isLoading} />
        </>
      )}
      <FormInput model={supplierInitials} disabled={loadingState.isLoading} />
      <FormInput model={supplierPosition} disabled={loadingState.isLoading} />
      <FormSelect
        model={departureCountryId}
        options={dictionariesStore.countries.list.items}
        disabled={loadingState.isLoading}
      />
      <FormSelect
        model={destinationCountryId}
        options={dictionariesStore.countries.list.items}
        disabled={loadingState.isLoading}
      />
      <FormInput model={manufacturer} disabled={loadingState.isLoading} />
      {needConsignee && <FormInput model={consignee} disabled={loadingState.isLoading} />}
      <FormInputTextArea
        className={s.fullWidth}
        model={additionalInfo}
        disabled={loadingState.isLoading}
        autoSize={{ minRows: 1, maxRows: 5 }}
      />
      <FormUpload
        className={s.fullWidth}
        model={sealAndSignature}
        variant={sealAndSignature.isUploaded ? 'default' : 'dragger'}
        disabled={loadingState.isLoading}
      />
      <SelectGenerationDocumentLanguage
        className={cn(s.fullWidth, s.language)}
        model={documentLanguage}
        disabled={loadingState.isLoading}
      />
    </>
  );
};

export default observer(PaymentBillForm);
