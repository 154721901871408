import { IRootStore } from '@/app/store';

import { BidsPageModel } from './BidsPageModel';
import { ChatsPageModel } from './ChatsPageModel';
import { NotificationsPageModel } from './NotificationsPageModel';
import { ProfilePageModel } from './ProfilePageModel';
import { TradesPageModel } from './TradesPageModel';

export class PagesModel {
  readonly profile: ProfilePageModel;

  readonly notifications: NotificationsPageModel;

  readonly bids: BidsPageModel;

  readonly trades: TradesPageModel;

  readonly chats: ChatsPageModel;

  readonly trade = null;
  readonly documents = null;
  readonly companies = null;
  readonly main = null;
  readonly login = null;
  readonly createPassword = null;
  readonly verification = null;

  constructor(rootStore: IRootStore) {
    this.profile = new ProfilePageModel({ rootStore, pagesModel: this });
    this.notifications = new NotificationsPageModel({ rootStore, pagesModel: this });
    this.bids = new BidsPageModel({ rootStore, pagesModel: this });
    this.trades = new TradesPageModel({ rootStore, pagesModel: this });
    this.chats = new ChatsPageModel({ rootStore, pagesModel: this });
  }
}
