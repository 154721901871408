import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { CustomAlert, StepDescription } from '@/shared/ui';
import { FormUpload } from '@/shared/ui/form/FormUpload';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { UploadFilesStepModel } from '../../model';
import { StageModel, StepComponentProps } from '../../types';

type BaseUploadFileStepProps<StageModel> = StepComponentProps<StageModel>;

const UploadFilesStep = <Model extends StageModel>({
  model,
  stepKey,
  description,
  approveButtonText,
}: BaseUploadFileStepProps<Model>) => {
  const step = stepKey && model[stepKey];

  if (!(step instanceof UploadFilesStepModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {step.isRejected && (
        <CustomAlert
          type="error"
          message={(t) => t('messages.rejectedDocError', { ns: 'file' })}
          description={step.error}
        />
      )}
      <FormUpload model={step.files} disabled={step.isDisabled || step.isLoading} />
      <ApproveButton
        loading={step.isLoading}
        disabled={step.isDisabled || step.approveDisabled}
        onClick={step.approveStep}
        text={approveButtonText}
      />
    </StepDescription>
  );
};

export default observer(UploadFilesStep);
