import { ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseStepModel, BaseStepModelParams } from '../BaseStepModel';

type Params<Step, Action> = BaseStepModelParams<Step, Action> & {
  confirmed: ValueModel<boolean>;
};

export class ConfirmationStepModel<Step, Action> extends BaseStepModel<Step, Action> {
  readonly confirmed: ValueModel<boolean>;

  constructor({ confirmed, ...params }: Params<Step, Action>) {
    super(params);

    this.confirmed = confirmed;
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<boolean>;
  } & BaseStepModelParams<Step, Action>): ConfirmationStepModel<Step, Action> {
    return new ConfirmationStepModel({
      confirmed: new ValueModel(Boolean(data)),
      ...params,
    });
  }
}
