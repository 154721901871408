import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { Area, AreaChart, YAxis } from 'recharts';

import { themeToken } from '@/shared/config/themeConfig';
import { isNullable } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';

import { ITickerHistory } from '../../types/client';

type TickerAreaChartProps = {
  className?: string;
  height?: number;
  width?: number;
  tickerHistory: Nullable<ITickerHistory>;
};

const TickerAreaChart: React.FC<TickerAreaChartProps> = ({ className, height, width, tickerHistory }) => {
  if (isNullable(tickerHistory) || tickerHistory.history.length < 2) {
    return null;
  }

  const { ticker, history } = tickerHistory;

  const isPositiveTrend = history[history.length - 1].price >= history[0].price;
  const color = isPositiveTrend ? themeToken.green6 : themeToken.red5;
  const gradientId = `${ticker}-price-gradient`;

  return (
    <AreaChart
      className={className}
      height={height ?? 50}
      width={width ?? 150}
      margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
      data={history}
    >
      <YAxis type="number" domain={['dataMin', 'dataMax']} hide />
      <defs>
        <linearGradient id={gradientId} x1="0" y1="0" x2="0" y2="1">
          <stop stopColor={color} stopOpacity={0.4} />
          <stop offset="100%" stopColor={color} stopOpacity={0} />
        </linearGradient>
      </defs>
      <Area dataKey="price" stroke={color} strokeWidth={1} fill={`url(#${gradientId})`} />
    </AreaChart>
  );
};

export default observer(TickerAreaChart);
