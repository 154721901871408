import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import * as React from 'react';

import { themeToken } from '@/shared/config/themeConfig';

type TrendArrowProps = {
  className?: string;
  positive: boolean;
  negative: boolean;
  size?: number;
};

const TrendArrow: React.FC<TrendArrowProps> = ({ className, positive, negative, size = 16 }) => {
  return (
    <>
      {positive && <ArrowUpOutlined className={className} style={{ color: themeToken.green6, fontSize: size }} />}
      {negative && (
        <ArrowDownOutlined className={className} style={{ color: themeToken.colorErrorActive, fontSize: size }} />
      )}
    </>
  );
};

export default React.memo(TrendArrow);
