import * as React from 'react';

const EducationIcon: React.FC = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.9375 12.3274L9 14.5422L5.0625 12.3274V9.98731L3.9375 9.3623V12.9853L9 15.833L14.0625 12.9853V9.3623L12.9375 9.98731V12.3274Z"
        fill="currentColor"
      />
      <path
        d="M9 1.61646L1.125 5.69978V6.67481L9 11.0497L15.75 7.29981V10.4063H16.875V5.69978L9 1.61646ZM14.625 6.63786L13.5 7.26283L9 9.76297L4.5 7.26283L3.375 6.63786L2.59527 6.20466L9 2.8837L15.4047 6.20466L14.625 6.63786Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default React.memo(EducationIcon);
