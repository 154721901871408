import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { UploadOrGenerateDocument } from '@/entities/file';

import { DeliveryConfirmationModel } from '../../model';

import DeliveryConfirmationForm from './DeliveryConfirmationForm';

type ConfirmationModelProps = {
  model: DeliveryConfirmationModel;
  disabled?: boolean;
};

const DeliveryConfirmation: React.FC<ConfirmationModelProps> = ({ model, disabled }) => {
  const { userStore } = useRootStore();

  return (
    <UploadOrGenerateDocument
      model={model}
      disabled={disabled}
      generatable={userStore.isSupplier}
      FieldsComponent={DeliveryConfirmationForm}
    />
  );
};

export default observer(DeliveryConfirmation);
