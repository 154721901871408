import { debounce } from '@kts-front/utils';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, TooltipProps, XAxis, YAxis } from 'recharts';

import { CurrentTickerHistoryModel } from '@/entities/dictionary/model';
import { ITicker } from '@/entities/dictionary/types';
import { themeToken } from '@/shared/config/themeConfig';
import { useWidth } from '@/shared/hooks';
import { Loader } from '@/shared/ui';

import PriceChartTooltip from './PriceChartTooltip';
import YAxisTick from './YAxisTick';
import { getPriceDomain, getXAxisInterval } from './config';

import s from './TickerPriceChart.module.scss';

type Props = {
  historyModel: CurrentTickerHistoryModel;
  currentTicker: ITicker;
  width?: string | number;
  height?: string | number;
};

const MAX_DIGIT_WIDTH = 8;

const TickerPriceChart: React.FC<Props> = ({ historyModel, currentTicker, width = '100%', height = 395 }) => {
  const { loadingStage, chartType, priceDifference, chartTypeHistory, xAxisTicks, formatPrice, formatDateTime } =
    historyModel;

  const [tooltipProps, setTooltipProps] = React.useState<TooltipProps<string, string>>({});

  const handleTooltipProps = debounce({
    timeout: 0,
    func: setTooltipProps,
  });

  const renderTooltipContent = React.useCallback<(props: TooltipProps<string, string>) => React.ReactNode>((props) => {
    handleTooltipProps(props);

    return null;
  }, []);

  /**
   * Устанавливает цвет линии
   */
  const lineStroke = React.useMemo(() => {
    if (priceDifference?.isPositive) {
      return themeToken.green;
    }

    if (priceDifference?.isNegative) {
      return themeToken.red5;
    }
  }, [priceDifference]);

  /**
   * Вычисляет ширину оси Y по максимальному значению
   */
  const yAxisWidth: number = React.useMemo(() => {
    const firstPrice = chartTypeHistory.at(0)?.price ?? 0;

    /** Получаем минимальное и максимальное значения */
    const priceRange = chartTypeHistory.reduce<[number, number]>(
      (acc, { price }) => {
        if (price < acc[0]) {
          acc[0] = price;
        }

        if (price > acc[1]) {
          acc[1] = price;
        }

        return acc;
      },
      [firstPrice, firstPrice],
    );

    /** Получаем верхний порог графика и его длину ввиде строки */
    const maxPrice = getPriceDomain(priceRange)[1];
    const maxPriceLength = formatPrice(maxPrice).length;

    return maxPriceLength * MAX_DIGIT_WIDTH;
  }, [chartTypeHistory]);

  const { isTablet, isDesktop } = useWidth();

  const xAxisPadding = React.useMemo(() => {
    let padding = 8;

    if (isTablet) {
      padding = 12;
    }

    if (isDesktop) {
      padding = 24;
    }

    return { right: yAxisWidth + padding };
  }, [yAxisWidth, isTablet, isDesktop]);

  const xAxisProps = React.useMemo(
    () => getXAxisInterval({ isDesktop, chartType, ticksInfo: xAxisTicks }),
    [isDesktop, chartType, xAxisTicks],
  );

  if (loadingStage.isLoading) {
    return <Loader className={s.chart_loader} minHeight={height} />;
  }

  return (
    <ResponsiveContainer width={width} height={height}>
      <LineChart data={chartTypeHistory} margin={{ top: 1, right: -1, bottom: 1, left: 1 }}>
        <CartesianGrid strokeDasharray="2 2" stroke={themeToken.colorFillContentHover} />
        <XAxis
          dataKey="datetime"
          tickFormatter={formatDateTime}
          padding={xAxisPadding}
          tickMargin={8}
          tick={{ fontSize: 12, textAnchor: 'start' }}
          {...xAxisProps}
        />
        <YAxis
          dataKey="price"
          orientation="right"
          domain={getPriceDomain}
          tickFormatter={formatPrice}
          tickCount={10}
          tick={<YAxisTick />}
          width={yAxisWidth}
          axisLine={false}
          tickLine={false}
          mirror
        />
        <Tooltip content={renderTooltipContent} cursor={{ stroke: themeToken.colorTextPlaceholder }} />
        <Line
          type="monotone"
          dataKey="price"
          stroke={lineStroke}
          dot={false}
          activeDot={
            <PriceChartTooltip
              tooltipProps={tooltipProps}
              chartType={chartType}
              currency={currentTicker.currency?.label}
            />
          }
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default observer(TickerPriceChart);
