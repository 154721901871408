export const trades = {
  en: {
    header: {
      title: 'Trades',
      description: null,
    },
    search: {
      title: 'Search',
      description: null,
    },
  },
  ru: {
    header: {
      title: 'Сделки',
      description: 'Здесь будут отображаться все сделки, в которых вы принимаете участие.',
    },
    search: {
      title: 'Поиск',
      description: 'Поиск сделок возможен по химическому или лигатурному весу.',
    },
  },
  zh: {
    header: {
      title: '交易',
      description: null,
    },
    search: {
      title: null,
      description: null,
    },
  },
};
