import { Flex, Menu } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation } from 'react-router';

import { useRootStore } from '@/app/store';
import { TickerWidget } from '@/entities/dictionary';
import { LanguageSelectorCallback, Logo } from '@/shared/ui';
import { getPathname } from '@/shared/utils';

import { getGeneralMenuItems, getMenuItems, menuUrlsMap } from '../../config';
import { SideMenuButton } from '../SideMenuButton';

import s from './SideMenu.module.scss';

type Props = {
  className?: string;
  isMenuOpened: boolean;
  toggleMenuOpen?: VoidFunction;
  closeMenu?: VoidFunction;
};

const PREFERRED_PATHNAME = 'notifications';
const PREFIX_FOR_OMIT = 'profile/';

const SideMenu: React.FC<Props> = ({ className, isMenuOpened, toggleMenuOpen, closeMenu }) => {
  const { userStore, routerStore } = useRootStore();
  const { userModel, userRole, needVerifyCompany, logout } = userStore;
  const location = useLocation();

  const languageSelectorCallbackRef = React.useRef<LanguageSelectorCallback | null>(null);
  const [selectedKey, setSelectedKey] = React.useState<string>(
    getPathname({ location, preferredKey: PREFERRED_PATHNAME }),
  );

  React.useEffect(() => {
    setSelectedKey(getPathname({ location, preferredKey: PREFERRED_PATHNAME }));
  }, [location.pathname]);

  const handleMenuItemClick = React.useCallback(
    ({ key }: { key: string }) => {
      if (key === menuUrlsMap.logout.key) {
        logout(getPathname({ location, prefixForOmit: PREFIX_FOR_OMIT }));
      }

      routerStore.navigate(menuUrlsMap[key].url);
      setSelectedKey(key);
      closeMenu?.();
    },
    [closeMenu, location.pathname],
  );

  const items = React.useMemo(
    () =>
      getMenuItems({
        userRole,
        needVerifyCompany,
        selectedKey,
        handleMenuItemClick,
      }),
    [userModel, needVerifyCompany, selectedKey, handleMenuItemClick],
  );

  const generalItems = React.useMemo(
    () =>
      getGeneralMenuItems({
        handleMenuItemClick,
        isMenuOpened,
        languageSelectorCallbackRef,
      }),
    [handleMenuItemClick, isMenuOpened, languageSelectorCallbackRef.current],
  );

  return (
    <div className={cn(s['side-menu'], !isMenuOpened && s['side-menu_closed'], className)}>
      <div className={s['side-menu_logo_wrapper']}>
        <Logo className={cn(s['side-menu_logo'], !isMenuOpened && s['side-menu_logo_closed'])} size={16} />
      </div>
      <SideMenuButton onClick={toggleMenuOpen ?? closeMenu} className={s['side-menu_button']} />
      <Flex className={s['side-menu_menu_wrapper']} vertical>
        <Menu
          className={s['side-menu_menu']}
          defaultSelectedKeys={[selectedKey]}
          selectedKeys={[selectedKey]}
          mode="inline"
          inlineCollapsed={!isMenuOpened}
          items={items}
        />
        <TickerWidget className={s['side-menu_widget']} />
      </Flex>
      <Menu className={s['side-menu_generalMenu']} mode="inline" inlineCollapsed={!isMenuOpened} items={generalItems} />
    </div>
  );
};

export default observer(SideMenu);
