import { useCallback, useRef } from 'react';

import { Nullable } from '../types/values';

export const useLastKnown = <T>(value: Nullable<T>): [Nullable<T>, VoidFunction] => {
  const previousRef = useRef<Nullable<T>>(null);

  if (value !== null) {
    previousRef.current = value;
  }

  const resetLastKnown = useCallback(() => {
    previousRef.current = null;
  }, []);

  return [previousRef.current, resetLastKnown];
};
