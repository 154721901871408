import { DoubleRightOutlined } from '@ant-design/icons';
import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useWidth } from '@/shared/hooks';
import { isNullable } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';
import { CustomButton } from '@/shared/ui/form/CustomButton';

import { CurrentTickerHistoryModel } from '../../model';
import { ITicker } from '../../types';
import PriceChart from '../TickerPriceChart';

import TicketDetailsHeader from './TicketDetailsHeader';

import s from './TicketDetails.module.scss';

type Props = {
  historyModel: CurrentTickerHistoryModel;
  currentTicker: Nullable<ITicker>;
};

const TicketDetails: React.FC<Props> = ({ currentTicker, historyModel }) => {
  const { isTablet } = useWidth();

  if (isNullable(currentTicker)) {
    return null;
  }

  return (
    <Flex className={s.details} vertical flex={isTablet ? 3 : 2}>
      <CustomButton
        className={s.details_close}
        type="text"
        icon={<DoubleRightOutlined />}
        onClick={historyModel.closeHistory}
      />
      <Flex className={s.details_content}>
        <TicketDetailsHeader currentTicker={currentTicker} historyModel={historyModel} />
        <PriceChart currentTicker={currentTicker} historyModel={historyModel} />
      </Flex>
    </Flex>
  );
};

export default observer(TicketDetails);
