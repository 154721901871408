import { ITickerHistory, TickerHistory } from '../types/client';
import { TickerServer, TickerType } from '../types/server';

export class TickerHistoryModel implements ITickerHistory {
  readonly ticker: TickerType;
  readonly history: TickerHistory;

  constructor({ ticker, history }: ITickerHistory) {
    this.ticker = ticker;
    this.history = history;
  }

  static fromJson(server: TickerServer): TickerHistoryModel {
    return new TickerHistoryModel({
      ticker: server.ticker,
      history: server.history,
    });
  }
}
