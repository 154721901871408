import { DocumentFileType, UploadOrGenerateDocumentModel, UploadOrGenerateDocumentModelParams } from '@/entities/file';
import { BasePaymentBillDocPayload } from '@/entities/stage/types';

import { PaymentBillFieldsModel } from './PaymentBillFieldsModel';

export class PaymentBillModel extends UploadOrGenerateDocumentModel<
  DocumentFileType.paymentBill,
  BasePaymentBillDocPayload,
  PaymentBillFieldsModel
> {
  readonly generationFields: PaymentBillFieldsModel;

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.paymentBill>) {
    super(params);

    this.generationFields = new PaymentBillFieldsModel(params);
  }
}
