import { computed, makeObservable } from 'mobx';

import { ListModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import {
  IProductDictionary,
  IProductPrice,
  IUnitDictionary,
  ProductDictionaryServer,
  ProductWeightType,
} from '../types';

import { UnitDictionaryModel } from './UnitDictionaryModel';

type ProductDictionaryModelParams = Omit<IProductDictionary, 'currency'>;

export class ProductDictionaryModel implements IProductDictionary {
  readonly value: number;
  readonly label: string;
  readonly title: string;
  readonly type: string;
  readonly profinance: Nullable<IProductPrice>;
  readonly unitList: IProductDictionary['unitList'];
  readonly shapeList: IProductDictionary['shapeList'];

  constructor(params: ProductDictionaryModelParams) {
    this.value = params.value;
    this.label = params.label;
    this.title = params.title;
    this.type = params.type;
    this.profinance = params.profinance;
    this.unitList = params.unitList;
    this.shapeList = params.shapeList;

    makeObservable(this, {
      currency: computed,
    });
  }

  get currency(): Nullable<IUnitDictionary> {
    return this.profinance?.currency ?? null;
  }

  static fromJson({ price, shapes, units, ...server }: ProductDictionaryServer): ProductDictionaryModel {
    const unitList = new ListModel<UnitDictionaryModel<number, ProductWeightType>, number>();

    unitList.fillByRawData(units, (raw) => ({
      entity: UnitDictionaryModel.fromJson(raw),
      key: raw.id,
    }));

    const shapeList = new ListModel<UnitDictionaryModel, number>();

    shapeList.fillByRawData(shapes, (raw) => ({
      entity: UnitDictionaryModel.fromJson(raw),
      key: raw.id,
    }));

    const { profinance } = price;

    const profinancePrice = profinance && {
      date: new Date(profinance.date),
      current: profinance.current,
      previous: profinance.previous,
      currency: UnitDictionaryModel.fromJson(profinance.currency),
    };

    return new ProductDictionaryModel({
      value: server.id,
      label: server.name,
      title: server.name,
      type: server.slug,
      profinance: profinancePrice,
      unitList,
      shapeList,
    });
  }
}
