import { TranslationString } from '@/shared/types/localization';

import { DocumentFileType, GenerationDocumentLanguage } from './types';

export const documentFileTypeList = Object.values(DocumentFileType);

type MapDocTypeToLabel = Record<DocumentFileType, TranslationString>;

export const mapDocTypeToLabel: MapDocTypeToLabel = documentFileTypeList.reduce<MapDocTypeToLabel>((map, docType) => {
  map[docType] = (t) => t(`docTypeLabel.${docType}`, { ns: 'file' });

  return map;
}, {} as MapDocTypeToLabel);

export const getDocTypeLabel = (docType: DocumentFileType) => {
  return docType in mapDocTypeToLabel ? mapDocTypeToLabel[docType] : docType;
};

export const mapDocumentLanguageToLabel =
  (type: GenerationDocumentLanguage): TranslationString =>
  (t) =>
    t(`languageDownloadDoc.documentLanguage.${type}`, { ns: 'file' });
