import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { UploadOrGenerateDocument } from '@/entities/file';

import { AcceptanceCertificateModel } from '../../model/fieldsModel/AcceptanceCertificateModel';

import AcceptanceCertificateForm from './AcceptanceCertificateForm';

type AcceptanceCertificateProps = {
  model: AcceptanceCertificateModel;
  disabled?: boolean;
};

const AcceptanceCertificate: React.FC<AcceptanceCertificateProps> = ({ model, disabled }) => {
  return <UploadOrGenerateDocument model={model} disabled={disabled} FieldsComponent={AcceptanceCertificateForm} />;
};

export default observer(AcceptanceCertificate);
