import { action, computed, makeObservable } from 'mobx';

import { DocumentGenerationWithStatusServer, DocumentWithStatusServer } from '@/entities/file';
import { ToggleModel } from '@/shared/model';
import { InputTextAreaModel } from '@/shared/model/form/InputTextAreaModel';
import { CheckStatus } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator } from '@/shared/utils/validators';

import { BaseStepModel, BaseStepModelParams } from '../BaseStepModel';

type Params<Step, Action> = BaseStepModelParams<Step, Action> & {
  status: Nullable<CheckStatus>;
  error: InputTextAreaModel;
};

type Payload<Action> = {
  action: Action;
  description: string;
};

export class DocumentVerifyingStepModel<Step, Action> extends BaseStepModel<Step, Action, Payload<Action>> {
  readonly status: Nullable<CheckStatus>;
  readonly error: InputTextAreaModel;

  private readonly _modalState = new ToggleModel();

  protected toRejectJson() {
    if (!this.rejectAction || !this.error.value) {
      return null;
    }

    return {
      action: this.rejectAction,
      description: this.error.value,
    };
  }

  constructor({ status, error, ...params }: Params<Step, Action>) {
    super(params);

    this.status = status;
    this.error = error;

    makeObservable(this, {
      isWaiting: computed,
      isRejected: computed,
      isApproved: computed,
      isOpenModal: computed,

      onChangeError: action.bound,
      closeModal: action.bound,
      openModal: action.bound,
    });
  }

  get isWaiting(): boolean {
    return this.status === CheckStatus.waiting;
  }

  get isRejected(): boolean {
    return this.status === CheckStatus.rejected;
  }

  get isApproved(): boolean {
    return this.status === CheckStatus.approved;
  }

  get isOpenModal(): boolean {
    return this._modalState.isOpen;
  }

  onChangeError(e: React.ChangeEvent<HTMLTextAreaElement>) {
    this.error.change(e.target.value);
  }

  closeModal() {
    if (!this.isRejected) {
      this.error.resetValue();
    }

    this._modalState.close();
  }

  openModal() {
    this._modalState.open();
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data: Nullable<DocumentWithStatusServer | DocumentGenerationWithStatusServer>;
  } & BaseStepModelParams<Step, Action>): DocumentVerifyingStepModel<Step, Action> {
    return new DocumentVerifyingStepModel({
      status: data?.status ?? null,
      error: new InputTextAreaModel({
        initialValue: data?.error_message ?? '',
        label: (t) => t('documentVerifyingForm.label', { ns: 'file' }),
        placeholder: (t) => t('documentVerifyingForm.placeholder', { ns: 'file' }),
        validators: [emptyValueValidator()],
        required: true,
      }),
      ...params,
    });
  }
}
