import { ThemeConfig, theme } from 'antd';
import { AliasToken } from 'antd/es/theme/internal';

import colors from '../styles/colors.module.scss';

/**
 * Добавляем кастомные цвета к стандарным AliasToken
 */
type CustomAliasToken = AliasToken & {
  colorSecondary: string;
  colorBg: string;
  colorFillLight: string;
  colorFillContentLight: string;
  colorFillContentHoverLight: string;
};

interface CustomThemeConfig extends ThemeConfig {
  token: CustomAliasToken;
}

export const themeConfig: CustomThemeConfig = {
  hashed: false,
  token: {
    fontFamily: 'SF Pro Text, sans-serif',
    ...colors,
  } as CustomAliasToken,
  components: {
    Alert: {
      defaultPadding: '20px 16px',
      withDescriptionPadding: '20px 16px',
    },
    Button: {
      defaultShadow: 'none',
      primaryShadow: 'none',
      dangerShadow: 'none',
    },
    Input: {
      activeShadow: colors.focusPrimary,
    },
    Menu: {
      itemBg: 'transparent',
      itemSelectedBg: colors.colorFillContentLight,
      itemActiveBg: colors.colorFillContentHoverLight,
      itemHoverBg: colors.colorFillContentHoverLight,
      iconMarginInlineEnd: 8,
    },
    Typography: {
      titleMarginBottom: 0,
      titleMarginTop: 0,
    },
    Modal: {
      titleFontSize: 24,
    },
    Steps: {
      dotSize: 10,
      dotCurrentSize: 10,
      titleLineHeight: 24,
      iconSize: 24,
    },
    Tabs: {
      horizontalItemGutter: 10,
    },
    Table: {
      headerBg: colors.colorFillAlter,
      rowHoverBg: colors.controlItemBgActive,
      cellPaddingBlockSM: 12,
      cellPaddingInlineSM: 16,
    },
    Divider: {
      marginLG: 0,
    },
  },
};

/**
 * Для получения цветов вне файлов .scss
 * Дефолтные цвета Antd: console.log({ defaultThemeToken: theme.getDesignToken() });
 */
export const themeToken = theme.getDesignToken(themeConfig) as CustomAliasToken;
