import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { SelectGenerationDocumentLanguage } from '@/entities/file';
import { useWidth } from '@/shared/hooks';
import { FormDatePicker } from '@/shared/ui/form/FormDatePicker';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormUpload } from '@/shared/ui/form/FormUpload';

import { SupplierSpecificationFieldsModel } from '../../model';
import HandlingIngotsTable from '../HandlingIngotsTable';

import ExpandIngotList from './ExpandIngotList';

import s from './SupplierSpecification.module.scss';

type SupplierSpecificationFormProps = {
  model: SupplierSpecificationFieldsModel;
};

const SupplierSpecificationForm: React.FC<SupplierSpecificationFormProps> = ({ model }) => {
  const {
    specificationNumber,
    specificationDate,
    supplierInitials,
    supplierPosition,
    sealAndSignature,
    documentLanguage,
    ingots,
    loadingState,
  } = model;
  const { isMobile } = useWidth();

  return (
    <Flex vertical gap={24}>
      <Flex vertical={isMobile} gap={12} flex={1}>
        <FormInput className={s.fullWidth} model={specificationNumber} disabled={loadingState.isLoading} />
        <FormDatePicker className={s.fullWidth} model={specificationDate} disabled={loadingState.isLoading} />
      </Flex>
      <HandlingIngotsTable model={ingots} mobileRender={<ExpandIngotList model={ingots} />} />
      <Flex vertical={isMobile} gap={12} flex={1}>
        <FormInput className={s.fullWidth} model={supplierInitials} disabled={loadingState.isLoading} />
        <FormInput className={s.fullWidth} model={supplierPosition} disabled={loadingState.isLoading} />
      </Flex>
      <FormUpload
        model={sealAndSignature}
        variant={sealAndSignature.isUploaded ? 'default' : 'dragger'}
        disabled={loadingState.isLoading}
      />
      <SelectGenerationDocumentLanguage model={documentLanguage} disabled={loadingState.isLoading} />
    </Flex>
  );
};

export default observer(SupplierSpecificationForm);
