import { action, computed, makeObservable } from 'mobx';
import queryString from 'query-string';

import { IRootStore } from '@/app/store';
import { IDictionariesStore, ProductType } from '@/entities/dictionary';
import { BidListFilterParams, BidListStoreQueryParams } from '@/features/bids/types';
import { apiStore } from '@/shared/api';
import { ListOffsetModel, LoadingStageModel, QueryParamsModel, ValueModel } from '@/shared/model';
import { Nullable, Options } from '@/shared/types/values';

import { BidFixingType, BidListPayload, BidStatus, BidType, CompanyOptionsResponse } from '../types';

const PARAMS_KEYS: Array<keyof BidListStoreQueryParams> = ['status', 'product_id', 'type', 'fixing_type', 'search'];

export class BaseBidListFilters<M> {
  readonly searchFilter: ValueModel<string>;
  readonly statusFilter: ValueModel<Nullable<BidStatus>>;
  readonly metalFilter: ValueModel<Nullable<number>>;
  readonly fixingTypeFilter: ValueModel<Nullable<BidFixingType>>;
  readonly typeFilter: ValueModel<Nullable<BidType>>;
  readonly companyIdFilter: ValueModel<Nullable<number>>;
  readonly companyOptionLoading = new LoadingStageModel();

  private readonly _list: ListOffsetModel<M, number>;
  private readonly _queryParams: QueryParamsModel<BidListStoreQueryParams>;
  private readonly _rootStore: IRootStore;
  private readonly _companyOptions = new ValueModel<Options<number>>([]);
  private readonly _companyOptionsRequest = apiStore.createRequest<CompanyOptionsResponse>();

  constructor({ queryParams, rootStore, list }: BidListFilterParams<M>) {
    const initialQueryParams = this._normalizeQueryParams(queryParams);

    this._queryParams = new QueryParamsModel<BidListStoreQueryParams>({
      params: initialQueryParams,
      routerStore: rootStore.routerStore,
    });
    this._rootStore = rootStore;

    this.searchFilter = new ValueModel<string>(this.params.search);
    this.statusFilter = new ValueModel<Nullable<BidStatus>>(this.params.status);
    this.metalFilter = new ValueModel<Nullable<number>>(this.params.product_id);
    this.fixingTypeFilter = new ValueModel<Nullable<BidFixingType>>(this.params.fixing_type);
    this.typeFilter = new ValueModel<Nullable<BidType>>(this.params.type);
    this._list = list;
    this.companyIdFilter = new ValueModel<Nullable<number>>(this.params.company_id);

    this._list.changeLimit(initialQueryParams.limit);
    this._list.changeOffset(initialQueryParams.offset);

    makeObservable(this, {
      params: computed,
      paramsJson: computed,
      isFiltered: computed,
      metalFilterOptions: computed,
      companyNameFilterOptions: computed,

      changeSearchFilter: action.bound,
      changeStatusFilter: action.bound,
      changeMetalFilter: action.bound,
      changeTypeFilter: action.bound,
      changeFixingTypeFilter: action.bound,
      resetFilters: action.bound,
      loadCompanyOptions: action.bound,
    });
  }

  private get _dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get params() {
    return this._queryParams.params;
  }

  get paramsJson(): BidListPayload {
    return this.toJson();
  }

  get isFiltered(): boolean {
    const params = this.params;

    return PARAMS_KEYS.some((key) => Boolean(params[key]));
  }

  get companyNameFilterOptions(): Options<number> {
    return this._companyOptions.value;
  }

  get metalFilterOptions(): Options<number> {
    const metallist = this._dictionaries.products.list.entities.get(ProductType.metal)?.items;

    return metallist ?? [];
  }

  applyFilters() {
    this._queryParams.setParams({
      limit: this._list.limit,
      offset: this._list.offset,
      status: this.statusFilter.value,
      product_id: this.metalFilter.value,
      type: this.typeFilter.value,
      fixing_type: this.fixingTypeFilter.value,
      search: this.searchFilter.value,
      company_id: this.companyIdFilter.value,
    });
  }

  resetFilters(): void {
    this.searchFilter.change('');
    this.statusFilter.change(null);
    this.metalFilter.change(null);
    this.fixingTypeFilter.change(null);
    this.typeFilter.change(null);
    this.companyIdFilter.change(null);
    this.applyFilters();
  }

  protected toJson(): BidListPayload {
    return {
      limit: this._list.limit,
      offset: this._list.offset,
      ...(this.typeFilter.value ? { type: this.typeFilter.value } : {}),
      ...(this.fixingTypeFilter.value ? { fixing_type: this.fixingTypeFilter.value } : {}),
      ...(this.statusFilter.value ? { status: this.statusFilter.value } : {}),
      ...(this.metalFilter.value ? { product_id: this.metalFilter.value } : {}),
      ...(this.searchFilter.value ? { search: this.searchFilter.value } : {}),
      ...(this.companyIdFilter.value ? { company_id: this.companyIdFilter.value } : {}),
    };
  }

  protected _normalizeQueryParams(params: URLSearchParams): BidListStoreQueryParams {
    const paramsObj: Partial<BidListStoreQueryParams> = queryString.parse(params.toString(), {
      parseNumbers: true,
      parseBooleans: true,
      arrayFormat: 'bracket',
    });

    return {
      limit: paramsObj.limit ?? 10,
      offset: paramsObj.offset ?? 0,
      status: paramsObj.status ?? null,
      product_id: paramsObj.product_id ?? null,
      type: paramsObj.type ?? null,
      fixing_type: paramsObj.fixing_type ?? null,
      search: paramsObj.search ?? '',
      company_id: paramsObj.company_id ?? null,
    };
  }

  changeSearchFilter = (value: string) => {
    this.searchFilter.change(value);
    this._list.changeOffset(0);
    this.applyFilters();
  };

  changeStatusFilter = (value: Nullable<BidStatus>) => {
    if (value !== this.statusFilter.value) {
      this.statusFilter.change(value);
      this._list.changeOffset(0);
      this.applyFilters();
    }
  };

  changeMetalFilter = (value: Nullable<number>): void => {
    if (value !== this.metalFilter.value) {
      this.metalFilter.change(value);
      this._list.changeOffset(0);
      this.applyFilters();
    }
  };

  changeTypeFilter = (value: Nullable<BidType>): void => {
    if (value !== this.typeFilter.value) {
      this.typeFilter.change(value);
      this._list.changeOffset(0);
      this.applyFilters();
    }
  };

  changeFixingTypeFilter = (value: Nullable<BidFixingType>): void => {
    if (value !== this.fixingTypeFilter.value) {
      this.fixingTypeFilter.change(value);
      this._list.changeOffset(0);
      this.applyFilters();
    }
  };

  changeCompanyNameFilter = (value: Nullable<number>): void => {
    if (value !== this.companyIdFilter.value) {
      this.companyIdFilter.change(value);
      this._list.changeOffset(0);
      this.applyFilters();
    }
  };

  loadCompanyOptions = async (url: string): Promise<void> => {
    if (!url) return;

    this.companyOptionLoading.loading();

    const response = await this._companyOptionsRequest.call({
      url: url,
    });

    if (response.isError) {
      this.companyOptionLoading.error();

      return;
    }

    this.companyOptionLoading.success();

    this._companyOptions.change(
      response.data.companies.map((server) => ({
        label: server.name,
        value: server.id,
      })),
    );
  };
}
