import * as React from 'react';

type Props = Partial<{
  className: string;
  index: number;
  payload: { value: number };
  tickFormatter: (number: number) => string;
  visibleTicksCount: number;
  x: number;
  y: number;
}>;

const YAxisTick = ({
  index,
  visibleTicksCount,
  payload,
  tickFormatter,
  ...props
}: Props): React.ReactElement<SVGElement> => {
  if (!index || !payload) {
    return <text />;
  }

  const isLastTick = Boolean(visibleTicksCount && index === visibleTicksCount - 1);
  const textY = isLastTick ? (props.y ?? 0) + 8 : (props.y ?? 0) + 16;

  return (
    <text {...props} y={textY} fontSize={12}>
      {tickFormatter ? tickFormatter(payload.value) : payload.value}
    </text>
  );
};

export default React.memo(YAxisTick);
