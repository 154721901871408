import { action, computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { RouteKey } from '@/shared/config/routes';

import { IPresentedElement } from '../../types';

import { PagesModel } from './PagesModel';

export type BasePageModelParams = {
  pagesModel: PagesModel;
  rootStore: IRootStore;
};

type Params = BasePageModelParams & {
  routeKey: RouteKey;
  url: string;
};

export abstract class BasePageModel {
  readonly routeKey: RouteKey;
  readonly url: string;

  protected readonly _rootStore: IRootStore;

  protected readonly _pagesModel: PagesModel;

  constructor(parms: Params) {
    this.routeKey = parms.routeKey;
    this.url = parms.url;

    this._rootStore = parms.rootStore;
    this._pagesModel = parms.pagesModel;

    makeObservable(this, {
      elements: computed,

      goToPage: action.bound,
    });
  }

  abstract get elements(): Array<IPresentedElement>;

  goToPage(): void {
    this._rootStore.routerStore.navigate(this.url, {
      unstable_flushSync: true,
    });
  }
}
