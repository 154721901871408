import { IRootStore } from '@/app/store';
import { ProductDictionaryModel, ProductType, UnitDictionaryModel } from '@/entities/dictionary';
import { TickerModel } from '@/entities/dictionary/model/TickerModel';
import { isNullable } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';

import {
  ProductPriceWsBody,
  StockMarketWsEvents,
  StockMarketWsEventType,
  SubscriptionChannel,
  WSLocation,
} from '../types';

import { BaseWsEventsHandlingModel } from './BaseWsEventsHandlingModel';

type Params = {
  rootStore: IRootStore;
};

export class StockMarketWsEventsHandlingStore extends BaseWsEventsHandlingModel<
  StockMarketWsEvents,
  WSLocation.stockMarket,
  'all'
> {
  constructor({ rootStore }: Params) {
    super({
      location: WSLocation.stockMarket,
      rootStore,
    });
  }

  protected get _subscriptionChannel(): Nullable<SubscriptionChannel<WSLocation.stockMarket, 'all'>> {
    return `${WSLocation.stockMarket}:all`;
  }

  protected _wsEventHandler(): void {
    if (this.handlingStage.isLoading) {
      return;
    }

    const event = this._eventQueue.shift();

    if (!event) {
      return;
    }

    this.handlingStage.loading();

    switch (event.type) {
      case StockMarketWsEventType.productPrice:
        this._updateMetalPrice(event.body);
        this._updateTickerPrice(event.body);

        break;
    }

    this.handlingStage.success();
  }

  private readonly _updateMetalPrice = ({ product, ...priceServer }: ProductPriceWsBody): void => {
    const { products } = this._rootStore.dictionariesStore;
    const metalList = products.list.entities.get(ProductType.metal);
    const metal = product && metalList?.entities.get(product.id);

    if (isNullable(product) || isNullable(priceServer.currency) || isNullable(metalList) || isNullable(metal)) {
      return;
    }

    const newPrice = {
      date: new Date(priceServer.date),
      current: priceServer.current,
      previous: priceServer.previous,
      currency: UnitDictionaryModel.fromJson(priceServer.currency),
    };

    const updatedMetal = new ProductDictionaryModel({
      value: product.id,
      label: metal.label,
      title: metal.title,
      type: metal.type,
      unitList: metal.unitList,
      shapeList: metal.shapeList,
      profinance: newPrice,
    });

    metalList.updateEntity({ key: product.id, entity: updatedMetal });
  };

  private readonly _updateTickerPrice = (body: ProductPriceWsBody): void => {
    const { tickers } = this._rootStore.dictionariesStore;

    const updatedTicker = new TickerModel({
      date: new Date(body.date),
      tickerType: body.ticker,
      current: body.current,
      previous: body.previous,
      currency: body.currency && UnitDictionaryModel.fromJson(body.currency),
      localizationStore: this._rootStore.localizationStore,
    });

    tickers.list.updateEntity({ key: body.ticker, entity: updatedTicker });
  };
}
