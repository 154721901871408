import { CompanyType } from '@/entities/company';
import { DictionaryServer } from '@/entities/dictionary';
import {
  DocumentGenerationWithStatusServer,
  DocumentServer,
  DocumentWithStatusServer,
  FileServer,
} from '@/entities/file';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer, DestinationCountryDocsServer, OptionFieldsAction, ShipmentType } from './baseStage';

export enum ManagerShipmentStep {
  supplierSpecification = 'upload_shipment_specification',
  supplierSpecificationVerifying = 'shipment_specification_verifying',
  shipmentType = 'select_shipment_type',
  cargoShipment = 'cargo_shipment_confirmation',
  /** StageType.transit fields */
  transitCountry = 'select_transit_country',
  transitCountryAwb = 'upload_awb_for_transit_country',
  transitCountryAwbVerifying = 'shipment_transit_awb_verifying',
  /** StageType.direct fields */
  destinationCountryDocs = 'upload_direct_shipment_documents',
  destinationCountryAwb = 'upload_awb_for_direct_country',
  transfer = 'transfer',
}

export enum ManagerSpecificationVerifyingAction {
  approveSpecification = 'approve_shipment_specification_verifying',
  rejectSpecification = 'reject_shipment_specification_verifying',
}

export enum ManagerAwbVerifyingAction {
  approveAwb = 'approve_awb_verifying',
  rejectAwb = 'reject_awb_verifying',
}

export enum AwbTransitAction {
  uploadFile = 'upload_awb_for_transit_country',
  flightNumber = 'set_flight_number_for_transit_country',
}

export enum AwbDirectAction {
  uploadFile = 'upload_awb_for_direct_country',
  flightNumber = 'set_flight_number_for_direct_country',
}

export type ManagerShipmentAction =
  | Exclude<
      ManagerShipmentStep,
      | ManagerShipmentStep.supplierSpecificationVerifying
      | ManagerShipmentStep.transitCountryAwbVerifying
      | ManagerShipmentStep.destinationCountryAwb
      | ManagerShipmentStep.transitCountryAwb
    >
  | ManagerSpecificationVerifyingAction
  | ManagerAwbVerifyingAction
  | OptionFieldsAction.deleteSupplierSpec
  | AwbTransitAction
  | AwbDirectAction;

export enum SupplierShipmentStep {
  supplierSpecification = 'upload_shipment_specification',
  supplierSpecificationVerifying = 'shipment_specification_verifying',
  transitCountry = 'select_transit_country',
  transitCountryAwb = 'upload_awb_for_transit_country',
  transitCountryAwbVerifying = 'shipment_transit_awb_verifying',
  cargoShipment = 'cargo_shipment_confirmation',
  transfer = 'transfer',
}

export type SupplierShipmentAction = Extract<
  SupplierShipmentStep,
  SupplierShipmentStep.supplierSpecification | SupplierShipmentStep.transitCountryAwb
>;

export enum CustomerShipmentStep {
  shipmentType = 'select_shipment_type',
  destinationCountryDocs = 'upload_direct_shipment_documents',
  transfer = 'transfer',
}

type MapUserTypeToShipmentStageData = {
  [CompanyType.owner]: {
    [ManagerShipmentStep.supplierSpecification]: Nullable<DocumentGenerationWithStatusServer>;
    [ManagerShipmentStep.supplierSpecificationVerifying]: null;
    [ManagerShipmentStep.shipmentType]: Nullable<{ type: ShipmentType }>;
    [ManagerShipmentStep.cargoShipment]: Nullable<{ shipped: boolean }>;
    [ManagerShipmentStep.transitCountry]: Nullable<DictionaryServer>;
    [ManagerShipmentStep.transitCountryAwb]: Nullable<DocumentWithStatusServer>;
    [ManagerShipmentStep.transitCountryAwbVerifying]: null;
    [ManagerShipmentStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer>;
    [ManagerShipmentStep.destinationCountryAwb]: Nullable<DocumentServer>;
  };
  [CompanyType.supplier]: {
    [SupplierShipmentStep.supplierSpecification]: Nullable<DocumentGenerationWithStatusServer>;
    [SupplierShipmentStep.transitCountry]: null;
    [SupplierShipmentStep.cargoShipment]: Nullable<{ shipped: boolean }>;
    [SupplierShipmentStep.transitCountryAwb]: Nullable<DocumentWithStatusServer>;
    [SupplierShipmentStep.transitCountryAwbVerifying]: null;
  };
  [CompanyType.customer]: {
    [CustomerShipmentStep.destinationCountryDocs]: Nullable<DestinationCountryDocsServer<FileServer>>;
  };
};

type BaseShipmentStageDataServer<CT extends CompanyType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToShipmentStageData[CT]
>;

export type ManagerShipmentStageDataServer = BaseShipmentStageDataServer<CompanyType.owner, ManagerShipmentStep>;
export type SupplierShipmentStageDataServer = BaseShipmentStageDataServer<CompanyType.supplier, SupplierShipmentStep>;
export type CustomerShipmentStageDataServer = BaseShipmentStageDataServer<CompanyType.customer, CustomerShipmentStep>;

export type ShipmentStageDataServer =
  | ManagerShipmentStageDataServer
  | SupplierShipmentStageDataServer
  | CustomerShipmentStageDataServer;

export type ShipmentAction = ManagerShipmentAction | SupplierShipmentAction;
