import { action, computed, makeObservable } from 'mobx';

import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';

import { IVerificationFields } from '../../types/client';

export type VerificationFieldsModel = BaseFieldModel<any> | BaseVerificationFieldsModel<unknown>;

export abstract class BaseVerificationFieldsModel<
  Payload,
  FieldsModel extends VerificationFieldsModel = BaseFieldModel<any>,
> implements IVerificationFields<Payload>
{
  constructor() {
    makeObservable(this, {
      isFilled: computed,
      isChanged: computed,
      isError: computed,

      validate: action.bound,
      toJson: action.bound,
    });
  }

  get isFilled(): boolean {
    return this._fields.every((field) =>
      field instanceof BaseFieldModel ? !field.required || field.value : field.isFilled,
    );
  }

  get isChanged(): boolean {
    return this._fields.some((field) =>
      field instanceof BaseFieldModel ? field.initialValue !== field.value : field.isChanged,
    );
  }

  get isError(): boolean {
    return this._fields.some((field) => field.isError);
  }

  validate(): boolean {
    this._fields.forEach((field) => field.validate());

    return this.isError;
  }

  abstract toJson(): Partial<Payload>;

  protected abstract get _fields(): Array<FieldsModel>;
}
