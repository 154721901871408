import { UploadFile } from 'antd';
import { computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { ChatCoords } from '@/entities/chat';
import { CompanyModel } from '@/entities/company';
import { IDictionariesStore, IDictionary, ProductWeightType } from '@/entities/dictionary';
import { FileModel } from '@/entities/file';
import { ShipmentType, StageType } from '@/entities/stage';
import { CustomerModel, SupplierModel } from '@/entities/user';
import { isNumber } from '@/shared/types/typesGuard';
import { Amount, Nullable } from '@/shared/types/values';
import { formatNumber } from '@/shared/utils';
import { formatDate } from '@/shared/utils/formatDate';

import { ITradeInfo, TradeInfo, TradeResponse, TradeStatus } from '../types';

import { BaseTradeModel } from './BaseTradeModel';

type TradeInfoData = Omit<ITradeInfo, 'info' | 'userStore'>;

type TradeInfoModelParams = {
  data: Omit<TradeInfoData, 'ligatureWeight'> & { shipmentType: Nullable<ShipmentType> };
  rootStore: IRootStore;
};

export class TradeInfoModel extends BaseTradeModel<TradeInfoData> implements ITradeInfo {
  readonly currentStageType: StageType;
  readonly currentStep: Nullable<string>;
  readonly shipmentType: Nullable<ShipmentType>;

  readonly legalCompanyFrom: Nullable<IDictionary>;
  readonly legalCompanyTo: Nullable<IDictionary>;
  readonly buyAmount: Nullable<Amount>;
  readonly buyAmountTotal: Nullable<Amount>;

  readonly transitTrackingLink: Nullable<string>;
  readonly destinationTrackingLink: Nullable<string>;
  readonly transitCountry: Nullable<number>;
  readonly destinationLogisticCompany: Nullable<IDictionary>;
  readonly transitLogisticCompany: Nullable<IDictionary>;

  readonly arbitraryDocuments: Nullable<UploadFile[]>;
  readonly supplierSpecification: Nullable<UploadFile>;
  readonly warrantyLoa: Nullable<UploadFile>;
  readonly paymentTaxesCertificate: Nullable<UploadFile>;
  readonly authorizationLetter: Nullable<UploadFile>;
  readonly finalizationPhoto: Nullable<UploadFile>;

  readonly chats: Array<ChatCoords>;

  constructor({ data, rootStore }: TradeInfoModelParams) {
    super({ data, rootStore });

    this.currentStep = data.currentStep;
    this.currentStageType = data.currentStageType;
    this.shipmentType = data.shipmentType;
    this.legalCompanyFrom = data.legalCompanyFrom;
    this.legalCompanyTo = data.legalCompanyTo;
    this.buyAmount = data.buyAmount;
    this.buyAmountTotal = data.buyAmountTotal;

    this.transitTrackingLink = data.transitTrackingLink;
    this.destinationTrackingLink = data.destinationTrackingLink;
    this.transitCountry = data.transitCountry;
    this.destinationLogisticCompany = data.destinationLogisticCompany;
    this.transitLogisticCompany = data.transitLogisticCompany;

    this.arbitraryDocuments = data.arbitraryDocuments;
    this.supplierSpecification = data.supplierSpecification;
    this.warrantyLoa = data.warrantyLoa;
    this.paymentTaxesCertificate = data.paymentTaxesCertificate;
    this.authorizationLetter = data.authorizationLetter;
    this.finalizationPhoto = data.finalizationPhoto;

    this.chats = data.chats;

    makeObservable(this, {
      isResponsiblePerson: computed,
      tradeDisabled: computed,
      stageDisabled: computed,
      trackingLink: computed,
      info: computed,
    });
  }

  get isResponsiblePerson(): boolean {
    const { userModel, isOwner, isSupplier, isCustomer } = this._rootStore.userStore;

    const userId = userModel && userModel.id;
    const supplierEmployeeId = this.supplierEmployee?.id;
    const customerEmployeeId = this.customerEmployee?.id;

    return isOwner || (isSupplier && userId === supplierEmployeeId) || (isCustomer && userId === customerEmployeeId);
  }

  get tradeDisabled(): boolean {
    const { isOwnerObserver } = this._rootStore.userStore;

    return this.tradeStatus === TradeStatus.finished || isOwnerObserver || !this.isResponsiblePerson;
  }

  get stageDisabled(): boolean {
    return this.stageType !== this.currentStageType || this.currentStep === 'finish';
  }

  get trackingLink(): Nullable<string> {
    if (Boolean(this.destinationCountryCargoArrivalDate)) {
      return null;
    }

    const isDestinationTrackingLink =
      this.shipmentType === ShipmentType.direct ||
      Boolean(this.shipmentType === ShipmentType.transit && this.transitCountryCargoArrivalDate);

    return isDestinationTrackingLink ? this.destinationTrackingLink : this.transitTrackingLink;
  }

  get dictionaries(): IDictionariesStore {
    return this._rootStore.dictionariesStore;
  }

  get info(): TradeInfo {
    const product = this.tradeDictionaries?.product ?? null;
    const productType = this.tradeDictionaries?.productType ?? null;
    const weightUnit = this.tradeDictionaries?.weightUnit ?? null;

    return {
      productType: productType && productType.label,
      weightWithUnit:
        weightUnit && this._formatNumberWithUnit(this.weight, this._tWeightUnit(weightUnit.slug, this.weight), 3),
      ligatureWeightWithUnit:
        product &&
        weightUnit &&
        this._formatNumberWithUnit(this.ligatureWeight, this._tWeightUnit(weightUnit.slug, this.ligatureWeight), 3),
      fixingDate: formatDate(this.fixingDate, 'DD-MM-YYYY'),
      product: product && product.label,

      /** Скрываем инфу для наблюдателя владельца */
      ...(this._rootStore.userStore.isOwnerObserver
        ? {
            customerCompany: null,
            supplierCompany: null,
            supplierEmployee: null,
            customerEmployee: null,
            legalCompanyFrom: null,
            legalCompanyTo: null,
          }
        : {
            customerCompany: this.customerCompany && this.customerCompany.name,
            supplierCompany: this.supplierCompany && this.supplierCompany.name,
            supplierEmployee: this.supplierEmployee && this.supplierEmployee.fio,
            customerEmployee: this.customerEmployee && this.customerEmployee.fio,
            legalCompanyFrom: this.legalCompanyFrom && this.legalCompanyFrom.label,
            legalCompanyTo: this.legalCompanyTo && this.legalCompanyTo.label,
          }),
      /** */

      sellAmount: this._amountToString(this.sellAmount),
      sellAmountTotal: this._amountToString(this.sellAmountTotal),
      buyAmount: this._amountToString(this.buyAmount),
      buyAmountTotal: this._amountToString(this.buyAmountTotal),
      transitCountry:
        (this.transitCountry && this.dictionaries.countries.list.getEntity(this.transitCountry)?.label) || null,
      transitLogisticCompany: this.transitLogisticCompany && this.transitLogisticCompany.label,
      destinationLogisticCompany: this.destinationLogisticCompany && this.destinationLogisticCompany.label,
      totalAmountUSD: isNumber(this.customerPayment) ? this._formatNumberWithUnit(this.customerPayment, 'USD') : null,
      chats: this.chats,
    };
  }

  private readonly _amountToString = (amount: Nullable<Amount>): Nullable<string> => {
    if (!amount) {
      return null;
    }

    return this._formatNumberWithUnit(amount.sum, amount.currency.code);
  };

  private readonly _formatNumberWithUnit = (number: number, unit: string, decimals: number = 2): string => {
    return formatNumber(number, {
      unit,
      decimals,
      language: this._rootStore.localizationStore.lng,
    });
  };

  private readonly _tWeightUnit = (unit: ProductWeightType, weight: number): string => {
    const { t } = this._rootStore.localizationStore;

    return t(`weightType.${unit}`, {
      ns: 'dictionary',
      count: Math.round(weight),
    });
  };

  static fromJson({ json, rootStore }: { json: TradeResponse; rootStore: IRootStore }): TradeInfoModel {
    return new TradeInfoModel({
      data: {
        id: json.id,
        currentStep: json.stage.data.current_step,
        tradeStatus: json.status,
        stageType: json.stage.key,
        shipmentType: json.stage.data.shipment_type,
        currentStageType: json.stage.data.current_stage,
        productTypeId: json.product_type_id,
        productId: json.product_id,
        weight: json.weight,
        weightUnitId: json.weight_unit_id,
        fixingDate: new Date(json.fixing_date),
        fixingPrice: json.bid?.fixing_price ?? null,
        customerCompany: json.customer && CompanyModel.fromJson(json.customer.company),
        supplierCompany: json.supplier && CompanyModel.fromJson(json.supplier.company),
        customerEmployee: json.customer && CustomerModel.fromJson(json.customer),
        supplierEmployee: json.supplier && SupplierModel.fromJson(json.supplier),

        sellAmount: this.amountFromJson(json.sell_price, json.sell_price_unit),
        sellAmountTotal: this.amountFromJson(json.sell_price_total, json.sell_price_unit),
        buyAmount: this.amountFromJson(json.buy_price, json.buy_price_unit),
        buyAmountTotal: this.amountFromJson(json.buy_price_total, json.buy_price_unit),
        legalCompanyFrom: this.dictionaryFromJson(json.legal_company_from),
        legalCompanyTo: this.dictionaryFromJson(json.legal_company_to),
        transitCountryCargoArrivalDate: this.dateFromJson(json.arrived_in_transit_country_at),
        transitCountryCargoShipmentDate: this.dateFromJson(json.shipped_from_transit_country_at),
        destinationCountryCargoArrivalDate: this.dateFromJson(json.arrived_at),
        cargoDeliveredDate: this.dateFromJson(json.delivered_at),
        customerPayment: json.transaction_amount_usd,
        paymentDate: this.dateFromJson(json.finished_at),

        transitTrackingLink: json.transit_tracking_link,
        destinationTrackingLink: json.destination_tracking_link,
        transitCountry: json.transit_country,
        destinationLogisticCompany: this.dictionaryFromJson(json.destination_logistic_company),
        transitLogisticCompany: this.dictionaryFromJson(json.transit_logistic_company),

        arbitraryDocuments: FileModel.fileListFromJson(json.stage.data.arbitrary_documents),
        supplierSpecification: FileModel.fileFromJson(json.stage.data.supplier_specification),
        warrantyLoa: FileModel.fileFromJson(json.stage.data.warranty_loa),
        paymentTaxesCertificate: FileModel.fileFromJson(json.stage.data.indirect_taxes_payment_certificate),
        authorizationLetter: FileModel.fileFromJson(json.stage.data.authorization_letter),
        finalizationPhoto: FileModel.fileFromJson(json.stage.data.finalization_photo),
        etdDate: this.dateFromJson(json.etd),
        chats: json.threads.map((thread) => ({
          chatId: thread.chat_id,
          threadId: thread.thread_id,
          companyType: thread.company_type,
        })),
      },
      rootStore,
    });
  }
}
