import { Typography } from 'antd';
import { TitleProps } from 'antd/es/typography/Title';
import cn from 'classnames';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

import s from './CustomTypographyTitle.module.scss';

type CustomTypographyTitleProps = Omit<TitleProps, 'children'> & {
  children: TranslationNode;
};

const CustomTypographyTitle = React.forwardRef<HTMLElement, CustomTypographyTitleProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <Typography.Title ref={ref} className={cn(s.title, className)} {...props}>
        <TransComp>{children}</TransComp>
      </Typography.Title>
    );
  },
);

CustomTypographyTitle.displayName = 'CustomTypographyTitle';

export default CustomTypographyTitle;
