import { action, computed, makeObservable } from 'mobx';

import { CustomerPaymentServer } from '@/entities/stage/types';
import { ListModel, ToggleModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

import { BaseStepModel, BaseStepModelParams } from '../../../fieldsModel';
import { CustomerPaymentModel } from '../CustomerPaymentModel';

type Params<Step, Action> = BaseStepModelParams<Step, Action> & {
  paymentList: ListModel<CustomerPaymentModel, number>;
};

export class CustomerPaymentFieldsModel<Step, Action> extends BaseStepModel<Step, Action> {
  readonly paymentList: ListModel<CustomerPaymentModel, number>;

  readonly modalState = new ToggleModel();

  constructor({ paymentList, ...params }: Params<Step, Action>) {
    super(params);

    this.paymentList = paymentList;

    makeObservable(this, {
      isError: computed,
      isApproved: computed,
      isDeleting: computed,

      addPayment: action.bound,
      removePayment: action.bound,
      openModal: action.bound,
      completeTrade: action.bound,
    });
  }

  get isDisabled(): boolean {
    const { tradeInfo } = this.tradeWorkflowStore;

    return tradeInfo.stageDisabled || tradeInfo.tradeDisabled;
  }

  get isError(): boolean {
    return this.paymentList.items.some((payment) => payment.isError);
  }

  get isApproved(): boolean {
    return this.paymentList.items.every((payment) => payment.isApprovedMode);
  }

  get isDeleting(): boolean {
    return this.paymentList.items.some((payment) => payment.deletingStage.isLoading);
  }

  get approveDisabled(): boolean {
    return !this.paymentList.length || this.isError || !this.isApproved || this.isDeleting;
  }

  get successModalState(): ToggleModel {
    return this.tradeWorkflowStore.successModalState;
  }

  private _validatePayments = (): boolean => {
    return this.paymentList.items.some((payment) => payment.validate());
  };

  openModal(): void {
    const isError = this._validatePayments();

    if (!isError) {
      this.modalState.open();
    }
  }

  addPayment(): void {
    const paymentId = this.paymentList.length + 1;
    this.paymentList.addEntity({
      entity: CustomerPaymentModel.fromJson({
        id: paymentId,
        tradeWorkflowStore: this.tradeWorkflowStore,
      }),
      key: paymentId,
    });
  }

  removePayment(id: number): void {
    this.paymentList.removeEntity(id);
  }

  async completeTrade(): Promise<void> {
    const { isError } = await this.approveStep();

    if (!isError) {
      this.modalState.close();
      this.tradeWorkflowStore.successModalState.open();
    }
  }

  static fromJson<Step, Action>({
    data: payments,
    ...params
  }: { data?: Nullable<CustomerPaymentServer[]> } & BaseStepModelParams<Step, Action>): CustomerPaymentFieldsModel<
    Step,
    Action
  > {
    const paymentList = new ListModel<CustomerPaymentModel, number>();

    if (payments && payments.length) {
      payments.forEach((payment) => {
        paymentList.addEntity({
          entity: CustomerPaymentModel.fromJson({
            id: payment.id,
            payment,
            tradeWorkflowStore: params.tradeWorkflowStore,
          }),
          key: payment.id,
        });
      });
    } else {
      const paymentId = 1;
      paymentList.addEntity({
        entity: CustomerPaymentModel.fromJson({
          id: paymentId,
          tradeWorkflowStore: params.tradeWorkflowStore,
        }),
        key: paymentId,
      });
    }

    return new CustomerPaymentFieldsModel({
      paymentList,
      ...params,
    });
  }
}
