import { useToggleState } from '@kts-front/hooks';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useMatch } from 'react-router';

import { useRootStore } from '@/app/store';
import { routerUrls } from '@/shared/config/routes';
import { useWidth } from '@/shared/hooks';

import { SideMenu } from '../SideMenu';
import SideMenuNavBar from '../SideMenuNavBar';

import s from './SideMenuPageLayout.module.scss';

type SideMenuPageLayoutProps = React.PropsWithChildren;

const SideMenuPageLayout: React.FC<SideMenuPageLayoutProps> = ({ children }) => {
  const isMainPage = useMatch(routerUrls.main.mask);
  const isLoginPage = useMatch(routerUrls.login.mask);
  const isCreatePasswordPage = useMatch(routerUrls.createPassword.mask);

  const isAuthorizedPage = React.useMemo(
    () => !isLoginPage && !isCreatePasswordPage && !isMainPage,
    [isLoginPage, isCreatePasswordPage, isMainPage],
  );

  const { stockMarketStore, dictionariesStore } = useRootStore();
  const { toggle: toggleMenuOpen, close: closeMenu, opened: isMenuOpened } = useToggleState(true);
  const { isDesktop } = useWidth();

  React.useLayoutEffect(() => {
    if (!isDesktop) {
      closeMenu();
    }
  }, [isDesktop]);

  React.useEffect(() => {
    if (isAuthorizedPage) {
      dictionariesStore.tickers.load();
      stockMarketStore.subscribe();
    } else {
      dictionariesStore.tickers.list.reset();
      stockMarketStore.unsubscribe();
    }
  }, [isAuthorizedPage, stockMarketStore]);

  if (!isAuthorizedPage) {
    return children;
  }

  return (
    <div className={s['page-layout']}>
      {isDesktop ? (
        <SideMenu
          className={cn(s['page-layout__menu-desktop'])}
          isMenuOpened={isMenuOpened}
          toggleMenuOpen={toggleMenuOpen}
        />
      ) : (
        <SideMenuNavBar isMenuOpened={isMenuOpened} toggleMenuOpen={toggleMenuOpen} />
      )}
      <div className={cn(s['page-layout__content'], !isMenuOpened && s['page-layout__content_wider'])}>{children}</div>
    </div>
  );
};

export default observer(SideMenuPageLayout);
