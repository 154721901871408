import { QuestionCircleOutlined } from '@ant-design/icons';
import { BaseResponse } from '@kts-front/types';
import { Flex, Tooltip } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { EmailListModel } from '@/entities/notification/model/EmailListModel';
import { themeToken } from '@/shared/config/themeConfig';
import { TransComp } from '@/shared/ui/TransComp';
import { CustomFormItem } from '@/shared/ui/form/CustomFormItem';

import { ChangeEmailListParams } from '../../types';
import { EmailListInput } from '../EmailListInput';

type FormEmailListInputProps = {
  className?: string;
  disabled?: boolean;
  onChange: (params: ChangeEmailListParams) => Promise<BaseResponse>;
  model: EmailListModel;
};

const FormEmailListInput: React.FC<FormEmailListInputProps> = ({ className, disabled, onChange, model }) => {
  return (
    <CustomFormItem className={className} ref={model.ref} model={model}>
      <Flex gap={8}>
        <EmailListInput {...model.props} onChange={onChange} disabled={model.disabled || disabled} />
        <Tooltip placement="top" title={<TransComp>{model.tooltip}</TransComp>}>
          <QuestionCircleOutlined style={{ fontSize: 16, color: themeToken.colorIcon }} />
        </Tooltip>
      </Flex>
    </CustomFormItem>
  );
};

export default observer(FormEmailListInput);
