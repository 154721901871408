import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/shared/api';
import { ListModel } from '@/shared/model';
import { ApiListResponse } from '@/shared/types/api';
import { ID } from '@/shared/types/meta';

import { DictionaryServer, IDictionaryStore, UnitDictionaryListResponse } from '../../types';
import { UnitDictionaryModel } from '../UnitDictionaryModel';

import { defaultParams } from './config';

export type DictionaryListResponse<T extends ID = number> = ApiListResponse<DictionaryServer<T>>;

export class CountriesStore implements IDictionaryStore<UnitDictionaryModel> {
  readonly list = new ListModel<UnitDictionaryModel, number>();

  private readonly countryListRequest = apiStore.createRequest<UnitDictionaryListResponse>({
    url: apiUrls.dictionaries.countryList,
  });

  async load(): Promise<BaseResponse> {
    const response = await this.countryListRequest.call({
      params: defaultParams,
    });

    if (response.isError) {
      return { isError: true };
    }

    this.list.fillByRawData(
      response.data.results,
      (raw) => ({
        key: raw.id,
        entity: UnitDictionaryModel.fromJson(raw),
      }),
      true,
    );

    return { isError: false };
  }
}
