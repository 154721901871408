import { TranslationString } from './localization';
import { CheckStatus } from './meta';

export const stringIsNumber = (str: string): boolean => !isNaN(Number(str));

type NullOrUndefined = null | undefined;
export const isNullable = (value: unknown): value is NullOrUndefined => value === null || value === undefined;

export const isString = (value: unknown): value is string => typeof value === 'string';

export const isNumber = (value: unknown): value is number => typeof value === 'number';

export const isStringOrNumber = (value: unknown): value is string | number => isString(value) || isNumber(value);

export const isBoolean = (value: unknown): value is boolean => typeof value === 'boolean';

export const isObject = (value: unknown): value is Record<PropertyKey, unknown> =>
  typeof value === 'object' && value !== null && !Array.isArray(value);

// eslint-disable-next-line @typescript-eslint/ban-types
export const isFunction = (value: unknown): value is Function =>
  typeof value === 'function' || value instanceof Function;

export const isTranslationString = (value: unknown): value is TranslationString => isString(value) || isFunction(value);

export const isCheckStatus = (value: unknown): value is CheckStatus =>
  Object.values(CheckStatus).some((status) => status === value);
