import { action, computed, makeObservable } from 'mobx';

import { ValueModel } from '@/shared/model';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';

import { IPresentedElement } from '../types';

type Params = {
  title: TranslationString;
  description: TranslationString;
  beforeAction?: VoidFunction;
  afterActionForward?: VoidFunction;
  afterActionBackward?: VoidFunction;
};

export class PresentedElementModel<Node extends HTMLElement> implements IPresentedElement<Node> {
  readonly title: TranslationString;
  readonly description: TranslationString;

  private readonly _beforeAction: ValueModel<Nullable<VoidFunction>>;
  private readonly _afterActionForward: ValueModel<Nullable<VoidFunction>>;
  private readonly _afterActionBackward: ValueModel<Nullable<VoidFunction>>;

  private readonly _nodeModel = new ValueModel<Nullable<Node>>(null);

  constructor(params: Params) {
    this.title = params.title;
    this.description = params.description;

    this._beforeAction = new ValueModel(params.beforeAction ?? null);
    this._afterActionForward = new ValueModel(params.afterActionForward ?? null);
    this._afterActionBackward = new ValueModel(params.afterActionBackward ?? null);

    makeObservable(this, {
      node: computed,
      presentable: computed,

      beforeAction: computed,
      afterActionForward: computed,
      afterActionBackward: computed,

      setBeforeAction: action.bound,
      setAfterActionForward: action.bound,
      setAfterActionBackward: action.bound,

      refCallback: action.bound,
    });
  }

  get node(): Nullable<Node> {
    return this._nodeModel.value;
  }

  get presentable(): boolean {
    return Boolean(this.node);
  }

  get beforeAction(): Nullable<VoidFunction> {
    return this._beforeAction.value;
  }

  get afterActionForward(): Nullable<VoidFunction> {
    return this._afterActionForward.value;
  }

  get afterActionBackward(): Nullable<VoidFunction> {
    return this._afterActionBackward.value;
  }

  setBeforeAction(beforeAction: Nullable<VoidFunction>): void {
    this._beforeAction.change(beforeAction);
  }

  setAfterActionForward(action: Nullable<VoidFunction>): void {
    this._afterActionForward.change(action);
  }

  setAfterActionBackward(action: Nullable<VoidFunction>): void {
    this._afterActionBackward.change(action);
  }

  refCallback(node: Nullable<Node>) {
    this._nodeModel.change(node);
  }
}
