import { ApiRequest } from '@kts-front/call-api';
import { BaseResponse } from '@kts-front/types';
import { action, computed, makeObservable } from 'mobx';

import { ITradeWorkflowStore, TradeResponse } from '@/entities/trade';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';

export type BaseStepModelParams<Step, Action> = {
  tradeWorkflowStore: ITradeWorkflowStore;
  approveAction: Action;
  rejectAction?: Action;
  step: Step;
};

type ServerPayload<Action> = {
  action: Action;
};

export abstract class BaseStepModel<Step, Action, Payload extends ServerPayload<Action> = ServerPayload<Action>> {
  protected readonly approveAction: Action;
  protected readonly rejectAction: Nullable<Action>;
  protected readonly step: Step;

  readonly loadingStage = new LoadingStageModel();
  readonly tradeWorkflowStore: ITradeWorkflowStore;

  protected readonly _stepRequest: ApiRequest<TradeResponse>;

  constructor({ tradeWorkflowStore, approveAction, rejectAction, step }: BaseStepModelParams<Step, Action>) {
    this._stepRequest = apiStore.createRequest({
      method: 'POST',
      url: apiUrls.trade.action(tradeWorkflowStore.tradeId),
    });

    this.step = step;
    this.approveAction = approveAction;
    this.rejectAction = rejectAction ?? null;
    this.tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      isCurrentStep: computed,
      isLoading: computed,
      isDisabled: computed,

      approveStep: action.bound,
      rejectStep: action.bound,
    });
  }

  get isCurrentStep(): boolean {
    return this.step === this.tradeWorkflowStore.stageModel.step;
  }

  get isLoading(): boolean {
    return this.loadingStage.isLoading;
  }

  get isDisabled(): boolean {
    return this.step !== this.tradeWorkflowStore.stageModel.step || this.tradeWorkflowStore.tradeInfo.tradeDisabled;
  }

  protected toApproveJson(): Nullable<Payload> {
    return {
      action: this.approveAction,
    } as Payload;
  }

  protected toRejectJson(): Nullable<Payload> {
    if (!this.rejectAction) {
      return null;
    }

    return {
      action: this.rejectAction,
    } as Payload;
  }

  async approveStep(): Promise<BaseResponse> {
    const json = this.toApproveJson();

    if (this.loadingStage.isLoading || !json) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const response = await this._stepRequest.call({
      multipartFormData: true,
      data: json,
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    this.loadingStage.success();
    this.tradeWorkflowStore.updateTradeWorkflow(response.data);

    return { isError: false };
  }

  async rejectStep(): Promise<BaseResponse> {
    const json = this.toRejectJson();

    if (this.loadingStage.isLoading || !json) {
      return { isError: true };
    }

    this.loadingStage.loading();

    const response = await this._stepRequest.call({
      multipartFormData: true,
      data: json,
    });

    if (response.isError) {
      this.loadingStage.error();

      return { isError: true };
    }

    this.loadingStage.success();
    this.tradeWorkflowStore.updateTradeWorkflow(response.data);

    return { isError: false };
  }
}
