import { DocumentFileType, UploadOrGenerateDocumentModel } from '@/entities/file';
import {
  StageModelJsonParams,
  StageType,
  SupplierConfirmationStageDataServer,
  SupplierConfirmationStep,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/entities/stage/utils';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import {
  DeliveryConfirmationModel,
  UploadOrGenerateDocumentStepModel,
  WaitingFileStepModel,
  WaitingStepModel,
} from '../../fieldsModel';

import { stepsOrder } from './config';

type Params = BaseStageModelParams<SupplierConfirmationStep> & {
  deliveryConfirmStep: UploadOrGenerateDocumentStepModel<
    SupplierConfirmationStep.deliveryConfirmation,
    SupplierConfirmationStep.deliveryConfirmation,
    DocumentFileType.deliveryConfirmation,
    DeliveryConfirmationModel
  >;
  confirmVerifyingStep: WaitingStepModel<SupplierConfirmationStep>;
  confirmSigningStep: WaitingFileStepModel<SupplierConfirmationStep>;
};

export class SupplierConfirmationStageModel extends BaseStageModel<StageType.confirmation, SupplierConfirmationStep> {
  readonly deliveryConfirmStep: UploadOrGenerateDocumentStepModel<
    SupplierConfirmationStep.deliveryConfirmation,
    SupplierConfirmationStep.deliveryConfirmation,
    DocumentFileType.deliveryConfirmation,
    DeliveryConfirmationModel
  >;
  readonly confirmVerifyingStep: WaitingStepModel<SupplierConfirmationStep>;
  readonly confirmSigningStep: WaitingFileStepModel<SupplierConfirmationStep>;

  constructor({ deliveryConfirmStep, confirmVerifyingStep, confirmSigningStep, ...params }: Params) {
    super({
      type: StageType.confirmation,
      ...params,
    });

    this.deliveryConfirmStep = deliveryConfirmStep;
    this.confirmVerifyingStep = confirmVerifyingStep;
    this.confirmSigningStep = confirmSigningStep;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): Array<SupplierConfirmationStep> {
    let steps: Array<SupplierConfirmationStep> = [];

    if (this.deliveryConfirmStep.isWaitVerifying) {
      steps = [
        SupplierConfirmationStep.deliveryConfirmation,
        SupplierConfirmationStep.confirmationVerifying,
        SupplierConfirmationStep.confirmationSigning,
      ];
    } else {
      steps = [SupplierConfirmationStep.deliveryConfirmation, SupplierConfirmationStep.confirmationSigning];
    }

    return steps;
  }

  get isApprovedDeliveryConfirm(): boolean {
    return this.deliveryConfirmStep.isApproved;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<SupplierConfirmationStageDataServer>): SupplierConfirmationStageModel {
    return new SupplierConfirmationStageModel({
      deliveryConfirmStep: UploadOrGenerateDocumentStepModel.fromJson({
        uploadOrGenerateDocument: new DeliveryConfirmationModel(
          UploadOrGenerateDocumentModel.paramsFromJson({
            data: dataServer.steps[SupplierConfirmationStep.deliveryConfirmation]?.document,
            docType: DocumentFileType.deliveryConfirmation,
            tradeWorkflowStore,
            required: true,
            fileName: (t) => t('deliveryConfirmation.fileName', { ns: 'stage' }),
          }),
        ),
        data: dataServer.steps[SupplierConfirmationStep.deliveryConfirmation],
        step: SupplierConfirmationStep.deliveryConfirmation,
        approveAction: SupplierConfirmationStep.deliveryConfirmation,
        tradeWorkflowStore,
      }),
      confirmVerifyingStep: WaitingStepModel.fromJson({
        step: SupplierConfirmationStep.confirmationVerifying,
        tradeWorkflowStore,
      }),
      confirmSigningStep: WaitingFileStepModel.fromJson({
        data: dataServer.steps[SupplierConfirmationStep.confirmationSigning],
        step: SupplierConfirmationStep.confirmationSigning,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
