import { DownOutlined } from '@ant-design/icons';
import { useToggleState } from '@kts-front/hooks';
import { Flex, Select } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';

import { LanguageSelectorCallback } from './types';

import s from './LanguageSelector.module.scss';

type LanguageSelectorProps = {
  className?: string;
  isMenuOpened: boolean;
  callbackRef: React.MutableRefObject<LanguageSelectorCallback | null>;
};

const LanguageSelector: React.FC<LanguageSelectorProps> = ({ className, isMenuOpened, callbackRef }) => {
  const { localizationStore } = useRootStore();
  const { t, lng, changeLng, langOptions } = localizationStore;
  const { opened, toggle, close } = useToggleState();

  React.useImperativeHandle(
    callbackRef,
    () => ({
      toggleLanguageSelector: toggle,
    }),
    [],
  );

  React.useEffect(() => {
    close();
  }, [lng, isMenuOpened]);

  return (
    <>
      <Select
        open={opened}
        className={cn(s.language, !isMenuOpened && s.language_closed, className)}
        options={langOptions}
        defaultValue={lng}
        onChange={changeLng}
        labelRender={() => null}
      />
      <Flex className={s.language_label} align="center" gap={8}>
        {t(`locales.${lng}`, { ns: 'shared' })}
        {isMenuOpened && <DownOutlined />}
      </Flex>
    </>
  );
};

export default observer(LanguageSelector);
