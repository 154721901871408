import { Nullable } from '@/shared/types/values';

import { CurrencyPairType, ProductMetalType, UnitDictionaryServer } from './baseServer';

export enum ChartType {
  day = 'day',
  week = 'week',
  month = 'month',
}

export type TickerType = ProductMetalType | CurrencyPairType;

export type TickerHistoryServer = Array<{
  datetime: string;
  price: number;
}>;

export type TickerServer = {
  date: string;
  current: number;
  previous: Nullable<number>;
  currency: Nullable<UnitDictionaryServer>;
  ticker: TickerType;
  history: TickerHistoryServer;
};

export type TickerHistoriesServer = {
  history: Record<ChartType, TickerHistoryServer>;
};

export type TickerListResponse = Array<TickerServer>;
