import { Flex, Segmented } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { chartTypeOptions } from '@/entities/dictionary/config';
import { CurrentTickerHistoryModel } from '@/entities/dictionary/model';
import { ITicker } from '@/entities/dictionary/types';
import { useTypedTranslation } from '@/shared/hooks';
import { CustomTypographyText, TrendArrow } from '@/shared/ui';

import s from './TicketDetailsHeader.module.scss';

type Props = {
  currentTicker: ITicker;
  historyModel: CurrentTickerHistoryModel;
};

const TicketDetailsHeader: React.FC<Props> = ({ currentTicker, historyModel }) => {
  const { t } = useTypedTranslation('dictionary');

  const { tickerType, formatedCurrent } = currentTicker;
  const { priceDifference, chartType, changeChartType } = historyModel;

  const differenceClassName = React.useMemo(
    () =>
      cn(
        s.ticker_difference,
        priceDifference?.isPositive && s.ticker_difference_positive,
        priceDifference?.isNegative && s.ticker_difference_negative,
      ),
    [priceDifference],
  );

  const differencePrefix = React.useMemo(
    () => (
      <span className={s.ticker_difference_prefix}>
        {priceDifference?.isPositive && '+'}
        {priceDifference?.isNegative && '-'}
      </span>
    ),
    [priceDifference],
  );

  return (
    <Flex vertical gap={24}>
      <Flex vertical>
        <CustomTypographyText className={s.ticker_label}>{t(`tickerType.${tickerType}`)}</CustomTypographyText>
        <Flex justify="space-between" gap={8} wrap="wrap">
          <Flex align="center" gap={8} wrap="wrap">
            {formatedCurrent && (
              <CustomTypographyText className={s.ticker_value} type="secondary">
                {formatedCurrent}
              </CustomTypographyText>
            )}
            {priceDifference && (
              <Flex align="center" gap={8}>
                <CustomTypographyText className={differenceClassName}>
                  {differencePrefix}
                  {priceDifference.sum.replace('-', '')}
                </CustomTypographyText>
                <CustomTypographyText className={differenceClassName}>
                  ({differencePrefix}
                  {`${priceDifference.percent.replace('-', '')} %`})
                </CustomTypographyText>
                <TrendArrow positive={priceDifference.isPositive} negative={priceDifference.isNegative} size={18} />
              </Flex>
            )}
          </Flex>
          <Segmented defaultValue={chartType} options={chartTypeOptions} onChange={changeChartType} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default observer(TicketDetailsHeader);
