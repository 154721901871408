import { Drawer } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { useRootStore } from '@/app/store';
import { useWidth } from '@/shared/hooks';
import { CustomTypographyTitle } from '@/shared/ui';
import { getPathname } from '@/shared/utils';

import { menuUrlsMap } from '../../config';
import { SideMenu } from '../SideMenu';
import { SideMenuButton } from '../SideMenuButton';

import s from './SideMenuNavBar.module.scss';

type SideMenuNavBarProps = {
  className?: string;
  isMenuOpened: boolean;
  toggleMenuOpen: VoidFunction;
};

const SideMenuNavBar: React.FC<SideMenuNavBarProps> = ({ className, isMenuOpened, toggleMenuOpen }) => {
  const { userStore, onboardingStore } = useRootStore();
  const location = useLocation();
  const pathName = getPathname(location);

  const { isDesktop } = useWidth();

  const titleRefCallback = React.useMemo(() => {
    if (isDesktop) {
      return;
    }

    const { profile, trades, chats } = onboardingStore.pages;

    switch (pathName) {
      case 'profile':
        return userStore.isClientObserver ? profile.me.refCallback : null;

      case 'trades':
        return trades.header.refCallback;

      case 'chats':
        return chats.header.refCallback;
    }
  }, [pathName, isDesktop]);

  return (
    <>
      <div className={cn(s.navbar, className)}>
        <SideMenuButton onClick={toggleMenuOpen} />
        <CustomTypographyTitle ref={titleRefCallback} level={4}>
          {menuUrlsMap[pathName].label}
        </CustomTypographyTitle>
      </div>
      <Drawer rootClassName={s.navbar_drawer} placement="left" width={248} open={isMenuOpened} onClose={toggleMenuOpen}>
        <SideMenu className={s.navbar_drawer_menu} isMenuOpened={true} closeMenu={toggleMenuOpen} />
      </Drawer>
    </>
  );
};

export default observer(SideMenuNavBar);
