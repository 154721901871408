export const isFilledObject = (value: object): boolean => {
  return Object.values(value).some((item) => {
    switch (typeof item) {
      case 'number':
        return true;

      case 'object':
        return item !== null && isFilledObject(item);
    }

    return item;
  });
};
