import { routerUrls } from '@/shared/config/routes';

import { IPresentedElement } from '../../types';
import { PresentedElementModel } from '../PresentedElementModel';

import { BasePageModel, BasePageModelParams } from './BasePageModel';

const T_OPTIONS = { ns: 'onboarding' } as const;

export class ProfilePageModel extends BasePageModel {
  readonly me = new PresentedElementModel<HTMLSpanElement>({
    title: (t) => t('profile.myProfile.title', T_OPTIONS),
    description: (t) => t('profile.myProfile.description', T_OPTIONS),
    beforeAction: () => this._rootStore.routerStore.navigate(routerUrls.profile.children.me.create()),
  });

  readonly company = new PresentedElementModel<HTMLSpanElement>({
    title: (t) => t('profile.company.title', T_OPTIONS),
    description: (t) => t('profile.company.description', T_OPTIONS),
    beforeAction: () => this._rootStore.routerStore.navigate(routerUrls.profile.children.company.create()),
  });

  readonly employees = new PresentedElementModel<HTMLSpanElement>({
    title: (t) => t('profile.employees.title', T_OPTIONS),
    description: (t) => t('profile.employees.description', T_OPTIONS),
    beforeAction: () => this._rootStore.routerStore.navigate(routerUrls.profile.children.employees.create()),
  });

  readonly notifications = new PresentedElementModel<HTMLSpanElement>({
    title: (t) => t('profile.notifications.title', T_OPTIONS),
    description: (t) => t('profile.notifications.description', T_OPTIONS),
    beforeAction: () => this._rootStore.routerStore.navigate(routerUrls.profile.children.notifications.create()),
  });

  constructor(params: BasePageModelParams) {
    super({
      ...params,
      routeKey: 'profile',
      url: routerUrls.profile.create(),
    });
  }

  get elements(): Array<IPresentedElement> {
    const { isClientObserver, isClientEmployee } = this._rootStore.userStore;

    if (isClientObserver) {
      return [this.me];
    }

    if (isClientEmployee) {
      return [this.me, this.notifications];
    }

    return [this.me, this.company, this.employees, this.notifications];
  }
}
