import { IngotIdType, IngotServer } from '@/entities/stage/types';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';

import { IngotModel, IngotModelParams } from '../IngotModel';

export type CustomerIngotModelParams = IngotModelParams;

export class CustomerIngotModel extends IngotModel {
  private readonly _uinFields: Array<BaseFieldModel<any>>;
  private readonly _yearFields: Array<BaseFieldModel<any>>;

  constructor(params: CustomerIngotModelParams) {
    super(params);

    this._uinFields = [this.ingotNumber, this.weight, this.ligatureWeight, this.metalPurity, this.uin];
    this._yearFields = [this.ingotNumber, this.weight, this.ligatureWeight, this.metalPurity, this.year];
  }

  get fields(): Array<BaseFieldModel<any>> {
    return [...this._uinFields, this.year];
  }

  getYearOrUinFields = (value: IngotIdType): Array<BaseFieldModel<any>> => {
    if (value === IngotIdType.year) {
      return this._yearFields;
    }

    return this._uinFields;
  };

  static fromDefaultParams(): CustomerIngotModel {
    return new CustomerIngotModel({
      ingotNumber: '',
      weight: '',
      ligatureWeight: '',
      metalPurity: '',
      year: null,
      uin: '',
    });
  }

  static formJson(data: IngotServer): CustomerIngotModel {
    return new CustomerIngotModel({
      ingotNumber: data.bar_number,
      weight: String(data.weight),
      ligatureWeight: String(data.ligature_weight ?? ''),
      metalPurity: String(data.metal_purity),
      year: new Date(Number(data.year), 0, 1),
      uin: String(data.uin ?? ''),
    });
  }
}
