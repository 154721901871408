import { DeleteOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { Observer } from 'mobx-react-lite';
import * as React from 'react';

import { TypographyTextWithTooltip } from '@/shared/ui';
import { ColumnsData } from '@/shared/ui/Table';
import { CustomButton } from '@/shared/ui/form/CustomButton';
import { CustomInput } from '@/shared/ui/form/CustomInput';

import { SupplierIngotListModel } from './SupplierIngotListModel';
import { SupplierIngotsTableData } from './types';

const T_OPTIONS = { ns: 'stage' } as const;

const formInputRender = ({
  ingotList,
  key,
  field,
  disabled,
}: {
  ingotList: SupplierIngotListModel;
  key: string;
  field: Exclude<keyof SupplierIngotsTableData, 'key' | 'year' | 'id'>;
  formDisabled?: boolean;
  disabled?: boolean;
}) => {
  const ingotModel = ingotList.list.getEntity(key);

  return (
    <Observer>
      {() =>
        ingotModel && (
          <CustomInput {...ingotModel[field].props} disabled={disabled || ingotModel[field].props.disabled} />
        )
      }
    </Observer>
  );
};

const formDateRender = ({ ingotList, key }: { ingotList: SupplierIngotListModel; key: string }) => {
  const ingotModel = ingotList.list.getEntity(key);

  return <Observer>{() => ingotModel && <DatePicker {...ingotModel.year.props} picker="year" />}</Observer>;
};

export const getColumns = (ingotList: SupplierIngotListModel): ColumnsData<SupplierIngotsTableData> => [
  {
    title: <TypographyTextWithTooltip text={(t) => t('ingot.ingotNumber', T_OPTIONS)} />,
    dataIndex: 'ingotNumber',
    key: 'ingotNumber',
    width: 140,
    render: (key: string) => formInputRender({ ingotList, key, field: 'ingotNumber' }),
  },
  {
    title: <TypographyTextWithTooltip text={(t) => t('supplierSpecification.series', T_OPTIONS)} />,
    dataIndex: 'series',
    key: 'series',
    width: 140,
    render: (key: string) => formInputRender({ ingotList, key, field: 'series' }),
  },
  {
    title: <TypographyTextWithTooltip text={(t) => t('supplierSpecification.ligatureWeight', T_OPTIONS)} />,
    dataIndex: 'ligatureWeight',
    key: 'ligatureWeight',
    width: 140,
    render: (key: string) =>
      formInputRender({ ingotList, key, field: 'ligatureWeight', disabled: !ingotList.isNeedLigatureWeight }),
  },
  {
    title: <TypographyTextWithTooltip text={(t) => t('supplierSpecification.weight', T_OPTIONS)} />,
    dataIndex: 'weight',
    key: 'weight',
    width: 140,
    render: (key: string) =>
      formInputRender({ ingotList, key, field: 'weight', disabled: ingotList.isNeedLigatureWeight }),
  },
  {
    title: <TypographyTextWithTooltip text={(t) => t('supplierSpecification.try', T_OPTIONS)} />,
    dataIndex: 'metalPurity',
    key: 'metalPurity',
    width: 140,
    render: (key: string) => formInputRender({ ingotList, key, field: 'metalPurity' }),
  },
  {
    title: <TypographyTextWithTooltip text={(t) => t('ingot.yearOfProduction', T_OPTIONS)} />,
    dataIndex: 'year',
    key: 'year',
    width: 140,
    render: (key: string) => formDateRender({ ingotList, key }),
  },
  {
    title: <TypographyTextWithTooltip text={(t) => t('ingot.uin', T_OPTIONS)} />,
    dataIndex: 'uin',
    key: 'uin',
    width: 140,
    render: (key: string) => formInputRender({ ingotList, key, field: 'uin' }),
  },
  {
    dataIndex: 'key',
    key: 'key',
    align: 'center',
    width: 64,
    fixed: 'right',
    render: (key: string) => (
      <CustomButton
        disabled={ingotList.list.length === 1}
        icon={<DeleteOutlined />}
        onClick={() => ingotList.deleteIngot(key)}
      />
    ),
  },
];
