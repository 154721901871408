import { TranslationString } from '@/shared/types/localization';
import { Options } from '@/shared/types/values';

import { EAEUCountrySlug, ResidentType, VerificationDocumentType, VerificationStatus } from './types';

const T_OPTIONS = { ns: 'verification' } as const;

export const residentTypeOptions: Options<ResidentType> = Object.values(ResidentType).map((value) => ({
  label: (t) => t(`residentType.${value}`, T_OPTIONS),
  value,
}));

export const mapVerificationDocTypeToLabel = (docType: VerificationDocumentType): TranslationString => {
  return (t) => t(`docType.${docType}`, T_OPTIONS);
};

export const mapVerificationStatusToLabel =
  (type: VerificationStatus): TranslationString =>
  (t) =>
    t(`verificationStatus.${type}`, { ns: 'verification' });

export const EAEUCountrySlugs = Object.values(EAEUCountrySlug);
