import { Flex, Segmented } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useWidth } from '@/shared/hooks';

import { chartTypeOptions } from '../../config';
import { ITicker, ITickerStore } from '../../types';
import Ticker from '../Ticker';
import TickerAreaChart from '../TickerAreaChart';
import PriceChart from '../TickerPriceChart';

import s from './ExpandableTicker.module.scss';

type Props = {
  ticker: ITicker;
  tickerStore: ITickerStore;
};

const ExpandableTicker: React.FC<Props> = ({ ticker, tickerStore }) => {
  const { isMobile, isTablet } = useWidth();
  const { tickerType, chartType, changeChartType, openHistory } = tickerStore.currentTickerHistory;

  const isActive = React.useMemo(() => ticker.tickerType === tickerType, [ticker.tickerType, tickerType]);

  const handleOpen = React.useCallback(() => openHistory(ticker.tickerType), [ticker.tickerType, openHistory]);

  const tickeRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (isActive && tickeRef.current) {
      tickeRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [isActive, tickeRef.current]);

  return (
    <Flex ref={tickeRef} className={cn(isMobile && s.card_active)} key={ticker.tickerType} vertical gap={16}>
      <Flex
        className={cn(s.card, !isMobile && isActive && s.card_active)}
        align="center"
        justify="space-between"
        gap={8}
        onClick={handleOpen}
      >
        <Ticker className={s.drawer_ticker} model={ticker} showTrendArrow={false} />
        <TickerAreaChart
          tickerHistory={tickerStore.historyList.getEntity(ticker.tickerType)}
          height={isTablet ? 30 : 50}
          width={isTablet ? 60 : 150}
        />
      </Flex>
      {isMobile && isActive && (
        <>
          <Segmented
            className={s.segmented}
            defaultValue={chartType}
            options={chartTypeOptions}
            onChange={changeChartType}
            block
          />
          <PriceChart currentTicker={ticker} historyModel={tickerStore.currentTickerHistory} height={369} />
        </>
      )}
    </Flex>
  );
};

export default observer(ExpandableTicker);
