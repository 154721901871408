import { Button, ButtonProps } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { TranslationNode } from '@/shared/types/localization';
import { isFunction } from '@/shared/types/typesGuard';

import s from './CustomButton.module.scss';

export type CustomButtonProps = Omit<ButtonProps, 'children'> & {
  children?: TranslationNode;
};

const CustomButton = React.forwardRef<HTMLButtonElement, CustomButtonProps>(
  ({ className, children, ...props }, ref) => {
    const { t } = useTypedTranslation();

    const childrenNode = React.useMemo(
      () => (isFunction(children) ? <span>{children(t)}</span> : children),
      [children, t],
    );

    return (
      <Button ref={ref} className={cn(className, s.button)} {...props}>
        {childrenNode}
      </Button>
    );
  },
);

CustomButton.displayName = 'CustomButton';

export default CustomButton;
