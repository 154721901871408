import { computed, makeObservable } from 'mobx';

import { CompanyType } from '@/entities/company';
import {
  DocumentFileType,
  DocumentGenerationServer,
  DocumentGenerationWithStatusServer,
  GenerationFieldsModel,
  UploadOrGenerateDocumentModel,
} from '@/entities/file';
import { mapUserServerToCompanyType } from '@/entities/user';
import { CheckStatus } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { BaseStepModel, BaseStepModelParams } from '../BaseStepModel';

type ExpectedDocumentModel<DocType extends DocumentFileType> = UploadOrGenerateDocumentModel<
  DocType,
  unknown,
  GenerationFieldsModel<DocType, unknown>
>;

type Params<
  Step,
  Action,
  DocType extends DocumentFileType,
  DocumentModel extends ExpectedDocumentModel<DocType>,
> = BaseStepModelParams<Step, Action> & {
  uploadOrGenerateDocument: DocumentModel;
  status: Nullable<CheckStatus>;
  error: Nullable<string>;
  uploadedBy: Nullable<CompanyType>;
  generatedBy: Nullable<CompanyType>;
};

export class UploadOrGenerateDocumentStepModel<
  Step,
  Action,
  DocType extends DocumentFileType,
  DocumentModel extends ExpectedDocumentModel<DocType>,
> extends BaseStepModel<Step, Action> {
  readonly uploadOrGenerateDocument: DocumentModel;

  readonly status: Nullable<CheckStatus>;
  readonly error: Nullable<string>;
  readonly uploadedBy: Nullable<CompanyType>;
  readonly generatedBy: Nullable<CompanyType>;

  constructor({
    uploadOrGenerateDocument,
    status,
    error,
    uploadedBy,
    generatedBy,
    ...params
  }: Params<Step, Action, DocType, DocumentModel>) {
    super(params);

    this.uploadOrGenerateDocument = uploadOrGenerateDocument;
    this.status = status;
    this.error = error;
    this.uploadedBy = uploadedBy;
    this.generatedBy = generatedBy;

    makeObservable(this, {
      isOwnerDocument: computed,
      isSupplierDocument: computed,
      isNeedVerifying: computed,
      isWaitVerifying: computed,
      isWaiting: computed,
      isApproved: computed,
      isRejected: computed,
      approveDisabled: computed,
    });
  }

  get isOwnerDocument(): boolean {
    return this.uploadedBy === CompanyType.owner || this.generatedBy === CompanyType.owner;
  }

  get isSupplierDocument(): boolean {
    return this.uploadedBy === CompanyType.supplier || this.generatedBy === CompanyType.supplier;
  }

  get isNeedVerifying(): boolean {
    const { isOwner } = this.tradeWorkflowStore.rootStore.userStore;

    return this.isSupplierDocument && isOwner && (this.isWaiting || this.isApproved);
  }

  get isWaitVerifying(): boolean {
    const { isSupplier } = this.tradeWorkflowStore.rootStore.userStore;

    return this.isSupplierDocument && isSupplier && (this.isWaiting || this.isApproved);
  }

  get isWaiting(): boolean {
    return this.status === CheckStatus.waiting;
  }

  get isApproved(): boolean {
    return this.status === CheckStatus.approved;
  }

  get isRejected(): boolean {
    return this.status === CheckStatus.rejected;
  }

  get approveDisabled(): boolean {
    return !this.uploadOrGenerateDocument.isUploaded || this.uploadOrGenerateDocument.isError;
  }

  static fromJson<
    Step,
    Action,
    DocType extends DocumentFileType,
    DocumentModel extends ExpectedDocumentModel<DocType>,
  >({
    data,
    uploadOrGenerateDocument,
    ...params
  }: {
    data: Nullable<DocumentGenerationWithStatusServer | DocumentGenerationServer>;
    uploadOrGenerateDocument: DocumentModel;
  } & BaseStepModelParams<Step, Action>): UploadOrGenerateDocumentStepModel<Step, Action, DocType, DocumentModel> {
    const document = data && 'document' in data ? data.document : null;
    const generation = document && 'generation' in document ? document.generation : null;

    return new UploadOrGenerateDocumentStepModel({
      uploadOrGenerateDocument,
      status: data && 'status' in data ? data.status : null,
      error: data && 'error_message' in data ? data.error_message : null,
      uploadedBy: document && mapUserServerToCompanyType(document.uploaded_by),
      generatedBy: generation && mapUserServerToCompanyType(generation.generated_by),
      ...params,
    });
  }
}
