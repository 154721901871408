import { Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { useTypedTranslation } from '@/shared/hooks';
import { Nullable } from '@/shared/types/values';
import { CustomTypographyText, CustomTypographyTitle } from '@/shared/ui';
import { CustomButton } from '@/shared/ui/form/CustomButton';

import { IPresentedElement } from '../../types';

import s from './Onboarding.module.scss';

type Props = {
  currentElement: Nullable<IPresentedElement>;
};

const TooltipTitle: React.FC<Props> = ({ currentElement }) => {
  const { onboardingStore } = useRootStore();
  const { hasPrevElement, hasNextElement, getPrevElement, getNextElement, stopOnboarding } = onboardingStore;

  const { t } = useTypedTranslation('onboarding');

  return (
    <Flex vertical gap={24}>
      <Flex vertical gap={8}>
        <CustomTypographyTitle level={5}>{currentElement?.title}</CustomTypographyTitle>
        <CustomTypographyText type="secondary">{currentElement?.description}</CustomTypographyText>
      </Flex>
      <Flex className={s.buttons}>
        {hasNextElement && (
          <CustomButton className={cn(s.buttons_skip)} type="text" onClick={stopOnboarding}>
            {t('buttons.skip')}
          </CustomButton>
        )}
        <Flex className={s.buttons_navigation}>
          {hasPrevElement && (
            <CustomButton className={s.buttons_prev} type="text" onClick={getPrevElement}>
              {t('buttons.prevStep')}
            </CustomButton>
          )}
          <CustomButton className={s.buttons_next} type="primary" onClick={getNextElement}>
            {hasNextElement ? t('buttons.nextStep') : t('buttons.flowEnd')}
          </CustomButton>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default observer(TooltipTitle);
