import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useTypedTranslation } from '@/shared/hooks';
import { CustomModal, StepDescription } from '@/shared/ui';
import { FormInputTextArea } from '@/shared/ui/form/FormInputTextArea';

import { DocumentVerifyingStepModel } from '../../model';
import { StageModel, StepComponentProps } from '../../types';

import s from './DocumentVerifyingStep.module.scss';

const DocumentVerifyingStep = <Model extends StageModel>({
  model,
  stepKey,
  description,
}: StepComponentProps<Model>) => {
  const { t } = useTypedTranslation('file');
  const step = stepKey && model[stepKey];

  if (!(step instanceof DocumentVerifyingStepModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      <CustomModal
        title={t('documentVerifyingForm.modal.title')}
        width={640}
        open={step.isOpenModal}
        onCancel={step.closeModal}
        cancelButtonProps={{ disabled: step.isLoading }}
        onOk={step.rejectStep}
        okText={t('documentVerifyingForm.modal.okText')}
        okButtonProps={{
          loading: step.isLoading,
          disabled: step.error.isError || !step.error.value,
        }}
        danger
      >
        <FormInputTextArea model={step.error} disabled={step.isLoading} />
      </CustomModal>
      <Flex gap={8} wrap="wrap">
        <Button
          className={cn(!step.isWaiting && s.step_chosen)}
          icon={<CloseOutlined />}
          danger={step.isRejected}
          onClick={step.openModal}
          size="small"
        >
          {t('documentVerifyingForm.modal.reject')}
        </Button>
        <Button
          className={cn(step.isApproved && s.step_approved_button, !step.isWaiting && s.step_chosen)}
          icon={<CheckOutlined />}
          onClick={step.approveStep}
          size="small"
        >
          {t('documentVerifyingForm.modal.approve')}
        </Button>
      </Flex>
    </StepDescription>
  );
};

export default observer(DocumentVerifyingStep);
