import cn from 'classnames';
import * as React from 'react';

import { TranslationString } from '@/shared/types/localization';

import { CustomTypographyTitle } from '../CustomTypographyTitle';

import s from './ListPageHeader.module.scss';

export type ListPageHeaderProps = React.PropsWithChildren<{
  title: TranslationString;
  className?: string;
  titleRef?: React.Ref<HTMLElement>;
}>;

const ListPageHeader = ({
  className,
  title,
  children,
  titleRef,
}: ListPageHeaderProps): React.ReactElement<ListPageHeaderProps> => {
  return (
    <div className={cn(s['list-page-header'], className)}>
      <CustomTypographyTitle ref={titleRef} className={s['list-page-header__title']} ellipsis>
        {title}
      </CustomTypographyTitle>
      <div className={s['list-page-header__actions']}>{children}</div>
    </div>
  );
};

export default React.memo(ListPageHeader);
