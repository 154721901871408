import { DocumentFileType, UploadOrGenerateDocumentModel, UploadOrGenerateDocumentModelParams } from '@/entities/file';
import { DeliveryConfirmationDocPayload } from '@/entities/stage/types';

import { DeliveryConfirmationFieldsModel } from './DeliveryConfirmationFieldsModel';

export class DeliveryConfirmationModel extends UploadOrGenerateDocumentModel<
  DocumentFileType.deliveryConfirmation,
  DeliveryConfirmationDocPayload,
  DeliveryConfirmationFieldsModel
> {
  readonly generationFields: DeliveryConfirmationFieldsModel;

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.deliveryConfirmation>) {
    super(params);

    this.generationFields = new DeliveryConfirmationFieldsModel(params);
  }
}
