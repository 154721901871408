import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { UploadOrGenerateDocument } from '@/entities/file';

import { PaymentBillModel } from '../../model';

import PaymentBillForm from './PaymentBillForm';

type Props = {
  model: PaymentBillModel;
  disabled?: boolean;
};

const PaymentBill: React.FC<Props> = ({ model, disabled }) => {
  const { userStore } = useRootStore();

  return (
    <UploadOrGenerateDocument
      model={model}
      disabled={disabled}
      generatable={userStore.isSupplier}
      FieldsComponent={PaymentBillForm}
    />
  );
};

export default observer(PaymentBill);
