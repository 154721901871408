import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';

import { IVerificationStep } from '../../types/client';
import { VerificationStep } from '../../types/server';
import { BaseVerificationFieldsModel, VerificationFieldsModel } from '../BaseVerificationFieldsModel';

type Params<Step extends VerificationStep> = {
  step: Step;
};

export abstract class BaseVerificationStepModel<
    Step extends VerificationStep,
    Payload,
    FieldsModel extends VerificationFieldsModel = BaseFieldModel<any>,
  >
  extends BaseVerificationFieldsModel<Payload, FieldsModel>
  implements IVerificationStep<Step, Payload>
{
  readonly step: Step;

  constructor(params: Params<Step>) {
    super();

    this.step = params.step;
  }
}
