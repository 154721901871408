import { Flex } from 'antd';
import * as React from 'react';

import { ITicker, TickerType } from '../../types';
import Ticker from '../Ticker';

import s from './TickerList.module.scss';

type TickerListProps = {
  className?: string;
  tickerList: Array<ITicker>;
  onClick: (tickerType: TickerType) => void;
};

const TickerList = React.forwardRef<HTMLDivElement, TickerListProps>(({ className, tickerList, onClick }, ref) => {
  return (
    <Flex className={className} ref={ref}>
      {tickerList.map((ticker) => (
        <Ticker className={s.ticker} key={ticker.tickerType} model={ticker} onClick={onClick} />
      ))}
    </Flex>
  );
});

TickerList.displayName = 'TickerList';

export default TickerList;
