import { Flex } from 'antd';
import cn from 'classnames';
import * as React from 'react';

import { useWidth } from '@/shared/hooks';
import { TranslationString } from '@/shared/types/localization';
import { CustomTypographyTitle } from '@/shared/ui';

import s from './VerificationFieldsWrapper.module.scss';

type Props = React.PropsWithChildren<{
  className?: string;
  label?: TranslationString;
}>;

const VerificationFieldsWrapper: React.FC<Props> = ({ className, label, children }) => {
  const { isMobile } = useWidth();

  return (
    <Flex className={s.fields_wrapper} vertical gap={isMobile ? 16 : 24}>
      {label && <CustomTypographyTitle level={5}>{label}</CustomTypographyTitle>}
      <div className={cn(s.fields, className)}>{children}</div>
    </Flex>
  );
};

export default VerificationFieldsWrapper;
