import { CompanyType } from '@/entities/company';
import { DocumentGenerationServer, DocumentsServer } from '@/entities/file';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer } from './baseStage';

export enum ManagerPaymentStep {
  uploadBill = 'upload_bill',
  payBill = 'pay_bill',
  transfer = 'transfer',
}

export enum ManagerBillVerifyingAction {
  approveBillVerifying = 'approve_bill_verifying',
  rejectBillVerifying = 'reject_bill_verifying',
}

export type ManagerPaymentAction = ManagerPaymentStep;

export enum SupplierPaymentStep {
  uploadBill = 'upload_bill',
  payBill = 'pay_bill',
  transfer = 'transfer',
}

export type SupplierPaymentAction = SupplierPaymentStep;

export type MapUserTypeToPaymentStageData = {
  [CompanyType.owner]: {
    [ManagerPaymentStep.uploadBill]: Nullable<DocumentGenerationServer>;
    [ManagerPaymentStep.payBill]: Nullable<DocumentsServer>;
  };
  [CompanyType.supplier]: {
    [SupplierPaymentStep.uploadBill]: Nullable<DocumentGenerationServer>;
    [SupplierPaymentStep.payBill]: Nullable<DocumentsServer>;
  };
  [CompanyType.customer]: null;
};

type BasePaymentStageDataServer<CT extends CompanyType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToPaymentStageData[CT]
>;

export type ManagerPaymentStageDataServer = BasePaymentStageDataServer<CompanyType.owner, ManagerPaymentStep>;
export type SupplierPaymentStageDataServer = BasePaymentStageDataServer<CompanyType.supplier, SupplierPaymentStep>;

export type PaymentStageDataServer = ManagerPaymentStageDataServer | SupplierPaymentStageDataServer;

export type PaymentAction = ManagerPaymentAction;
