import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StepDescription } from '@/shared/ui';
import { FormUpload } from '@/shared/ui/form/FormUpload';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { UploadFilesStepModel } from '../../model';
import { StageModel, StepComponentProps } from '../../types';

const VerifiableDocumentStep = <Model extends StageModel>({
  model,
  stepKey,
  description,
  approveButtonText,
}: StepComponentProps<Model>) => {
  const step = stepKey && model[stepKey];

  if (!(step instanceof UploadFilesStepModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      <FormUpload model={step.files} disabled={step.isDisabled || step.isLoading} />
      {!step.isNeedVerifying && (
        <ApproveButton
          loading={step.isLoading}
          disabled={step.isDisabled || step.approveDisabled || !step.files.isUploaded}
          onClick={step.approveStep}
          text={approveButtonText}
        />
      )}
    </StepDescription>
  );
};

export default observer(VerifiableDocumentStep);
