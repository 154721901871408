import {
  CustomerFinalizationStageDataServer,
  CustomerFinalizationStep,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { WaitingFilesStepModel, WaitingStepModel } from '../../fieldsModel';

import { mapCustomerStepToNumber, TradeCloseCustomerDocs, tradeCloseCustomerDocsOptions } from './config';

type Params = BaseStageModelParams<CustomerFinalizationStep> & {
  cargoDeliveryFields: WaitingStepModel<CustomerFinalizationStep>;
  tradeCloseCustomerDocsFields: WaitingFilesStepModel<CustomerFinalizationStep, TradeCloseCustomerDocs>;
};

export class CustomerFinalizationStageModel extends BaseStageModel<StageType.finalization, CustomerFinalizationStep> {
  cargoDeliveryFields: WaitingStepModel<CustomerFinalizationStep>;
  tradeCloseCustomerDocsFields: WaitingFilesStepModel<CustomerFinalizationStep, TradeCloseCustomerDocs>;

  constructor({ cargoDeliveryFields, tradeCloseCustomerDocsFields, ...params }: Params) {
    super({
      type: StageType.finalization,
      ...params,
    });

    this.cargoDeliveryFields = cargoDeliveryFields;
    this.tradeCloseCustomerDocsFields = tradeCloseCustomerDocsFields;
  }

  get isCargoDelivered(): boolean {
    return this.step !== CustomerFinalizationStep.cargoDelivery;
  }

  get isTradeCloseDocsUploaded(): boolean {
    return Boolean(this.tradeCloseCustomerDocsFields.files?.every(({ file }) => file.isUploaded));
  }

  get isFinished(): boolean {
    return this.step === CustomerFinalizationStep.finish;
  }

  get currentStep(): number {
    return mapCustomerStepToNumber(this.step);
  }

  get steps(): CustomerFinalizationStep[] {
    return [
      CustomerFinalizationStep.cargoDelivery,
      CustomerFinalizationStep.tradeCloseCustomerDocs,
      CustomerFinalizationStep.finish,
    ];
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<CustomerFinalizationStageDataServer>): CustomerFinalizationStageModel {
    return new CustomerFinalizationStageModel({
      cargoDeliveryFields: WaitingStepModel.fromJson({
        step: CustomerFinalizationStep.cargoDelivery,
        tradeWorkflowStore,
      }),
      tradeCloseCustomerDocsFields: WaitingFilesStepModel.fromJson({
        data: dataServer.steps[CustomerFinalizationStep.tradeCloseCustomerDocs],
        options: tradeCloseCustomerDocsOptions,
        step: CustomerFinalizationStep.tradeCloseCustomerDocs,
        tradeWorkflowStore,
      }),
      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
