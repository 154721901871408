import { UserRole } from '@/entities/user';
import { RouteKey, routerUrls } from '@/shared/config/routes';
import { getPathname } from '@/shared/utils';

const requiredRouteKeys = ['login', 'createPassword'] as const;

export const getRoleRouteKeyMap = (needVerifyCompany: boolean): Record<UserRole, Array<RouteKey>> => ({
  [UserRole.ownerAdmin]: [
    ...requiredRouteKeys,
    'profile',
    'notifications',
    'companies',
    'trades',
    'documents',
    'chats',
    'bids',
  ],
  [UserRole.ownerManager]: [
    ...requiredRouteKeys,
    'profile',
    'notifications',
    'companies',
    'trades',
    'documents',
    'chats',
    'bids',
  ],
  [UserRole.ownerObserver]: [...requiredRouteKeys, 'bids'],
  [UserRole.clientAdmin]: needVerifyCompany
    ? [...requiredRouteKeys, 'profile', 'verification']
    : [...requiredRouteKeys, 'profile', 'notifications', 'trades', 'chats', 'bids'],
  [UserRole.clientEmployee]: [...requiredRouteKeys, 'profile', 'notifications', 'trades', 'chats', 'bids'],
  [UserRole.clientObserver]: [...requiredRouteKeys, 'profile', 'trades', 'bids'],
});

export const getRoleRouteMap = (needVerifyCompany: boolean): Record<UserRole, string[]> => {
  const roleRouteKeyMap = getRoleRouteKeyMap(needVerifyCompany);

  return {
    [UserRole.ownerAdmin]: roleRouteKeyMap[UserRole.ownerAdmin].map((key) => getPathname(routerUrls[key].mask)),
    [UserRole.ownerManager]: roleRouteKeyMap[UserRole.ownerManager].map((key) => getPathname(routerUrls[key].mask)),
    [UserRole.ownerObserver]: roleRouteKeyMap[UserRole.ownerObserver].map((key) => getPathname(routerUrls[key].mask)),
    [UserRole.clientAdmin]: roleRouteKeyMap[UserRole.clientAdmin].map((key) => getPathname(routerUrls[key].mask)),
    [UserRole.clientEmployee]: roleRouteKeyMap[UserRole.clientEmployee].map((key) => getPathname(routerUrls[key].mask)),
    [UserRole.clientObserver]: roleRouteKeyMap[UserRole.clientObserver].map((key) => getPathname(routerUrls[key].mask)),
  };
};

export const getDefaultRoleRoute = (needVerifyCompany: boolean): Record<UserRole, string> => ({
  [UserRole.ownerAdmin]: routerUrls.companies.create(),
  [UserRole.ownerManager]: routerUrls.companies.create(),
  [UserRole.ownerObserver]: routerUrls.bids.create(),
  [UserRole.clientAdmin]: needVerifyCompany ? routerUrls.verification.create() : routerUrls.trades.create(),
  [UserRole.clientEmployee]: routerUrls.trades.create(),
  [UserRole.clientObserver]: routerUrls.trades.create(),
});
