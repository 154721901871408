import { Nullable } from './values';

export enum DateFormat {
  time = 'HH:mm',
  timeSec = 'HH:mm:ss',
  date = 'YYYY-MM-DD',
  dateTime = 'YYYY-MM-DD HH:mm',
  dateTimeSec = 'YYYY-MM-DD HH:mm:ss',
  dateTimeISO = 'YYYY-MM-DDTHH:mm:ss',
}

export enum LoadingStage {
  notStarted = 'notStarted',
  loading = 'loading',
  success = 'success',
  error = 'error',
}

export enum SortDirection {
  asc = 'asc',
  desc = 'desc',
  none = 'none',
}

export enum ListDirection {
  forward = 'forward',
  backward = 'backward',
}

export enum CheckStatus {
  waiting = 'waiting',
  approved = 'approved',
  rejected = 'rejected',
}

export type SortDirectionAntd = 'ascend' | 'descend' | undefined | null;

export type ID = number | string;

export type MapDataFieldsToNullable<T> = {
  [K in keyof T]: Nullable<T[K]>;
};

export type MapDataFieldsToNull<T> = {
  [K in keyof T]: null;
};
