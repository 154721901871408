import { FormItemProps as AntdFormItemProps, Form } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { TranslationString } from '@/shared/types/localization';
import { CheckStatus } from '@/shared/types/meta';
import { isCheckStatus } from '@/shared/types/typesGuard';

import { TypographyTextWithTooltip } from '../../TypographyTextWithTooltip';

import FormItemDescription, { FormItemDescriptionProps } from './FormItemDescription';
import FormItemLabel from './FormItemLabel';

import s from './FormItem.module.scss';

type BaseProps =
  | {
      model: BaseFieldModel<any>;
    }
  | {
      label: TranslationString;
      required?: boolean;
      tooltip?: TranslationString;
      description?: TranslationString;
    };

type CustomFormItemProps = React.PropsWithChildren<
  BaseProps & {
    className?: string;
    checkStatus?: CheckStatus;
  }
>;

const CustomFormItem = React.forwardRef<HTMLDivElement, CustomFormItemProps>(
  ({ className, children, ...props }, ref) => {
    const modelCheckStatus =
      'model' in props && 'checkStatus' in props.model && isCheckStatus(props.model.checkStatus)
        ? props.model.checkStatus
        : null;

    const checkStatus = modelCheckStatus ?? props.checkStatus;

    const formItemProps: AntdFormItemProps = React.useMemo(() => {
      if ('label' in props) {
        return {
          className: cn(s['form-item'], !props.label && s['form-item_nolabel'], className),
          label: <FormItemLabel {...props} checkStatus={checkStatus} />,
        };
      } else {
        return {
          className: cn(s['form-item'], !props.model.label && s['form-item_nolabel'], className),
          label: (
            <FormItemLabel
              label={props.model.label}
              required={props.model.required}
              tooltip={props.model.tooltip}
              checkStatus={checkStatus}
            />
          ),
          validateStatus: props.model.isError ? 'error' : 'success',
          help: props.model.error && <TypographyTextWithTooltip text={props.model.error} />,
        };
      }
    }, [props, className]);

    const descriptionProps: FormItemDescriptionProps = React.useMemo(() => {
      if ('label' in props) {
        return {
          description: props.description,
          required: props.required,
        };
      } else {
        return {
          description: props.model.description,
          required: props.model.required,
        };
      }
    }, [props]);

    return (
      <Form.Item {...formItemProps} colon={false}>
        <FormItemDescription {...descriptionProps} />
        <div ref={ref}>{children}</div>
      </Form.Item>
    );
  },
);

CustomFormItem.displayName = 'CustomFormItem';

export default observer(CustomFormItem);
