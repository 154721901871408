import { DocumentFileType, UploadOrGenerateDocumentModel } from '@/entities/file';
import {
  ManagerConfirmationAction,
  ManagerConfirmationStageDataServer,
  ManagerConfirmationStep,
  ManagerConfirmationVerifyingAction,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/entities/stage/utils';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import {
  DeliveryConfirmationModel,
  DocumentVerifyingStepModel,
  UploadFilesStepModel,
  UploadOrGenerateDocumentStepModel,
} from '../../fieldsModel';

import { stepsOrder } from './config';

type Params = BaseManagerStageModelParams<ManagerConfirmationStep> & {
  deliveryConfirmStep: UploadOrGenerateDocumentStepModel<
    ManagerConfirmationStep.deliveryConfirmation,
    ManagerConfirmationStep.deliveryConfirmation,
    DocumentFileType.deliveryConfirmation,
    DeliveryConfirmationModel
  >;
  confirmVerifyingStep: DocumentVerifyingStepModel<ManagerConfirmationStep, ManagerConfirmationAction>;
  confirmSigningStep: UploadFilesStepModel<
    ManagerConfirmationStep,
    ManagerConfirmationAction,
    DocumentFileType.confirmationSigning
  >;
};

export class ManagerConfirmationStageModel extends BaseManagerStageModel<
  StageType.confirmation,
  ManagerConfirmationStep
> {
  readonly deliveryConfirmStep: UploadOrGenerateDocumentStepModel<
    ManagerConfirmationStep.deliveryConfirmation,
    ManagerConfirmationStep.deliveryConfirmation,
    DocumentFileType.deliveryConfirmation,
    DeliveryConfirmationModel
  >;
  readonly confirmVerifyingStep: DocumentVerifyingStepModel<ManagerConfirmationStep, ManagerConfirmationAction>;
  readonly confirmSigningStep: UploadFilesStepModel<
    ManagerConfirmationStep,
    ManagerConfirmationAction,
    DocumentFileType.confirmationSigning
  >;

  constructor({ deliveryConfirmStep, confirmVerifyingStep, confirmSigningStep, ...params }: Params) {
    super({
      type: StageType.confirmation,
      ...params,
    });

    this.deliveryConfirmStep = deliveryConfirmStep;
    this.confirmVerifyingStep = confirmVerifyingStep;
    this.confirmSigningStep = confirmSigningStep;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): Array<ManagerConfirmationStep> {
    let steps: Array<ManagerConfirmationStep> = [];

    if (this.deliveryConfirmStep.isNeedVerifying) {
      steps = [
        ManagerConfirmationStep.deliveryConfirmation,
        ManagerConfirmationStep.confirmationVerifying,
        ManagerConfirmationStep.confirmationSigning,
      ];
    } else {
      steps = [ManagerConfirmationStep.deliveryConfirmation, ManagerConfirmationStep.confirmationSigning];
    }

    if (this.step === ManagerConfirmationStep.transfer) {
      steps.push(ManagerConfirmationStep.transfer);
    }

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerConfirmationStageDataServer>): ManagerConfirmationStageModel {
    return new ManagerConfirmationStageModel({
      deliveryConfirmStep: UploadOrGenerateDocumentStepModel.fromJson({
        uploadOrGenerateDocument: new DeliveryConfirmationModel(
          UploadOrGenerateDocumentModel.paramsFromJson({
            data: dataServer.steps[ManagerConfirmationStep.deliveryConfirmation]?.document,
            docType: DocumentFileType.deliveryConfirmation,
            tradeWorkflowStore,
            required: true,
            fileName: (t) => t('deliveryConfirmation.fileName', { ns: 'stage' }),
          }),
        ),
        data: dataServer.steps[ManagerConfirmationStep.deliveryConfirmation],
        step: ManagerConfirmationStep.deliveryConfirmation,
        approveAction: ManagerConfirmationStep.deliveryConfirmation,
        tradeWorkflowStore,
      }),

      confirmVerifyingStep: DocumentVerifyingStepModel.fromJson({
        data: dataServer.steps[ManagerConfirmationStep.deliveryConfirmation],
        step: ManagerConfirmationStep.confirmationVerifying,
        approveAction: ManagerConfirmationVerifyingAction.approveConfirmationVerifying,
        rejectAction: ManagerConfirmationVerifyingAction.rejectConfirmationVerifying,
        tradeWorkflowStore,
      }),
      confirmSigningStep: UploadFilesStepModel.fromJson({
        data: dataServer.steps[ManagerConfirmationStep.confirmationSigning],
        step: ManagerConfirmationStep.confirmationSigning,
        approveAction: ManagerConfirmationStep.confirmationSigning,
        docType: DocumentFileType.confirmationSigning,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
