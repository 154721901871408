import { CompanyType } from '@/entities/company';
import {
  BaseFileServer,
  DocumentGenerationWithStatusServer,
  DocumentServer,
  GenerationDocumentLanguage,
} from '@/entities/file';
import { ID, MapDataFieldsToNullable } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { BaseStageDataServer } from './baseStage';
import { Incoterms } from './invoice';

export enum ManagerConfirmationStep {
  deliveryConfirmation = 'delivery_confirmation',
  confirmationVerifying = 'confirmation_verifying',
  confirmationSigning = 'confirmation_signing',
  transfer = 'transfer',
}

export enum ManagerConfirmationVerifyingAction {
  approveConfirmationVerifying = 'approve_confirmation_verifying',
  rejectConfirmationVerifying = 'reject_confirmation_verifying',
}

export type ManagerConfirmationAction =
  | Exclude<ManagerConfirmationStep, ManagerConfirmationStep.confirmationVerifying>
  | ManagerConfirmationVerifyingAction;

export enum SupplierConfirmationStep {
  deliveryConfirmation = 'delivery_confirmation',
  confirmationVerifying = 'confirmation_verifying',
  confirmationSigning = 'confirmation_signing',
  transfer = 'transfer',
}

export type SupplierConfirmationAction = Extract<
  SupplierConfirmationStep,
  SupplierConfirmationStep.deliveryConfirmation
>;

type MapUserTypeToConfirmationStageData = {
  [CompanyType.owner]: {
    [ManagerConfirmationStep.deliveryConfirmation]: Nullable<DocumentGenerationWithStatusServer>;
    [ManagerConfirmationStep.confirmationVerifying]: null;
    [ManagerConfirmationStep.confirmationSigning]: Nullable<DocumentServer>;
  };
  [CompanyType.supplier]: {
    [SupplierConfirmationStep.deliveryConfirmation]: Nullable<DocumentGenerationWithStatusServer>;
    [SupplierConfirmationStep.confirmationVerifying]: null;
    [SupplierConfirmationStep.confirmationSigning]: null;
  };
  [CompanyType.customer]: null;
};

type BaseConfirmationStageDataServer<CT extends CompanyType, Step> = BaseStageDataServer<
  Step,
  MapUserTypeToConfirmationStageData[CT]
>;

export type ManagerConfirmationStageDataServer = BaseConfirmationStageDataServer<
  CompanyType.owner,
  ManagerConfirmationStep
>;
export type SupplierConfirmationStageDataServer = BaseConfirmationStageDataServer<
  CompanyType.supplier,
  SupplierConfirmationStep
>;

export type ConfirmationStageDataServer = ManagerConfirmationStageDataServer | SupplierConfirmationStageDataServer;

export type ConfirmationAction = ManagerConfirmationAction;

export type DeliveryConfirmationDocPayload = {
  contract_date: string;
  contract_number: string;
  currency_id: number;
  customer_initials: string;
  delivery_country_id: number;
  delivery_date: string;
  exchange_rate?: string;
  export_country_id: number;
  incoterms: Incoterms;
  language: GenerationDocumentLanguage;
  metal_purity: string;
  number_of_ingots: string;
  payment_date: string;
  price_per_gram: string;
  price_per_ounce: string;
  supplier_initials: string;
  trade_date: string;
  trade_number: string;
  signature_with_seal: File | ID;
};

export type DeliveryConfirmationDocResponse = Partial<
  MapDataFieldsToNullable<
    Omit<DeliveryConfirmationDocPayload, 'signature_with_seal'> & {
      signature_with_seal: BaseFileServer;
    }
  >
>;
