import { when } from 'mobx';

import { routerUrls } from '@/shared/config/routes';

import { IPresentedElement } from '../../types';
import { PresentedElementModel } from '../PresentedElementModel';

import { BasePageModel, BasePageModelParams } from './BasePageModel';

const T_OPTIONS = { ns: 'onboarding' } as const;

export class NotificationsPageModel extends BasePageModel {
  readonly filters = new PresentedElementModel<HTMLDivElement>({
    title: (t) => t('notifications.filters.title', T_OPTIONS),
    description: (t) => t('notifications.filters.description', T_OPTIONS),
  });

  readonly settingsButton = new PresentedElementModel<HTMLButtonElement>({
    title: (t) => t('notifications.settingsButton.title', T_OPTIONS),
    description: (t) => t('notifications.settingsButton.description', T_OPTIONS),
  });

  readonly settingsTitle = new PresentedElementModel({
    title: (t) => t('notifications.settingsTitle.title', T_OPTIONS),
    description: (t) => t('notifications.settingsTitle.description', T_OPTIONS),
    beforeAction: async () => {
      await when(() => Boolean(this.settingsButton.node));
      this.settingsButton.node?.click();
    },
  });

  constructor(params: BasePageModelParams) {
    super({
      ...params,
      routeKey: 'notifications',
      url: routerUrls.notifications.create(),
    });
  }

  get elements(): Array<IPresentedElement> {
    return [this.filters, this.settingsButton, this.settingsTitle] as Array<IPresentedElement>;
  }
}
