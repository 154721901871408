import { BaseResponse } from '@kts-front/types';

import { apiStore, apiUrls } from '@/shared/api';
import { ListModel } from '@/shared/model';

import { IProductsStore, ProductDictionaryListResponse, ProductType } from '../../types';
import { ProductDictionaryModel } from '../ProductDictionaryModel';

import { defaultParams } from './config';

type ProductList = ListModel<ProductDictionaryModel, number>;

export class ProductsStore implements IProductsStore {
  readonly list = new ListModel<ProductList, ProductType>();

  private readonly productListRequest = apiStore.createRequest<ProductDictionaryListResponse>({
    url: apiUrls.dictionaries.productList,
  });

  async load(type: ProductType): Promise<BaseResponse> {
    if (this.productListRequest.isLoading) {
      return { isError: false };
    }

    const response = await this.productListRequest.call({
      params: { ...defaultParams, type_slug: type },
    });

    if (response.isError) {
      return { isError: true };
    }

    const productList = new ListModel<ProductDictionaryModel, number>();

    productList.fillByRawData(
      response.data.results,
      (raw) => ({
        entity: ProductDictionaryModel.fromJson(raw),
        key: raw.id,
      }),
      true,
    );

    this.list.updateEntity({
      entity: productList,
      key: type,
    });

    return { isError: false };
  }
}
