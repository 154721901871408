import { DateFormat } from '@/shared/types/meta';

import { ChartType, TickInfo } from '../../types';

/**
 * Рассчитывает нижний и верхний порог для графика
 */
export const getPriceDomain = ([dataMin, dataMax]: [number, number]): [number, number] => {
  const diff = (dataMax - dataMin) / 4;

  const min = dataMin - diff;
  const max = dataMax + diff;

  return [min < 0 ? 0 : min, max];
};

export const mapChartTypeToDateFormat: Record<ChartType, string> = {
  [ChartType.day]: DateFormat.time,
  [ChartType.week]: 'DD MMMM HH:mm',
  [ChartType.month]: 'DD MMMM',
};

type Props = {
  isDesktop: boolean;
  chartType: ChartType;
  ticksInfo: Array<TickInfo>;
};

export const getXAxisInterval = ({
  chartType,
  isDesktop,
  ticksInfo,
}: Props): { interval: number; ticks: Array<string> } => {
  const maxTickCount = {
    [ChartType.day]: isDesktop ? 13 : 7,
    [ChartType.week]: isDesktop ? 5 : 4,
    [ChartType.month]: isDesktop ? 7 : 4,
  }[chartType];

  const maxInterval = {
    [ChartType.day]: isDesktop ? 2 : 3,
    [ChartType.week]: isDesktop ? 1 : 2,
    [ChartType.month]: isDesktop ? 4 : 9,
  }[chartType];

  const minPointCount = {
    [ChartType.day]: 40,
    [ChartType.week]: 5,
    [ChartType.month]: 1,
  }[chartType];

  const interval =
    ticksInfo.length > maxTickCount
      ? maxInterval
      : ticksInfo.reduce((acc, { pointCount }) => acc + pointCount, 0) < minPointCount
        ? 1
        : 0;

  const ticks =
    interval > 0
      ? ticksInfo.map(({ datetime }) => datetime)
      : ticksInfo.reduce<Array<string>>((acc, { datetime, pointCount }) => {
          /** Добавляем тики с количесвом точек не меньше минимального */
          if (pointCount >= minPointCount) {
            acc.push(datetime);
          }

          return acc;
        }, []);

  return { interval, ticks };
};
