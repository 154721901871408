import { HistoryAction } from '@/entities/tradeHistory';

import {
  AwbDirectAction,
  AwbTransitAction,
  ManagerAwbDestinationAction,
  ManagerAwbVerifyingAction,
  ManagerBillVerifyingAction,
  ManagerConfirmationStep,
  ManagerConfirmationVerifyingAction,
  ManagerCreationStep,
  ManagerFinalizationStep,
  ManagerPaymentStep,
  ManagerShipmentStep,
  ManagerSpecificationVerifyingAction,
  ManagerSupplierAcceptanceCertificateVerifyingAction,
  ManagerTransactionAction,
  ManagerTransportationStep,
  OptionFieldsAction,
} from '../../stage';

export const tradeHistory = {
  en: {
    manager: {
      [ManagerCreationStep.createTrade]: 'Trade № {{tradeId}} was created',

      [ManagerConfirmationStep.deliveryConfirmation]: 'Delivery confirmation was uploaded',
      [ManagerConfirmationStep.confirmationSigning]: 'Delivery confirmation was signed',

      [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]: 'Delivery confirmation was marked as correct',
      [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]: 'Delivery confirmation was marked as erroneous',

      [ManagerPaymentStep.uploadBill]: 'The Invoice was uploaded',
      [ManagerPaymentStep.payBill]: 'The Invoice was paid',

      [ManagerBillVerifyingAction.approveBillVerifying]: 'Invoice was marked as correct',
      [ManagerBillVerifyingAction.rejectBillVerifying]: 'Invoice was marked as erroneous',

      [ManagerShipmentStep.supplierSpecification]: 'Shipment specification was uploaded',
      [ManagerShipmentStep.shipmentType]: 'Shipment type selected',
      [ManagerShipmentStep.cargoShipment]: 'Confirmed shipment of cargo',
      [ManagerShipmentStep.transitCountry]: 'Transit country selected',
      [ManagerShipmentStep.destinationCountryDocs]: 'Direct shipment documents were uploaded',

      [ManagerSpecificationVerifyingAction.approveSpecification]: 'Shipment specification was marked as correct',
      [ManagerSpecificationVerifyingAction.rejectSpecification]: 'Shipment specification was marked as erroneous',
      [ManagerAwbVerifyingAction.approveAwb]: 'AWB was marked as correct',
      [ManagerAwbVerifyingAction.rejectAwb]: 'AWB was marked as erroneous',
      [AwbTransitAction.uploadFile]: 'AWB for transit country was uploaded',
      [AwbTransitAction.flightNumber]: 'Flight number for transit country loaded',
      [AwbDirectAction.uploadFile]: 'AWB for destination country was uploaded',
      [AwbDirectAction.flightNumber]: 'Flight number for destination country loaded',

      [ManagerTransportationStep.transitCountryCargoArrival]: 'Arrival of cargo in transit country confirmed',
      [ManagerTransportationStep.cargoArrival]: 'Arrival of cargo in destination country confirmed',
      [ManagerTransportationStep.supplierAcceptanceCertificate]:
        'Delivery and acceptance certificate signed on one side was uploaded',
      [ManagerTransportationStep.supplierSignedAcceptanceCertificate]:
        'Delivery and acceptance certificate signed on both sides was uploaded',
      [ManagerTransportationStep.destinationCountryDocs]: 'Destination country documents were uploaded',
      [ManagerTransportationStep.transitCountryCargoShipment]: 'Shipment of cargo from transit country confirmed',
      [ManagerTransportationStep.destinationCountryCargoArrival]: 'Arrival of cargo in destination country confirmed',

      [ManagerSupplierAcceptanceCertificateVerifyingAction.approveSupplierAcceptanceCertificate]:
        'Delivery and acceptance certificate signed on one side was marked as correct',
      [ManagerSupplierAcceptanceCertificateVerifyingAction.rejectSupplierAcceptanceCertificate]:
        'Delivery and acceptance certificate signed on one side was marked as erroneous',

      [ManagerAwbDestinationAction.uploadFile]: 'AWB for destination country was uploaded',
      [ManagerAwbDestinationAction.flightNumber]: 'Flight number for destination country loaded',

      [ManagerFinalizationStep.cargoDelivered]: 'Cargo delivery confirmed',
      [ManagerFinalizationStep.tradeCloseCustomerDocs]: 'Trade close documents for customer were uploaded',
      [ManagerTransactionAction.addTransaction]: 'Payment transaction completed',
      [ManagerTransactionAction.editTransaction]: 'Payment transaction edited',
      [ManagerTransactionAction.deleteTransaction]: 'Payment transaction deleted',
      [ManagerTransactionAction.finish]: 'The deal is completed',

      [OptionFieldsAction.deleteSupplierSpec]: 'Supplier specification deleted',
      [OptionFieldsAction.setDestinationLogisticCompany]: 'A logistics company in the destination country selected',
      [OptionFieldsAction.setTransitLogisticCompany]: 'A logistics company in the transit country selected',
      [OptionFieldsAction.setTransitTrackingLink]: 'Added tracking link to transit country',
      [OptionFieldsAction.setDestinationTrackingLink]: 'Added tracking link to destination country',
      [OptionFieldsAction.uploadFinalizationPhoto]: 'Finalization photo was uploaded',
      [OptionFieldsAction.uploadAuthorizationLetter]: 'Authorization letter was uploaded',
      [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: 'Indirect taxes payment certificate was uploaded',
      [OptionFieldsAction.uploadWarrantyLoa]: 'Warranty LOA was uploaded',
      [OptionFieldsAction.setDeliveryTime]: 'Expected delivery time set',

      [HistoryAction.transferToPayment]: 'The deal has been moved to the "Payment" stage',
      [HistoryAction.transferToShipment]: 'The deal has been moved to the "Shipment" stage',
      [HistoryAction.transferToTransportation]: 'The deal has been moved to the "Transportation" stage',
      [HistoryAction.transferToFinalization]: 'The deal has been moved to the "Finalization" stage',
      [HistoryAction.cargoDirectShipmentConfirm]: 'Shipment of cargo to the destination country confirmed',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Shipment of cargo to the transit country confirmed',
    },
    supplier: {
      [AwbTransitAction.uploadFile]: 'AWB for destination country uploaded',
      [AwbTransitAction.flightNumber]: 'The flight number for the destination country is indicated',
      [ManagerTransportationStep.transitCountryCargoArrival]: 'Arrival of cargo in destination country confirmed',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Shipment of cargo to the destination country confirmed',
      [HistoryAction.transferToTransportation]: 'The deal has been moved to the "Completing the deal" stage',
      [HistoryAction.transferToFinalization]: 'The manager marked the deal as completed',
    },
  },
  ru: {
    manager: {
      [ManagerCreationStep.createTrade]: 'Создана сделка № {{tradeId}}',

      [ManagerConfirmationStep.deliveryConfirmation]: 'Загружено подтверждение поставки',
      [ManagerConfirmationStep.confirmationSigning]: 'Подписано подтверждение поставки',

      [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]:
        'Подтверждение доставки было отмечено как корректное',
      [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]:
        'Подтверждение доставки было отмечено как ошибочное',

      [ManagerPaymentStep.uploadBill]: 'Загружен счет от поставщика',
      [ManagerPaymentStep.payBill]: 'Счет оплачен',

      [ManagerBillVerifyingAction.approveBillVerifying]: 'Счет от поставщика отмечен верным',
      [ManagerBillVerifyingAction.rejectBillVerifying]: 'Счет от поставщика был отмечен ошибочным',

      [ManagerShipmentStep.supplierSpecification]: 'Спецификация груза была загружена',
      [ManagerShipmentStep.shipmentType]: 'Выбран тип отгрузки',
      [ManagerShipmentStep.cargoShipment]: 'Подтверждена отгрузка груза',
      [ManagerShipmentStep.transitCountry]: 'Выброна страна транзита',
      [ManagerShipmentStep.destinationCountryDocs]: 'Документы прямой доставки были загружены',

      [ManagerSpecificationVerifyingAction.approveSpecification]: 'Спецификация груза была отмечена как корректная',
      [ManagerSpecificationVerifyingAction.rejectSpecification]: 'Спецификация груза была отмечена как ошибочная',
      [ManagerAwbVerifyingAction.approveAwb]: 'AWB отмечено корректным',
      [ManagerAwbVerifyingAction.rejectAwb]: 'AWB отмечено ошибочным',
      [AwbTransitAction.uploadFile]: 'Загружен AWB для страны транзита',
      [AwbTransitAction.flightNumber]: 'Указан номер рейса для страны транзита',
      [AwbDirectAction.uploadFile]: 'Загружен AWB для страны назначения',
      [AwbDirectAction.flightNumber]: 'Указан номер рейса для страны назначения',

      [ManagerTransportationStep.transitCountryCargoArrival]: 'Подтверждено прибытие груза в страну транзита',
      [ManagerTransportationStep.cargoArrival]: 'Подтверждено прибытие груза в страну назначения',
      [ManagerTransportationStep.supplierAcceptanceCertificate]:
        'Загружен акт приема-передачи, подписанный с одной стороны',
      [ManagerTransportationStep.supplierSignedAcceptanceCertificate]:
        'Загружен акт приема-передачи, подписанный с двух сторон',
      [ManagerTransportationStep.destinationCountryDocs]: 'Документы для страны назначения были загружены',
      [ManagerTransportationStep.transitCountryCargoShipment]: 'Подтверждена отгрузка груза из страны транзита',
      [ManagerTransportationStep.destinationCountryCargoArrival]: 'Подтверждено прибытие груза в страну назначения',

      [ManagerSupplierAcceptanceCertificateVerifyingAction.approveSupplierAcceptanceCertificate]:
        'Акт приема-передачи, подписанный с одной стороны был отмечен как корректный',
      [ManagerSupplierAcceptanceCertificateVerifyingAction.rejectSupplierAcceptanceCertificate]:
        'Акт приема-передачи, подписанный с одной стороны был отмечен как ошибочный',

      [ManagerAwbDestinationAction.uploadFile]: 'Загружен AWB для страны назначения',
      [ManagerAwbDestinationAction.flightNumber]: 'Указан номер рейса для страны назначения',

      [ManagerFinalizationStep.cargoDelivered]: 'Подтверждена доставка груза',
      [ManagerFinalizationStep.tradeCloseCustomerDocs]: 'Документы для закрытия сделки для клиента были загружены',
      [ManagerTransactionAction.addTransaction]: 'Произведена транзакция оплаты',
      [ManagerTransactionAction.editTransaction]: 'Произведено редактирование транзакции оплаты',
      [ManagerTransactionAction.deleteTransaction]: 'Произведено удаление транзакции оплаты',
      [ManagerTransactionAction.finish]: 'Сделка завершена',

      [OptionFieldsAction.deleteSupplierSpec]: 'Удалена спецификация поставщика',
      [OptionFieldsAction.setDestinationLogisticCompany]: 'Установлена логистическая компания в стране назначения',
      [OptionFieldsAction.setTransitLogisticCompany]: 'Установлена логистическая компания в стране транзита',
      [OptionFieldsAction.setTransitTrackingLink]: 'Указана ссылка для отслеживания транзита до страны транзита',
      [OptionFieldsAction.setDestinationTrackingLink]: 'Указана ссылка для отслеживания до страны назначения',
      [OptionFieldsAction.uploadFinalizationPhoto]: 'Финальная фотография была загружена',
      [OptionFieldsAction.uploadAuthorizationLetter]: 'Доверенность была загружена',
      [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: 'Сертификат об уплате косвенных налогов был загружен',
      [OptionFieldsAction.uploadWarrantyLoa]: 'Гарантийное письмо LOA было загружено',
      [OptionFieldsAction.setDeliveryTime]: 'Указано ожидаемое время доставки',

      [HistoryAction.transferToPayment]: 'Сделка переведена на этап "Оплата"',
      [HistoryAction.transferToShipment]: 'Сделка переведена на этап "Отгрузка"',
      [HistoryAction.transferToTransportation]: 'Сделка переведена на этап "Перевозка"',
      [HistoryAction.transferToFinalization]: 'Сделка переведена на этап "Завершение сделки"',
      [HistoryAction.cargoDirectShipmentConfirm]: 'Подтверждена отгрузка груза в страну назначения',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Подтверждена отгрузка груза в страну транзита',
    },
    supplier: {
      [AwbTransitAction.uploadFile]: 'Загружен AWB для страны назначения',
      [AwbTransitAction.flightNumber]: 'Указан номер рейса для страны назначения',
      [ManagerTransportationStep.transitCountryCargoArrival]: 'Подтверждено прибытие груза в страну назначения',
      [HistoryAction.cargoTransitShipmentConfirm]: 'Подтверждена отгрузка груза в страну назначения',
      [HistoryAction.transferToTransportation]: 'Сделка переведена на этап "Завершение сделки"',
      [HistoryAction.transferToFinalization]: 'Менеджер отметил сделку завершенной',
    },
  },
  zh: {
    manager: {
      [ManagerCreationStep.createTrade]: '交易编号 {{tradeId}} 已创建',

      [ManagerConfirmationStep.deliveryConfirmation]: '交货确认已上传',
      [ManagerConfirmationStep.confirmationSigning]: '交货确认已签署',

      [ManagerConfirmationVerifyingAction.approveConfirmationVerifying]: '交货确认被标记为正确',
      [ManagerConfirmationVerifyingAction.rejectConfirmationVerifying]: '交货确认被标记为错误',

      [ManagerPaymentStep.uploadBill]: '供应商发票已上传',
      [ManagerPaymentStep.payBill]: '发票已支付',

      [ManagerBillVerifyingAction.approveBillVerifying]: '供应商发票标记为正确',
      [ManagerBillVerifyingAction.rejectBillVerifying]: '供应商发票标记为错误',

      [ManagerShipmentStep.supplierSpecification]: '货物规格已上传',
      [ManagerShipmentStep.shipmentType]: '装运类型已选择',
      [ManagerShipmentStep.cargoShipment]: '货物装运已确认',
      [ManagerShipmentStep.transitCountry]: '过境国已选择',
      [ManagerShipmentStep.destinationCountryDocs]: '直接交付文件已上传',

      [ManagerSpecificationVerifyingAction.approveSpecification]: '货物规格被标记为正确',
      [ManagerSpecificationVerifyingAction.rejectSpecification]: '货物规格被标记为错误',
      [ManagerAwbVerifyingAction.approveAwb]: 'AWB标记为正确',
      [ManagerAwbVerifyingAction.rejectAwb]: 'AWB标记为错误',
      [AwbTransitAction.uploadFile]: '已为过境国选择AWB',
      [AwbTransitAction.flightNumber]: '指示过境国的航班号',
      [AwbDirectAction.uploadFile]: '已上传目的国AWB',
      [AwbDirectAction.flightNumber]: '目的地国家的航班号已显示',

      [ManagerTransportationStep.transitCountryCargoArrival]: '货物抵达过境国已确认',
      [ManagerTransportationStep.cargoArrival]: '货物抵达目的国已确认',
      [ManagerTransportationStep.supplierAcceptanceCertificate]: '上传单方面签字的验收证书',
      [ManagerTransportationStep.supplierSignedAcceptanceCertificate]: null,
      [ManagerTransportationStep.destinationCountryDocs]: '目的地国家的文件已上传',
      [ManagerTransportationStep.transitCountryCargoShipment]: '货物装运目的国已确认',
      [ManagerTransportationStep.destinationCountryCargoArrival]: '货物抵达目的国已确认',

      [ManagerSupplierAcceptanceCertificateVerifyingAction.approveSupplierAcceptanceCertificate]:
        '单方面签字的验收证书，标记为正确',
      [ManagerSupplierAcceptanceCertificateVerifyingAction.rejectSupplierAcceptanceCertificate]:
        '单方面签字的验收证书，标记为错误',

      [ManagerAwbDestinationAction.uploadFile]: '已上传目的国AWB',
      [ManagerAwbDestinationAction.flightNumber]: '目的地国家的航班号已显示',

      [ManagerFinalizationStep.cargoDelivered]: '货物交付已确认',
      [ManagerFinalizationStep.tradeCloseCustomerDocs]: '客户的结束交易文件已上传',
      [ManagerTransactionAction.addTransaction]: '付款交易完成',
      [ManagerTransactionAction.editTransaction]: '已编辑付款交易',
      [ManagerTransactionAction.deleteTransaction]: '付款交易已删除',
      [ManagerTransactionAction.finish]: '交易完成',

      [OptionFieldsAction.deleteSupplierSpec]: '供应商规格已删除',
      [OptionFieldsAction.setDestinationLogisticCompany]: '在目的国的物流公司已设定',
      [OptionFieldsAction.setTransitLogisticCompany]: '在过境国的物流公司已设定',
      [OptionFieldsAction.setTransitTrackingLink]: '注明用于中转跟踪前往中转国家的链接',
      [OptionFieldsAction.setDestinationTrackingLink]: '注明用于跟踪前往目的地国家的链接',
      [OptionFieldsAction.uploadFinalizationPhoto]: '最终照片已上传',
      [OptionFieldsAction.uploadAuthorizationLetter]: '授权书已上传',
      [OptionFieldsAction.uploadIndirectTaxesPaymentCertificate]: '间接税完税证明已上传',
      [OptionFieldsAction.uploadWarrantyLoa]: 'LOA保证函已上传',
      [OptionFieldsAction.setDeliveryTime]: '预计交货时间已表示了',

      [HistoryAction.transferToPayment]: '交易已进入“付款”阶段',
      [HistoryAction.transferToShipment]: '交易已进入“装运”阶段',
      [HistoryAction.transferToTransportation]: '交易已进入“运输”阶段',
      [HistoryAction.transferToFinalization]: '交易已进入“交易完成”阶段',
      [HistoryAction.cargoDirectShipmentConfirm]: '确认货物已运往目的国',
      [HistoryAction.cargoTransitShipmentConfirm]: '确认货物已运往过境国',
    },
    supplier: {
      [AwbTransitAction.uploadFile]: '已上传目的国AWB',
      [AwbTransitAction.flightNumber]: '目的地国家的航班号已显示',
      [ManagerTransportationStep.transitCountryCargoArrival]: '货物抵达目的国已确认',
      [HistoryAction.cargoTransitShipmentConfirm]: '确认货物已运往目的国',
      [HistoryAction.transferToTransportation]: '交易已进入“交易完成”阶段',
      [HistoryAction.transferToFinalization]: '经理将交易标记为已完成',
    },
  },
};
