export const chats = {
  en: {
    header: {
      title: 'Chats',
      description: null,
    },
  },
  ru: {
    header: {
      title: 'Чаты',
      description: `Чаты формируются по сделкам. В списке чатов будут отображаться ФИО менеджеров, с которыми вы будете работать. 
      После создания хотя бы одной сделки, в чате с менеджером появится закрепленный чат “Общий стрим“ — это место, куда будут транслироваться сообщения из всех чатов по сделкам. 
      В данный чат следует писать, если контекст общения не связан с конкретной сделкой.`,
    },
  },
  zh: {
    header: {
      title: '聊天',
      description: null,
    },
  },
};
