import { DeleteOutlined } from '@ant-design/icons';
import { DatePicker, Typography } from 'antd';
import { Observer } from 'mobx-react-lite';
import * as React from 'react';

import { IngotIdType } from '@/entities/stage/types/server/customerSpecification';
import { TranslationString } from '@/shared/types/localization';
import { TransComp } from '@/shared/ui';
import { ColumnsData } from '@/shared/ui/Table';
import { CustomButton } from '@/shared/ui/form/CustomButton';
import { CustomInput } from '@/shared/ui/form/CustomInput';

import { CustomerIngotListModel } from './CustomerIngotListModel';
import { IngotTableData } from './types';

const T_OPTIONS = { ns: 'stage' } as const;

const Title = (props: { title: TranslationString }) => {
  const title = <TransComp>{props.title}</TransComp>;

  return <Typography.Text ellipsis={{ tooltip: title }}>{title}</Typography.Text>;
};

const formInputRender = ({
  ingotList,
  key,
  field,
}: {
  ingotList: CustomerIngotListModel;
  key: string;
  field: Exclude<keyof IngotTableData, 'key' | 'year'>;
}) => {
  const ingotModel = ingotList.list.getEntity(key);

  return <Observer>{() => ingotModel && <CustomInput {...ingotModel[field].props} />}</Observer>;
};

const formDateRender = ({ ingotList, key }: { ingotList: CustomerIngotListModel; key: string }) => {
  const ingotModel = ingotList.list.getEntity(key);

  return <Observer>{() => ingotModel && <DatePicker {...ingotModel.year.props} picker="year" />}</Observer>;
};

const yearFields = ['ingotNumber', 'weight', 'ligatureWeight', 'metalPurity', 'year', 'key'] as const;
const uinFields = ['ingotNumber', 'weight', 'ligatureWeight', 'metalPurity', 'uin', 'key'] as const;

export const getColumns = (ingotList: CustomerIngotListModel): ColumnsData<IngotTableData> => {
  const mapColumnKeyToColumnData: Record<keyof IngotTableData, ColumnsData<IngotTableData>[number]> = {
    ingotNumber: {
      title: <Title title={(t) => t('ingot.ingotNumber', T_OPTIONS)} />,
      dataIndex: 'ingotNumber',
      key: 'ingotNumber',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'ingotNumber' }),
    },
    weight: {
      title: <Title title={(t) => t('ingot.weight', T_OPTIONS)} />,
      dataIndex: 'weight',
      key: 'weight',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'weight' }),
    },
    ligatureWeight: {
      title: <Title title={(t) => t('ingot.ligatureWeight', T_OPTIONS)} />,
      dataIndex: 'ligatureWeight',
      key: 'ligatureWeight',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'ligatureWeight' }),
    },
    metalPurity: {
      title: <Title title={(t) => t('ingot.metalPurity', T_OPTIONS)} />,
      dataIndex: 'metalPurity',
      key: 'metalPurity',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'metalPurity' }),
    },
    year: {
      title: <Title title={(t) => t('ingot.yearOfProduction', T_OPTIONS)} />,
      dataIndex: 'year',
      key: 'year',
      width: 140,
      render: (key: string) => formDateRender({ ingotList, key }),
    },
    uin: {
      title: <Title title={(t) => t('ingot.uin', T_OPTIONS)} />,
      dataIndex: 'uin',
      key: 'uin',
      width: 140,
      render: (key: string) => formInputRender({ ingotList, key, field: 'uin' }),
    },
    key: {
      title: null,
      dataIndex: 'key',
      key: 'key',
      align: 'center',
      width: 64,
      fixed: 'right',
      render: (key: string) => (
        <CustomButton
          disabled={ingotList.list.length === 1}
          icon={<DeleteOutlined />}
          onClick={() => ingotList.deleteIngot(key)}
        />
      ),
    },
  };

  const fields = ingotList.yearOrUin.value === IngotIdType.year ? yearFields : uinFields;

  return fields.map((key) => mapColumnKeyToColumnData[key]);
};
