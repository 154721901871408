import { Flex } from 'antd';
import * as React from 'react';

import { useWidth } from '@/shared/hooks';
import { TranslationString } from '@/shared/types/localization';
import { CustomTypographyText, CustomTypographyTitle } from '@/shared/ui';

type VerificationStepHeaderProps = {
  className?: string;
  title: TranslationString;
  description?: TranslationString;
};

const VerificationStepHeader: React.FC<VerificationStepHeaderProps> = ({ className, title, description }) => {
  const { isMobile } = useWidth();

  return (
    <Flex className={className} vertical gap={isMobile ? 12 : 16}>
      <CustomTypographyTitle level={4}>{title}</CustomTypographyTitle>
      {description && <CustomTypographyText type="secondary">{description}</CustomTypographyText>}
    </Flex>
  );
};

export default React.memo(VerificationStepHeader);
