export const profile = {
  en: {
    myProfile: {
      title: null,
      description: null,
    },
    company: {
      title: null,
      description: null,
    },
    employees: {
      title: null,
      description: null,
    },
    notifications: {
      title: null,
      description: null,
    },
  },
  ru: {
    myProfile: {
      title: 'Мой профиль',
      description: 'Здесь вы можете изменить свои контактные данные и/или пароль.',
    },
    company: {
      title: 'Сведения о компании',
      description: 'Здесь вы можете изменить контактную информацию о компании.',
    },
    employees: {
      title: 'Сведения о сотрудниках',
      description: 'Здесь вы можете добавить или изменить информацию о сотрудниках.',
    },
    notifications: {
      title: 'Уведомления',
      description: 'Здесь будут отображаться все уведомления по сделкам.',
    },
  },
  zh: {
    myProfile: {
      title: null,
      description: null,
    },
    company: {
      title: null,
      description: null,
    },
    employees: {
      title: null,
      description: null,
    },
    notifications: {
      title: null,
      description: null,
    },
  },
};
