import { NonEAEUCountryDocsServer, VerificationDocumentType } from '@/entities/verification/types';
import { CheckStatus } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { BaseVerificationFieldsModel } from '../../BaseVerificationFieldsModel';
import { VerificationUploadFilesModel } from '../../VerificationUploadFilesModel';
import { initVerificationUploadFilesModel } from '../config';

type Params = {
  companyCharter: VerificationUploadFilesModel<VerificationDocumentType.companyCharter>;
  memorandumAssociation: VerificationUploadFilesModel<VerificationDocumentType.memorandumAssociation>;
  directorAuthorityCertificate: VerificationUploadFilesModel<VerificationDocumentType.directorAuthorityCertificate>;
  tradeRegisterExtract: VerificationUploadFilesModel<VerificationDocumentType.tradeRegisterExtract>;
  stateRegistrationCertificate: VerificationUploadFilesModel<VerificationDocumentType.stateRegistrationCertificate>;
  directorPassport: VerificationUploadFilesModel<VerificationDocumentType.directorPassport>;
  leaseAgreement: VerificationUploadFilesModel<VerificationDocumentType.leaseAgreement>;
  companyEstablishDecision: VerificationUploadFilesModel<VerificationDocumentType.companyEstablishDecision>;
  activitiesLicense: VerificationUploadFilesModel<VerificationDocumentType.activitiesLicense>;
  attorneyPower: VerificationUploadFilesModel<VerificationDocumentType.attorneyPower>;
  directorAppointOrderOrDecision: VerificationUploadFilesModel<VerificationDocumentType.directorAppointOrderOrDecision>;
  businessReputationReview: VerificationUploadFilesModel<VerificationDocumentType.businessReputationReview>;
};

export class NonEAEUCountryDocsModel extends BaseVerificationFieldsModel<null> {
  readonly companyCharter: VerificationUploadFilesModel<VerificationDocumentType.companyCharter>;
  readonly memorandumAssociation: VerificationUploadFilesModel<VerificationDocumentType.memorandumAssociation>;
  readonly directorAuthorityCertificate: VerificationUploadFilesModel<VerificationDocumentType.directorAuthorityCertificate>;
  readonly tradeRegisterExtract: VerificationUploadFilesModel<VerificationDocumentType.tradeRegisterExtract>;
  readonly stateRegistrationCertificate: VerificationUploadFilesModel<VerificationDocumentType.stateRegistrationCertificate>;
  readonly directorPassport: VerificationUploadFilesModel<VerificationDocumentType.directorPassport>;
  readonly leaseAgreement: VerificationUploadFilesModel<VerificationDocumentType.leaseAgreement>;
  readonly companyEstablishDecision: VerificationUploadFilesModel<VerificationDocumentType.companyEstablishDecision>;
  readonly activitiesLicense: VerificationUploadFilesModel<VerificationDocumentType.activitiesLicense>;
  readonly attorneyPower: VerificationUploadFilesModel<VerificationDocumentType.attorneyPower>;
  readonly directorAppointOrderOrDecision: VerificationUploadFilesModel<VerificationDocumentType.directorAppointOrderOrDecision>;
  readonly businessReputationReview: VerificationUploadFilesModel<VerificationDocumentType.businessReputationReview>;

  constructor(params: Params) {
    super();

    this.companyCharter = params.companyCharter;
    this.memorandumAssociation = params.memorandumAssociation;
    this.directorAuthorityCertificate = params.directorAuthorityCertificate;
    this.tradeRegisterExtract = params.tradeRegisterExtract;
    this.stateRegistrationCertificate = params.stateRegistrationCertificate;
    this.directorPassport = params.directorPassport;
    this.leaseAgreement = params.leaseAgreement;
    this.companyEstablishDecision = params.companyEstablishDecision;
    this.activitiesLicense = params.activitiesLicense;
    this.attorneyPower = params.attorneyPower;
    this.directorAppointOrderOrDecision = params.directorAppointOrderOrDecision;
    this.businessReputationReview = params.businessReputationReview;
  }

  get isError(): boolean {
    return this._fields.some((field) => field.isError || field.checkStatus === CheckStatus.rejected);
  }

  get isFilled(): boolean {
    return this._fields.every((field) => !field.required || field.isUploaded);
  }

  get isChanged(): boolean {
    return this._fields.some((field) => field.touched);
  }

  toJson(): null {
    return null;
  }

  protected get _fields(): Array<VerificationUploadFilesModel<VerificationDocumentType>> {
    return [
      this.companyCharter,
      this.memorandumAssociation,
      this.directorAuthorityCertificate,
      this.tradeRegisterExtract,
      this.stateRegistrationCertificate,
      this.directorPassport,
      this.leaseAgreement,
      this.companyEstablishDecision,
      this.activitiesLicense,
      this.attorneyPower,
      this.directorAppointOrderOrDecision,
      this.businessReputationReview,
    ];
  }

  static fromJson(server: Nullable<NonEAEUCountryDocsServer>): NonEAEUCountryDocsModel {
    return new NonEAEUCountryDocsModel({
      companyCharter: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.companyCharter,
        required: true,
      }),
      memorandumAssociation: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.memorandumAssociation,
        required: true,
      }),
      directorAuthorityCertificate: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.directorAuthorityCertificate,
        required: true,
      }),
      tradeRegisterExtract: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.tradeRegisterExtract,
        required: true,
      }),
      stateRegistrationCertificate: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.stateRegistrationCertificate,
        required: true,
      }),
      directorPassport: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.directorPassport,
        required: true,
      }),
      leaseAgreement: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.leaseAgreement,
        required: true,
      }),
      companyEstablishDecision: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.companyEstablishDecision,
        required: false,
      }),
      activitiesLicense: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.activitiesLicense,
        required: false,
      }),
      attorneyPower: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.attorneyPower,
        required: false,
      }),
      directorAppointOrderOrDecision: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.directorAppointOrderOrDecision,
        required: false,
      }),
      businessReputationReview: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.businessReputationReview,
        required: false,
      }),
    });
  }
}
