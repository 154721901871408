import { EAEUCountryDocsServer, VerificationDocumentType } from '@/entities/verification/types';
import { CheckStatus } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { BaseVerificationFieldsModel } from '../../BaseVerificationFieldsModel';
import { VerificationUploadFilesModel } from '../../VerificationUploadFilesModel';
import { initVerificationUploadFilesModel } from '../config';

type Params = {
  companyCharter: VerificationUploadFilesModel<VerificationDocumentType.companyCharter>;
  unifiedStateRegisterExtract: VerificationUploadFilesModel<VerificationDocumentType.unifiedStateRegisterExtract>;
  directorAppointOrder: VerificationUploadFilesModel<VerificationDocumentType.directorAppointOrder>;
  directorPassport: VerificationUploadFilesModel<VerificationDocumentType.directorPassport>;
  leaseAgreement: VerificationUploadFilesModel<VerificationDocumentType.leaseAgreement>;
  companyEstablishDecision: VerificationUploadFilesModel<VerificationDocumentType.companyEstablishDecision>;
  activitiesLicense: VerificationUploadFilesModel<VerificationDocumentType.activitiesLicense>;
  attorneyPower: VerificationUploadFilesModel<VerificationDocumentType.attorneyPower>;
};

export class EAEUCountryDocsModel extends BaseVerificationFieldsModel<null> {
  readonly companyCharter: VerificationUploadFilesModel<VerificationDocumentType.companyCharter>;
  readonly unifiedStateRegisterExtract: VerificationUploadFilesModel<VerificationDocumentType.unifiedStateRegisterExtract>;
  readonly directorAppointOrder: VerificationUploadFilesModel<VerificationDocumentType.directorAppointOrder>;
  readonly directorPassport: VerificationUploadFilesModel<VerificationDocumentType.directorPassport>;
  readonly leaseAgreement: VerificationUploadFilesModel<VerificationDocumentType.leaseAgreement>;
  readonly companyEstablishDecision: VerificationUploadFilesModel<VerificationDocumentType.companyEstablishDecision>;
  readonly activitiesLicense: VerificationUploadFilesModel<VerificationDocumentType.activitiesLicense>;
  readonly attorneyPower: VerificationUploadFilesModel<VerificationDocumentType.attorneyPower>;

  constructor(params: Params) {
    super();

    this.companyCharter = params.companyCharter;
    this.unifiedStateRegisterExtract = params.unifiedStateRegisterExtract;
    this.directorAppointOrder = params.directorAppointOrder;
    this.directorPassport = params.directorPassport;
    this.leaseAgreement = params.leaseAgreement;
    this.companyEstablishDecision = params.companyEstablishDecision;
    this.activitiesLicense = params.activitiesLicense;
    this.attorneyPower = params.attorneyPower;
  }

  get isError(): boolean {
    return this._fields.some((field) => field.isError || field.checkStatus === CheckStatus.rejected);
  }

  get isFilled(): boolean {
    return this._fields.every((field) => !field.required || field.isUploaded);
  }

  get isChanged(): boolean {
    return this._fields.some((field) => field.touched);
  }

  toJson(): null {
    return null;
  }

  protected get _fields(): Array<VerificationUploadFilesModel<VerificationDocumentType>> {
    return [
      this.companyCharter,
      this.unifiedStateRegisterExtract,
      this.directorAppointOrder,
      this.directorPassport,
      this.leaseAgreement,
      this.companyEstablishDecision,
      this.activitiesLicense,
      this.attorneyPower,
    ];
  }

  static fromJson(server: Nullable<EAEUCountryDocsServer>): EAEUCountryDocsModel {
    return new EAEUCountryDocsModel({
      companyCharter: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.companyCharter,
        required: true,
      }),
      unifiedStateRegisterExtract: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.unifiedStateRegisterExtract,
        required: true,
      }),
      directorAppointOrder: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.directorAppointOrder,
        required: true,
      }),
      directorPassport: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.directorPassport,
        required: true,
      }),
      leaseAgreement: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.leaseAgreement,
        required: true,
      }),
      companyEstablishDecision: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.companyEstablishDecision,
        required: false,
      }),
      activitiesLicense: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.activitiesLicense,
        required: false,
      }),
      attorneyPower: initVerificationUploadFilesModel({
        server,
        docType: VerificationDocumentType.attorneyPower,
        required: false,
      }),
    });
  }
}
