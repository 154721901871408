import { FileOutlined, FileTextOutlined, FundOutlined, MessageOutlined, UserOutlined } from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import * as React from 'react';

import { getRoleRouteKeyMap } from '@/app/routes/config';
import { UserRole } from '@/entities/user';
import { RouteKey, routerUrls } from '@/shared/config/routes';
import { themeToken } from '@/shared/config/themeConfig';
import { TranslationString } from '@/shared/types/localization';
import { Nullable } from '@/shared/types/values';
import { LanguageSelector, LanguageSelectorCallback, TransComp } from '@/shared/ui';
import { BuildingIcon, LanguageFilledIcon, LeaveFilledIcon } from '@/shared/ui/icons';

import { NotificationsBell } from './ui/NotificationsBell';

const T_OPTIONS = { ns: 'menu' } as const;

const getColor = (selectedKey: string, key: string) =>
  selectedKey === key ? themeToken.colorWhite : themeToken.colorSecondary;

export const menuUrlsMap: Record<string, { key: RouteKey | 'logout'; url: string; label: TranslationString }> = {
  companies: {
    key: 'companies',
    url: routerUrls.companies.create(),
    label: (t) => t('companies', T_OPTIONS),
  },
  trades: {
    key: 'trades',
    url: routerUrls.trades.create(),
    label: (t) => t('trades', T_OPTIONS),
  },
  documents: {
    key: 'documents',
    url: routerUrls.documents.create(),
    label: (t) => t('documents', T_OPTIONS),
  },
  logout: {
    key: 'logout',
    url: routerUrls.login.create(),
    label: (t) => t('logout', T_OPTIONS),
  },
  profile: {
    key: 'profile',
    url: routerUrls.profile.create(),
    label: (t) => t('profile', T_OPTIONS),
  },
  notifications: {
    key: 'notifications',
    url: routerUrls.notifications.create(),
    label: (t) => t('notifications', T_OPTIONS),
  },
  chats: {
    key: 'chats',
    url: routerUrls.chats.create(),
    label: (t) => t('chats', T_OPTIONS),
  },
  bids: {
    key: 'bids',
    url: routerUrls.bids.create(),
    label: (t) => t('bids', { ns: 'menu' }),
  },
};

type MenuParams = {
  userRole: Nullable<UserRole>;
  needVerifyCompany: boolean;
  selectedKey: string;
  handleMenuItemClick: (params: { key: string }) => void;
};

export const getMenuItems = ({
  userRole,
  needVerifyCompany,
  selectedKey,
  handleMenuItemClick,
}: MenuParams): Array<ItemType> => {
  if (!userRole) {
    return [];
  }

  const routeKeys = getRoleRouteKeyMap(needVerifyCompany)[userRole];

  const baseItems = [
    {
      key: menuUrlsMap.profile.key,
      label: <TransComp>{menuUrlsMap.profile.label}</TransComp>,
      icon: <UserOutlined style={{ fontSize: 16 }} />,
      style: { color: getColor(selectedKey, menuUrlsMap.profile.key) },
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.profile.key }),
    },
    {
      key: menuUrlsMap.notifications.key,
      label: <TransComp>{menuUrlsMap.notifications.label}</TransComp>,
      icon: <NotificationsBell style={{ fontSize: 16 }} />,
      style: { color: getColor(selectedKey, menuUrlsMap.notifications.key) },
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.notifications.key }),
    },
    {
      key: menuUrlsMap.companies.key,
      label: <TransComp>{menuUrlsMap.companies.label}</TransComp>,
      icon: <BuildingIcon style={{ fontSize: 16 }} />,
      style: { color: getColor(selectedKey, menuUrlsMap.companies.key) },
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.companies.key }),
    },
    {
      key: menuUrlsMap.trades.key,
      label: <TransComp>{menuUrlsMap.trades.label}</TransComp>,
      icon: <FileTextOutlined style={{ fontSize: 16 }} />,
      style: { color: getColor(selectedKey, menuUrlsMap.trades.key) },
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.trades.key }),
    },
    {
      key: menuUrlsMap.documents.key,
      label: <TransComp>{menuUrlsMap.documents.label}</TransComp>,
      icon: <FileOutlined style={{ fontSize: 16 }} />,
      style: { color: getColor(selectedKey, menuUrlsMap.documents.key) },
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.documents.key }),
    },
    {
      key: menuUrlsMap.chats.key,
      label: <TransComp>{menuUrlsMap.chats.label}</TransComp>,
      icon: <MessageOutlined style={{ fontSize: 16 }} />,
      style: { color: getColor(selectedKey, menuUrlsMap.chats.key) },
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.chats.key }),
    },
    {
      key: menuUrlsMap.bids.key,
      label: <TransComp>{menuUrlsMap.bids.label}</TransComp>,
      icon: <FundOutlined style={{ fontSize: 16 }} />,
      style: { color: getColor(selectedKey, menuUrlsMap.bids.key) },
      onClick: () => handleMenuItemClick({ key: menuUrlsMap.bids.key }),
    },
  ];

  return baseItems.filter(({ key }) => key !== 'logout' && routeKeys.includes(key));
};

type GeneralMenuParams = {
  isMenuOpened: boolean;
  languageSelectorCallbackRef: React.MutableRefObject<LanguageSelectorCallback | null>;
  handleMenuItemClick: (params: { key: string }) => void;
};

export const getGeneralMenuItems = ({
  isMenuOpened,
  languageSelectorCallbackRef,
  handleMenuItemClick,
}: GeneralMenuParams): Array<ItemType> => [
  {
    type: 'group',
    children: [
      {
        key: 'language',
        label: <LanguageSelector isMenuOpened={isMenuOpened} callbackRef={languageSelectorCallbackRef} />,
        icon: <LanguageFilledIcon size={16} />,
        style: { color: themeToken.colorSecondary },
        onClick: () => languageSelectorCallbackRef.current?.toggleLanguageSelector(),
      },
      {
        key: menuUrlsMap.logout.key,
        label: <TransComp>{menuUrlsMap.logout.label}</TransComp>,
        icon: <LeaveFilledIcon size={16} />,
        style: { color: themeToken.colorSecondary },
        onClick: () => handleMenuItemClick({ key: menuUrlsMap.logout.key }),
      },
    ],
  },
];
