import * as React from 'react';

import { useWidth } from '@/shared/hooks';
import { isNullable } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';

import { IPresentedElement } from '../../types';

type Props = {
  currentElement: Nullable<IPresentedElement>;
};

export const usePresentedElement = ({ currentElement }: Props) => {
  const ghostElementRef = React.useRef<HTMLDivElement>(null);

  const { isMobile } = useWidth();

  React.useLayoutEffect(() => {
    const presentedElement = currentElement?.node;
    const ghostElement = ghostElementRef.current;

    if (isNullable(ghostElement) || isNullable(presentedElement)) {
      return;
    }

    const updateElementPosition = () => {
      presentedElement.scrollIntoView();

      /**
       * Получаем координаты текущего элемента
       * */
      const { top, left, width, height } = presentedElement.getBoundingClientRect();

      const indent = isMobile ? 5 : 10;

      /**
       * Устанавливаем координаты для элемента-призрака, чтобы корректно отобразить тултип
       * */
      ghostElement.style.setProperty('width', `calc(${width}px + ${indent * 2}px)`);
      ghostElement.style.setProperty('height', `calc(${height}px + ${indent * 2}px)`);
      ghostElement.style.setProperty('top', `calc(${top}px - ${indent}px)`);
      ghostElement.style.setProperty('left', `calc(${left}px - ${indent}px)`);
    };

    updateElementPosition();

    window.addEventListener('resize', updateElementPosition);

    return () => {
      window.removeEventListener('resize', updateElementPosition);

      /**
       * Сбрасываем координаты для элемента-призрака
       * */
      ghostElement?.style.removeProperty('top');
      ghostElement?.style.removeProperty('left');
      ghostElement?.style.removeProperty('width');
      ghostElement?.style.removeProperty('height');
    };
  }, [currentElement?.node, ghostElementRef.current, isMobile]);

  return { ghostElementRef };
};
