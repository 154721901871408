import * as React from 'react';

import { TROY_OUNCE_CONVERSION_COEFFICIENT } from '@/shared/config/constants';
import { TransComp } from '@/shared/ui';

import { ChartType, ProductWeightType } from './types';

export const mapWeightUnitToGram: Record<ProductWeightType, number> = {
  [ProductWeightType.gram]: 1,
  [ProductWeightType.kilogram]: 1_000,
  [ProductWeightType.ton]: 1_000_000,
  [ProductWeightType.troyOunce]: TROY_OUNCE_CONVERSION_COEFFICIENT,
};

export const convertWeightToGram = ({
  weight,
  weightUnit,
}: {
  weight: number;
  weightUnit: ProductWeightType;
}): number => {
  return mapWeightUnitToGram[weightUnit] * weight;
};

export const chartTypeOptions: Array<{ value: ChartType; label: React.ReactNode }> = Object.values(ChartType).map(
  (value) => ({
    value,
    label: <TransComp>{(t) => t(`chartType.${value}`, { count: 1, ns: 'dictionary' })}</TransComp>,
  }),
);
