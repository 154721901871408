import { ExpandAltOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';

import Ticker from '../Ticker';
import TickerAreaChart from '../TickerAreaChart';

import s from './TickerWidget.module.scss';

type TickerWidgetProps = {
  className?: string;
};

const TickerWidget: React.FC<TickerWidgetProps> = ({ className }) => {
  const { dictionariesStore } = useRootStore();
  const tickerStore = dictionariesStore.tickers;

  const handleClick = React.useCallback(() => {
    tickerStore.openDrawer();
  }, [tickerStore]);

  return (
    <Flex className={cn(s.widget, className)} vertical gap={8}>
      {tickerStore.list.items.map((ticker) => (
        <Flex key={ticker.tickerType} align="center" justify="space-between" gap={8}>
          <Ticker className={s.widget_ticker} model={ticker} showTrendArrow={false} />
          <TickerAreaChart
            className={s.widget_chart}
            tickerHistory={tickerStore.historyList.getEntity(ticker.tickerType)}
            height={28}
            width={56}
          />
        </Flex>
      ))}
      <Button className={s.widget_button} size="small" type="text" icon={<ExpandAltOutlined />} onClick={handleClick} />
    </Flex>
  );
};

export default observer(TickerWidget);
