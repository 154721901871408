import * as Sentry from '@sentry/react';
import * as React from 'react';
import { Outlet } from 'react-router-dom';

import { TickerDrawer } from '@/entities/dictionary';
import { ErrorBoundary } from '@/pages/ErrorBoundary';
import { useNetworkStatus, WidthProvider } from '@/shared/hooks';
import { Loader, OfflineStatus } from '@/shared/ui';
import { SideMenuPageLayout } from '@/widgets/menu';
import { Onboarding } from '@/widgets/onboarding';

import { RootStoreContextProvider } from './store';

export const App = (): React.ReactElement => {
  const { isOnline } = useNetworkStatus();

  const Content = Sentry.withErrorBoundary(Outlet, {
    fallback: <ErrorBoundary />,
  });

  return isOnline ? (
    <RootStoreContextProvider>
      <WidthProvider>
        <SideMenuPageLayout>
          <React.Suspense fallback={<Loader />}>
            <Content />
            <TickerDrawer />
            <Onboarding />
          </React.Suspense>
        </SideMenuPageLayout>
      </WidthProvider>
    </RootStoreContextProvider>
  ) : (
    <OfflineStatus />
  );
};
