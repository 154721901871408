import { DocumentFileType, UploadOrGenerateDocumentModel, UploadOrGenerateDocumentModelParams } from '@/entities/file';
import { AcceptanceCertificateDocPayload } from '@/entities/stage/types';

import { AcceptanceCertificateFiedsModel } from './AcceptanceCertificateFiedsModel';

export class AcceptanceCertificateModel extends UploadOrGenerateDocumentModel<
  DocumentFileType.customerAcceptanceCertificate,
  AcceptanceCertificateDocPayload,
  AcceptanceCertificateFiedsModel
> {
  readonly generationFields: AcceptanceCertificateFiedsModel;

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.customerAcceptanceCertificate>) {
    super(params);

    this.generationFields = new AcceptanceCertificateFiedsModel(params);
  }
}
