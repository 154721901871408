import { SelectModel, SelectModelParams } from '@/shared/model/form/SelectModel';
import { Options } from '@/shared/types/values';
import { emptyValueValidator } from '@/shared/utils/validators';

import { mapDocumentLanguageToLabel } from '../config';
import { GenerationDocumentLanguage } from '../types';

type Params = SelectModelParams<GenerationDocumentLanguage> & {
  documentLanguages: Array<GenerationDocumentLanguage>;
};

export class SelectGenerationDocumentLanguageModel extends SelectModel<GenerationDocumentLanguage> {
  private _documentLanguages: Array<GenerationDocumentLanguage> = [];

  constructor({ documentLanguages, ...params }: Params) {
    super({
      label: (t) => t('languageDownloadDoc.title', { ns: 'file' }),
      required: true,
      validators: [emptyValueValidator()],
      ...params,
    });

    this._documentLanguages = documentLanguages;
  }

  get options(): Options<GenerationDocumentLanguage> {
    return this._documentLanguages.map((language) => ({
      label: mapDocumentLanguageToLabel(language),
      value: language,
    }));
  }
}
