import { CompanyType } from '@/entities/company';
import { DocumentFileType } from '@/entities/file';
import {
  StageModelJsonParams,
  StageType,
  SupplierFinalizationAction,
  SupplierFinalizationStageDataServer,
  SupplierFinalizationStep,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/entities/stage/utils';

import { BaseManagerStageModel } from '../../BaseManagerStageModel';
import { BaseStageModel, BaseStageModelParams } from '../../BaseStageModel';
import { UploadFilesStepModel, WaitingDateStepModel, WaitingFileStepModel, WaitingStepModel } from '../../fieldsModel';

import { stepsOrder } from './config';

type Params = BaseStageModelParams<SupplierFinalizationStep> & {
  cargoArrivalFields: WaitingDateStepModel<SupplierFinalizationStep>;
  supplierAcceptanceCertificateFields: UploadFilesStepModel<
    SupplierFinalizationStep,
    SupplierFinalizationAction,
    DocumentFileType.supplierAcceptanceCertificate
  >;
  supplierAcceptanceCertificateVerifyingFields: WaitingStepModel<SupplierFinalizationStep>;
  supplierSignedAcceptanceCertificateFields: WaitingFileStepModel<SupplierFinalizationStep>;
};

export class SupplierFinalizationStageModel extends BaseStageModel<StageType.finalization, SupplierFinalizationStep> {
  cargoArrivalFields: WaitingDateStepModel<SupplierFinalizationStep>;
  supplierAcceptanceCertificateFields: UploadFilesStepModel<
    SupplierFinalizationStep,
    SupplierFinalizationAction,
    DocumentFileType.supplierAcceptanceCertificate
  >;
  supplierAcceptanceCertificateVerifyingFields: WaitingStepModel<SupplierFinalizationStep>;
  supplierSignedAcceptanceCertificateFields: WaitingFileStepModel<SupplierFinalizationStep>;

  constructor(params: Params) {
    super({
      type: StageType.finalization,
      ...params,
    });

    this.cargoArrivalFields = params.cargoArrivalFields;
    this.supplierAcceptanceCertificateFields = params.supplierAcceptanceCertificateFields;
    this.supplierAcceptanceCertificateVerifyingFields = params.supplierAcceptanceCertificateVerifyingFields;
    this.supplierSignedAcceptanceCertificateFields = params.supplierSignedAcceptanceCertificateFields;
  }

  private get _isSupplierAcceptanceCertificateVerifying(): boolean {
    return this.supplierAcceptanceCertificateFields.uploadedBy === CompanyType.supplier;
  }

  get isCargoArrived(): boolean {
    return this.step !== SupplierFinalizationStep.cargoArrival && this.cargoArrivalFields.date.value !== null;
  }

  get isAcceptanceCertificateApproved(): boolean {
    return this.supplierAcceptanceCertificateFields.isApproved;
  }

  get isSignedAcceptanceCertificateUploaded(): boolean {
    return this.supplierSignedAcceptanceCertificateFields.file.isUploaded;
  }

  get isFinished(): boolean {
    return this.isSignedAcceptanceCertificateUploaded || this.step === SupplierFinalizationStep.finish;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): SupplierFinalizationStep[] {
    return [
      SupplierFinalizationStep.cargoArrival,
      SupplierFinalizationStep.supplierAcceptanceCertificate,
      /** Если нужна верификация акта, то добавляем шаг верификации */
      ...(this._isSupplierAcceptanceCertificateVerifying
        ? [SupplierFinalizationStep.supplierAcceptanceCertificateVerifying]
        : []),
      SupplierFinalizationStep.supplierSignedAcceptanceCertificate,
      SupplierFinalizationStep.finish,
    ];
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<SupplierFinalizationStageDataServer>): SupplierFinalizationStageModel {
    const { destinationCountryCargoArrivalDate } = tradeWorkflowStore.tradeInfo;

    return new SupplierFinalizationStageModel({
      cargoArrivalFields: WaitingDateStepModel.fromJson({
        data: dataServer.steps[SupplierFinalizationStep.cargoArrival]?.date ?? destinationCountryCargoArrivalDate,
        step: SupplierFinalizationStep.cargoArrival,
        tradeWorkflowStore,
      }),
      supplierAcceptanceCertificateFields: UploadFilesStepModel.fromJson({
        data: dataServer.steps[SupplierFinalizationStep.supplierAcceptanceCertificate],
        step: SupplierFinalizationStep.supplierAcceptanceCertificate,
        approveAction: SupplierFinalizationStep.supplierAcceptanceCertificate,
        docType: DocumentFileType.supplierAcceptanceCertificate,
        tradeWorkflowStore,
      }),
      supplierAcceptanceCertificateVerifyingFields: WaitingStepModel.fromJson({
        step: SupplierFinalizationStep.supplierAcceptanceCertificateVerifying,
        tradeWorkflowStore,
      }),
      supplierSignedAcceptanceCertificateFields: WaitingFileStepModel.fromJson({
        data: dataServer.steps[SupplierFinalizationStep.supplierSignedAcceptanceCertificate],
        step: SupplierFinalizationStep.supplierSignedAcceptanceCertificate,
        tradeWorkflowStore,
      }),
      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
