export const bids = {
  en: {
    createButton: {
      title: null,
      description: null,
    },
    applyCreateModal: {
      title: null,
      description: null,
    },
    replyButton: {
      title: null,
      description: null,
    },
    discount: {
      title: null,
      description: null,
    },
    myReplies: {
      title: null,
      description: null,
    },
    myBids: {
      title: null,
      description: null,
    },
  },
  ru: {
    createButton: {
      title: 'Создание заявки',
      description: 'При нажатии на кнопку откроется окно для создания заявки.',
    },
    applyCreateModal: {
      title: 'Создание заявки',
      description:
        'В открывшемся окне необходимо заполнить обязательные поля и нажать кнопку “Создать“, после чего заявка будет отправлена на рассмотрение менеджеру.',
    },
    replyButton: {
      title: 'Отклик на заявку',
      description: 'Оставить отклик на заявку можно нажав на кнопку “Откликнуться”.',
    },
    discount: {
      title: 'Отклик на заявку',
      description:
        'В открывшемся окне необходимо выбрать базис поставки, вид дисконта/премии, а также ввести его значение. Введите положительное значение, если выбираете премию, и отрицательное, если дисконт.',
    },
    myReplies: {
      title: 'Мои отклики',
      description: 'Здесь отображаются все отправленные вами отклики на заявки.',
    },
    myBids: {
      title: 'Мои заявки',
      description: 'Здесь будут отображаться все ваши созданные заявки.',
    },
  },
  zh: {
    createButton: {
      title: null,
      description: null,
    },
    applyCreateModal: {
      title: null,
      description: null,
    },
    replyButton: {
      title: null,
      description: null,
    },
    discount: {
      title: null,
      description: null,
    },
    myReplies: {
      title: null,
      description: null,
    },
    myBids: {
      title: null,
      description: null,
    },
  },
};
