import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StepDescription } from '@/shared/ui';
import { FormUpload } from '@/shared/ui/form/FormUpload';

import { WaitingFilesStepModel } from '../../model';
import { StageModel, StepComponentProps } from '../../types';

type Props<StageModel> = StepComponentProps<StageModel>;

const WaitingWithFilesStep = <Model extends StageModel>({ model, stepKey, description }: Props<Model>) => {
  const step = stepKey && model[stepKey];

  if (!(step instanceof WaitingFilesStepModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      {step.files && step.files.map(({ docType, file }) => <FormUpload key={docType} model={file} disabled={true} />)}
    </StepDescription>
  );
};

export default observer(WaitingWithFilesStep);
