import { DocumentFileType, UploadOrGenerateDocumentModel, UploadOrGenerateDocumentModelParams } from '@/entities/file';
import { InvoiceDocPayload } from '@/entities/stage/types';

import { InvoiceFiedsModel } from './InvoiceFiedsModel';

export type InvoiceDocTypes = DocumentFileType.exportInvoice | DocumentFileType.customerCommercialInvoice;

export class InvoiceModel<DocType extends InvoiceDocTypes> extends UploadOrGenerateDocumentModel<
  DocType,
  InvoiceDocPayload,
  InvoiceFiedsModel<DocType>
> {
  readonly generationFields: InvoiceFiedsModel<DocType>;

  constructor(params: UploadOrGenerateDocumentModelParams<DocType>) {
    super(params);

    this.generationFields = new InvoiceFiedsModel(params);
  }
}
