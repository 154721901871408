import { isString } from 'antd/es/button';
import { action, computed, makeObservable } from 'mobx';
import { nanoid } from 'nanoid';

import { IHandlingIngots, IngotIdType } from '@/entities/stage/types';
import { ListModel } from '@/shared/model';
import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { RadioListModel } from '@/shared/model/form/RadioListModel';
import { ColumnsData } from '@/shared/ui/Table';

import { CustomerIngotModel } from '../CustomerIngotModel';

import { getColumns } from './config';
import { IngotTableData } from './types';

type Params = {
  yearOrUin: RadioListModel<IngotIdType>;
};

export class CustomerIngotListModel implements IHandlingIngots<IngotTableData> {
  readonly list = new ListModel<CustomerIngotModel, string>();
  readonly yearOrUin: RadioListModel<IngotIdType>;

  constructor({ yearOrUin }: Params) {
    this.yearOrUin = yearOrUin;

    this.addIngot();

    makeObservable(this, {
      columns: computed,
      rows: computed,
      isError: computed,
      isEmptyValue: computed,
      countIngots: computed,

      addIngot: action.bound,
      deleteIngot: action.bound,
    });
  }

  get isError(): boolean {
    return this._yearOrUinFields.some((field) => field.isError);
  }

  get isEmptyValue(): boolean {
    return this._yearOrUinFields.some((field) => !(isString(field.value) ? field.value.trim() : field.value));
  }

  get countIngots(): number {
    const yearOrUin = this.yearOrUin.value;

    if (!yearOrUin) {
      return 0;
    }

    return this.list.items.reduce((acc, row) => {
      if (
        row
          .getYearOrUinFields(yearOrUin)
          .some((field) => (isString(field.value) ? !field.value.trim() : !field.value) || field.isError)
      ) {
        return acc;
      }

      return acc + 1;
    }, 0);
  }

  get columns(): ColumnsData<IngotTableData> {
    return getColumns(this);
  }

  get rows(): Array<IngotTableData> {
    return this.list.items.map((_, index) => {
      const key = this.list.keys[index];

      return {
        key: key,
        ingotNumber: key,
        weight: key,
        ligatureWeight: key,
        metalPurity: key,
        year: key,
        uin: key,
      };
    });
  }

  addIngot(): void {
    this.list.addEntity({
      key: nanoid(),
      entity: CustomerIngotModel.fromDefaultParams(),
    });
  }

  deleteIngot(key: string): void {
    if (this.list.length > 1) {
      this.list.removeEntity(key);
    }
  }

  private get _yearOrUinFields(): Array<BaseFieldModel<any>> {
    const yearOrUin = this.yearOrUin.value;

    return yearOrUin ? this.list.items.flatMap((item) => item.getYearOrUinFields(yearOrUin)) : [];
  }
}
