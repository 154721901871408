import { BaseResponse } from '@kts-front/types';

import { UserRole } from '@/entities/user';
import { LoadingStageModel } from '@/shared/model';

import {
  ICompanyDictionary,
  IDictionary,
  IDictionaryStore,
  IProductsStore,
  IProductTypeDictionary,
  ISignatoryDictionary,
  IUnitDictionary,
} from './baseClient';
import { ITickerStore } from './ticker';

export enum DictionaryPage {
  trade = 'trade',
  trades = 'trades',
  profile = 'profile',
  bids = 'bids',
  documents = 'documents',
  chats = 'chats',
  verification = 'verification',
}

export enum DictionaryField {
  suppliers = 'suppliers',
  customers = 'customers',
  countries = 'countries',
  currencies = 'currencies',
  legalCompanies = 'legalCompanies',
  logisticCompanies = 'logisticCompanies',
  productTypes = 'productTypes',
  products = 'products',
  signatories = 'signatories',
  tickers = 'tickers',
}

export type DictionaryFielsdArgs = Partial<{
  [DictionaryField.products]: Parameters<IProductsStore['load']>;
}>;

export interface IDictionariesStore {
  readonly legalCompanies: IDictionaryStore<IDictionary>;
  readonly logisticCompanies: IDictionaryStore<IDictionary>;
  readonly currencies: IDictionaryStore<IDictionary>;
  readonly productTypes: IDictionaryStore<IProductTypeDictionary>;
  readonly products: IProductsStore;
  readonly countries: IDictionaryStore<IUnitDictionary>;
  readonly suppliers: IDictionaryStore<ICompanyDictionary>;
  readonly customers: IDictionaryStore<ICompanyDictionary>;
  readonly signatories: IDictionaryStore<ISignatoryDictionary>;
  readonly tickers: ITickerStore;

  readonly loadingStage: LoadingStageModel;
  readonly isInitialLoading: boolean;

  loadCompanies(): Promise<BaseResponse>;
  loadDictionaries(fields: DictionaryField[], args?: DictionaryFielsdArgs): Promise<BaseResponse>;
  loadDictionariesByPage(page: DictionaryPage, userRole: UserRole): Promise<BaseResponse>;
  destroy(): void;
}

type DictionaryFielsdWithArgs = keyof DictionaryFielsdArgs;

const dictionariesWithArgs: Array<DictionaryFielsdWithArgs> = [DictionaryField.products];

export const isDictionaryFieldNeedArgs = (field: DictionaryField): field is DictionaryFielsdWithArgs => {
  return dictionariesWithArgs.some((fieldWithArgs) => fieldWithArgs === field);
};
