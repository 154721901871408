import { action, computed, makeObservable, observable } from 'mobx';

import { ShipmentType } from '@/entities/stage/types';
import { Nullable } from '@/shared/types/values';

import { BaseStepModel, BaseStepModelParams } from '../../../fieldsModel';

type Params<Step, Action> = BaseStepModelParams<Step, Action> & {
  shipmentType: Nullable<ShipmentType>;
};

type Payload<Action> = {
  action: Action;
  shipment_type: ShipmentType;
};

export class ShipmentTypeFieldsModel<Step, Action> extends BaseStepModel<Step, Action, Payload<Action>> {
  private _shipmentType: Nullable<ShipmentType> = null;

  constructor({ shipmentType, ...params }: Params<Step, Action>) {
    super(params);

    this._shipmentType = shipmentType;

    makeObservable<this, '_shipmentType'>(this, {
      _shipmentType: observable,

      shipmentType: computed,

      setTransitShipmentType: action.bound,
      setDirectShipmentType: action.bound,
    });
  }

  get shipmentType(): Nullable<ShipmentType> {
    return this._shipmentType;
  }

  toApproveJson() {
    if (!this.shipmentType) {
      return null;
    }

    return {
      action: this.approveAction,
      shipment_type: this.shipmentType,
    };
  }

  setTransitShipmentType() {
    this._shipmentType = ShipmentType.transit;
  }

  setDirectShipmentType() {
    this._shipmentType = ShipmentType.direct;
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<ShipmentType>;
  } & BaseStepModelParams<Step, Action>): ShipmentTypeFieldsModel<Step, Action> {
    return new ShipmentTypeFieldsModel({
      shipmentType: data ?? null,
      ...params,
    });
  }
}
