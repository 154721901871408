import { action, computed, makeObservable, observable, reaction } from 'mobx';

import { getRoleRouteKeyMap } from '@/app/routes/config';
import { IRootStore } from '@/app/store';
import { apiStore, apiUrls } from '@/shared/api';
import { RouteKey } from '@/shared/config/routes';
import { LocalStore, ToggleModel, ValueModel } from '@/shared/model';
import { isNullable } from '@/shared/types/typesGuard';
import { Nullable } from '@/shared/types/values';

import { IOnboardingStore, IPresentedElement } from '../types';

import { PagesModel } from './PagesModel';
import { BasePageModel } from './PagesModel/BasePageModel';
import { routesOrder } from './config';

type Params = {
  rootStore: IRootStore;
};

export class OnboardingStore extends LocalStore implements IOnboardingStore {
  readonly pages: PagesModel;

  private readonly _tooltipState = new ToggleModel();

  private readonly _currentPageIndex = new ValueModel<Nullable<number>>(null);
  private readonly _currentElementIndex = new ValueModel<Nullable<number>>(null);
  private readonly _rootStore: IRootStore;

  constructor({ rootStore }: Params) {
    super();

    this.pages = new PagesModel(rootStore);

    this._rootStore = rootStore;

    makeObservable(this, {
      pages: observable,

      currentElement: computed,
      showOnboardingButton: computed,
      isOnboarding: computed,
      hasPrevElement: computed,
      hasNextElement: computed,

      getPrevElement: action.bound,
      getNextElement: action.bound,
      startOnboarding: action.bound,
      stopOnboarding: action.bound,
      resetOnboarding: action.bound,
    });

    this.addReactions([
      reaction(
        () => this.showOnboardingButton && !this._userStore.onboardingDone,
        (startOnboarding) => {
          startOnboarding && this.startOnboarding();
        },
      ),
    ]);
  }

  get showOnboardingButton(): boolean {
    const { userModel, isClientAdmim, isCustomer, isSupplier } = this._userStore;

    if (isClientAdmim) {
      return Boolean(userModel?.verified);
    }

    return isCustomer || isSupplier;
  }

  get currentElement(): Nullable<IPresentedElement> {
    const currentElementIndex = this._currentElementIndex.value;
    const currentElement = isNullable(currentElementIndex) ? null : this._userPageElements[currentElementIndex];

    return currentElement;
  }

  get isOnboarding(): boolean {
    return this._tooltipState.isOpen;
  }

  get hasPrevElement(): boolean {
    const currentPageIndex = this._currentPageIndex.value;
    const currentElementIndex = this._currentElementIndex.value;

    if (isNullable(currentPageIndex) || isNullable(currentElementIndex)) {
      return false;
    }

    return currentPageIndex > 0 || currentElementIndex > 0;
  }

  get hasNextElement(): boolean {
    const currentPageIndex = this._currentPageIndex.value;
    const currentElementIndex = this._currentElementIndex.value;

    if (isNullable(currentPageIndex) || isNullable(currentElementIndex)) {
      return false;
    }

    return currentPageIndex < this._userPages.length - 1 || currentElementIndex < this._userPageElements.length - 1;
  }

  getPrevElement(): void {
    this.currentElement?.afterActionBackward?.();

    const currentPageIndex = this._currentPageIndex.value;
    const currentElementIndex = this._currentElementIndex.value;

    if (isNullable(currentPageIndex) || isNullable(currentElementIndex)) {
      return;
    }

    if (currentElementIndex > 0) {
      const prevElementIndex = currentElementIndex - 1;
      this._userPageElements[prevElementIndex].beforeAction?.();

      this._currentElementIndex.change(prevElementIndex);
    } else if (currentPageIndex > 0) {
      const prevPageIndex = currentPageIndex - 1;
      const prevPage = this._userPages[prevPageIndex];
      const prevElementIndex = prevPage.elements.length - 1;

      prevPage.goToPage();
      prevPage.elements.at(prevElementIndex)?.beforeAction?.();

      this._currentPageIndex.change(prevPageIndex);
      this._currentElementIndex.change(prevElementIndex);
    }
  }

  getNextElement(): void {
    this.currentElement?.afterActionForward?.();

    const currentPageIndex = this._currentPageIndex.value;
    const currentElementIndex = this._currentElementIndex.value;

    if (isNullable(currentPageIndex) || isNullable(currentElementIndex)) {
      return;
    }

    if (currentElementIndex < this._userPageElements.length - 1) {
      const nextElementIndex = currentElementIndex + 1;
      this._userPageElements[nextElementIndex].beforeAction?.();

      this._currentElementIndex.change(nextElementIndex);
    } else if (currentPageIndex < this._userPages.length - 1) {
      const nextPageIndex = currentPageIndex + 1;
      const nextPage = this._userPages[nextPageIndex];

      nextPage.goToPage();
      nextPage.elements.at(0)?.beforeAction?.();

      this._currentPageIndex.change(nextPageIndex);
      this._currentElementIndex.change(0);
    } else {
      this.stopOnboarding();
    }
  }

  startOnboarding(route: RouteKey = 'profile'): void {
    const pageIndex = this._userPages.findIndex((page) => page.routeKey === route);

    if (pageIndex !== -1) {
      const nextPage = this._userPages[pageIndex];
      nextPage.goToPage();
      nextPage.elements.at(0)?.beforeAction?.();

      this._currentPageIndex.change(pageIndex);
      this._currentElementIndex.change(0);
      this._tooltipState.open();
    }
  }

  stopOnboarding(): void {
    this._tooltipState.close();

    if (!this._userStore.onboardingDone) {
      apiStore
        .createRequest({
          method: 'POST',
          url: apiUrls.user.onboardingDone,
        })
        .call();
    }
  }

  resetOnboarding(): void {
    this._currentPageIndex.reset();
    this._currentElementIndex.reset();
  }

  private get _userPages(): Array<BasePageModel> {
    const { userRole, needVerifyCompany } = this._userStore;
    const availablePages = userRole ? getRoleRouteKeyMap(needVerifyCompany)[userRole] : [];

    return routesOrder.reduce<Array<BasePageModel>>((acc, routeKey) => {
      const page = this.pages[routeKey];

      if (availablePages.includes(routeKey) && page !== null) {
        acc.push(page);
      }

      return acc;
    }, []);
  }

  private get _userPageElements(): Array<IPresentedElement> {
    const currentPageIndex = this._currentPageIndex.value;
    const currentPage = isNullable(currentPageIndex) ? null : this._userPages[currentPageIndex];

    return currentPage?.elements ?? [];
  }

  private get _userStore(): IRootStore['userStore'] {
    return this._rootStore.userStore;
  }
}
