import { Tooltip } from 'antd';
import { Typography } from 'antd/lib';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';

import { TransComp } from '@/shared/ui';

import { AccountModel } from '../../model';
import { NotificationService } from '../../types';

import { accountTagProps } from './config';

import s from './AccountTag.module.scss';

type AccountTagProps = {
  className?: string;
  service: NotificationService;
  account: AccountModel;
};

const AccountTag: React.FC<AccountTagProps> = ({ className, service, account }) => {
  const tagProps = accountTagProps[service];

  if (!tagProps) {
    return null;
  }

  return (
    <Tooltip title={account.phone && formatPhoneNumberIntl(account.phone)}>
      <Typography.Link
        className={cn(s.tag, className)}
        href={account.link ?? undefined}
        target="_blank"
        style={{ backgroundColor: tagProps.color }}
      >
        {tagProps.icon}
        <TransComp>
          {account.accountName ?? ((t) => t(`settings.accounts.${service}`, { ns: 'notifications' }))}
        </TransComp>
      </Typography.Link>
    </Tooltip>
  );
};

export default observer(AccountTag);
