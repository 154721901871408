import { Typography } from 'antd';
import { TextProps } from 'antd/es/typography/Text';
import * as React from 'react';

import { TranslationNode } from '@/shared/types/localization';

import { TransComp } from '../TransComp';

type CustomTypographyTextProps = Omit<TextProps, 'children'> & {
  children: TranslationNode;
};

const CustomTypographyText = React.forwardRef<HTMLSpanElement, CustomTypographyTextProps>(
  ({ children, ...props }, ref) => {
    return (
      <Typography.Text ref={ref} {...props}>
        <TransComp>{children}</TransComp>
      </Typography.Text>
    );
  },
);

CustomTypographyText.displayName = 'CustomTypographyText';

export default CustomTypographyText;
