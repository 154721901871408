import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { SelectGenerationDocumentLanguage } from '@/entities/file';
import { CustomSelect } from '@/shared/ui/form/CustomSelect';
import { FormDatePicker } from '@/shared/ui/form/FormDatePicker';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormSelect } from '@/shared/ui/form/FormSelect';
import { FormUpload } from '@/shared/ui/form/FormUpload';

import { incotermOptions } from '../../config';
import { DeliveryConfirmationFieldsModel } from '../../model';

import s from './DeliveryConformation.module.scss';

type Props = {
  model: DeliveryConfirmationFieldsModel;
};

const DeliveryConfirmationForm: React.FC<Props> = ({ model }) => {
  const {
    docNumber,
    docCreateDate,
    tradeNumber,
    tradeCreateDate,
    metalPurity,
    gramPrice,
    currencyId,
    ouncePrice,
    datePayment,
    dateSupplies,
    incoterms,
    deliveryCountry,
    exportCountry,
    documentLanguage,
    exchangeRate,
    countIngot,
    supplierInitials,
    customerInitials,
    sealAndSignature,

    loadingState,
    currencyOptions,
  } = model;

  const { dictionariesStore } = useRootStore();

  return (
    <>
      <FormInput model={docNumber} disabled={loadingState.isLoading} />
      <FormDatePicker model={docCreateDate} disabled={loadingState.isLoading} />
      <FormInput model={tradeNumber} disabled={loadingState.isLoading} />
      <FormDatePicker model={tradeCreateDate} disabled={loadingState.isLoading} />
      <FormInput
        model={gramPrice}
        disabled={loadingState.isLoading}
        addonAfter={
          <CustomSelect
            className={s.select}
            popupClassName={s.select_popup}
            {...currencyId.props}
            options={currencyOptions}
            disabled={loadingState.isLoading}
          />
        }
      />
      <FormInput
        model={ouncePrice}
        disabled
        addonAfter={
          <CustomSelect
            className={s.select}
            popupClassName={s.select_popup}
            {...currencyId.props}
            options={currencyOptions}
            disabled
          />
        }
      />
      <FormDatePicker model={datePayment} disabled={loadingState.isLoading} />
      <FormDatePicker model={dateSupplies} disabled={loadingState.isLoading} />
      <FormSelect model={incoterms} options={incotermOptions} disabled={loadingState.isLoading} />
      <FormSelect
        model={deliveryCountry}
        options={dictionariesStore.countries.list.items}
        disabled={loadingState.isLoading}
      />
      <FormSelect
        model={exportCountry}
        options={dictionariesStore.countries.list.items}
        disabled={loadingState.isLoading}
      />
      <FormInput model={exchangeRate} disabled={!exchangeRate.required || loadingState.isLoading} />
      <FormInput model={countIngot} disabled={loadingState.isLoading} />
      <FormInput model={metalPurity} disabled={loadingState.isLoading} />
      <FormInput model={supplierInitials} disabled={loadingState.isLoading} />
      <FormInput model={customerInitials} disabled={loadingState.isLoading} />
      <FormUpload
        className={s.fullWidth}
        model={sealAndSignature}
        variant={sealAndSignature.isUploaded ? 'default' : 'dragger'}
        disabled={loadingState.isLoading}
      />
      <SelectGenerationDocumentLanguage
        className={cn(s.fullWidth, s.language)}
        model={documentLanguage}
        disabled={loadingState.isLoading}
      />
    </>
  );
};

export default observer(DeliveryConfirmationForm);
