import { computed, makeObservable } from 'mobx';

import { ValueModel } from '@/shared/model';
import { Nullable } from '@/shared/types/values';
import { getFullName } from '@/shared/utils/getFullName';

import { BaseUserServer, IBaseUser } from '../../types';

export type BaseUserModelParams = Omit<IBaseUser, 'fio'>;

export class BaseUserModel implements IBaseUser {
  readonly id: number;
  readonly name: string;
  readonly email: string;
  readonly surname: string;
  readonly patronymic: Nullable<string>;
  readonly phone: Nullable<string>;
  readonly newNotificationsCount: ValueModel<number>;
  readonly onboardingDone: boolean;

  constructor(data: BaseUserModelParams) {
    this.id = data.id;
    this.name = data.name;
    this.surname = data.surname;
    this.patronymic = data.patronymic;
    this.email = data.email;
    this.phone = data.phone;
    this.newNotificationsCount = data.newNotificationsCount;
    this.onboardingDone = data.onboardingDone;

    makeObservable(this, {
      fio: computed,
    });
  }

  get fio(): string {
    return getFullName(this);
  }

  static baseUserParamsFromJson(data: BaseUserServer): BaseUserModelParams {
    return {
      id: data.id,
      email: data.email,
      name: data.name,
      surname: data.surname,
      patronymic: data.patronymic,
      phone: data.phone,
      newNotificationsCount: new ValueModel(data.new_notifications_count),
      onboardingDone: data.onboarding_done,
    };
  }
}
