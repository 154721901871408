import { computed, makeObservable } from 'mobx';

import { DatePickerModel } from '@/shared/model/form/DatePickerModel';
import { Nullable } from '@/shared/types/values';
import { formatDate } from '@/shared/utils';
import { emptyValueValidator } from '@/shared/utils/validators';

import { BaseStepModel, BaseStepModelParams } from '../BaseStepModel';

type Params<Step, Action> = BaseStepModelParams<Step, Action> & {
  date: DatePickerModel;
};

type SelectDatePayload<Action> = {
  action: Action;
  date: string;
};

export class SelectDateStepModel<
  Step,
  Action,
  Payload extends SelectDatePayload<Action> = SelectDatePayload<Action>,
> extends BaseStepModel<Step, Action, Payload> {
  readonly date: DatePickerModel;

  constructor({ date, ...params }: Params<Step, Action>) {
    super(params);
    this.date = date;

    makeObservable(this, {
      approveDisabled: computed,
    });
  }

  get approveDisabled(): boolean {
    return !this.date.value;
  }

  toApproveJson() {
    if (!this.date.value) return null;

    const date = formatDate(this.date.value);

    return {
      action: this.approveAction,
      date,
    } as Payload;
  }

  static fromJson<Step, Action>({
    data,
    ...params
  }: {
    data?: Nullable<Date>;
  } & BaseStepModelParams<Step, Action>): SelectDateStepModel<Step, Action> {
    return new SelectDateStepModel({
      date: new DatePickerModel({
        initialValue: data || null,
        required: true,
        placeholder: (t) => t('placeholders.date', { ns: 'shared' }),
        validators: [emptyValueValidator()],
        ignoreOnBlurValidation: true,
      }),
      ...params,
    });
  }
}
