import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { InputModel } from '@/shared/model/form/InputModel';
import { Nullable } from '@/shared/types/values';
import { numberParser } from '@/shared/utils';
import { emailValidator, emptyValueValidator, phoneValidator, stringLengthValidator } from '@/shared/utils/validators';

import { CompanyInfoStepPayload, CompanyInfoStepServer, VerificationStep } from '../../types/server';
import { BaseVerificationStepModel } from '../BaseVerificationStepModel';

type Params = {
  inn: InputModel;
  nameRu: InputModel;
  slugRu: InputModel;
  nameEn: InputModel;
  addressRu: InputModel;
  addressEn: InputModel;
  location: InputModel;
  phone: InputModel;
  email: InputModel;
  director: InputModel;
  directorAddress: InputModel;
  registrationNumber: InputModel;
  bankName: InputModel;
  bankAccount: InputModel;
  bic: InputModel;
  kpp: InputModel;
  correspondentAccount: InputModel;
  currency: InputModel;
};

const T_OPTIONS = { ns: 'verification' } as const;

export class CompanyInfoStepModel extends BaseVerificationStepModel<
  VerificationStep.companyInfo,
  CompanyInfoStepPayload
> {
  readonly inn: InputModel;
  readonly nameRu: InputModel;
  readonly slugRu: InputModel;
  readonly nameEn: InputModel;
  readonly addressRu: InputModel;
  readonly addressEn: InputModel;
  readonly location: InputModel;
  readonly email: InputModel;
  readonly phone: InputModel;
  readonly director: InputModel;
  readonly directorAddress: InputModel;
  readonly registrationNumber: InputModel;
  readonly bankName: InputModel;
  readonly bankAccount: InputModel;
  readonly bic: InputModel;
  readonly kpp: InputModel;
  readonly correspondentAccount: InputModel;
  readonly currency: InputModel;

  constructor(params: Params) {
    super({ step: VerificationStep.companyInfo });

    this.inn = params.inn;
    this.nameRu = params.nameRu;
    this.slugRu = params.slugRu;
    this.nameEn = params.nameEn;
    this.addressRu = params.addressRu;
    this.addressEn = params.addressEn;
    this.location = params.location;
    this.phone = params.phone;
    this.email = params.email;
    this.director = params.director;
    this.directorAddress = params.directorAddress;
    this.registrationNumber = params.registrationNumber;
    this.bankName = params.bankName;
    this.bankAccount = params.bankAccount;
    this.bic = params.bic;
    this.kpp = params.kpp;
    this.correspondentAccount = params.correspondentAccount;
    this.currency = params.currency;
  }

  toJson(): Partial<CompanyInfoStepPayload> {
    const inn = this.inn.value;
    const name_ru = this.nameRu.value;
    const slug_ru = this.slugRu.value;
    const name_en = this.nameEn.value;
    const address_ru = this.addressRu.value;
    const address_en = this.addressEn.value;
    const location = this.location.value;
    const email = this.email.value;
    const phone = this.phone.value;
    const director = this.director.value;
    const director_address = this.directorAddress.value;
    const registration_number = this.registrationNumber.value;
    const bank_name = this.bankName.value;
    const bank_account = this.bankAccount.value && Number(this.bankAccount.value);
    const bic = this.bic.value && Number(this.bic.value);
    const kpp = this.kpp.value;
    const correspondent_account = this.correspondentAccount.value && Number(this.correspondentAccount.value);
    const currency = this.currency.value;

    return {
      ...(inn ? { inn } : {}),
      ...(name_ru ? { name_ru } : {}),
      ...(slug_ru ? { slug_ru } : {}),
      ...(name_en ? { name_en } : {}),
      ...(address_ru ? { address_ru } : {}),
      ...(address_en ? { address_en } : {}),
      ...(location ? { location } : {}),
      ...(email ? { email } : {}),
      ...(phone ? { phone } : {}),
      ...(director ? { director } : {}),
      ...(director_address ? { director_address } : {}),
      ...(registration_number ? { registration_number } : {}),
      ...(bank_name ? { bank_name } : {}),
      ...(bank_account ? { bank_account } : {}),
      ...(bic ? { bic } : {}),
      ...(kpp ? { kpp } : {}),
      ...(correspondent_account ? { correspondent_account } : {}),
      ...(currency ? { currency } : {}),
    };
  }

  protected get _fields(): Array<BaseFieldModel<any>> {
    return [
      this.inn,
      this.nameRu,
      this.slugRu,
      this.nameEn,
      this.addressRu,
      this.addressEn,
      this.location,
      this.kpp,
      this.phone,
      this.email,
      this.director,
      this.directorAddress,
      this.registrationNumber,
      this.bankName,
      this.bankAccount,
      this.currency,
      this.bic,
      this.correspondentAccount,
    ];
  }

  static fromJson(server: Nullable<CompanyInfoStepServer>): CompanyInfoStepModel {
    return new CompanyInfoStepModel({
      inn: new InputModel({
        initialValue: server?.inn ?? '',
        label: (t) => t('content.companyInfoStep.inn', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      nameRu: new InputModel({
        initialValue: server?.name_ru ?? '',
        label: (t) => t('content.companyInfoStep.nameRu', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      slugRu: new InputModel({
        initialValue: server?.slug_ru ?? '',
        label: (t) => t('content.companyInfoStep.slugRu', T_OPTIONS),
        ignoreOnBlurValidation: true,
        validators: [stringLengthValidator(128)],
      }),
      nameEn: new InputModel({
        initialValue: server?.name_en ?? '',
        label: (t) => t('content.companyInfoStep.nameEn', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      addressRu: new InputModel({
        initialValue: server?.address_ru ?? '',
        label: (t) => t('content.companyInfoStep.addressRu', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      addressEn: new InputModel({
        initialValue: server?.address_en ?? '',
        label: (t) => t('content.companyInfoStep.addressEn', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      location: new InputModel({
        initialValue: server?.location ?? '',
        label: (t) => t('content.companyInfoStep.location', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      phone: new InputModel({
        initialValue: server?.phone ?? '',
        label: (t) => t('content.companyInfoStep.phone', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), phoneValidator],
      }),
      email: new InputModel({
        initialValue: server?.email ?? '',
        label: (t) => t('content.companyInfoStep.email', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), emailValidator],
      }),
      director: new InputModel({
        initialValue: server?.director ?? '',
        label: (t) => t('content.companyInfoStep.director', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      directorAddress: new InputModel({
        initialValue: server?.director_address ?? '',
        label: (t) => t('content.companyInfoStep.directorAddress', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      registrationNumber: new InputModel({
        initialValue: server?.registration_number ?? '',
        label: (t) => t('content.companyInfoStep.registrationNumber', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      bankName: new InputModel({
        initialValue: server?.bank_name ?? '',
        label: (t) => t('content.companyInfoStep.bank', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      bankAccount: new InputModel({
        initialValue: String(server?.bank_account ?? ''),
        parser: numberParser({ maxFractionDigits: 0 }),
        label: (t) => t('content.companyInfoStep.bankAccount', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      currency: new InputModel({
        initialValue: server?.currency ?? '',
        label: (t) => t('content.companyInfoStep.currency', T_OPTIONS),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      bic: new InputModel({
        initialValue: String(server?.bic ?? ''),
        label: (t) => t('content.companyInfoStep.bic', T_OPTIONS),
        parser: numberParser({ maxFractionDigits: 0 }),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      kpp: new InputModel({
        initialValue: server?.kpp ?? '',
        label: (t) => t('content.companyInfoStep.kpp', T_OPTIONS),
        parser: numberParser({ maxFractionDigits: 0 }),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
      correspondentAccount: new InputModel({
        initialValue: String(server?.correspondent_account ?? ''),
        label: (t) => t('content.companyInfoStep.correspondentAccount', T_OPTIONS),
        parser: numberParser({ maxFractionDigits: 0 }),
        ignoreOnBlurValidation: true,
        required: true,
        validators: [emptyValueValidator(), stringLengthValidator(128)],
      }),
    });
  }
}
