import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { SelectGenerationDocumentLanguageModel } from '@/entities/file/model';
import { FormSelect } from '@/shared/ui/form/FormSelect';

import s from './SelectGenerationDocumentLanguage.module.scss';

type SelectGenerationDocumentLanguageProps = {
  className?: string;
  disabled?: boolean;
  model: SelectGenerationDocumentLanguageModel;
};

const SelectGenerationDocumentLanguage: React.FC<SelectGenerationDocumentLanguageProps> = ({
  className,
  model,
  disabled,
}) => {
  return (
    <div className={cn(s.select_wrapper, className)}>
      <FormSelect model={model} className={s.select} options={model.options} disabled={disabled} />
    </div>
  );
};

export default observer(SelectGenerationDocumentLanguage);
