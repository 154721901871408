import cn from 'classnames';
import * as React from 'react';

import s from './Logo.module.scss';

type LogoProps = {
  size?: 16 | 32;
  className?: string;
};

const Logo = ({ size = 16, className }: LogoProps): React.ReactElement<LogoProps> => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={cn(s['logo'], s[`logo_size-${size}`], className)}
      width="44"
      height="24"
      fill="none"
      viewBox="0 0 44 24"
    >
      <path
        d="M0 10.6883H13.6137V24H24.2638V7.78789H21.4737V21.2008H16.4029V7.88827H2.78927V2.79923H38.0476V0H0V10.6883Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default Logo;
