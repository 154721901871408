import { observer } from 'mobx-react-lite';
import * as React from 'react';
import { useMemo } from 'react';
import { useLocation } from 'react-router';

import { routerUrls } from '@/shared/config/routes';
import { Loader } from '@/shared/ui';
import { getPathname } from '@/shared/utils';

import { getDefaultRoleRoute, getRoleRouteMap } from '../routes';
import { useRootStore } from '../store';

type WithAuthProps<P> = {
  Component: React.ComponentType<P>;
  needAuth?: boolean;
};

export const withAuthCheck = <P extends Record<string, unknown>>({ Component, needAuth = true }: WithAuthProps<P>) =>
  observer((props: P) => {
    const { userStore, routerStore, centrifugeStore } = useRootStore();
    const { userLoadingStage, authorized, notStartedVerifyingCompany, needVerifyCompany, userRole } = userStore;
    const location = useLocation();
    const rootPathname = getPathname(location);

    React.useEffect(() => {
      if (!authorized) {
        centrifugeStore.disconnect();
      }
    }, [authorized]);

    const redirectUrl = useMemo(() => {
      /**
       * Если мы в процессе загрузки,
       * или если это страница логина и требуется проверка компании,
       * то не надо редиректить
       */
      if (
        userLoadingStage.isLoading ||
        userLoadingStage.isNotStarted ||
        (notStartedVerifyingCompany && (rootPathname === 'login' || rootPathname === 'create_password'))
      ) {
        return null;
      }

      if (authorized && userRole) {
        const defaultAuthorizedPage = getDefaultRoleRoute(needVerifyCompany)[userRole];
        const roleRoutes = getRoleRouteMap(needVerifyCompany)[userRole];

        if (needAuth) {
          /** Если пользователь с этой ролью не может видеть эту страницу, то редиректим на дефолтную страницу для его роли */
          if (!roleRoutes.includes(rootPathname)) {
            return defaultAuthorizedPage;
          }

          return null;
        }

        /** Если авторизованы и страница отображается только для неавторизованных, то редиректим на дефолтную страницу для его роли */
        return defaultAuthorizedPage;
      }

      /** Если не авторизованы и нужна авторизация, то редиректим на логин */
      if (needAuth) {
        return routerUrls.login.create();
      }

      return null;
    }, [userLoadingStage.isNotStarted, userLoadingStage.isLoading, authorized, notStartedVerifyingCompany, userRole]);

    React.useEffect(() => {
      if (redirectUrl) {
        routerStore.navigate(redirectUrl);
      }
    }, [redirectUrl]);

    if (userLoadingStage.isLoading || redirectUrl) {
      return <Loader />;
    }

    return <Component {...props} />;
  });
