import { Flex } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { useTypedTranslation, useWidth } from '@/shared/hooks';
import { CustomDrawer } from '@/shared/ui';

import ExpandableTicker from '../ExpandableTicker';
import TicketDetails from '../TicketDetails';

import s from './TickerDrawer.module.scss';

const TickerDrawer: React.FC = () => {
  const { dictionariesStore } = useRootStore();
  const { t } = useTypedTranslation('dictionary');
  const { isMobile, isTablet } = useWidth();

  const { list, currentTickerHistory, isOpenDrawer, closeDrawer } = dictionariesStore.tickers;
  const { tickerType } = currentTickerHistory;

  return (
    <CustomDrawer
      rootClassName={s.drawer}
      open={isOpenDrawer}
      onClose={closeDrawer}
      title={t('tickerDrawer.title')}
      width={isMobile ? '100%' : tickerType ? 1000 : 400}
      footer={null}
    >
      <Flex className={s.drawer_content}>
        <Flex className={s.drawer_content_list} vertical gap={4} flex={isTablet ? 1 : 1.25}>
          {list.items.map((ticker) => (
            <ExpandableTicker key={ticker.tickerType} ticker={ticker} tickerStore={dictionariesStore.tickers} />
          ))}
        </Flex>
        {!isMobile && (
          <TicketDetails currentTicker={tickerType && list.getEntity(tickerType)} historyModel={currentTickerHistory} />
        )}
      </Flex>
    </CustomDrawer>
  );
};

export default observer(TickerDrawer);
