import { ResidentType, VerificationDocumentType, VerificationStatus, VerificationStep } from '@/entities/verification';
import { ApiErrorCode } from '@/shared/types/api';

export const verification = {
  en: {
    header: {
      title: 'KYC verification',
      steps: {
        [VerificationStep.residency]: 'Residency',
        [VerificationStep.questionnaire]: 'KYC Questionnaire',
        [VerificationStep.statutoryDocs]: 'Statutory Documents',
        [VerificationStep.companyInfo]: 'Company Information',
        [VerificationStep.verificationStatus]: 'Verification Status',
      },
    },
    content: {
      prevStep: 'Previous step',
      nextStep: 'Next step',
      submit: 'Submit for review',

      residencyStep: {
        title: 'Select residency status',
        description: 'Please provide your residency status and country of incorporation to continue the process.',
        residency: 'Residency status',
        countryId: 'Country of company registration',
      },
      verificationStatusStep: {
        title: 'Document verification status',
        description: 'Once your documents are verified, a status notification will be sent to your email.',
        table: {
          title: 'History of checks',
          dataTime: 'Date of dispatch',
          status: 'Status',
          comments: "Reviewer's comment",
        },
      },

      questionnaireStep: {
        title: 'Filling out the KYC questionnaire',
        description: 'Download the blank of questionnaire, fill it out and upload the document.',
        downloadForm: 'Download the blank',
        uploadForm: 'Upload the questionnaire',
      },

      statutoryDocsStep: {
        title: 'Uploading statutory documents',
        description:
          'Before uploading documents, please ensure that they are correct and meet the requirements to avoid delays in verification.',
      },

      companyInfoStep: {
        title: 'Filling out a company card',
        description: 'Fill in your company information manually.',
        inn: 'INN / TRN',
        nameRu: 'Full name in Russian',
        slugRu: 'Short name in Russian',
        nameEn: 'Name in English',
        addressRu: 'Legal and postal address',
        addressEn: 'Legal and postal address in English',
        location: 'Location address',
        phone: 'Phone number',
        email: 'Email',
        director: 'Director',
        directorAddress: "Director's Registration Address",
        registrationNumber: 'Registration number',
        bank: 'Name of the bank',
        bankAccount: 'Bank account number',
        currency: 'Currency',
        bic: 'BIC',
        kpp: 'KPP',
        correspondentAccount: 'Correspondent account number',
      },
    },
    submitModal: {
      title: 'Submit for review',
      description: 'After sending, editing will not be available. Make sure all data is filled in correctly.',
    },
    support: {
      forQuestions: 'For questions:',
    },
    notCompletedModal: {
      title: 'Verification not completed',
      description:
        'You are leaving the KYC verification page, but your verification is not yet complete. Complete the verification to gain full access to the platform.',
      buttonStay: 'Stay',
      buttonOut: 'Log out',
    },
    residentType: {
      [ResidentType.resident]: 'Resident of the Russian Federation',
      [ResidentType.nonResident]: 'Non-resident',
    },
    docType: {
      [VerificationDocumentType.questionnaire]: 'KYC questionnaire',
      [VerificationDocumentType.companyCharter]: 'Company Charter',
      [VerificationDocumentType.unifiedStateRegisterExtract]: 'Extract from the Unified State Register',
      [VerificationDocumentType.directorAppointOrder]: 'Order on the appointment of the Director',
      [VerificationDocumentType.directorPassport]: 'Passport of the director/founder',
      [VerificationDocumentType.leaseAgreement]: 'Lease Agreement',
      [VerificationDocumentType.memorandumAssociation]: 'Memorandum of Association',
      [VerificationDocumentType.directorAuthorityCertificate]: 'Certificate of Authority of the Director',
      [VerificationDocumentType.tradeRegisterExtract]: 'Extract from the trade register',
      [VerificationDocumentType.stateRegistrationCertificate]: 'Certificate of state registration',
      [VerificationDocumentType.companyEstablishDecision]: 'Decision to establish a Company',
      [VerificationDocumentType.activitiesLicense]: 'License for the right to carry out activities',
      [VerificationDocumentType.attorneyPower]: 'Power of attorney',
      [VerificationDocumentType.directorAppointOrderOrDecision]:
        'Order on the appointment of the director or decision on the appointment of the director',
      [VerificationDocumentType.businessReputationReview]: 'Business reputation review',
    },
    messages: {
      [ApiErrorCode.existsRejectedDocuments]: {
        title: null,
        description: null,
      },
      saveStepError: null,
      submitSuccessTitle: 'Documents have been successfully sent for verification!',
      submitSuccessDescription: 'Follow the change in the status of your response in this section.',
      submitSuccessError: 'An error occurred while sending documents',
    },
    verificationStatus: {
      [VerificationStatus.inProgress]: 'In progress',
      [VerificationStatus.rejected]: 'Test failed',
      [VerificationStatus.verified]: 'Test passed',
      [VerificationStatus.pending]: 'Under consideration',
    },
  },
  ru: {
    header: {
      title: 'Верификация KYC',
      steps: {
        [VerificationStep.residency]: 'Резидентство',
        [VerificationStep.questionnaire]: 'Анкета KYC',
        [VerificationStep.statutoryDocs]: 'Уставные документы',
        [VerificationStep.companyInfo]: 'Информация о компании',
        [VerificationStep.verificationStatus]: 'Статус верификации',
      },
    },
    content: {
      prevStep: 'Предыдущий шаг',
      nextStep: 'Следующий шаг',
      submit: 'Отправить на проверку',

      residencyStep: {
        title: 'Выбор статуса резидентства',
        description: 'Укажите статус резидентства и страну регистрации компании, чтобы продолжить процесс.',
        residency: 'Статус резидентства',
        countryId: 'Cтрана регистрации компании',
      },
      verificationStatusStep: {
        title: 'Статус проверки документов',
        description: 'После завершения проверки документов на вашу почту будет отправлено уведомление о статусе.',
        table: {
          title: 'История проверок',
          dataTime: 'Дата отправки',
          status: 'Статус',
          comments: 'Комментарий проверяющего',
        },
      },

      questionnaireStep: {
        title: 'Заполнение анкеты KYC',
        description: 'Скачайте бланк анкеты, заполните его и загрузите документ.',
        downloadForm: 'Скачайте бланк',
        uploadForm: 'Загрузите анкету',
      },

      statutoryDocsStep: {
        title: 'Загрузка уставных документов',
        description:
          'Перед загрузкой документов убедитесь в их правильности и соответствии требованиям, чтобы избежать задержек в верификации.',
      },

      companyInfoStep: {
        title: 'Заполнение карточки компании',
        description: 'Заполните вручную информацию о вашей компании.',
        inn: 'ИНН / TRN',
        nameRu: 'Полное наименование на русском языке',
        slugRu: 'Сокращенное наименование на русском языке',
        nameEn: 'Наименование на английском языке',
        addressRu: 'Юридический и почтовый адрес',
        addressEn: 'Юридический и почтовый адрес на английском',
        location: 'Адрес местонахождения',
        phone: 'Номер телефона',
        email: 'Электронная почта',
        director: 'Директор',
        directorAddress: 'Адрес регистрации Директора',
        registrationNumber: 'Номер регистрации',
        bank: 'Наименование банка',
        bankAccount: 'Расчетный счет',
        currency: 'Валюта',
        bic: 'БИК',
        kpp: 'КПП',
        correspondentAccount: 'Корреспондентский счет',
      },
    },
    submitModal: {
      title: 'Отправить на проверку',
      description: 'После отправки редактирование будет недоступно. Убедитесь что все данные заполнены правильно.',
    },
    support: {
      forQuestions: 'По вопросам:',
    },
    notCompletedModal: {
      title: 'Верификация не завершена',
      description:
        'Вы покидаете страницу проверки KYC, но ваша верификация еще не завершена. Пройдите проверку до конца, чтобы получить полный доступ к платформе.',
      buttonStay: 'Остаться',
      buttonOut: 'Выйти',
    },
    residentType: {
      [ResidentType.resident]: 'Резидент РФ',
      [ResidentType.nonResident]: 'Нерезидент',
    },
    docType: {
      [VerificationDocumentType.questionnaire]: 'Анкета KYC',
      [VerificationDocumentType.companyCharter]: 'Устав компании',
      [VerificationDocumentType.unifiedStateRegisterExtract]: 'Выписка из единого государственного реестра',
      [VerificationDocumentType.directorAppointOrder]: 'Приказ о назначении Директора',
      [VerificationDocumentType.directorPassport]: 'Паспорт директора/учредителя',
      [VerificationDocumentType.leaseAgreement]: 'Договор аренды',
      [VerificationDocumentType.memorandumAssociation]: 'Учредительный договор',
      [VerificationDocumentType.directorAuthorityCertificate]: 'Свидетельство о полномочиях директора',
      [VerificationDocumentType.tradeRegisterExtract]: 'Выписка из торгового реестра',
      [VerificationDocumentType.stateRegistrationCertificate]: 'Свидетельство о государственной регистрации',
      [VerificationDocumentType.companyEstablishDecision]: 'Решение о создании Компании',
      [VerificationDocumentType.activitiesLicense]: 'Лицензия на право осуществления деятельности',
      [VerificationDocumentType.attorneyPower]: 'Доверенность',
      [VerificationDocumentType.directorAppointOrderOrDecision]:
        'Приказ о назначении директора или решение о назначении директора',
      [VerificationDocumentType.businessReputationReview]: 'Отзыв о деловой репутации',
    },
    messages: {
      [ApiErrorCode.existsRejectedDocuments]: {
        title: 'Ошибка при сохранении шага',
        description: 'Убедитесь, что внесли все необходимые правки на предыдущих шагах.',
      },
      saveStepError: 'При сохранении шага произошла ошибка',
      submitSuccessTitle: 'Документы успешно отправлены на проверку!',
      submitSuccessDescription: 'Следите за изменением статуса вашего отклика в данном разделе.',
      submitSuccessError: 'При отправке документов произошла ошибка',
    },
    verificationStatus: {
      [VerificationStatus.inProgress]: 'В процессе',
      [VerificationStatus.rejected]: 'Проверка не пройдена',
      [VerificationStatus.verified]: 'Проверка пройдена',
      [VerificationStatus.pending]: 'На рассмотрении',
    },
  },
  zh: {
    header: {
      title: null,
      steps: {
        [VerificationStep.residency]: null,
        [VerificationStep.questionnaire]: null,
        [VerificationStep.statutoryDocs]: null,
        [VerificationStep.companyInfo]: null,
        [VerificationStep.verificationStatus]: null,
      },
    },
    content: {
      prevStep: null,
      nextStep: null,
      submit: null,

      residencyStep: {
        title: null,
        description: null,
        residency: null,
        countryId: null,
      },
      verificationStatusStep: {
        title: null,
        description: null,
        table: {
          title: null,
          dataTime: null,
          status: null,
          comments: null,
        },
      },

      questionnaireStep: {
        title: null,
        description: null,
        downloadForm: null,
        uploadForm: null,
      },

      statutoryDocsStep: {
        title: null,
        description: null,
      },

      companyInfoStep: {
        title: null,
        description: null,
        inn: null,
        nameRu: null,
        slugRu: null,
        nameEn: null,
        addressRu: null,
        addressEn: null,
        location: null,
        phone: null,
        email: null,
        director: null,
        directorAddress: null,
        registrationNumber: null,
        bank: null,
        bankAccount: null,
        currency: null,
        bic: null,
        kpp: null,
        correspondentAccount: null,
      },
    },
    submitModal: {
      title: null,
      description: null,
    },
    support: {
      forQuestions: null,
    },
    notCompletedModal: {
      title: null,
      description: null,
      buttonStay: null,
      buttonOut: null,
    },
    residentType: {
      [ResidentType.resident]: null,
      [ResidentType.nonResident]: null,
    },
    docType: {
      [VerificationDocumentType.questionnaire]: null,
      [VerificationDocumentType.companyCharter]: null,
      [VerificationDocumentType.unifiedStateRegisterExtract]: null,
      [VerificationDocumentType.directorAppointOrder]: null,
      [VerificationDocumentType.directorPassport]: null,
      [VerificationDocumentType.leaseAgreement]: null,
      [VerificationDocumentType.memorandumAssociation]: null,
      [VerificationDocumentType.directorAuthorityCertificate]: null,
      [VerificationDocumentType.tradeRegisterExtract]: null,
      [VerificationDocumentType.stateRegistrationCertificate]: null,
      [VerificationDocumentType.companyEstablishDecision]: null,
      [VerificationDocumentType.activitiesLicense]: null,
      [VerificationDocumentType.attorneyPower]: null,
      [VerificationDocumentType.directorAppointOrderOrDecision]: null,
      [VerificationDocumentType.businessReputationReview]: null,
    },
    messages: {
      [ApiErrorCode.existsRejectedDocuments]: {
        title: null,
        description: null,
      },
      saveStepError: null,
      submitSuccessTitle: null,
      submitSuccessDescription: null,
      submitSuccessError: null,
    },
    verificationStatus: {
      [VerificationStatus.inProgress]: null,
      [VerificationStatus.rejected]: null,
      [VerificationStatus.verified]: null,
      [VerificationStatus.pending]: null,
    },
  },
};
