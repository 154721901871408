import { ApiRequest } from '@kts-front/call-api';
import { action, computed, makeObservable } from 'mobx';

import { MessageType } from '@/entities/message';
import { ITradeWorkflowStore } from '@/entities/trade';
import { apiStore, apiUrls } from '@/shared/api';
import { LoadingStageModel } from '@/shared/model';
import { DatePickerModel, DatePickerModelParams } from '@/shared/model/form/DatePickerModel';
import { DateFormat } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';

import { OptionFieldsAction } from '../../types';

import { getTextParams } from './config';

type ExpectedDeliveryTimeModelParams = Omit<DatePickerModelParams, 'normalizationFormat'> & {
  tradeWorkflowStore: ITradeWorkflowStore;
};

type ExpectedDeliveryTimeModelPayload = {
  action: OptionFieldsAction.setDeliveryTime;
  etd?: string;
};

export class ExpectedDeliveryTimeModel extends DatePickerModel {
  readonly loadingStage = new LoadingStageModel();
  readonly tradeWorkflowStore: ITradeWorkflowStore;

  private readonly _request: ApiRequest<unknown>;

  constructor({ tradeWorkflowStore, ...params }: ExpectedDeliveryTimeModelParams) {
    super({
      ...params,
      normalizationFormat: DateFormat.dateTimeSec,
    });

    this._request = apiStore.createRequest({
      method: 'POST',
      multipartFormData: true,
      url: apiUrls.trade.action(tradeWorkflowStore.tradeId),
    });

    this.tradeWorkflowStore = tradeWorkflowStore;

    makeObservable(this, {
      approveDisabled: computed,

      submit: action.bound,
    });
  }

  get approveDisabled(): boolean {
    return !this.initialValue && !this.value;
  }

  private _toJson(): Nullable<ExpectedDeliveryTimeModelPayload> {
    /**
     * Если не было первоначального значения, то не можем удалить на бэке
     */
    if (!this.initialValue && !this.value) {
      return null;
    }

    /**
     * Для удаления etd отправляем только action
     */
    return {
      action: OptionFieldsAction.setDeliveryTime,
      ...(this.value ? { etd: this.value.toISOString() } : {}),
    };
  }

  async submit(): Promise<void> {
    const payload = this._toJson();

    if (this.loadingStage.isLoading || !payload) {
      return;
    }

    this.loadingStage.loading();

    const response = await this._request.call({
      data: payload,
    });

    if (response.isError) {
      this.loadingStage.error();
      this.tradeWorkflowStore.rootStore.notificationsStore.addNotification({
        type: MessageType.error,
        message: (t) => t('optionalFields.messages.saveDataError', { ns: 'stage' }),
      });

      return;
    }

    this.tradeWorkflowStore.rootStore.notificationsStore.addNotification({
      type: MessageType.success,
      message: (t) => t('optionalFields.messages.saveDataSuccess', { ns: 'stage' }),
    });

    this.loadingStage.success();

    this.tradeWorkflowStore.reloadStage();
  }

  static fromStore(tradeWorkflowStore: ITradeWorkflowStore, disabled: boolean): ExpectedDeliveryTimeModel {
    const { tradeInfo, rootStore } = tradeWorkflowStore;

    return new ExpectedDeliveryTimeModel({
      ...getTextParams(rootStore.userStore.companyType, tradeInfo.etdDate),
      initialValue: tradeInfo.etdDate,
      disabled,
      tradeWorkflowStore,
    });
  }
}
