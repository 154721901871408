import { Radio } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { useTypedTranslation } from '@/shared/hooks';
import { CustomAlert, StepDescription } from '@/shared/ui';
import { FormInput } from '@/shared/ui/form/FormInput';
import { FormUpload } from '@/shared/ui/form/FormUpload';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { UploadAwbOrFlightNumberStepModel } from '../../model';
import { AwbType, StageModel, StepComponentProps } from '../../types';

import s from './AwbOrFlightNumberStep.module.scss';

type AwbOrFlightNumberStepProps<StageModel> = StepComponentProps<StageModel>;

const AwbOrFlightNumberStep = <Model extends StageModel>({ model, stepKey }: AwbOrFlightNumberStepProps<Model>) => {
  const { userStore } = useRootStore();
  const { t } = useTypedTranslation('stage');

  const step = stepKey && model[stepKey];

  if (!(step instanceof UploadAwbOrFlightNumberStepModel)) {
    return null;
  }

  return (
    <StepDescription>
      <Radio.Group
        size="small"
        className={s.radio}
        disabled={step.isDisabled}
        defaultValue={step.awbType.value}
        onChange={(e) => {
          step.awbType.change(e.target.value);
          step.reset();
        }}
      >
        <Radio.Button value={AwbType.file}>{t('requiredStep.awbDoc.buttonFile')}</Radio.Button>
        <Radio.Button value={AwbType.number}> {t('requiredStep.awbDoc.buttonNumber')}</Radio.Button>
      </Radio.Group>

      {step.awbType.value === AwbType.file ? (
        <>
          {userStore.isSupplier && step.isRejected && (
            <CustomAlert
              type="error"
              message={(t) => t('messages.rejectedDocError', { ns: 'file' })}
              description={step.error}
            />
          )}
          <FormUpload model={step.files} disabled={step.isDisabled || step.isLoading} />
        </>
      ) : (
        <FormInput model={step.flightNumber} disabled={step.isDisabled} />
      )}
      <ApproveButton
        loading={step.isLoading}
        disabled={step.isDisabled || step.approveDisabled}
        onClick={step.approveStep}
      />
    </StepDescription>
  );
};

export default observer(AwbOrFlightNumberStep);
