import i18next from 'i18next';

import { isString, stringIsNumber } from '../types/typesGuard';

import { fixNumberDecimals } from './fixNumberDecimals';

type FormatNumberOptions = {
  decimals?: number;
  language?: string;
  unit?: string;
};

const defaultOptions: FormatNumberOptions = {
  decimals: 2,
  language: i18next.language,
};

export const formatNumber = (number: number, options?: FormatNumberOptions): string => {
  /**
   * Проверка на случай, если number не является числом
   * (иногда приходит с бэка строка вместо числа)
   * */
  if (isString(number)) {
    if (stringIsNumber(number)) {
      number = Number(number);
    } else {
      throw new Error('formatNumber: number не является числом');
    }
  }

  const { decimals, language, unit } = { ...defaultOptions, ...options };

  const formattedNumber = fixNumberDecimals(number, decimals).toLocaleString(language, {
    minimumFractionDigits: decimals,
  });

  return unit ? `${formattedNumber}\u00A0${unit}` : formattedNumber;
};

/**
 * @deprecated Утилита будет удалена, вместо нее использовать formatNumber
 * */
export const formatNumberWithUnit = (number: number, unit: string, decimals: number = 2): string => {
  /**
   * Проверка на случай, если number не является числом
   * (иногда приходит с бэка строка вместо числа)
   * */
  if (isString(number)) {
    if (stringIsNumber(number)) {
      number = Number(number);
    } else {
      throw new Error('formatNumberWithUnit: number не является числом');
    }
  }

  const formattedNum = fixNumberDecimals(number, decimals).toLocaleString(i18next.language, {
    minimumFractionDigits: decimals,
  });

  return `${formattedNum}\u00A0${unit}`;
};
