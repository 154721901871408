import { routerUrls } from '@/shared/config/routes';

import { IPresentedElement } from '../../types';
import { PresentedElementModel } from '../PresentedElementModel';

import { BasePageModel, BasePageModelParams } from './BasePageModel';

const T_OPTIONS = { ns: 'onboarding' } as const;

export class ChatsPageModel extends BasePageModel {
  readonly header = new PresentedElementModel<HTMLDivElement>({
    title: (t) => t('chats.header.title', T_OPTIONS),
    description: (t) => t('chats.header.description', T_OPTIONS),
  });

  constructor(params: BasePageModelParams) {
    super({
      ...params,
      routeKey: 'chats',
      url: routerUrls.chats.create(),
    });
  }

  get elements(): Array<IPresentedElement> {
    return [this.header] as Array<IPresentedElement>;
  }
}
