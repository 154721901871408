import { DocumentFileType, UploadOrGenerateDocumentModel, UploadOrGenerateDocumentModelParams } from '@/entities/file';
import { SupplierSpecificationDocPayload } from '@/entities/stage/types';

import { SupplierSpecificationFieldsModel } from './SupplierSpecificationFieldsModel';

export class SupplierSpecificationModel extends UploadOrGenerateDocumentModel<
  DocumentFileType.supplierSpecification,
  SupplierSpecificationDocPayload,
  SupplierSpecificationFieldsModel
> {
  readonly generationFields: SupplierSpecificationFieldsModel;

  constructor(params: UploadOrGenerateDocumentModelParams<DocumentFileType.supplierSpecification>) {
    super(params);

    this.generationFields = new SupplierSpecificationFieldsModel(params);
  }
}
