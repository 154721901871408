import { DocumentFileType } from '@/entities/file';
import {
  ManagerAwbDestinationAction,
  ManagerSupplierAcceptanceCertificateVerifyingAction,
  ManagerTransportationAction,
  ManagerTransportationStageDataServer,
  ManagerTransportationStep,
  ShipmentType,
  StageModelJsonParams,
  StageType,
} from '@/entities/stage/types';
import { mapStepToNumber } from '@/entities/stage/utils';

import { BaseManagerStageModel, BaseManagerStageModelParams } from '../../BaseManagerStageModel';
import {
  ConfirmationStepModel,
  DestinationCountryDocumentsStepModel,
  DocumentVerifyingStepModel,
  SelectDateStepModel,
  UploadAwbOrFlightNumberStepModel,
  UploadFilesStepModel,
} from '../../fieldsModel';

import { stepsOrder } from './config';

type Params = BaseManagerStageModelParams<ManagerTransportationStep> & {
  transitCountryCargoArrivalFields: SelectDateStepModel<ManagerTransportationStep, ManagerTransportationAction>;
  cargoArrivalFields: SelectDateStepModel<ManagerTransportationStep, ManagerTransportationAction>;

  supplierAcceptanceCertificateFields: UploadFilesStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction,
    DocumentFileType.supplierAcceptanceCertificate
  >;
  supplierAcceptanceCertificateVerifyingFields: DocumentVerifyingStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  supplierSignedAcceptanceCertificateFields: UploadFilesStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction,
    DocumentFileType.signedSupplierAcceptanceCertificate
  >;

  destinationCountryDocsFields: DestinationCountryDocumentsStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  destinationCountryAwbFields: UploadAwbOrFlightNumberStepModel<
    ManagerTransportationStep,
    ManagerAwbDestinationAction.uploadFile,
    ManagerAwbDestinationAction.flightNumber,
    DocumentFileType.awbForDestinationCountry
  >;
  transitCountryCargoShipmentFields: ConfirmationStepModel<ManagerTransportationStep, ManagerTransportationAction>;
  destinationCountryCargoArrivalFields: SelectDateStepModel<ManagerTransportationStep, ManagerTransportationAction>;
};

export class ManagerTransportationStageModel extends BaseManagerStageModel<
  StageType.transportation,
  ManagerTransportationStep
> {
  readonly transitCountryCargoArrivalFields: SelectDateStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  readonly cargoArrivalFields: SelectDateStepModel<ManagerTransportationStep, ManagerTransportationAction>;

  readonly supplierAcceptanceCertificateFields: UploadFilesStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction,
    DocumentFileType.supplierAcceptanceCertificate
  >;
  readonly supplierAcceptanceCertificateVerifyingFields: DocumentVerifyingStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  readonly supplierSignedAcceptanceCertificateFields: UploadFilesStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction,
    DocumentFileType.signedSupplierAcceptanceCertificate
  >;

  readonly destinationCountryDocsFields: DestinationCountryDocumentsStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  readonly destinationCountryAwbFields: UploadAwbOrFlightNumberStepModel<
    ManagerTransportationStep,
    ManagerAwbDestinationAction.uploadFile,
    ManagerAwbDestinationAction.flightNumber,
    DocumentFileType.awbForDestinationCountry
  >;
  readonly transitCountryCargoShipmentFields: ConfirmationStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;
  readonly destinationCountryCargoArrivalFields: SelectDateStepModel<
    ManagerTransportationStep,
    ManagerTransportationAction
  >;

  constructor(params: Params) {
    super({
      type: StageType.transportation,
      ...params,
    });

    this.transitCountryCargoArrivalFields = params.transitCountryCargoArrivalFields;
    this.cargoArrivalFields = params.cargoArrivalFields;
    this.supplierAcceptanceCertificateFields = params.supplierAcceptanceCertificateFields;
    this.supplierAcceptanceCertificateVerifyingFields = params.supplierAcceptanceCertificateVerifyingFields;
    this.supplierSignedAcceptanceCertificateFields = params.supplierSignedAcceptanceCertificateFields;
    this.destinationCountryDocsFields = params.destinationCountryDocsFields;
    this.destinationCountryAwbFields = params.destinationCountryAwbFields;
    this.transitCountryCargoShipmentFields = params.transitCountryCargoShipmentFields;
    this.destinationCountryCargoArrivalFields = params.destinationCountryCargoArrivalFields;
  }

  get currentStep(): number {
    return mapStepToNumber({ step: this.step, steps: this.steps, stepsOrder });
  }

  get steps(): ManagerTransportationStep[] {
    let steps: ManagerTransportationStep[] = [];

    /** Шаги для загрузки и верификации акта приема-передачи, подписаннной с одной стороны */
    const supplierAcceptanceCertificateSteps = [
      ManagerTransportationStep.supplierAcceptanceCertificate,
      /** Если нужна верификация акта, то добавляем шаг верификации */
      ...(this.supplierAcceptanceCertificateFields.isNeedVerifying
        ? [ManagerTransportationStep.supplierAcceptanceCertificateVerifying]
        : []),
    ];

    if (this.isShipmentTypeTransit) {
      steps = [
        ManagerTransportationStep.transitCountryCargoArrival,
        ...supplierAcceptanceCertificateSteps,
        ManagerTransportationStep.supplierSignedAcceptanceCertificate,
        ManagerTransportationStep.destinationCountryDocs,
        ManagerTransportationStep.destinationCountryAwb,
        ManagerTransportationStep.transitCountryCargoShipment,
        ManagerTransportationStep.destinationCountryCargoArrival,
      ];
    }

    if (this.isShipmentTypeDirect) {
      steps = [
        ManagerTransportationStep.cargoArrival,
        ...supplierAcceptanceCertificateSteps,
        ManagerTransportationStep.supplierSignedAcceptanceCertificate,
      ];
    }

    if (this.step === ManagerTransportationStep.transfer) {
      steps.push(ManagerTransportationStep.transfer);
    }

    return steps;
  }

  static fromJson({
    dataServer,
    tradeWorkflowStore,
  }: StageModelJsonParams<ManagerTransportationStageDataServer>): ManagerTransportationStageModel {
    const { transitCountryCargoArrivalDate, destinationCountryCargoArrivalDate } = tradeWorkflowStore.tradeInfo;
    const { steps } = dataServer;

    return new ManagerTransportationStageModel({
      transitCountryCargoArrivalFields: SelectDateStepModel.fromJson({
        data: steps[ManagerTransportationStep.transitCountryCargoArrival]?.date ?? transitCountryCargoArrivalDate,
        step: ManagerTransportationStep.transitCountryCargoArrival,
        approveAction: ManagerTransportationStep.transitCountryCargoArrival,
        tradeWorkflowStore,
      }),
      cargoArrivalFields: SelectDateStepModel.fromJson({
        data: steps[ManagerTransportationStep.cargoArrival]?.date ?? destinationCountryCargoArrivalDate,
        step: ManagerTransportationStep.cargoArrival,
        approveAction: ManagerTransportationStep.cargoArrival,
        tradeWorkflowStore,
      }),
      supplierAcceptanceCertificateFields: UploadFilesStepModel.fromJson({
        data: steps[ManagerTransportationStep.supplierAcceptanceCertificate],
        step: ManagerTransportationStep.supplierAcceptanceCertificate,
        approveAction: ManagerTransportationStep.supplierAcceptanceCertificate,
        docType: DocumentFileType.supplierAcceptanceCertificate,
        tradeWorkflowStore,
      }),
      supplierAcceptanceCertificateVerifyingFields: DocumentVerifyingStepModel.fromJson({
        data: steps[ManagerTransportationStep.supplierAcceptanceCertificate],
        step: ManagerTransportationStep.supplierAcceptanceCertificateVerifying,
        approveAction: ManagerSupplierAcceptanceCertificateVerifyingAction.approveSupplierAcceptanceCertificate,
        rejectAction: ManagerSupplierAcceptanceCertificateVerifyingAction.rejectSupplierAcceptanceCertificate,
        tradeWorkflowStore,
      }),
      supplierSignedAcceptanceCertificateFields: UploadFilesStepModel.fromJson({
        data: steps[ManagerTransportationStep.supplierSignedAcceptanceCertificate],
        step: ManagerTransportationStep.supplierSignedAcceptanceCertificate,
        approveAction: ManagerTransportationStep.supplierSignedAcceptanceCertificate,
        docType: DocumentFileType.signedSupplierAcceptanceCertificate,
        tradeWorkflowStore,
      }),
      destinationCountryDocsFields: DestinationCountryDocumentsStepModel.fromJson({
        shipmentType: ShipmentType.transit,
        data: steps[ManagerTransportationStep.destinationCountryDocs],
        step: ManagerTransportationStep.destinationCountryDocs,
        approveAction: ManagerTransportationStep.destinationCountryDocs,
        tradeWorkflowStore,
      }),
      destinationCountryAwbFields: UploadAwbOrFlightNumberStepModel.fromServer({
        data: steps[ManagerTransportationStep.destinationCountryAwb],
        step: ManagerTransportationStep.destinationCountryAwb,
        approveFileAction: ManagerAwbDestinationAction.uploadFile,
        approveNumberAction: ManagerAwbDestinationAction.flightNumber,
        docType: DocumentFileType.awbForDestinationCountry,
        tradeWorkflowStore,
      }),
      transitCountryCargoShipmentFields: ConfirmationStepModel.fromJson({
        data: steps[ManagerTransportationStep.transitCountryCargoShipment]?.shipped,
        step: ManagerTransportationStep.transitCountryCargoShipment,
        approveAction: ManagerTransportationStep.transitCountryCargoShipment,
        tradeWorkflowStore,
      }),
      destinationCountryCargoArrivalFields: SelectDateStepModel.fromJson({
        data:
          steps[ManagerTransportationStep.destinationCountryCargoArrival]?.date ?? destinationCountryCargoArrivalDate,
        step: ManagerTransportationStep.destinationCountryCargoArrival,
        approveAction: ManagerTransportationStep.destinationCountryCargoArrival,
        tradeWorkflowStore,
      }),

      ...BaseManagerStageModel.paramsFromJson({ dataServer, tradeWorkflowStore }),
    });
  }
}
