import { CompanyType } from '@/entities/company';

import { mapUserServerToCompanyType, mapUserServerToUserRole } from '../../config';
import { IUser, UserRole, UserServer } from '../../types';
import { BaseUserModel, BaseUserModelParams } from '../BaseUserModel';

type UserModelParams<CT, UR> = BaseUserModelParams & {
  type: CT;
  role: UR;
};

export class UserModel<CT extends CompanyType = CompanyType, UR extends UserRole = UserRole>
  extends BaseUserModel
  implements IUser<CT, UR>
{
  readonly role: UR;
  readonly type: CT;
  readonly verified = null;
  readonly kycStatus = null;

  constructor(params: UserModelParams<CT, UR>) {
    super(params);

    this.role = params.role;
    this.type = params.type;
  }

  static fromServer(server: UserServer): UserModel {
    return new UserModel({
      ...BaseUserModel.baseUserParamsFromJson(server),
      role: mapUserServerToUserRole(server),
      type: mapUserServerToCompanyType(server),
    });
  }
}
