import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { DateFormat } from '@/shared/types/meta';
import { Nullable } from '@/shared/types/values';
import { ColumnsData } from '@/shared/ui/Table';
import { formatDate } from '@/shared/utils';

import { IVerificationHistory, IVerificationStatusTableData } from '../../types/client';
import { VerificationStatusStepServer, VerificationStep } from '../../types/server';
import { BaseVerificationStepModel } from '../BaseVerificationStepModel';

import { getColumns } from './config';

type Params = {
  history: Array<IVerificationHistory>;
};

export class VerificationStatusStepModel extends BaseVerificationStepModel<VerificationStep.verificationStatus, null> {
  readonly step = VerificationStep.verificationStatus;
  readonly history: Array<IVerificationHistory>;

  constructor({ history }: Params) {
    super({ step: VerificationStep.verificationStatus });

    this.history = history;
  }

  get columns(): ColumnsData<IVerificationStatusTableData> {
    return getColumns();
  }

  get rows(): Array<IVerificationStatusTableData> {
    return this.history.map((item) => ({
      datetime: formatDate(item.datetime, DateFormat.dateTime, true),
      status: item.status,
      comment: item.comment ?? '-',
    }));
  }

  toJson(): null {
    return null;
  }

  protected get _fields(): Array<BaseFieldModel<any>> {
    return [];
  }

  static fromJson(server: Nullable<VerificationStatusStepServer>): VerificationStatusStepModel {
    return new VerificationStatusStepModel({
      history: server?.history
        ? server.history.map<IVerificationHistory>((next) => ({
            ...next,
            rejectedSteps: next.rejected_steps ?? [],
          }))
        : [],
    });
  }
}
