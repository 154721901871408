import * as React from 'react';

import { TypographyTextWithTooltip } from '@/shared/ui';
import { ColumnsData } from '@/shared/ui/Table';

import { IVerificationStatusTableData, VerificationStatus } from '../../types';
import { VerificationStatusView } from '../../ui';

const T_OPTIONS = { ns: 'verification' } as const;

export const getColumns = (): ColumnsData<IVerificationStatusTableData> => [
  {
    title: <TypographyTextWithTooltip text={(t) => t('content.verificationStatusStep.table.dataTime', T_OPTIONS)} />,
    dataIndex: 'datetime',
    key: 'datetime',
    width: 190,
    ellipsis: true,
  },
  {
    title: <TypographyTextWithTooltip text={(t) => t('content.verificationStatusStep.table.status', T_OPTIONS)} />,
    dataIndex: 'status',
    key: 'status',
    width: 190,
    ellipsis: true,
    render: (status: VerificationStatus) => <VerificationStatusView status={status} />,
  },
  {
    title: <TypographyTextWithTooltip text={(t) => t('content.verificationStatusStep.table.comments', T_OPTIONS)} />,
    dataIndex: 'comment',
    key: 'comment',
    width: 190,
    ellipsis: true,
    render: (comment: string) => <TypographyTextWithTooltip text={comment} />,
  },
];
