import { Nullable } from '@/shared/types/values';

import { StatutoryDocsStepServer, VerificationStep } from '../../types/server';
import { BaseVerificationFieldsModel } from '../BaseVerificationFieldsModel';
import { BaseVerificationStepModel } from '../BaseVerificationStepModel';

import { EAEUCountryDocsModel } from './EAEUCountryDocsModel';
import { NonEAEUCountryDocsModel } from './NonEAEUCountryDocsModel';

type Params = {
  documentsFields: EAEUCountryDocsModel | NonEAEUCountryDocsModel;
};

export class StatutoryDocsStepModel extends BaseVerificationStepModel<
  VerificationStep.statutoryDocs,
  null,
  BaseVerificationFieldsModel<null>
> {
  readonly documentsFields: EAEUCountryDocsModel | NonEAEUCountryDocsModel;

  constructor(params: Params) {
    super({ step: VerificationStep.statutoryDocs });

    this.documentsFields = params.documentsFields;
  }

  toJson(): null {
    return null;
  }

  protected get _fields(): Array<BaseVerificationFieldsModel<null>> {
    return [this.documentsFields];
  }

  static fromJson(server: Nullable<StatutoryDocsStepServer>): StatutoryDocsStepModel {
    let documentsFields: EAEUCountryDocsModel | NonEAEUCountryDocsModel;

    switch (server?.company_from_eaeu) {
      case true:
        documentsFields = EAEUCountryDocsModel.fromJson(server);

        break;

      case false:
        documentsFields = NonEAEUCountryDocsModel.fromJson(server);

        break;

      default:
        documentsFields = EAEUCountryDocsModel.fromJson(null);
    }

    return new StatutoryDocsStepModel({
      documentsFields,
    });
  }
}
