import { NotificationCategory, NotificationService } from '@/entities/notification';
import { ApiErrorCode } from '@/shared/types/api';

export const notifications = {
  en: {
    category: {
      [NotificationCategory.statusChange]: 'Trade status changes',
      [NotificationCategory.docUpload]: 'Document upload updates',
      [NotificationCategory.docVerification]: 'Confirmation of document uploading from managers',
      [NotificationCategory.bidInfo]: 'Information about bids',
    },
    service: {
      [NotificationService.telegram]: 'To Telegram',
      [NotificationService.whatsapp]: 'To WhatsApp',
      [NotificationService.email]: 'To email',
      [NotificationService.chat]: 'In the chat',
      chatTooltip: 'Notifications to chats are currently only available for information about applications',
    },
    list: {
      actions: {
        readAll: 'Mark all as read',
        typeFilter: 'Filter by type',
        settings: 'Notification settings',
        rangePickerPlaceholder: 'Select date',
        clearList: 'Clear list',
      },
      emptyList: 'While the notification list is empty',
      emptyFilteredList: 'No notifications found matching these filters',
      messages: {
        fetchError: 'Failed to fetch notifications',
        readAllSuccess: 'All notifications marked as read',
        readAllError: 'Failed to mark all notifications as read',
        readOneSuccess: 'Notification marked as read',
        readOneError: 'Failed to mark notification as read',
      },
    },
    settings: {
      backButton: 'Back',
      title: 'Notification settings',
      accounts: {
        [NotificationService.email]: 'My email',
        [NotificationService.telegram]: 'My Telegram',
        [NotificationService.whatsapp]: 'My WhatsApp',
        [NotificationService.chat]: null,
      },
      sectionHeadings: {
        whereToSend: 'Where to send',
        whatToSend: 'What to send',
      },
      emailList: {
        label: 'To whom:',
        description: 'Mailing addresses for receiving copies of documents',
        tooltip: 'To add an email address, enter your email in the input field and press Enter',
        [ApiErrorCode.emailAlreadyExists]: 'The email address is already on the list',
      },
      telegramModal: {
        title: 'Setting up notifications in Telegram',
        stepTitle: 'Step {{step}}',
        goToBotDescription: 'Go to the bot in Telegram',
        startBotDescription: 'In the bot, click the /start button',
        waitBotDescription: 'Waiting for consent...',
        goToBotButton: 'Go to the bot',
        successButton: 'Ready',
        alert: {
          success: 'Bot connection was successful!',
          error: 'Try later',
        },
      },
      unsubscribeModal: {
        title: 'Confirm to turn off notifications',
        description: 'Are you sure you want to turn off all notifications? You may miss important updates',
        okText: 'Turn off all',
      },
      messages: {
        fetchError: 'Failed to fetch notification settings',
        fetchTelegramBotLinkError: 'Failed to get link to bot in Telegram',
        saveWhatsAppPhoneError: 'Failed to save WhatsApp phone',
        changeError: 'Failed to change notification settings',
        changeSuccess: 'Notification settings changed successfully',
      },
    },
    language: {
      title: 'Notification language',
    },
  },
  ru: {
    category: {
      [NotificationCategory.statusChange]: 'Изменения статуса заказов',
      [NotificationCategory.docUpload]: 'Обновления по загрузке документов',
      [NotificationCategory.docVerification]: 'Подтверждения по загрузке документов от менеджеров',
      [NotificationCategory.bidInfo]: 'Информация о заявках',
    },
    service: {
      [NotificationService.telegram]: 'В Телеграм',
      [NotificationService.whatsapp]: 'В WhatsApp',
      [NotificationService.email]: 'На почту',
      [NotificationService.chat]: 'В чат',
      chatTooltip: 'Уведомления в чаты пока доступны только по информации о заявках',
    },
    list: {
      actions: {
        readAll: 'Отметить все как прочитанные',
        typeFilter: 'Фильтровать по типу',
        settings: 'Настройки уведомлений',
        rangePickerPlaceholder: 'Выберите дату',
        clearList: 'Очистить список',
      },
      emptyList: 'Пока список уведомлений пуст',
      emptyFilteredList: 'По данным фильтрам уведомления не найдены',
      messages: {
        fetchError: 'Ошибка получения уведомлений',
        readAllSuccess: 'Все уведомления отмечены как прочитанные',
        readAllError: 'Не удалось отметить все уведомления как прочитанные',
        readOneSuccess: 'Уведомление отмечено как прочитанное',
        readOneError: 'Не удалось отметить уведомление как прочитанное',
      },
    },
    settings: {
      backButton: 'Назад',
      title: 'Настройки уведомлений',
      accounts: {
        [NotificationService.email]: 'Мой почтовый ящик',
        [NotificationService.telegram]: 'Мой Телеграм',
        [NotificationService.whatsapp]: 'Мой WhatsApp',
        [NotificationService.chat]: null,
      },
      sectionHeadings: {
        whereToSend: 'Куда присылать',
        whatToSend: 'Что присылать',
      },
      emailList: {
        label: 'Кому:',
        description: 'Почтовые адреса для получения копий документов',
        tooltip: 'Для добавления почтового адреса введите email в поле ввода и нажмите Enter',
        [ApiErrorCode.emailAlreadyExists]: 'Электронная почта уже есть в списке',
      },
      telegramModal: {
        title: 'Настройка уведомлений в Telegram',
        stepTitle: 'Шаг {{step}}',
        goToBotDescription: 'Перейдите в бота в Telegram',
        startBotDescription: 'В боте нажмите кнопку /start',
        waitBotDescription: 'Ожидание согласия...',
        goToBotButton: 'Перейти в бота',
        successButton: 'Готово',
        alert: {
          success: 'Подключение бота прошло успешно!',
          error: 'Попробуйте позже',
        },
      },
      unsubscribeModal: {
        title: 'Подтвердите отключение уведомлений',
        description: 'Вы уверены, что хотите отключить все уведомления? Вы можете пропустить важные обновления',
        okText: 'Отключить все',
      },
      messages: {
        fetchError: 'Не удалось получить настройки уведомлений',
        fetchTelegramBotLinkError: 'Не удалось получить ссылку на бота в Telegram',
        saveWhatsAppPhoneError: 'Не удалось сохранить номер WhatsApp',
        changeError: 'Не удалось изменить настройки уведомлений',
        changeSuccess: 'Настройки уведомлений изменены',
      },
    },
    language: {
      title: 'Язык уведомлений',
    },
  },
  zh: {
    category: {
      [NotificationCategory.statusChange]: '订单状态变更',
      [NotificationCategory.docUpload]: '文件上传更新',
      [NotificationCategory.docVerification]: '经理确认文件上传',
      [NotificationCategory.bidInfo]: '申请信息',
    },
    service: {
      [NotificationService.telegram]: '电报',
      [NotificationService.whatsapp]: null,
      [NotificationService.email]: '电邮',
      [NotificationService.chat]: '聊天窗中',
      chatTooltip: '聊天窗中的通知目前仅适用于申请信息',
    },
    list: {
      actions: {
        readAll: '全部标记为已读',
        typeFilter: '按类别过滤',
        settings: '通知设置',
        rangePickerPlaceholder: '选择日期',
        clearList: '清除列表',
      },
      emptyList: '通知列表为空',
      emptyFilteredList: '根据这些过滤未找到任何通知',
      messages: {
        fetchError: '通知接收错误',
        readAllSuccess: '所有通知均标记为已读',
        readAllError: '无法将所有通知标记为已读',
        readOneSuccess: '通知标记为已读',
        readOneError: '无法将通知标记为已读',
      },
    },
    settings: {
      backButton: '退回',
      title: '通知设置',
      accounts: {
        [NotificationService.email]: '我的邮箱',
        [NotificationService.telegram]: '我的Telegram',
        [NotificationService.whatsapp]: '我的WhatsApp',
        [NotificationService.chat]: null,
      },
      sectionHeadings: {
        whereToSend: '发送到哪里',
        whatToSend: '发送什么',
      },
      emailList: {
        label: '发给:',
        description: '用于接收通知副本的邮寄地址',
        tooltip: '要添加电子邮件地址，请在输入字段中输入您的email，然后按Enter',
        [ApiErrorCode.emailAlreadyExists]: '电子邮件已在列表中',
      },
      telegramModal: {
        title: 'Н在Telegram中设置通知',
        stepTitle: '步骤{{step}}',
        goToBotDescription: '去Telegram自动程序',
        startBotDescription: '在自动程序中，单击/开始',
        waitBotDescription: '等待同意…',
        goToBotButton: '去到自动程序',
        successButton: '完成了',
        alert: {
          success: '自动程序连接成功了!',
          error: '稍后再试',
        },
      },
      unsubscribeModal: {
        title: '确认关闭通知',
        description: '您确定要关闭所有通知吗？您可能会错过重要更新',
        okText: '全部禁用',
      },
      messages: {
        fetchError: '获取通知设置失败',
        fetchTelegramBotLinkError: '在Telegram中找不到自动程序的链接',
        saveWhatsAppPhoneError: null,
        changeError: '更改通知设置失败',
        changeSuccess: '通知设置已更改',
      },
    },
    language: {
      title: '通知语言',
    },
  },
};
