import { BaseFieldModel } from '@/shared/model/form/BaseFieldModel';
import { RadioListModel } from '@/shared/model/form/RadioListModel';
import { SelectModel } from '@/shared/model/form/SelectModel';
import { Nullable } from '@/shared/types/values';
import { emptyValueValidator } from '@/shared/utils/validators';

import { residentTypeOptions } from '../../config';
import { ResidencyStepPayload, ResidencyStepServer, ResidentType, VerificationStep } from '../../types/server';
import { BaseVerificationStepModel } from '../BaseVerificationStepModel';

type Params = {
  residentType: RadioListModel<ResidentType>;
  countryId: SelectModel<number>;
};

const T_OPTIONS = { ns: 'verification' } as const;

export class ResidencyStepModel extends BaseVerificationStepModel<VerificationStep.residency, ResidencyStepPayload> {
  readonly residentType: RadioListModel<ResidentType>;
  readonly countryId: SelectModel<number>;

  constructor(params: Params) {
    super({ step: VerificationStep.residency });

    this.residentType = params.residentType;
    this.countryId = params.countryId;
  }

  toJson(): Partial<ResidencyStepPayload> {
    const type = this.residentType.value;
    const country_id = this.countryId.value;

    return {
      ...(type ? { type } : {}),
      ...(country_id ? { country_id } : {}),
    };
  }

  protected get _fields(): Array<BaseFieldModel<any>> {
    return [this.residentType, this.countryId];
  }

  static fromJson(server: Nullable<ResidencyStepServer>): ResidencyStepModel {
    return new ResidencyStepModel({
      residentType: new RadioListModel({
        initialValue: server?.type ?? null,
        label: (t) => t('content.residencyStep.residency', T_OPTIONS),
        options: residentTypeOptions,
        required: true,
        validators: [emptyValueValidator()],
      }),
      countryId: new SelectModel({
        initialValue: server?.country_id ?? null,
        label: (t) => t('content.residencyStep.countryId', T_OPTIONS),
        required: true,
        validators: [emptyValueValidator()],
      }),
    });
  }
}
