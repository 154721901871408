import { computed, makeObservable } from 'mobx';

import { IRootStore } from '@/app/store';
import { Nullable } from '@/shared/types/values';
import { formatNumber } from '@/shared/utils';

import { ITicker } from '../types/client';
import { TickerServer, TickerType } from '../types/server';

import { UnitDictionaryModel } from './UnitDictionaryModel';

type TickerDictionaryModelParams = {
  date: Date;
  tickerType: TickerType;
  current: number;
  previous: Nullable<number>;
  currency: ITicker['currency'];
  localizationStore: IRootStore['localizationStore'];
};

export class TickerModel implements ITicker {
  readonly date: Date;
  readonly tickerType: TickerType;
  readonly current: number;
  readonly previous: Nullable<number>;
  readonly currency: ITicker['currency'];

  private readonly _localizationStore: IRootStore['localizationStore'];

  constructor(params: TickerDictionaryModelParams) {
    this.date = params.date;
    this.tickerType = params.tickerType;
    this.current = params.current;
    this.previous = params.previous;
    this.currency = params.currency;

    this._localizationStore = params.localizationStore;

    makeObservable(this, {
      isPositive: computed,
      isNegative: computed,
      formatedDifference: computed,
      formatedCurrent: computed,
    });
  }

  private get _difference(): number {
    return this.current - (this.previous ?? this.current);
  }

  get isPositive(): boolean {
    return this._difference >= 0;
  }

  get isNegative(): boolean {
    return this._difference < 0;
  }

  get formatedDifference(): string {
    return formatNumber(this._difference, {
      language: this._localizationStore.lng,
    });
  }

  get formatedCurrent(): Nullable<string> {
    const currency = this.currency;

    return (
      currency &&
      formatNumber(this.current, {
        language: this._localizationStore.lng,
        unit: currency.label,
      })
    );
  }

  static fromJson(server: TickerServer, localizationStore: IRootStore['localizationStore']): TickerModel {
    return new TickerModel({
      date: new Date(server.date),
      tickerType: server.ticker,
      current: server.current,
      previous: server.previous,
      currency: server.currency && UnitDictionaryModel.fromJson(server.currency),
      localizationStore,
    });
  }
}
