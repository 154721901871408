import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { useRootStore } from '@/app/store';
import { StepDescription } from '@/shared/ui';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { PaymentBillModel, PaymentStageModel, UploadOrGenerateDocumentStepModel } from '../../model';
import { StepComponentProps } from '../../types';
import PaymentBill from '../PaymentBill';

const PaymentBillStep = <Model extends PaymentStageModel>({
  model,
  stepKey,
  description,
}: StepComponentProps<Model>) => {
  const { userStore } = useRootStore();
  const step = stepKey && model[stepKey];

  const isPaymentBillStep =
    step instanceof UploadOrGenerateDocumentStepModel && step.uploadOrGenerateDocument instanceof PaymentBillModel;

  if (!isPaymentBillStep) {
    return null;
  }

  return (
    <StepDescription description={description}>
      <PaymentBill model={step.uploadOrGenerateDocument} disabled={step.isDisabled || step.isLoading} />
      <ApproveButton
        loading={step.isLoading}
        disabled={step.isDisabled || step.approveDisabled}
        onClick={step.approveStep}
        block={userStore.isSupplier}
      />
    </StepDescription>
  );
};

export default observer(PaymentBillStep);
