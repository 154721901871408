import {
  DictionaryServer,
  PriceProvider,
  ProductMetalType,
  TickerType,
  UnitDictionaryServer,
} from '@/entities/dictionary';
import { Nullable } from '@/shared/types/values';

export enum StockMarketWsEventType {
  productPrice = 'product_price',
}

type ProductMetal = Omit<UnitDictionaryServer<number, ProductMetalType>, 'name'>;

export type ProductPriceWsBody = {
  price_provider: PriceProvider;
  ticker: TickerType;
  date: string;
  current: number;
  previous: number;
  weight: DictionaryServer;
  product: Nullable<ProductMetal>;
  currency: Nullable<UnitDictionaryServer>;
};

export type ProductPriceWsEvent = {
  type: StockMarketWsEventType.productPrice;
  body: ProductPriceWsBody;
};

export type StockMarketWsEvents = ProductPriceWsEvent;
