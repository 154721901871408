import { CheckCircleFilled } from '@ant-design/icons';
import { DatePicker } from 'antd';
import { observer } from 'mobx-react-lite';
import * as React from 'react';

import { StepDescription } from '@/shared/ui';
import { ApproveButton } from '@/shared/ui/form/buttons';

import { SelectDateStepModel } from '../../model/fieldsModel/SelectDateStepModel';
import { StageModel, StepComponentProps } from '../../types';

const SelectDateStep = <Model extends StageModel>({ model, stepKey, description }: StepComponentProps<Model>) => {
  const step = stepKey && model[stepKey];

  if (!(step instanceof SelectDateStepModel)) {
    return null;
  }

  return (
    <StepDescription description={description}>
      <DatePicker
        style={{ width: '50%' }}
        {...step.date.props}
        suffixIcon={!step.approveDisabled && <CheckCircleFilled />}
        disabled={step.isDisabled || step.isLoading}
      />
      <ApproveButton
        loading={step.isLoading}
        disabled={step.isDisabled || step.approveDisabled}
        onClick={step.approveStep}
      />
    </StepDescription>
  );
};

export default observer(SelectDateStep);
