import { TagConfig } from '@/shared/ui';

import { mapClientCompanyTypeToLabel } from '../../config';
import { ClientCompanyType, CompanyType } from '../../types/server';

export const companyTypeTagMap: Record<ClientCompanyType, TagConfig> = {
  [CompanyType.customer]: {
    color: 'orange',
    label: mapClientCompanyTypeToLabel(CompanyType.customer),
  },
  [CompanyType.supplier]: {
    color: 'geekblue',
    label: mapClientCompanyTypeToLabel(CompanyType.supplier),
  },
};
